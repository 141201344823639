import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import ItemContainers from "../components/item-containers";
import Call from "../hocs/call";
import {fetchHubItemContainers} from "../state/hub/hubActions";
import {nodesSelector} from "../state/hub/hubSelectors";
import {fetchNodeItemContainers} from "../state/node/nodeActions";
import themeConfig from "../theme-config/config.json";

const ItemContainersDomain = props => {
  const {itemContainerId, nodeCode} = useParams();
  const nodes = useSelector(nodesSelector);
  const nodeId = nodes.find(n => n.code === nodeCode)?.nodeId;
  const dispatch = useDispatch();
  const location = useLocation();
  const dashboards = props.itemContainers?.dashboard;
  const customPages = props.itemContainers?.customPages;
  return (
    <>
      <Call
        cb={() =>
          dispatch(nodeId ? fetchNodeItemContainers(nodeCode, "dashboard") : fetchHubItemContainers("dashboard"))
        }
        disabled={!location.pathname.includes("dashboards") || !!dashboards?.length || !themeConfig.enableNewDashboard}
      >
        <ItemContainers
          {...props}
          itemContainerId={props.itemContainerId || itemContainerId}
          itemContainers={location.pathname.includes("dashboards") ? dashboards : customPages}
        />
      </Call>
    </>
  );
};

export default ItemContainersDomain;
