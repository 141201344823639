import React from "react";
import {Box, makeStyles, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {configSelector} from "../../state/config/configSelectors";

interface CategoryTitleSectionProps {
  imageUrl: string;
  description: string;
  label: string;
}

const useStyles = makeStyles(() => ({
  sectionImage: {
    paddingRight: "50px",
    height: "100px"
  },
  sectionTitle: {
    fontSize: "32px"
  },
  sectionDescription: {
    fontSize: "24px",
    marginBottom: "0px"
  }
}));

const CategoryTitleSection = ({imageUrl, description, label}: CategoryTitleSectionProps) => {
  const classes = useStyles();
  const baseUrl = useSelector(configSelector).baseURL;
  return (
    <>
      <Box sx={{display: "flex", alignItems: "center", padding: "20px"}}>
        {imageUrl && (
          <img
            id="category_title_section_image"
            alt="selected category"
            className={classes.sectionImage}
            src={baseUrl + imageUrl}
          ></img>
        )}
        <Typography component="h2" className={classes.sectionTitle} id="category_title_section_title">
          {label}
        </Typography>
      </Box>
      {description && (
        <Typography component="p" id="category_title_section_description" className={classes.sectionDescription}>
          {description}
        </Typography>
      )}
    </>
  );
};

export default CategoryTitleSection;
