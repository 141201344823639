import {Reducer} from "redux";
import {INIT} from "../rootActions";

export type ItemContainerItemState = any | null;

const itemContainerReducer: Reducer<ItemContainerItemState> = (state = null, action) => {
  switch (action.type) {
    case INIT: {
      return action.payload.itemContainerFilterConfig;
    }
    default:
      return state;
  }
};

export default itemContainerReducer;
