import React, {Fragment} from "react";
import {withStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import CustomEmpty from "../custom-empty";
import Footer from "../footer";
import ModuleLoader from "../module-loader";
import NodeHeader from "../node-header";
import Page from "../page";
import {getPageTitle} from "../../utils/other";

const styles = theme => ({
  root: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    padding: "0 !important"
  },
  fullWidthContainer: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "column"
  }
});

const mapStateToProps = state => ({
  hub: state.hub,
  node: state.node,
  catalog: state.catalog
});

function ModulePage(props) {
  const {
    classes,

    moduleId,
    moduleComponent,
    moduleFallback,
    hideAppBar,
    hideFooter,
    isDefault,
    containerMaxWidth = "lg",

    hub,
    node,
    catalog
  } = props;
  const {t} = useTranslation();
  const location = useLocation();
  const params = useParams();

  return (
    <Fragment>
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page title={getPageTitle([], t)}>
        <div key={moduleId} className={classes.root}>
          {!hideAppBar && (
            <NodeHeader
              noNode={!node}
              hub={hub.hub}
              nodes={hub.nodes}
              node={node ? node : undefined}
              nodeId={node ? node.nodeId : undefined}
              isDefault={node ? isDefault : undefined}
              catalog={node ? catalog : undefined}
              title={node ? node.name : undefined}
            />
          )}
          <div id="main" className={classes.fullWidthContainer}>
            <Container maxWidth={containerMaxWidth}>
              <div id={`${node ? "node" : "hub"}__${moduleId}`}>
                <ModuleLoader
                  id={moduleId}
                  component={moduleComponent}
                  fallback={
                    <CustomEmpty
                      text={moduleFallback || t("components.modulePage.suspense.text") + "..."}
                      style={{padding: 48}}
                    />
                  }
                  urlParams={params}
                />
              </div>
            </Container>
            {!hideFooter && <Footer />}
          </div>
        </div>
      </Page>
    </Fragment>
  );
}

export default compose(connect(mapStateToProps), withStyles(styles))(ModulePage);
