import React from "react";
import {Box} from "@material-ui/core";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemRichTextDto} from "../../model/item-containers-models/itemRichTextDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import ItemContainerCol from "./ItemContainerCol";

interface ItemContainerRowProps {
  itemContainer: ItemContainerDto;
  row: (ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto)[];
  rowIdx: number;
}
const ItemContainerRow = ({itemContainer, row, rowIdx}: ItemContainerRowProps) => {
  return (
    <Box sx={{width: "100%"}} className="itemContainer_row">
      {row.map((col, idx) => (
        <ItemContainerCol
          key={col.itemId}
          itemContainer={itemContainer}
          rowIdx={rowIdx}
          colIdx={idx}
          itemContainerElem={col}
        />
      ))}
    </Box>
  );
};

export default ItemContainerRow;
