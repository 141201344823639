import React from "react";
import {Box, FormControlLabel, Grid, GridSize, Link, Paper, Radio, RadioGroup, Typography} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import {useSelector} from "react-redux";
import {CategoryModelView} from "../../model/item-containers-models/categoryModelView";
import CategoryTitleSection from "./CategoryTitleSection";
import {configSelector} from "../../state/config/configSelectors";

interface CatalogPortionPreviewProps {
  childrenCategories: CategoryModelView[];
  setInitialCategory: (id: string) => void;
  partialPath: CategoryModelView[];
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

interface CategoryRadioProps {
  hasImage: boolean;
  categoryImage: string;
  categoryTitle: string;
  id: string;
}
function CategoryRadio(props: CategoryRadioProps) {
  const baseUrl = useSelector(configSelector).baseURL;

  return (
    <Radio
      style={{width: "100%"}}
      disableRipple
      checkedIcon={
        props.hasImage ? (
          <img
            src={baseUrl + props.categoryImage}
            alt="selected category"
            style={{borderRadius: "10px", width: "100%"}}
          ></img>
        ) : (
          <Paper style={{display: "flex", width: "100%", padding: "10px"}}>
            <FolderIcon />
            <Typography variant="h5" style={{fontSize: 20}}>
              <Link style={{textDecoration: "underline"}}>{props.categoryTitle}</Link>
            </Typography>
          </Paper>
        )
      }
      icon={
        props.hasImage ? (
          <img
            src={baseUrl + props.categoryImage}
            alt="unselected category"
            style={{borderRadius: "10px", filter: "brightness(50%)", width: "100%"}}
          ></img>
        ) : (
          <Paper style={{display: "flex", width: "100%", padding: "10px"}}>
            <FolderIcon />
            <Typography variant="h5" style={{fontSize: 20}}>
              <Link>{props.categoryTitle}</Link>
            </Typography>
          </Paper>
        )
      }
      {...props}
    />
  );
}

interface CategoryRadioContainerProps {
  allCategoriesHaveImage: boolean;
  categoryRadioProps: CategoryRadioProps[];
  render: (prop: CategoryRadioProps) => React.ReactNode;
  columnDimensionsConfig: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}
const CategoryRadioContainer = ({
  allCategoriesHaveImage,
  categoryRadioProps,
  render,
  columnDimensionsConfig
}: CategoryRadioContainerProps) =>
  allCategoriesHaveImage ? (
    <Box
      className="item_container_category_preview_image_container"
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))"
      gridGap={1}
    >
      {categoryRadioProps.map(radioProps => (
        <React.Fragment key={radioProps.categoryTitle}>{render(radioProps)}</React.Fragment>
      ))}
    </Box>
  ) : (
    <Grid container direction="row" wrap="wrap" spacing={2} className="item_container_category_preview_grid_container">
      {categoryRadioProps.map(radioProps => (
        <Grid
          className="item_container_category_preview_grid_item"
          item
          key={radioProps.categoryTitle}
          xs={(columnDimensionsConfig.xs as GridSize) || 4}
          sm={(columnDimensionsConfig.sm as GridSize) || 4}
          md={(columnDimensionsConfig.md as GridSize) || 4}
          lg={(columnDimensionsConfig.lg as GridSize) || 4}
          xl={(columnDimensionsConfig.xl as GridSize) || 4}
        >
          {render(radioProps)}
        </Grid>
      ))}
    </Grid>
  );

const CatalogPortionPreview = ({
  childrenCategories,
  setInitialCategory,
  partialPath,
  xs,
  sm,
  md,
  lg,
  xl
}: CatalogPortionPreviewProps) => {
  const allCategoriesHaveImage = childrenCategories.every(c => !!c.image);
  const imageUrl = partialPath?.[0]?.image;
  const description = partialPath?.[0]?.description;
  const label = partialPath?.[0]?.label;

  return (
    <Box my={3}>
      <RadioGroup
        defaultValue={childrenCategories[0].id.toString()}
        style={{padding: "20px", backgroundColor: allCategoriesHaveImage ? "#9f9fac" : "", display: "block"}}
      >
        <CategoryRadioContainer
          allCategoriesHaveImage={allCategoriesHaveImage}
          categoryRadioProps={childrenCategories.map(c => ({
            hasImage: allCategoriesHaveImage,
            categoryImage: c.image,
            categoryTitle: c.label,
            id: c.id
          }))}
          columnDimensionsConfig={{xs, sm, md, lg, xl}}
          render={c => (
            <FormControlLabel
              value={c.id.toString()}
              style={{width: "100%", flex: 1}}
              onChange={(e: any) => setInitialCategory(e.target.value)}
              label=""
              control={
                <CategoryRadio
                  id={c.id}
                  hasImage={allCategoriesHaveImage}
                  categoryImage={c?.categoryImage}
                  categoryTitle={c.categoryTitle}
                />
              }
            />
          )}
        />
      </RadioGroup>
      <CategoryTitleSection imageUrl={imageUrl} label={label} description={description} />
    </Box>
  );
};

export default CatalogPortionPreview;
