import React from "react";
import {Box} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {CategoryOptionForm} from "../item-container-categories-list/CategoryOptionForm";
import {getAgencyAndCategoryFromFullId, getPathToSelectedCategory} from "../../utils/catalog";

const CategoryItem = ({
  item,
  rowIdx,
  colIdx,
  onEditClick
}: {
  item: ItemCategoryTemplateDto;
  rowIdx: number;
  colIdx: number;
  onEditClick: () => void;
}) => {
  const {categoryId, agencyId} = getAgencyAndCategoryFromFullId(item.category);
  const agency = item.catalog?.categoryGroups.find(agency => agency.id === agencyId);
  const selectedCategories = getPathToSelectedCategory(categoryId, agency).map(v => v.label);
  const hasMoreCategoryGroups = item.catalog?.categoryGroups.length > 1;

  return (
    <>
      <EditIcon style={{cursor: "pointer"}} onClick={onEditClick} />
      <br />
      <span>{[...(hasMoreCategoryGroups ? [agency.label] : []), ...selectedCategories].join(" -> ")}</span>
      <Box my={2}>
        <CategoryOptionForm item={item} rowIdx={rowIdx} colIdx={colIdx} />
      </Box>
    </>
  );
};

export default CategoryItem;
