import React from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import {useTranslation} from "react-i18next";
import {ItemContainerBuilderCol} from "./ItemContainerBuilderCol";
import {useItemContainerBuilder} from "./useItemContainerBuilder";

const MAX_VIEW_PER_ROW = 4;

export const ItemContainerBuilderRow = ({classes, row, rowIdx}) => {
  const {t} = useTranslation();
  const {handleRowRemove, handleColAdd, getRowItemProps, getRowMoveCursorProps, getColContainerProps, asDashboard} =
    useItemContainerBuilder();

  return (
    <div style={{flexGrow: 1}} className={classes.rowContainer}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "100%"
        }}
        {...getRowItemProps(rowIdx)}
      >
        {row.map((col, idx) => {
          return (
            <React.Fragment key={idx}>
              <div style={{display: "flex", width: "100%"}} {...getColContainerProps(rowIdx, idx)}>
                <ItemContainerBuilderCol classes={classes} rowIdx={rowIdx} colIdx={idx} col={col} />
              </div>
            </React.Fragment>
          );
        })}

        <div style={{flexGrow: 1}} className={classes.rowActions}>
          <Tooltip title={t("components.itemContainerBuilder.actions.addColumn")}>
            <span>
              <IconButton
                className={classes.rowAction}
                onClick={() => handleColAdd(rowIdx)}
                disabled={row.length === MAX_VIEW_PER_ROW}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t("components.itemContainerBuilder.actions.removeRow")}>
            <IconButton className={classes.rowAction} onClick={() => handleRowRemove(rowIdx)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {!asDashboard && (
            <Tooltip title={t("components.itemContainerBuilder.actions.moveRow")}>
              <IconButton {...getRowMoveCursorProps(rowIdx)}>
                <OpenWithIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
