import React, {Fragment, useCallback, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import InfoIcon from "@material-ui/icons/Info";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import Call from "../../../hocs/call";
import AttributeList from "../../attribute-list";
import ButtonSelect from "../../button-select";
import CustomDialogTitle from "../../custom-dialog-title";
import AttributeIcon from "../../custom-icons/AttributeIcon";
import DatasetStaticFiltersJsonStatWrapper from "../../dataset-static-filters/DatasetStaticFiltersJsonStatWrapper";
import ExportButton from "../../export-button";
import FullscreenDialog from "../../fullscreen-dialog";
import I18nTextField from "../../i18n-text-field";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../../label-format-selector/constants";
import ModulesPlaceholder from "../../modules-placeholder";
import Query from "../../query";
import {validateTemplateDefaultView, viewersFactory} from "../constant";
import DatasetViewerTools from "../DatasetViewerTools";
import DataViewerTimings from "../Timings";
import SingleViewerTemplateBuilder from "./TemplateBuilder";
import {ViewerMode} from "../../../state/dataset/constants";
import {
  fetchDatasetSVSdmxQuery,
  hideDatasetSVSdmxQuery,
  hideDatasetSVViewError,
  hideDatasetSVViewTemplate,
  showDatasetSVSdmxQuery,
  showDatasetSVViewTemplate,
  submitDatasetSVDownload,
  submitDatasetSVViewTemplate
} from "../../../state/dataset/single-viewer/actions";
import {CRITERIA_FILTER_TYPE_PERIODS, getCriteriaArrayFromObject} from "../../../utils/criteria";
import {getDatasetAttributeMap, getSeriesAttributeMap} from "../../../utils/dataset";
import {DOWNLOAD_FORMAT_EXCEL} from "../../../utils/download";
import {DECIMAL_SEPARATOR_DEFAULT} from "../../../utils/formatters";
import {validateI18nObj} from "../../../utils/i18n";
import {
  canSaveAsView,
  canSaveTemplate,
  canUseDatasetTools,
  canViewTemplateOrAnnotationIcon,
  canViewTimesLog
} from "../../../utils/user";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

const $ = window.jQuery;

const styles = theme => ({
  headerTitle: {
    color: theme.palette.primary.main,
    display: "inline-block",
    verticalAlign: "top"
  },
  title: {
    paddingTop: 7,
    minHeight: 48
  },
  titleText: {
    display: "unset",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0,
    marginRight: 4
  },
  titleAction: {
    marginTop: -10
  },
  layoutIcon: {
    width: 48,
    height: 48,
    padding: 12,
    color: "rgba(0, 0, 0, 0.54)"
  },
  queryContainer: {
    "& > *": {
      marginBottom: theme.spacing(2)
    },
    "& > *:last-child": {
      marginBottom: 0
    }
  },
  headerExtraActions: {
    display: "inline-block",
    verticalAlign: "top"
  },
  viewerModeSelect: {
    display: "none",
    width: 48
  }
});

const mapStateToProps = ({app, user, hub, dataset}) => ({
  defaultLanguage: app.language,
  languages: app.languages,
  user: user,
  exportConfig: hub.hub.exportConfig,
  jsonStat: dataset.singleViewer.dataset,
  territoryDim: dataset.singleViewer.territoryDim,
  timeDim: dataset.singleViewer.timeDim,
  viewerIdx: dataset.singleViewer.viewerIdx,
  chartType: dataset.singleViewer.chartType,
  view: dataset.singleViewer.view,
  template: dataset.singleViewer.template,
  hasViewLayout: dataset.singleViewer.hasViewLayout,
  hasTemplateLayout: dataset.singleViewer.hasTemplateLayout,
  hasAnnotationLayout: dataset.singleViewer.hasAnnotationLayout,
  tableLayout: dataset.singleViewer.tableLayout,
  mapLayout: dataset.singleViewer.mapLayout,
  chartLayout: dataset.singleViewer.chartLayout,
  timePeriodsByFreq: dataset.singleViewer.timePeriodsByFreq,
  labelFormat: dataset.singleViewer.labelFormat,
  temporalDimOrder: dataset.singleViewer.temporalDimOrder,
  showTrend: dataset.singleViewer.showTrend,
  showCyclical: dataset.singleViewer.showCyclical,
  criteria: dataset.singleViewer.criteria,
  decimalSeparator: dataset.singleViewer.decimalSeparator,
  roundingStrategy: dataset.singleViewer.roundingStrategy,
  decimalPlaces: dataset.singleViewer.decimalPlaces,
  tableEmptyChar: dataset.singleViewer.tableEmptyChar,
  chartSettings: dataset.singleViewer.chartSettings,
  mapSettings: dataset.singleViewer.mapSettings,
  enableVariation: dataset.singleViewer.enableVariation,
  timings: dataset.singleViewer.timings,
  isViewVisible: dataset.singleViewer.isViewVisible,
  isViewErrorVisible: dataset.singleViewer.isViewErrorVisible,
  viewErrorMessage: dataset.singleViewer.viewErrorMessage,
  isTemplateVisible: dataset.singleViewer.isTemplateVisible,
  isQueryVisible: dataset.singleViewer.isQueryVisible,
  structureQuery: dataset.singleViewer.structureQuery,
  dataQuery: dataset.singleViewer.dataQuery,
  detailLevel: dataset.singleViewer.detailLevel,
  isFullscreen: dataset.singleViewer.isFullscreen,
  isTableEnabled: dataset.singleViewer.isTableEnabled,
  isMapEnabled: dataset.singleViewer.isMapEnabled,
  isChartEnabled: dataset.singleViewer.isChartEnabled
});

const mapDispatchToProps = dispatch => ({
  onViewTemplateShow: isView => dispatch(showDatasetSVViewTemplate(isView)),
  onViewTemplateHide: isView => dispatch(hideDatasetSVViewTemplate(isView)),
  onViewTemplateSubmit: (nodeId, viewTemplate, isView) =>
    dispatch(submitDatasetSVViewTemplate(nodeId, viewTemplate, isView)),
  onViewErrorHide: isView => dispatch(hideDatasetSVViewError(isView)),
  onDownloadSubmit: (
    nodeId,
    datasetId,
    datasetTitle,
    criteria,
    layout,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t
  ) =>
    dispatch(
      submitDatasetSVDownload(
        nodeId,
        datasetId,
        datasetTitle,
        criteria,
        layout,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t
      )
    ),
  onQueryShow: () => dispatch(showDatasetSVSdmxQuery()),
  onQueryHide: () => dispatch(hideDatasetSVSdmxQuery()),
  fetchQuery: ({nodeId, datasetId, criteria, datasetTitle}) =>
    dispatch(fetchDatasetSVSdmxQuery(nodeId, datasetId, criteria, datasetTitle))
});

function SingleViewerHeader(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    nodeExtras,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    chartId,
    mapId,
    onRender,
    datasetMap,
    onToggleFullScreen,

    defaultLanguage,
    languages,
    user,
    exportConfig,
    jsonStat,
    territoryDim,
    timeDim,
    viewerIdx,
    chartType,
    view,
    template,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    tableLayout,
    mapLayout,
    chartLayout,
    timePeriodsByFreq,
    labelFormat,
    temporalDimOrder,
    showTrend,
    showCyclical,
    criteria,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    enableVariation,
    timings,
    isViewVisible,
    isViewErrorVisible,
    viewErrorMessage,
    isTemplateVisible,
    isQueryVisible,
    structureQuery,
    dataQuery,
    detailLevel,
    isFullscreen,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled,

    onViewTemplateShow,
    onViewTemplateHide,
    onViewTemplateSubmit,
    onViewErrorHide,
    onDownloadSubmit,
    onQueryShow,
    onQueryHide,
    fetchQuery
  } = props;

  const getChartList = () => {
    const additionalViews = mapLayout ? [viewers[0], viewers[1]] : [viewers[0]];
    if (nodeExtras?.ChartsSettings) {
      if (nodeExtras.ChartsSettings.length > 0) {
        const charts = viewers.filter(
          view => nodeExtras?.ChartsSettings?.some(obj => obj.chartType === view.chartType) && view.hidden !== true
        );
        charts.unshift(...additionalViews);
        return charts;
      } else {
        return [...additionalViews];
      }
    } else {
      return viewers.filter(view => view.hidden !== true);
    }
  };

  const downloadFormats = nodeExtras?.DownloadFormats;
  const showQueryInfo = nodeExtras?.QueryInfo;

  const {t} = useTranslation();
  const viewers = viewersFactory(t);
  const availableCharts = getChartList();

  const [tempView, setTempView] = useState(null);
  const [tempTemplate, setTempTemplate] = useState(null);

  const [notes, setNotes] = useState("");
  const [datasetAttributes, setDatasetAttributes] = useState([]);
  const [seriesAttributes, setSeriesAttributes] = useState([]);

  const [isNotesVisible, setNotesVisibility] = useState(false);
  const [isAttributesVisible, setAttributesVisibility] = useState(false);
  const [isTimingsVisible, setTimingsVisibility] = useState(false);

  const [isUpdatingView, setIsUpdatingView] = useState(false);

  useEffect(() => {
    const headerExtraActionsWidth = $("#data-viewer__header__extra-actions").outerWidth(true);
    $("#data-viewer__header__title-actions-container").width(`calc(100% - ${headerExtraActionsWidth}px)`);
  }, []);

  useEffect(() => {
    const actionsWidth = $("#data-viewer__header__actions").outerWidth(true);
    $("#data-viewer__header__title").width(`calc(100% - ${actionsWidth}px - 8px)`);
  });

  useEffect(() => {
    setNotes(datasetMap[datasetId]?.note || "");
  }, [datasetMap, datasetId]);

  useEffect(() => {
    setDatasetAttributes(getDatasetAttributeMap(jsonStat)?.[`${nodeCode},${datasetId}`] || []);
  }, [jsonStat, nodeCode, datasetId]);

  useEffect(() => {
    setSeriesAttributes(getSeriesAttributeMap(jsonStat, labelFormat)?.[`${nodeCode},${datasetId}`] || []);
  }, [jsonStat, labelFormat, nodeCode, datasetId]);

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  });

  const getDimensionsCombinationCount = useCallback(
    dimensions =>
      jsonStat && dimensions ? dimensions.reduce((acc, dim) => acc * jsonStat.size[jsonStat.id.indexOf(dim)], 1) : 0,
    [jsonStat]
  );

  const getCompleteViewTemplate = (viewTemplate, isUpdating) => ({
    defaultView: "table",
    enableCriteria: true,
    enableLayout: true,
    enableVariation: false,
    hiddenDimensions: [],
    ...viewTemplate,
    datasetId: datasetId,
    title: isUpdating ? viewTemplate.title : {[defaultLanguage]: datasetTitle},
    mode: ViewerMode.SingleViewer,
    criteria: getCriteriaArrayFromObject(criteria),
    layouts: {
      ...viewTemplate?.layouts,
      detailLevel: detailLevel,
      mapDetailLevel: undefined,
      labelFormat: labelFormat,
      temporalDimOrder: temporalDimOrder,
      showTrend: showTrend,
      showCyclical: showCyclical,
      chartType: chartType
    },
    decimalSeparator: {
      ...(viewTemplate?.decimalSeparator || {}),
      [defaultLanguage]:
        decimalSeparator !== null && decimalSeparator !== undefined ? decimalSeparator : DECIMAL_SEPARATOR_DEFAULT
    },
    roundingStrategy: roundingStrategy,
    decimalNumber: decimalPlaces === null || decimalPlaces === undefined || decimalPlaces < 0 ? "" : decimalPlaces
  });

  const handleViewOpen = isUpdating => {
    setIsUpdatingView(isUpdating);
    onViewTemplateShow(true);
    const completeView = getCompleteViewTemplate(view, isUpdating);
    setTempView({
      ...completeView,
      type: "view",
      defaultView: viewers[viewerIdx]?.type,
      layouts: {
        ...completeView.layouts,
        tableLayout: viewerIdx === null || viewerIdx === 0 ? tableLayout : undefined,
        tableEmptyChar: viewerIdx === null || viewerIdx === 0 ? tableEmptyChar : undefined,
        mapLayout: viewerIdx === null || viewerIdx === 1 ? mapLayout : undefined,
        ...(viewerIdx === null || viewerIdx === 1 ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: viewerIdx === null || viewerIdx >= 2 ? chartLayout : undefined,
        ...(viewerIdx === null || viewerIdx >= 2 ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {})
      }
    });
  };

  const handleViewClose = () => {
    onViewTemplateHide(true);
    setTempView(null);
  };

  const handleViewSubmit = viewId => {
    tempView.decimalSeparator = undefined;
    tempView.roundingStrategy = undefined;
    tempView.decimalNumber = undefined;
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempView,
        viewTemplateId: viewId ? Number(viewId) : undefined,
        layouts: JSON.stringify(tempView.layouts)
      },
      true
    );
  };

  const handleTemplateOpen = isUpdating => {
    onViewTemplateShow(false);
    const completeTemplate = getCompleteViewTemplate(template, isUpdating);
    setTempTemplate({
      ...completeTemplate,
      type: "template",
      layouts: {
        ...completeTemplate.layouts,
        tableLayout: tableLayout,
        tableDefaultLayout: "custom",
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {})
      }
    });
  };

  const handleTemplateClose = () => {
    onViewTemplateHide(false);
    setTempTemplate(null);
  };

  const handleTemplateSubmit = () => {
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempTemplate,
        layouts: JSON.stringify({
          ...tempTemplate.layouts,
          tableDefaultLayout: undefined
        }),
        roundingStrategy: tempTemplate.roundingStrategy === "inherit" ? null : tempTemplate.roundingStrategy,
        decimalNumber:
          tempTemplate.decimalNumber === null ||
          tempTemplate.decimalNumber === undefined ||
          tempTemplate.decimalNumber === ""
            ? -1
            : tempTemplate.decimalNumber
      },
      false
    );
  };

  const handleNotesShow = () => {
    setNotesVisibility(true);
  };

  const handleNotesHide = () => {
    setNotesVisibility(false);
  };

  const handleAttributesShow = () => {
    setAttributesVisibility(true);
  };

  const handleAttributesHide = () => {
    setAttributesVisibility(false);
  };

  const handleTimingsShow = () => {
    setTimingsVisibility(true);
  };

  const handleTimingsHide = () => {
    setTimingsVisibility(false);
  };

  const isValidTemplateDecimalNumber =
    (tempTemplate?.decimalNumber || "").length === 0 ||
    isValidIntegerInInclusiveRange(tempTemplate?.decimalNumber, 0, 20);

  const isValidTemplateChart = defaultView => {
    return availableCharts.find(({chartType, type}) => chartType === defaultView || type === defaultView);
  };

  const isValidTemplate =
    validateI18nObj(tempTemplate?.title) &&
    validateI18nObj(tempTemplate?.decimalSeparator) &&
    isValidTemplateDecimalNumber &&
    isValidTemplateChart(tempTemplate.defaultView) &&
    validateTemplateDefaultView(tempTemplate, isTableEnabled, isMapEnabled, isChartEnabled);

  return (
    <Fragment>
      <div id="data-viewer__header__title-actions-container" className={classes.headerTitle}>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Grid item id="data-viewer__header__title" className={classes.title}>
            <Typography variant={"h1"} className={classes.titleText}>
              {datasetTitle}
            </Typography>
            {notes.length > 0 && (
              <Tooltip title={t("scenes.dataViewer.header.action.information.tooltip")}>
                <IconButton
                  id="dataset-notes-btn"
                  aria-label={t("scenes.dataViewer.header.action.information.label")}
                  onClick={handleNotesShow}
                  className={classes.titleAction}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            {datasetAttributes.concat(seriesAttributes).length > 0 && (
              <Tooltip title={t("scenes.dataViewer.header.action.attributes.tooltip")}>
                <IconButton
                  id="dataset-attributes-btn"
                  aria-label={t("scenes.dataViewer.header.action.attributes.label")}
                  onClick={handleAttributesShow}
                  className={classes.titleAction}
                >
                  <AttributeIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item id="data-viewer__header__actions">
            <Grid container alignItems="center">
              {(() => {
                if (canViewTemplateOrAnnotationIcon(user, nodeId)) {
                  let title = null;
                  if (hasViewLayout) {
                    title = t("scenes.dataViewer.header.action.hasViewLayout.tooltip");
                  } else if (hasTemplateLayout) {
                    title = t("scenes.dataViewer.header.action.hasTemplateLayout.tooltip");
                  } else if (hasAnnotationLayout) {
                    title = t("scenes.dataViewer.header.action.hasAnnotationLayout.tooltip");
                  }

                  if (title) {
                    return (
                      <Grid
                        item
                        id="dataset-layout-info-btn"
                        className={classes.layoutIcon}
                        tabIndex={0}
                        aria-label={title}
                      >
                        <Tooltip title={title}>
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    );
                  }
                }
              })()}
              {canViewTimesLog(user, nodeId) && (
                <Grid item id="dataset-times-log-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.timesLog.tooltip")}>
                    <div>
                      <IconButton
                        aria-label={t("scenes.dataViewer.header.action.timesLog.label")}
                        onClick={handleTimingsShow}
                        disabled={timings === null}
                      >
                        <AccessTimeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              {canUseDatasetTools(user) && (
                <Grid item id="dataset-cache-template-btn">
                  <DatasetViewerTools templateId={template?.viewTemplateId || null} />
                </Grid>
              )}
              {(canSaveAsView(user) || canSaveTemplate(user, nodeId)) && (
                <Grid item id="dataset-save-btn">
                  <ButtonSelect
                    icon={<SaveIcon />}
                    ariaLabel={t("scenes.dataViewer.header.action.save.label")}
                    tooltip={t("scenes.dataViewer.header.action.save.tooltip")}
                    color="default"
                    disabled={!tableLayout && !chartLayout && !mapLayout}
                  >
                    {canSaveAsView(user) && (
                      <div onClick={() => handleViewOpen(false)}>
                        {t("scenes.dataViewer.header.action.save.values.createView")}
                      </div>
                    )}
                    {canSaveAsView(user) && hasViewLayout && viewId && (
                      <div onClick={() => handleViewOpen(true)}>
                        {t("scenes.dataViewer.header.action.save.values.updateView")}
                      </div>
                    )}
                    {canSaveTemplate(user, nodeId) && (
                      <div onClick={() => handleTemplateOpen(hasTemplateLayout)}>
                        {hasTemplateLayout
                          ? t("scenes.dataViewer.header.action.save.values.updateTemplate")
                          : t("scenes.dataViewer.header.action.save.values.createTemplate")}
                      </div>
                    )}
                  </ButtonSelect>
                </Grid>
              )}
              {
                <ModulesPlaceholder
                  id="view-widget-save-button"
                  viewTemplate={{
                    ...getCompleteViewTemplate(view, false),
                    type: "view",
                    defaultView: viewers[viewerIdx]?.type,
                    layouts: JSON.stringify({
                      ...(viewerIdx === null || viewerIdx === 0 ? {tableLayout} : undefined),
                      ...(viewerIdx === null || viewerIdx === 0 ? {tableEmptyChar} : undefined),
                      ...(viewerIdx === null || viewerIdx === 1 ? {mapLayout} : undefined),
                      ...(viewerIdx === null || viewerIdx === 1
                        ? getViewTemplateLayoutsFromMapSettings(mapSettings)
                        : {}),
                      ...(viewerIdx === null || viewerIdx >= 2 ? {chartLayout} : undefined),
                      ...(viewerIdx === null || viewerIdx >= 2
                        ? getViewTemplateLayoutsFromChartSettings(chartSettings)
                        : {})
                    }),
                    nodeId
                  }}
                />
              }
              {(attachedFiles || []).length > 0 && (
                <Grid item id="dataset-attachments-btn">
                  <ButtonSelect
                    icon={<AttachFileIcon />}
                    ariaLabel={t("scenes.dataViewer.header.action.attachments.label")}
                    tooltip={t("scenes.dataViewer.header.action.attachments.tooltip")}
                    color="default"
                    onChange={({attachedFile}) => window.open(attachedFile.url)}
                  >
                    {attachedFiles.map((attachedFile, idx) => {
                      const fileName = attachedFile.url.split("/").pop();
                      return (
                        <Tooltip title={attachedFile.description} key={idx} data-value={{attachedFile}}>
                          <div>{`${fileName} (${attachedFile.format})`}</div>
                        </Tooltip>
                      );
                    })}
                  </ButtonSelect>
                </Grid>
              )}
              {showQueryInfo && (
                <Grid item id="dataset-query-btn">
                  <Tooltip title={t("scenes.dataViewer.header.action.query.tooltip")}>
                    <div>
                      <IconButton aria-label={t("scenes.dataViewer.header.action.query.label")} onClick={onQueryShow}>
                        <DeveloperModeIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              <Grid item id="dataset-export-btn">
                <ExportButton
                  formats={downloadFormats}
                  jsonStat={jsonStat}
                  viewerIdx={viewerIdx}
                  isTableVisible={viewerIdx === 0}
                  isMapVisible={viewerIdx === 1}
                  isChartVisible={viewerIdx >= 2}
                  tableLayout={tableLayout}
                  mapId={mapId}
                  mapLayout={mapLayout}
                  mapSettings={mapSettings}
                  chartId={chartId}
                  chartLayout={chartLayout}
                  chartSettings={chartSettings}
                  labelFormat={labelFormat}
                  datasetTitle={datasetTitle}
                  getDimensionsCombinationCount={getDimensionsCombinationCount}
                  submitDownload={(format, extension, additionalParams) => {
                    const exportParams = {
                      labelFormat: labelFormat,
                      customLabelFormat: {
                        [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                      },
                      decimalNumber: decimalPlaces,
                      decimalSeparator: decimalSeparator,
                      roundingStrategy: roundingStrategy,
                      emptyCellPlaceHolder: tableEmptyChar,
                      hasVariation: enableVariation,
                      showTrend: showTrend,
                      showCyclical: showCyclical,
                      temporalDimOrder: temporalDimOrder,
                      exportConfig: exportConfig,
                      ...additionalParams
                    };
                    const layout =
                      format === DOWNLOAD_FORMAT_EXCEL || viewerIdx === 0
                        ? tableLayout
                        : viewerIdx === 1
                        ? mapLayout
                        : chartLayout;

                    const newCriteria = {...criteria};
                    if (viewerIdx === 1 && additionalParams?.exportOnlyCurrentView === true) {
                      newCriteria[territoryDim] = {
                        id: territoryDim,
                        filterValues: window.LMap.getDataIds(mapId)
                      };
                    }

                    onDownloadSubmit(
                      nodeId,
                      datasetId,
                      datasetTitle,
                      newCriteria,
                      layout,
                      format,
                      extension,
                      false,
                      exportParams,
                      defaultLanguage,
                      languages,
                      t
                    );
                  }}
                />
              </Grid>
              <Grid item id="fullscreen-btn">
                <Tooltip
                  title={
                    isFullscreen
                      ? t("scenes.dataViewer.actions.fullscreen.exit")
                      : t("scenes.dataViewer.actions.fullscreen.enter")
                  }
                >
                  <IconButton onClick={onToggleFullScreen}>
                    {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div id="data-viewer__header__extra-actions" className={classes.headerExtraActions} />
      <DatasetStaticFiltersJsonStatWrapper
        jsonStat={jsonStat}
        layout={viewerIdx === 0 ? tableLayout : viewerIdx === 1 ? mapLayout : chartLayout}
        labelFormat={labelFormat}
        isDimensionAllowed={dim => jsonStat.size[jsonStat.id.indexOf(dim)] === 1}
      />
      <Dialog open={isAttributesVisible} fullWidth maxWidth="md" onClose={handleAttributesHide}>
        <CustomDialogTitle onClose={handleAttributesHide}>
          {t("scenes.dataViewer.header.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={datasetAttributes}
            seriesAttributes={seriesAttributes}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAttributesHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isTimingsVisible} onClose={handleTimingsHide}>
        <DialogContent style={{width: 400}}>
          <DataViewerTimings timings={timings} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleTimingsHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isViewVisible} maxWidth="sm" fullWidth onClose={handleViewClose}>
        <CustomDialogTitle onClose={handleViewClose}>
          {isUpdatingView
            ? t("scenes.dataViewer.header.dialogs.view.title.update")
            : t("scenes.dataViewer.header.dialogs.view.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
              <Grid item xs={12}>
                <Alert severity="warning">{t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <I18nTextField
                  label={t("scenes.dataViewer.header.dialogs.view.form.title.label")}
                  required
                  variant="outlined"
                  value={tempView?.title || ""}
                  onChange={value => setTempView({...tempView, title: value})}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose}>{t("commons.confirm.cancel")}</Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => handleViewSubmit(isUpdatingView ? viewId : null)}
            disabled={!tempView || !validateI18nObj(tempView.title)}
          >
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isViewErrorVisible} maxWidth="md" onClose={onViewErrorHide}>
        <CustomDialogTitle onClose={onViewErrorHide}>
          {t("scenes.dataViewer.header.dialogs.duplicateViewError.title")}
        </CustomDialogTitle>
        <DialogContent>
          {viewErrorMessage && (
            <Fragment>
              {t("scenes.dataViewer.header.dialogs.duplicateViewError.content") + ": "}
              <b>{Object.keys(viewErrorMessage).join(", ")}</b>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onViewErrorHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <FullscreenDialog open={isTemplateVisible} onClose={handleTemplateClose}>
        <CustomDialogTitle onClose={handleTemplateClose}>
          {hasTemplateLayout
            ? t("scenes.dataViewer.header.dialogs.template.title.update")
            : t("scenes.dataViewer.header.dialogs.template.title.create")}
        </CustomDialogTitle>
        <DialogContent>
          <SingleViewerTemplateBuilder
            defaultLanguage={defaultLanguage}
            languages={languages}
            nodeId={nodeId}
            template={tempTemplate}
            onChange={setTempTemplate}
            viewers={availableCharts}
            jsonStat={jsonStat}
            timePeriodsByFreq={timePeriodsByFreq}
            labelFormat={labelFormat}
            tableLayout={tableLayout}
            chartLayout={chartLayout}
            mapLayout={mapLayout}
            criteria={criteria}
            timeDim={timeDim}
            isTableEnabled={isTableEnabled}
            isMapEnabled={isMapEnabled}
            isChartEnabled={isChartEnabled}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTemplateClose}>{t("commons.confirm.cancel")}</Button>
          <Button color="primary" onClick={handleTemplateSubmit} disabled={!isValidTemplate}>
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <Dialog open={isNotesVisible} fullWidth maxWidth="md" onClose={handleNotesHide}>
        <CustomDialogTitle onClose={handleNotesHide}>
          {t("scenes.dataViewer.header.dialogs.notes.title")}
        </CustomDialogTitle>
        <DialogContent>{notes}</DialogContent>
        <DialogActions>
          <Button onClick={handleNotesHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isQueryVisible} fullWidth maxWidth="md" onClose={onQueryHide}>
        <CustomDialogTitle onClose={onQueryHide}>{t("scenes.dataViewer.header.dialogs.query.title")}</CustomDialogTitle>
        <DialogContent className={classes.queryContainer}>
          <Call
            cb={fetchQuery}
            cbParam={{nodeId, datasetId, criteria, datasetTitle}}
            disabled={structureQuery !== null && dataQuery !== null}
          >
            {structureQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.structureQuery.title")}
                query={structureQuery}
              />
            )}
            {dataQuery && (
              <Query title={t("scenes.dataViewer.header.dialogs.query.content.dataQuery.title")} query={dataQuery} />
            )}
          </Call>
        </DialogContent>
        <DialogActions>
          <Button onClick={onQueryHide}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SingleViewerHeader);
