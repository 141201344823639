import {useRef} from "react";
import {DatasetData} from "../../model/IDatasetData";
import {ItemContainerType} from "../../model/item-containers-models/itemContainerType";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {useItemContainerFilters} from "../item-containers/ItemContainerFilters";
import useApi from "../../state/api/useApi";
import {fetchItemContainerViewDataset} from "../../state/itemContainer/itemContainerActions";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {itemContainerTypeToServiceNameMap} from "../../utils/itemContainers";

const getSearchCriterias = (viewTemplate: ItemViewTemplateDto, pageFilterValue: string) =>
  pageFilterValue
    ? viewTemplate.viewTemplate.criteria.map(v =>
        v.id === viewTemplate.filterDimension ? {...v, filterValues: [pageFilterValue]} : v
      )
    : viewTemplate.viewTemplate.criteria;

export const useViewDataset = (itemViewTemplate: ItemViewTemplateDto, itemContainerType: ItemContainerType) => {
  const {call, request, ...rest} = useApi<DatasetData>(
    external(fetchItemContainerViewDataset(itemViewTemplate), itemContainerTypeToServiceNameMap[itemContainerType]),
    {
      invokeImmediately: true,
      cacheAction: true,
      getCacheKey: req => req.url + req.data.map(criteria => criteria.id + criteria.filterValues.join(",")).join("/")
    }
  );
  const pageFilterValue = useItemContainerFilters();
  const lastFilterValue = useRef(pageFilterValue);

  if (lastFilterValue.current !== pageFilterValue && itemViewTemplate.filterDimension) {
    lastFilterValue.current = pageFilterValue;
    const newCriterias = getSearchCriterias(itemViewTemplate, pageFilterValue);
    call({
      ...request,
      data: getCriteriaArrayFromObject(newCriterias),
      clearCache: true
    });
  }

  return {call, request, ...rest};
};
