import React, {ChangeEvent, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, MenuItem, Select} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {NodeCatalogModelView} from "../../model/item-containers-models/nodeCatalogModelView";
import {LocalCategoryProvider} from "../../model/LocalCategoryProvider";
import {getNodeCatalogUrl} from "../../serverApi/urls";
import CategoriesTree from "../categories-tree";
import CustomDialogTitle from "../custom-dialog-title";
import useApi from "../../state/api/useApi";
import {nodesSelector} from "../../state/hub/hubSelectors";
import {initRequest} from "../../middlewares/request/requestActions";
import {getAgencyAndCategoryFromFullId, getPathToSelectedCategory} from "../../utils/catalog";

function getFullIdFromAgencyAndCategory(agencyId: string, categoryId: string) {
  return `${agencyId};${categoryId}`;
}

function getAgencyAndCategoryFromTreeSelection(
  selection: string[],
  catalog: NodeCatalogModelView
): {agencyId: string; categoryId: string} {
  if (!selection[0]) return null;
  if (catalog.categoryGroups.length > 1) {
    const [agencyId, ...rest] = selection[0].split("+");
    const categoryId = rest.pop();
    return {
      agencyId: agencyId || categoryId,
      categoryId
    };
  } else {
    return {
      agencyId: catalog.categoryGroups[0].id,
      categoryId: selection[0].split("+").pop()
    };
  }
}

interface CategorySelectionDialogProps {
  open: boolean;
  onClose: () => void;
  handleCategoryChange: (selectedCatalog: NodeCatalogModelView, fullId: string, nodeId: number) => void;
  item: ItemCategoryTemplateDto;
}

export const CategorySelectionDialog = ({open, onClose, handleCategoryChange, item}: CategorySelectionDialogProps) => {
  const {t} = useTranslation();
  const nodes = useSelector(nodesSelector);
  const [selectedNode, setSelectedNode] = useState(nodes.find(n => n.nodeId === item?.nodeId) || nodes[0]);
  const [selectedCatalog, setSelectedCatalog] = useState<NodeCatalogModelView>(item.catalog);

  const {call, request, loading} = useApi<NodeCatalogModelView>(
    initRequest(
      `item-container/category/fetch-node-catalog`,
      getNodeCatalogUrl(+selectedNode.nodeId),
      undefined,
      undefined,
      t => ({
        onStart: t("domains.catalog.messages.fetch.start")
      })
    ),
    {
      invokeImmediately: true,
      onSuccess: data => {
        setSelectedCatalog(data);
      },
      cache: true,
      getCacheKey: r => r.url
    }
  );

  const [selectedCategoryAndAgency, setSelectedCategoryAndAgency] = useState<{agencyId: string; categoryId: string}>(
    item.category ? getAgencyAndCategoryFromFullId(item.category) : null
  );

  const selectionCategoryNodeChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const [id] = event.target.value.split(",");
    const node = nodes.find(v => v.nodeId === +id);
    setSelectedNode(node);
    setSelectedCategoryAndAgency(null);
    call({...request, url: getNodeCatalogUrl(+id)});
  };

  const agency = selectedCatalog?.categoryGroups?.find(agency => agency.id === selectedCategoryAndAgency?.agencyId);
  const selectedCategoryPath =
    selectedCategoryAndAgency?.categoryId && selectedCategoryAndAgency?.agencyId
      ? selectedCatalog?.categoryGroups?.length > 1
        ? [agency.id, ...getPathToSelectedCategory(selectedCategoryAndAgency.categoryId, agency).map(v => v.id)]
        : getPathToSelectedCategory(selectedCategoryAndAgency.categoryId, agency).map(v => v.id)
      : [];

  return (
    <Dialog open={open} fullWidth disableEnforceFocus maxWidth="md" onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>
        {t("components.itemContainerBuilder.modals.category.title")}
      </CustomDialogTitle>
      <DialogContent>
        {selectedNode.nodeId > 0
          ? t("components.itemContainerBuilder.modals.category.selectedNode")
          : t("components.itemContainerBuilder.modals.category.selectANode")}
        <br />
        <Select value={selectedNode.nodeId + "," + selectedNode.code} onChange={selectionCategoryNodeChangeHandler}>
          {nodes
            .sort((a, b) => a.order - b.order)
            .map(n => {
              return (
                <MenuItem key={n.nodeId + "," + n.code} value={n.nodeId + "," + n.code}>
                  {n.name != null ? n.name : n.code}
                </MenuItem>
              );
            })}
        </Select>
        {selectedCatalog && !loading && (
          <CategoriesTree
            inputCatalog={
              new LocalCategoryProvider(
                selectedCatalog.categoryGroups as any,
                selectedCatalog.datasetMap as any,
                selectedCatalog.datasetUncategorized as any,
                selectedNode.nodeId,
                selectedCatalog.catalogLayers as any,
                t
              )
            }
            showDatasetList
            hideLeaf={true}
            showExpandControls={false}
            onCategoryClick={(selection: string[]) => {
              const agencyAndCategory = getAgencyAndCategoryFromTreeSelection(selection, selectedCatalog);
              setSelectedCategoryAndAgency(agencyAndCategory);
            }}
            showCategoriesFirst
            selectedCategoryPath={selectedCategoryPath}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          {t("commons.confirm.cancel")}
        </Button>
        <Button
          disabled={!selectedCategoryAndAgency?.agencyId}
          onClick={() => {
            handleCategoryChange(
              selectedCatalog,
              getFullIdFromAgencyAndCategory(selectedCategoryAndAgency.agencyId, selectedCategoryAndAgency.categoryId),
              selectedNode.nodeId
            );
            onClose();
          }}
        >
          {t("commons.confirm.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
