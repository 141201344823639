import React, {Fragment, useEffect} from "react";
import {Button} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import StorageIcon from "@material-ui/icons/Storage";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getDatasetInternalUrlFromDatasetId} from "../../links";
import CatalogInfoButton from "../catalog-info-button";
import CustomLink from "../custom-link";
import DatasetAttachments from "../dataset-attachments";
import DatasetMetadataButton from "../dataset-metadata-button";
import SanitizedHTML from "../sanitized-html";
import SdmxDownloadButton from "./SdmxDownloadButton";
import {nodesSelector} from "../../state/hub/hubSelectors";

const $ = window.jQuery;

const styles = () => ({
  dataset: {
    display: "flex",
    alignItems: "center"
  },
  actions: {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    "& > button": {
      padding: 8
    }
  },
  description: {
    width: "100%",
    padding: "16px !important"
  },
  descriptionTextWrapper: {
    fontSize: 16
  },
  descriptionTextWrapperCollapsed: {
    maxHeight: 48,
    overflow: "hidden"
  },
  descriptionTextWrapperExpanded: {
    minHeight: 48
  },
  descriptionText: {
    "& > p": {
      margin: 0
    }
  },
  descriptionIcon: {
    display: "none",
    justifyContent: "flex-end"
  },
  SDMXOptionbutton: {
    whiteSpace: "nowrap",
    padding: "8px",
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "darkgreen"
    }
  },
  browseDataOptionbutton: {
    whiteSpace: "nowrap",
    padding: "8px"
  },
  metadataOptionButton: {
    color: "white"
  }
});

// based on https://stackoverflow.com/a/7616484
const getIdFromDatasetIdentifier = identifier => {
  if (!identifier) {
    return undefined;
  }

  let hash = 0,
    i,
    chr;
  if (identifier.length === 0) return hash;
  for (i = 0; i < identifier.length; i++) {
    chr = identifier.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // convert to 32bit integer
  }
  return "id__" + hash;
};

const handleStyle = (dataset, isExpanded) => {
  const textHeight = $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`).outerHeight() || 0;
  const textWrapperHeight =
    $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`).outerHeight() || 0;
  $(`#${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`).css({
    display: textHeight > textWrapperHeight || isExpanded ? "flex" : "none"
  });
};

const DatasetCard = ({
  nodeId,
  dataset,
  classes,
  isExpanded,
  to,
  onExpand,
  onCollapse,
  onClick,
  xs,
  md,
  isMultiCategorized,
  categoryItem
}) => {
  const {t} = useTranslation();
  useEffect(() => {
    const f = () => handleStyle(dataset, isExpanded);
    f();
    window.addEventListener("resize", f);
    return () => window.removeEventListener("resize", f);
  }, [dataset, isExpanded]);

  const nodes = useSelector(nodesSelector);

  const downloadFormats = JSON.parse(
    categoryItem?.nodeExtras?.find(({key}) => key === "DownloadFormats")?.value || "[]"
  );
  const sdmxAvailableFormats = downloadFormats.filter(v => v === "structurespecificdata" || v === "compactdata");

  return (
    <Grid item xs={xs} md={md} id={getIdFromDatasetIdentifier(dataset.identifier)}>
      <Card>
        <div className={classes.dataset} style={{width: "100%"}}>
          <CustomLink
            to={to}
            onClick={onClick}
            text={
              <Fragment>
                {dataset.title}
                {isMultiCategorized && (
                  <Tooltip title={t("components.datasetCard.isMultiCategorized.title")}>
                    <InfoIcon fontSize="small" style={{margin: "0 0 -4px 8px"}} />
                  </Tooltip>
                )}
              </Fragment>
            }
            icon={<StorageIcon fontSize="small" />}
            textStyle={{
              fontSize: 18,
              minHeight: 48,
              padding: "16px 16px 0 16px"
            }}
            subText={dataset.source}
            subTextStyle={{
              fontSize: 14,
              fontWeight: "normal",
              color: "rgba(0, 0, 0, 0.54)",
              padding: "0 16px 16px 16px"
            }}
            disabled={dataset.catalogType === "ONLY_FILE"}
          />
          {categoryItem?.browseDataLink === "Internal" ? (
            <div className={classes.actions}>
              <Link
                to={getDatasetInternalUrlFromDatasetId(
                  nodes.find(n => categoryItem.nodeId === n.nodeId)?.code,
                  dataset.identifier
                )}
              >
                <Button
                  size="small"
                  className={`${classes.browseDataOptionbutton} category__list_browse_button`}
                  color="primary"
                  variant="contained"
                >
                  {t("components.datasetCard.buttons.browseData")}
                </Button>
              </Link>
            </div>
          ) : (
            categoryItem?.browseDataLink != null &&
            dataset?.externalUrlForBrowse && (
              <div className={classes.actions}>
                <Button
                  size="small"
                  className={`${classes.browseDataOptionbutton} category__list_browse_button`}
                  color="primary"
                  variant="contained"
                  onClick={() => window.open(dataset.externalUrlForBrowse)}
                >
                  {t("components.datasetCard.buttons.browseData")}
                </Button>
              </div>
            )
          )}

          {categoryItem?.enableSDMXDownload && sdmxAvailableFormats.length > 0 && (
            <SdmxDownloadButton
              nodeId={categoryItem.nodeId}
              datasetTitle={dataset.title}
              classes={classes}
              datasetId={dataset.identifier}
              avaiLableFormats={sdmxAvailableFormats}
            ></SdmxDownloadButton>
          )}

          {(dataset.attachedDataFiles || dataset.note || dataset.referenceMetadata) && (
            <div className={classes.actions}>
              <DatasetAttachments title={dataset.title} attachedDataFiles={dataset.attachedDataFiles} />
              {dataset.note && <CatalogInfoButton note={dataset.note} />}
              {dataset.referenceMetadata && (categoryItem ? categoryItem.enableMetadata : true) && (
                <DatasetMetadataButton
                  label={t("components.datasetCard.buttons.metadata")}
                  externalClassName={classes.metadataOptionButton}
                  asIconButton={false}
                  metadataUrl={dataset.referenceMetadata}
                  datasetId={dataset.identifier}
                  nodeId={nodeId}
                  iconSize="small"
                />
              )}
            </div>
          )}
        </div>
        {dataset.description && (
          <div className={classes.description}>
            <div
              id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text-wrapper`}
              className={`${classes.descriptionTextWrapper} ${
                isExpanded ? classes.descriptionTextWrapperExpanded : classes.descriptionTextWrapperCollapsed
              }`}
            >
              {dataset.description && (
                <SanitizedHTML
                  id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__text`}
                  html={dataset.description}
                  className={classes.descriptionText}
                />
              )}
            </div>
            <div
              id={`${getIdFromDatasetIdentifier(dataset.identifier)}__description__icon`}
              className={classes.descriptionIcon}
            >
              <Tooltip title={isExpanded ? t("commons.confirm.collapse") : t("commons.confirm.expand")}>
                <IconButton onClick={isExpanded ? onCollapse : onExpand}>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(DatasetCard);
