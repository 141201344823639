import React, {Fragment} from "react";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup} from "@material-ui/core";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ExportNodeViewModel} from "../../../../model/wshub-models/exportNodeViewModel";
import CustomDialogTitle from "../../../custom-dialog-title";
import PasswordInput from "../../../password-input";
import useApi from "../../../../state/api/useApi";
import {external} from "../../../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../../../middlewares/request/requestActions";

const downloadNodeFile = (data: string, nodeCode: string) => {
  const todayDate: Date = new Date();
  const link = document.createElement("a");
  link.href = "data:text/plain;charset=utf-8," + data.toString();
  link.download = nodeCode + "_" + todayDate.toISOString().split("T")[0].replace(/-/g, "") + ".dbrowser.bak";
  link.click();
};

type NodeExportFormType = ExportNodeViewModel & {confirmPassword: string};

interface NodeExportFormProps {
  onClose: () => void;
  nodeCode: string;
}
const NodeExportForm = ({onClose, nodeCode}: NodeExportFormProps) => {
  const {
    handleSubmit,
    watch,
    formState: {errors},
    control,
    setValue
  } = useForm<NodeExportFormType>({
    defaultValues: {
      includeNode: true,
      includeTemplate: false,
      includeDashboards: false,
      includeView: false,
      password: "",
      confirmPassword: ""
    }
  });

  const {t} = useTranslation();

  const isSamePassword = () => {
    const errorText: string = t(`scenes.nodesSettings.exportSettings.passwordError`);
    return watch("password") === watch("confirmPassword") || errorText;
  };

  const {call, request, clear} = useApi<string>(
    external(
      initRequest("exportNode", null, RequestMethod.POST, undefined, t => ({
        onStart: t("scenes.nodesSettings.exportSettings.loading")
      })),
      "dashboard"
    ),
    {
      onSuccess: d => {
        downloadNodeFile(d, nodeCode);
        clear();
        onClose();
      }
    }
  );

  const submitHandler: SubmitHandler<NodeExportFormType> = ({confirmPassword, ...data}) =>
    call({...request, data, url: `api/core/export/nodes/${nodeCode}`});

  return (
    <Dialog open={true} disableEnforceFocus maxWidth="sm" fullWidth onClose={onClose}>
      <Fragment>
        <CustomDialogTitle onClose={onClose}>
          {t("scenes.nodesSettings.modals.nodeExport", {nodeCode})}
        </CustomDialogTitle>
        <DialogContent
          style={{
            minHeight: 400
          }}
        >
          <form id="exportForm" onSubmit={handleSubmit(submitHandler)}>
            <h3>{t(`scenes.nodesSettings.exportSettings.selectSettings`)}</h3>
            <FormGroup>
              <Controller
                name="includeTemplate"
                control={control}
                render={({field}) => <FormControlLabel control={<Checkbox {...field} />} label="Template" />}
              />
              <Controller
                name="includeView"
                control={control}
                render={({field}) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        onChange={e => {
                          if (watch("includeDashboards")) {
                            setValue("includeDashboards", false);
                          }
                          field.onChange(e);
                        }}
                      />
                    }
                    label="View"
                  />
                )}
              />
              <Controller
                name="includeDashboards"
                control={control}
                render={({field}) => (
                  <FormControlLabel
                    control={
                      <Checkbox disabled={!watch("includeView")} {...field} checked={watch("includeDashboards")} />
                    }
                    label="Dashboard"
                  />
                )}
              />
              <h3>{t(`scenes.nodesSettings.exportSettings.passwordInfo`)}</h3>
              <Controller
                name="password"
                control={control}
                render={({field}) => (
                  <PasswordInput
                    {...field}
                    label="Password"
                    variant="outlined"
                    helperText={errors.password?.message}
                    error={!!errors.password}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  validate: isSamePassword
                }}
                render={({field}) => (
                  <PasswordInput
                    {...field}
                    style={{marginTop: "20px"}}
                    label="Confirm Password"
                    variant="outlined"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                )}
              />
            </FormGroup>
          </form>
        </DialogContent>
        <DialogActions>
          <Fragment>
            <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
            <Button form="exportForm" type="submit">
              {t(`scenes.nodesSettings.exportSettings.exportButton`)}
            </Button>
          </Fragment>
        </DialogActions>
      </Fragment>
    </Dialog>
  );
};

export default NodeExportForm;
