import React, {createRef, Fragment, useEffect, useState} from "react";
import {withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {compose} from "redux";
import Call from "../../hocs/call";
import {getNodeInternalUrl, goToCustomPage, goToNodeCategories, goToNodeDashboards} from "../../links";
import {NodeVisibility} from "../../model/IHubMinimalNode.d.ts";
import {ConfigNodeMenuId} from "../../model/item-containers-models/configNodeMenuId";
import CardsGrid from "../cards-grid";
import Footer from "../footer";
import Hero from "../hero";
import ItemContainers from "../item-containers";
import ModulesPlaceholder from "../modules-placeholder";
import NodeHeader from "../node-header";
import Page from "../page";
import PageSection from "../page-section";
import SanitizedHTML from "../sanitized-html";
import useLanguages from "../../state/hooks/useLanguages";
import {fetchNodeItemContainers} from "../../state/node/nodeActions";
import {nodeCustomHomeIdPageSelector, nodeCustomPagesSelector} from "../../state/node/nodeSelectors";
import {openSearchDialog} from "../../state/search-dialog/searchDialogActions";
import {WINDOW_SCREEN_WIDTH_MD} from "../../utils/constants";
import {getCustomPagesInMenu} from "../../utils/itemContainers";
import {getPageTitle} from "../../utils/other";
import themeConfig from "../../theme-config/config.json";

const styles = theme => ({
  main: {
    width: "100%",
    height: "100%"
  },
  fullWidthContainer: {
    backgroundColor: "#f5f5f5",
    width: "100%"
  },
  container: {
    paddingTop: theme.spacing(3)
  },
  anchorContainer: {
    position: "relative"
  },
  anchor: {
    position: "absolute",
    top: -theme.mixins.toolbar.minHeight
  },
  section: {
    paddingTop: theme.spacing(3)
  },
  nodesContainer: {
    marginTop: theme.spacing(2)
  }
});

const mapStateToProps = state => ({
  isA11y: state.app.isA11y,
  baseURL: state.config.baseURL,
  language: state.app.language,
  hub: state.hub,
  node: state.node,
  catalog: state.catalog
});

const mapDispatchToProps = dispatch => ({
  onSearch: () => dispatch(openSearchDialog())
});

const scrollToRef = ref => {
  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest"
  });
  ref.current.focus({preventScroll: true});
};

const Node = ({
  t,
  classes,
  defaultTreeOpen,
  defaultNodeConfigOpen,
  defaultAppConfigOpen,
  defaultUserConfigOpen,
  defaultNodesConfigOpen,
  node,
  nodeCode,
  hub,
  catalog,
  baseURL,
  isDefault,
  isA11y,
  onSearch,
  language
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const info = createRef();
  const nodes = createRef();

  useEffect(() => {
    const setSearchButtonVisibility = () => {
      setIsSearchVisible(window.innerWidth > WINDOW_SCREEN_WIDTH_MD);
    };
    setSearchButtonVisibility();
    window.addEventListener("resize", setSearchButtonVisibility);
    return () => window.removeEventListener("resize", setSearchButtonVisibility);
  }, []);

  const nodeMinimalInfo = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());

  const otherNodes = hub.nodes.filter(
    n => n.code.toLowerCase() !== nodeCode.toLowerCase() && n.visible !== NodeVisibility.No
  );

  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  const paramsStr = params.toString();
  const path = location.pathname;
  const nodeCustomPages = useSelector(nodeCustomPagesSelector);
  const nodeCustomHomePageId = useSelector(nodeCustomHomeIdPageSelector(nodeMinimalInfo.nodeId));
  const customPagesInCentralMenu = getCustomPagesInMenu(nodeCustomPages, ConfigNodeMenuId.CENTRAL);
  const {localizeI18nObj} = useLanguages();
  if (nodeCustomHomePageId) {
    return (
      <ItemContainers
        hideBreadcrumbs={true}
        isDefault={isDefault}
        itemContainerId={nodeCustomHomePageId}
        itemContainers={nodeCustomPages.filter(v => v.itemContainerId === nodeCustomHomePageId)}
      />
    );
  }

  const showNodeDashboardsCentral = () => {
    if (!isA11y) {
      if (themeConfig.enableNewDashboard) {
        return node?.itemContainers?.dashboard?.length > 0;
      } else {
        return node?.dashboards?.length > 0;
      }
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Call
        cbParam={{nodeId: node?.nodeId}}
        cb={({nodeId}) => dispatch(fetchNodeItemContainers(nodeId, "customPages"))}
        disabled={!node?.nodeId || !themeConfig.enableCustomPage}
      >
        <span />
      </Call>
      <Call
        cbParam={{nodeId: node?.nodeId}}
        cb={({nodeId}) => dispatch(fetchNodeItemContainers(nodeId, "dashboard"))}
        disabled={!node?.nodeId || !themeConfig.enableNewDashboard}
      >
        <span />
      </Call>
      {!isA11y && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      {node && node.description && node.description.length > 0 && (
        <HashLink to={{hash: "#node-information", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.nodeInformation")}
        </HashLink>
      )}
      {isDefault && otherNodes.length > 0 && (
        <HashLink to={{hash: "#other-nodes", search: location.search}} className="skip-link sr-only">
          {t("commons.hashLinks.otherNodes")}
        </HashLink>
      )}
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page
        id={isDefault ? "landing-page" : "node-page"}
        title={getPageTitle([nodeMinimalInfo?.name, hub?.hub?.name], t)}
      >
        <NodeHeader
          hub={hub.hub}
          nodes={hub.nodes}
          catalog={catalog}
          defaultTreeOpen={defaultTreeOpen}
          title={nodeMinimalInfo.name}
          node={node}
          defaultNodeConfigOpen={defaultNodeConfigOpen}
          defaultAppConfigOpen={defaultAppConfigOpen}
          defaultUserConfigOpen={defaultUserConfigOpen}
          defaultNodesConfigOpen={defaultNodesConfigOpen}
          nodeId={nodeMinimalInfo.nodeId}
          isDefault={isDefault}
        />
        <main id="main" className={`${classes.main} node`}>
          <Hero
            title={nodeMinimalInfo.name}
            slogan={node && node.slogan}
            logo={node && nodeMinimalInfo.logoURL && <img src={baseURL + nodeMinimalInfo.logoURL} alt="" />}
            background={
              nodeMinimalInfo.backgroundMediaURL ? (
                nodeMinimalInfo.backgroundMediaURL.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG|svg|SVG)$/) ? (
                  <div
                    style={{
                      background: `url("${
                        baseURL + nodeMinimalInfo.backgroundMediaURL
                      }") center center / cover no-repeat`
                    }}
                  />
                ) : (
                  <video autoPlay muted loop>
                    <source src={baseURL + nodeMinimalInfo.backgroundMediaURL} />
                  </video>
                )
              ) : (
                <div
                  style={{background: `url("./images/default-node-background.jpg") center center / cover no-repeat`}}
                />
              )
            }
            showExtraLogos={isDefault}
          >
            <Grid container justifyContent="center" spacing={2}>
              {node && node.description && node.description.length > 0 && (
                <Grid item>
                  <Button
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={() => scrollToRef(info)}
                    id="infos-btn"
                  >
                    {t("scenes.node.informations")}
                  </Button>
                </Grid>
              )}
              {catalog && !catalog.isEmpty && (
                <Grid item>
                  <Button
                    id="explore-btn"
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={() => goToNodeCategories(nodeCode)}
                  >
                    {t("scenes.node.explore")}
                  </Button>
                </Grid>
              )}
              {showNodeDashboardsCentral() && (
                <Grid item>
                  <Button
                    id="dashboards-btn"
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={() => goToNodeDashboards(nodeCode)}
                  >
                    {t("scenes.node.dashboards")}
                  </Button>
                </Grid>
              )}
              {isDefault && otherNodes.length > 0 && (
                <Grid item>
                  <Button
                    id="other-nodes-btn"
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={() => scrollToRef(nodes)}
                  >
                    {t("scenes.node.otherNodes")}
                  </Button>
                </Grid>
              )}
              {catalog && !catalog.isEmpty && isSearchVisible && (
                <Grid item>
                  <Button id="search-btn" size="large" color="secondary" variant="contained" onClick={onSearch}>
                    {t("scenes.node.search")}
                  </Button>
                </Grid>
              )}
              {!isA11y &&
                themeConfig.enableCustomPage &&
                customPagesInCentralMenu.map(v => (
                  <Grid item key={v.itemContainerId}>
                    <Button
                      id="customPages-btn"
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => goToCustomPage(v.itemContainerId, nodeCode)}
                    >
                      {localizeI18nObj(v.title)}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Hero>
          <div className={classes.fullWidthContainer}>
            <Container className={classes.container} id="section-container">
              {node && node.description && node.description.length > 0 && (
                <div className={classes.anchorContainer} id="description-section">
                  <div id="node-information" className={classes.anchor} ref={info} tabIndex={-1} />
                  <PageSection
                    className={`node-information ${classes.section}`}
                    sectiontitle={t("scenes.node.informations")}
                  >
                    <Box textAlign="justify">
                      <SanitizedHTML html={node.description} allowTarget />
                    </Box>
                  </PageSection>
                </div>
              )}
              <ModulesPlaceholder id="node-home-sections" className={classes.section} />
              {isDefault && otherNodes.length > 0 && (
                <div id="other-nodes-section" className={classes.anchorContainer}>
                  <div id="other-nodes" className={classes.anchor} ref={nodes} tabIndex={-1} />
                  <PageSection className={classes.section} sectiontitle={t("scenes.node.otherNodes")}>
                    <Box className={classes.nodesContainer}>
                      <nav aria-label={t("scenes.node.otherNodes")}>
                        <CardsGrid
                          list={otherNodes
                            .sort((a, b) => a.order - b.order)
                            .map(({code, name, backgroundMediaURL}) => ({
                              code,
                              id: code,
                              label: name,
                              image: backgroundMediaURL
                                ? baseURL + backgroundMediaURL
                                : "./images/default-node-background.jpg"
                            }))}
                          getHref={node => getNodeInternalUrl(node?.code)}
                          {...themeConfig.nodesGridProps}
                        />
                      </nav>
                    </Box>
                  </PageSection>
                </div>
              )}
            </Container>
            <Footer />
          </div>
        </main>
      </Page>
    </Fragment>
  );
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Node);
