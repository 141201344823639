import React, {Fragment, useEffect, useState} from "react";
import {makeStyles, Paper, Tab, Tabs} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {HashLink} from "react-router-hash-link";
import {getHomeInternalUrl, getNodeInternalUrl, goToHome} from "../../links";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemContainersMinimalInfoDto} from "../../model/item-containers-models/itemContainersMinimalInfoDto";
import CustomEmpty from "../custom-empty";
import CustomLink from "../custom-link";
import Footer from "../footer";
import ItemContainer from "../item-container";
import NodeHeader from "../node-header";
import Page from "../page";
import ItemContainerFilter, {FilterProvider} from "./ItemContainerFilters";
import useApi from "../../state/api/useApi";
import {appSelector} from "../../state/app/appSelectors";
import {catalogSelector} from "../../state/catalog/catalogSelectors";
import useLanguages from "../../state/hooks/useLanguages";
import {hubSelector} from "../../state/hub/hubSelectors";
import {clearItemContainersItemContainers, getItemContainerById} from "../../state/itemContainer/itemContainerActions";
import {nodeSelector} from "../../state/node/nodeSelectors";
import {showUserLoginForm} from "../../state/user/userActions";
import {userSelector} from "../../state/user/userSelectors";
import {getPageTitle} from "../../utils/other";
import {getIsUserAuthenticated} from "../../utils/user";

declare global {
  interface Window {
    language: string;
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    overflow: "hidden"
  },
  fullWidthContainer: {
    padding: "0 8px",
    width: "100%",
    height: "100%"
  },
  breadcrumbs: {
    padding: "16px 8px 8px"
  },
  breadcrumbsSeparator: {
    margin: "0 4px"
  },
  breadcrumbsLastElem: {
    color: theme.palette.primary.main,
    padding: 4
  },
  itemContainersTitle: {
    padding: "0 12px 4px",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0
  },
  itemContainerTitle: {
    padding: "0 12px 4px",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0
  },
  ItemContainer: {
    overflow: "auto"
  },
  ItemContainerHeader: {
    padding: "8px 4px"
  },
  itemContainerFilter: {
    padding: "0 12px"
  }
}));

const handleHeight = () => {
  const ItemContainersBreadcrumbsHeight = document.getElementById("itemContainers__breadcrumbs")
    ? document.getElementById("itemContainers__breadcrumbs").offsetHeight
    : 0;
  const ItemContainersTitleHeight = document.getElementById("itemContainers__title")
    ? document.getElementById("itemContainers__title").offsetHeight
    : 0;
  const ItemContainersFiltersHeight = document.getElementById("itemContainers__filters")
    ? document.getElementById("itemContainers__filters").offsetHeight
    : 0;
  const ItemContainersHeaderHeight = document.getElementById("itemContainers__header")
    ? document.getElementById("itemContainers__header").offsetHeight
    : 0;
  const footerHeight = document.getElementById("footer") ? document.getElementById("footer").offsetHeight : 0;
  if (document.getElementById("itemContainers__content")) {
    document
      .getElementById("itemContainers__content")
      .setAttribute(
        "style",
        `height: calc(100% - ${
          ItemContainersBreadcrumbsHeight +
          ItemContainersTitleHeight +
          ItemContainersFiltersHeight +
          ItemContainersHeaderHeight +
          footerHeight
        }px)`
      );
  }
};

const AccessibleLinks = ({nodeCode}: {nodeCode?: string}) => {
  const node = useSelector(nodeSelector);
  const {t} = useLanguages();
  const location = useLocation();
  const {isA11y} = useSelector(appSelector);
  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  const paramsStr = params.toString();
  const path = node?.nodeId && nodeCode ? `/${window.language}/${nodeCode.toLowerCase()}` : `/${window.language}`;
  return (
    <>
      {!isA11y && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink to={{hash: "#main", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink to={{hash: "#footer", search: location.search}} className="skip-link sr-only">
        {t("commons.hashLinks.footer")}
      </HashLink>
    </>
  );
};

const BreadcrumbSection = () => {
  const {t} = useLanguages();
  const hub = useSelector(hubSelector);
  const node = useSelector(nodeSelector);
  const classes = useStyles();
  return (
    <div id="itemContainers__breadcrumbs">
      <Breadcrumbs
        aria-label={t("components.results.breadcrumbs.ariaLabel")}
        classes={{
          root: classes.breadcrumbs,
          separator: classes.breadcrumbsSeparator
        }}
      >
        <CustomLink
          to={getHomeInternalUrl()}
          text={t("scenes.itemContainers.breadcrumbs.home")}
          textStyle={{padding: 4}}
        />
        {node?.nodeId && (hub.nodes || []).length > 1 && (
          <CustomLink to={getNodeInternalUrl(node.code)} text={node?.name || node.code} textStyle={{padding: 4}} />
        )}
        <span className={classes.breadcrumbsLastElem}>
          {window.location.href.includes("dashboards")
            ? t("scenes.dashboards.breadcrumbs.dashboards")
            : t("scenes.customPages.breadcrumbs.customPages")}
        </span>
      </Breadcrumbs>
    </div>
  );
};

const allHaveSameFilter = (items: ItemContainersMinimalInfoDto[]) =>
  items.every(
    item =>
      !!item.filterId &&
      items[0]?.filterId === item.filterId &&
      _.isEqual(items[0].filterLevelsSelected, item.filterLevelsSelected)
  );

interface ItemContainersProps {
  itemContainerId?: number;
  nodeCode?: string;
  isDefault: boolean;
  hideBreadcrumbs: boolean;
  itemContainers: ItemContainersMinimalInfoDto[];
}
function ItemContainers({
  itemContainerId,
  nodeCode,
  isDefault,
  hideBreadcrumbs,
  itemContainers = []
}: ItemContainersProps) {
  const classes = useStyles();
  const {t, localizeI18nObj} = useLanguages();
  const dispatch = useDispatch();
  const [tabIdx, setTabIdx] = useState(0);
  const user = useSelector(userSelector);
  const hub = useSelector(hubSelector);
  const node = useSelector(nodeSelector);
  const catalog = useSelector(catalogSelector);
  const location = useLocation();
  const nodeId = node?.nodeId;
  const currentItemContainerId = itemContainerId ?? itemContainers?.[tabIdx]?.itemContainerId;
  const hasGlobalFilter = allHaveSameFilter(itemContainers);
  const isDashboards = window.location.href.includes("dashboards");
  const {call, data, request, error} = useApi<ItemContainerDto>(
    getItemContainerById(
      currentItemContainerId,
      node?.nodeId || -1,
      location.pathname.includes("dashboards") ? "dashboard" : "custompage"
    ),
    {
      cache: true,
      getCacheKey: req => req.url
    }
  );
  useEffect(() => {
    handleHeight();
    if (currentItemContainerId) {
      call({...request, data, url: `ItemContainers/${currentItemContainerId}?nodeId=${node?.nodeId || -1}`});
    }
  }, [currentItemContainerId, user?.userId]);

  useEffect(() => {
    window.addEventListener("resize", handleHeight);
    return () => window.removeEventListener("resize", handleHeight);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearItemContainersItemContainers());
    };
  }, []);

  useEffect(() => {
    if (error) {
      if (!getIsUserAuthenticated(user)) {
        dispatch(
          showUserLoginForm(
            location.pathname.includes("dashboards") ? "NotAccessibleDashboard" : "NotAccessibleCustomPage",
            "warning",
            true
          )
        );
      } else {
        goToHome();
      }
    }
  }, [error]);

  const asCustomPage = t("scenes.itemContainer.asCustomPage");
  return (
    <FilterProvider filterData={data?.filter ? {filter: data.filter, selectedLevels: data.filterLevelsSelected} : null}>
      <Fragment>
        <AccessibleLinks nodeCode={nodeCode} />
        <Page
          title={
            node
              ? getPageTitle([t("scenes.itemContainer.title", {name: node?.name}), hub?.hub?.name], t)
              : getPageTitle([t("scenes.itemContainer.title", {name: hub?.hub?.name})], t)
          }
        >
          <div key={currentItemContainerId} className={classes.root}>
            <NodeHeader
              noNode={!nodeId}
              hub={hub.hub}
              nodes={hub.nodes}
              node={nodeId ? node : undefined}
              nodeId={nodeId ? nodeId : undefined}
              isDefault={nodeId ? isDefault : undefined}
              title={nodeId ? node?.name : undefined}
              catalog={nodeId ? catalog : undefined}
              getCustomA11yPath={isA11y => {
                if (isA11y) {
                  if (nodeCode) {
                    return `/${window.language}/${nodeCode.toLowerCase()}`;
                  } else {
                    return `/${window.language}`;
                  }
                } else {
                  return false;
                }
              }}
            />
            <main id="main" className={`${classes.fullWidthContainer} itemContainers`}>
              {!hideBreadcrumbs && <BreadcrumbSection />}

              {currentItemContainerId && !error ? (
                <>
                  <div id="itemContainers__title">
                    <Typography
                      variant={"h1"}
                      className={
                        itemContainerId || itemContainers.length === 1
                          ? classes.itemContainersTitle
                          : classes.itemContainerTitle
                      }
                    >
                      {itemContainerId || itemContainers.length === 1
                        ? localizeI18nObj(data?.title)
                        : isDashboards
                        ? t("scenes.dashboards.asItemContainer.title")
                        : t("scenes.itemContainer.itemContainer.title")}
                    </Typography>
                  </div>
                  {hasGlobalFilter && (
                    <div className={classes.itemContainerFilter}>
                      {data?.filter && <ItemContainerFilter itemContainer={data} />}
                    </div>
                  )}
                  {(itemContainers || []).length > 1 && (
                    <div id="itemContainers__header" className={classes.ItemContainerHeader}>
                      <Paper>
                        <Tabs
                          value={tabIdx}
                          variant="scrollable"
                          scrollButtons="auto"
                          onChange={(_, newValue) => {
                            setTabIdx(newValue);
                          }}
                        >
                          {itemContainers.map(({itemContainerId, title}, idx) => (
                            <Tab
                              key={idx}
                              label={localizeI18nObj(title)}
                              id={`tab__${itemContainerId}`}
                              aria-controls={`itemContainer__${itemContainerId}`}
                              tabIndex={0}
                            />
                          ))}
                        </Tabs>
                      </Paper>
                    </div>
                  )}
                  {!hasGlobalFilter && (
                    <div className={classes.itemContainerFilter}>
                      {data?.filter && <ItemContainerFilter itemContainer={data} />}
                    </div>
                  )}
                  <div id="itemContainers__content" className={classes.ItemContainer}>
                    <div
                      id={`itemContainer_${currentItemContainerId}`}
                      aria-labelledby={`tab__${currentItemContainerId}`}
                    >
                      <ItemContainer itemContainer={data} />
                    </div>
                  </div>
                </>
              ) : !error ? (
                <CustomEmpty
                  text={
                    nodeId
                      ? t("scenes.itemContainer.noItemContainer.node", {
                          type: isDashboards ? "Dashboards" : asCustomPage
                        })
                      : t("scenes.itemContainer.noItemContainer.hub", {
                          type: isDashboards ? "Dashboards" : asCustomPage
                        })
                  }
                />
              ) : (
                <CustomEmpty text={t("components.itemContainer.status.requestError")} />
              )}
              <Footer />
            </main>
          </div>
        </Page>
      </Fragment>
    </FilterProvider>
  );
}

export default ItemContainers;
