import _ from "lodash";
import {Reducer} from "redux";
import {DatasetData} from "../../model/IDatasetData";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ITEMCONTAINERS_ITEMCONTAINER_FETCH, ITEMCONTAINERS_ITEMCONTAINERS_CLEAR} from "./itemContainerActions";
import {REQUEST_ERROR, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {getCriteriaObjectFromArray} from "../../utils/criteria";

export type ItemContainerState = {
  itemContainers: Record<string, ItemContainerDto>;
  jsonStats: Record<string, Record<string, DatasetData | string>>;
  layouts: Record<string, Record<string, any>>;
  filterTrees: Record<string, Record<string, any>>;
  timePeriodsByFreq: Record<string, Record<string, any>>;
  dynamicViewPendingRequests: string[];
};

const initialState = {
  itemContainers: null,
  jsonStats: null,
  layouts: null,
  filterTrees: null,
  timePeriodsByFreq: null,
  dynamicViewPendingRequests: []
};

const itemContainerReducer: Reducer<ItemContainerState> = (state = initialState, action) => {
  switch (action.type) {
    case ITEMCONTAINERS_ITEMCONTAINERS_CLEAR: {
      return {
        ...initialState
      };
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case ITEMCONTAINERS_ITEMCONTAINER_FETCH: {
          const views = _.cloneDeep(action.payload.response.views);
          for (let key in views) {
            if (views.hasOwnProperty(key)) {
              views[key] = {
                ...views[key],
                datasetId: views[key].datasetId ? views[key].datasetId.split("+").join(",") : undefined,
                criteria: getCriteriaObjectFromArray(views[key].criteria),
                layouts: JSON.parse(views[key].layouts)
              };
            }
          }

          return {
            ...state,
            itemContainers: {
              ...state.itemContainers,
              [action.payload.extra.itemContainerId]: {
                ...action.payload.response,
                item: action.payload.response.item ? JSON.parse(JSON.stringify(action.payload.response.item)) : {},
                filterLevels: action.payload.response.filterLevels
                  ? JSON.parse(JSON.stringify(action.payload.response.filterLevels))
                  : {},
                views: views
              }
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case ITEMCONTAINERS_ITEMCONTAINER_FETCH: {
          return {
            ...state,
            itemContainers: {
              ...state.itemContainers,
              [action.payload.extra.itemContainerId]: null
            }
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default itemContainerReducer;
