import React from "react";
import {connect} from "react-redux";
import ModuleLoader from "../module-loader";

const mapStateToProps = state => ({
  modulesConfig: state.app.modulesConfig
});

const ModulesPlaceholder = ({modulesConfig, id: placeholderId, moduleFallback, className, style, ...props}) => (
  <div id={placeholderId} className={className} style={style}>
    {(modulesConfig.placeholders?.[placeholderId] || []).map((module, idx) => (
      <div key={idx} id={`${placeholderId}__${module.id}`}>
        <ModuleLoader
          id={module.id}
          component={module.component}
          fallback={moduleFallback || module.fallback}
          {...props}
        />
      </div>
    ))}
  </div>
);

export default connect(mapStateToProps)(ModulesPlaceholder);
