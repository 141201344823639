import React, {Fragment} from "react";
import {connect} from "react-redux";
import {useSelector} from "react-redux";
import {HashRouter, Route, Routes} from "react-router-dom";
import Error from "../components/error";
import ExternalLogin from "../components/external-login";
import Home from "../components/home";
import ModulePage from "../components/module-page";
import Node from "../components/node";
import WithCustomTheme from "../components/with-custom-theme";
import A11yDomain from "../domains/A11yDomain";
import AppDomain from "../domains/AppDomain";
import ConfirmEmailDomain from "../domains/ConfirmEmailDomain";
import DashboardsDomain from "../domains/DashboardsDomain";
import HubDomain from "../domains/HubDomain";
import ItemContainersDomain from "../domains/ItemContainersDomain";
import MultiLanguageDomain from "../domains/MultiLanguageDomain";
import NodeDomain from "../domains/NodeDomain";
import ResetPasswordDomain from "../domains/ResetPasswordDomain";
import NodeRouter from "./NodeRouter";
import {hubCustomHomeIdPageSelector} from "../state/hub/hubSelectors";
import themeConfig from "../theme-config/config.json";

const mapStateToProps = state => ({
  hub: state.hub,
  node: state.node,
  modulesConfig: state.app.modulesConfig
});

const HubRouter = ({hub, node, modulesConfig, clearPendingRequests, flushSpinner}) => {
  const defaultNode = (hub?.nodes || []).find(node => node.default === true) || null;
  const isDefault = (node && defaultNode && node.nodeId === defaultNode.nodeId) || false;
  const nodeCode = node ? node.code : null;
  const hubHomePageId = useSelector(hubCustomHomeIdPageSelector);

  return (
    <HashRouter>
      <AppDomain>
        <WithCustomTheme nodeCode={nodeCode}>
          <A11yDomain>
            <Routes>
              <Route
                path="/"
                element={
                  defaultNode !== null ? (
                    <HubDomain>
                      <NodeDomain nodeCode={defaultNode.code}>
                        <Node nodeCode={defaultNode.code} isDefault />
                      </NodeDomain>
                    </HubDomain>
                  ) : hubHomePageId ? (
                    <ItemContainersDomain
                      hideBreadcrumbs={true}
                      nodeCode={nodeCode}
                      isDefault={isDefault}
                      itemContainerId={hubHomePageId}
                    />
                  ) : (
                    <MultiLanguageDomain>
                      <HubDomain>
                        <Home />
                      </HubDomain>
                    </MultiLanguageDomain>
                  )
                }
              />
              <Route path="/auth/shibboleth" element={<ExternalLogin />} />
              <Route path="/error" element={<Error />} />
              <Route
                path="/:lang"
                element={
                  defaultNode !== null ? (
                    <MultiLanguageDomain>
                      <HubDomain>
                        <NodeDomain nodeCode={defaultNode.code}>
                          <Node nodeCode={defaultNode.code} isDefault />
                        </NodeDomain>
                      </HubDomain>
                    </MultiLanguageDomain>
                  ) : hubHomePageId ? (
                    <MultiLanguageDomain>
                      <HubDomain>
                        <ItemContainersDomain
                          hideBreadcrumbs={true}
                          nodeCode={nodeCode}
                          isDefault={isDefault}
                          itemContainerId={hubHomePageId}
                        />
                      </HubDomain>
                    </MultiLanguageDomain>
                  ) : (
                    <MultiLanguageDomain>
                      <HubDomain>
                        <Home />
                      </HubDomain>
                    </MultiLanguageDomain>
                  )
                }
              />
              {themeConfig.enableDashboard && (
                <Route
                  path="/:lang/dashboards"
                  element={
                    <MultiLanguageDomain>
                      <HubDomain>
                        {themeConfig.enableNewDashboard ? (
                          <ItemContainersDomain itemContainers={hub?.hub?.itemContainers} />
                        ) : (
                          <DashboardsDomain isDefault={isDefault} />
                        )}
                      </HubDomain>
                    </MultiLanguageDomain>
                  }
                />
              )}
              {themeConfig.enableDashboard && (
                <Route
                  path={`/:lang/dashboards/${themeConfig.enableNewDashboard ? ":itemContainerId" : ":dashboardId"}`}
                  element={
                    <MultiLanguageDomain>
                      <HubDomain>
                        {themeConfig.enableNewDashboard ? (
                          <ItemContainersDomain isDefault={defaultNode !== undefined} />
                        ) : (
                          <DashboardsDomain isDefault={isDefault} />
                        )}
                      </HubDomain>
                    </MultiLanguageDomain>
                  }
                />
              )}
              <Route
                path="/:lang/resetPassword"
                element={
                  <MultiLanguageDomain>
                    <ResetPasswordDomain>
                      {defaultNode !== null ? (
                        <HubDomain>
                          <NodeDomain nodeCode={defaultNode.code}>
                            <Node nodeCode={defaultNode.code} isDefault />
                          </NodeDomain>
                        </HubDomain>
                      ) : (
                        <HubDomain>
                          <Home />
                        </HubDomain>
                      )}
                    </ResetPasswordDomain>
                  </MultiLanguageDomain>
                }
              />
              <Route
                path="/:lang/confirmEmail"
                element={
                  <MultiLanguageDomain>
                    <ConfirmEmailDomain>
                      <Fragment>
                        {defaultNode !== null ? (
                          <HubDomain>
                            <NodeDomain nodeCode={defaultNode.code}>
                              <Node nodeCode={defaultNode.code} isDefault />
                            </NodeDomain>
                          </HubDomain>
                        ) : (
                          <HubDomain>
                            <Home />
                          </HubDomain>
                        )}
                      </Fragment>
                    </ConfirmEmailDomain>
                  </MultiLanguageDomain>
                }
              />
              {(modulesConfig.hubRoutes || []).map(module => {
                return (
                  <Route
                    key={module.id}
                    path={`/:lang/${module.route}/*`}
                    element={
                      <MultiLanguageDomain>
                        <HubDomain>
                          <ModulePage
                            moduleId={module.id}
                            moduleComponent={module.component}
                            moduleFallback={module.fallback}
                            hideAppBar={module.hideAppBar}
                            hideFooter={module.hideFooter}
                            containerMaxWidth={module.containerMaxWidth}
                          />
                        </HubDomain>
                      </MultiLanguageDomain>
                    }
                  />
                );
              })}
              <Route
                path="/:lang/:nodeCode/*"
                element={
                  <MultiLanguageDomain>
                    <HubDomain>
                      <NodeDomain>
                        <NodeRouter isDefault={isDefault} />
                      </NodeDomain>
                    </HubDomain>
                  </MultiLanguageDomain>
                }
              />
              {themeConfig.enableCustomPage && (
                <Route
                  path="/:lang/pages"
                  exact
                  element={
                    <MultiLanguageDomain>
                      <HubDomain>
                        <ItemContainersDomain
                          isDefault={defaultNode !== undefined}
                          itemContainers={hub?.hub?.itemContainers}
                        />
                      </HubDomain>
                    </MultiLanguageDomain>
                  }
                />
              )}
              {themeConfig.enableCustomPage && (
                <Route
                  path="/:lang/pages/:itemContainerId"
                  exact
                  element={
                    <MultiLanguageDomain>
                      <HubDomain>
                        <ItemContainersDomain isDefault={defaultNode !== undefined} />
                      </HubDomain>
                    </MultiLanguageDomain>
                  }
                />
              )}
            </Routes>
          </A11yDomain>
        </WithCustomTheme>
      </AppDomain>
    </HashRouter>
  );
};

export default connect(mapStateToProps)(HubRouter);
