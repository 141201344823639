import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {withStyles} from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import ErrorIcon from "@material-ui/icons/Error";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../hocs/call/index";
import CustomEmpty from "../custom-empty";
import AttributeDialog from "./AttributeDialog";
import {MAP_LAYERS_ALL} from "./constants";
import GeometriesWarningDialog from "./GeometriesWarningDialog";
import {
  clearMapGeometry,
  fetchMapGeometry,
  hideMapNoGeometryWarning,
  setMapGeometry,
  setMapGeometryChangedStatus
} from "../../state/maps/mapsActions";
import {usePrevious} from "../../utils/customHooks";
import {getFilterTreeFromJsonStat, VARIATION_DIMENSION_KEY} from "../../utils/dataset";
import {getFormattedValue} from "../../utils/formatters";
import {isNumeric, isValidIntegerInInclusiveRange} from "../../utils/validator";
import {
  getCleanedSetting,
  getGeneralizationLevelFromZoomLevel,
  getLayerOptions,
  getLayoutGeometries,
  getOverlayImageStyle,
  getOverlayStyle,
  getOverlayTextStyle,
  getTimeDimValueFromLayout,
  getTranslationOptions,
  isSettingsChanged
} from "./utils";
import "./style.css";

const styles = theme => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  map: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 0,
    filter: "blur(0)"
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2,
    visibility: "hidden"
  }
});

const mapStateToProps = state => {
  const hubExtras = JSON.parse(state.hub?.hub?.extras || "{}");
  return {
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    defaultExtent: state.appConfig.mapConfig.defaultExtent,
    geometryBorderColor: state.appConfig.mapConfig.geometryBorderColor,
    zoomLevelStepsMap: state.appConfig.mapConfig.zoomLevelStepsMap,
    baseMap: hubExtras?.BaseMap || null,
    mapCopyright: hubExtras?.MapCopyright || null,
    mapLayer: hubExtras?.MapLayer || null,
    mapLayersConfig: state.maps.mapLayersConfig,
    maps: state.maps.maps
  };
};

const mapDispatchToProps = dispatch => ({
  onGeometryFetch: (
    mapStateUuid,
    nodeId,
    territoryDimValues,
    format,
    detailLevel,
    timeDimValue,
    territoryDimCodelist,
    extent,
    generalizationLevel
  ) =>
    dispatch(
      fetchMapGeometry(
        mapStateUuid,
        nodeId,
        territoryDimValues,
        format,
        detailLevel,
        timeDimValue,
        territoryDimCodelist,
        extent,
        generalizationLevel
      )
    ),
  onGeometrySet: (mapStateUuid, geometries) => dispatch(setMapGeometry(mapStateUuid, geometries)),
  onGeometryChangedStatusSet: (mapStateUuid, changed) => dispatch(setMapGeometryChangedStatus(mapStateUuid, changed)),
  onGeometryClear: mapStateUuid => dispatch(clearMapGeometry(mapStateUuid)),
  onWarningHide: mapStateUuid => dispatch(hideMapNoGeometryWarning(mapStateUuid))
});

const getInitialLocationObject = (generalizationLevels, enablePagination) => ({
  extent: null,
  generalizationLevel:
    generalizationLevels === undefined || generalizationLevels === null || enablePagination !== true
      ? null
      : (generalizationLevels || []).length > 0
      ? generalizationLevels[generalizationLevels.length - 1]
      : 0
});

const RENDER_TYPE_NONE = "none";
const RENDER_TYPE_UPDATE_LAYER = "updateLayer";
const RENDER_TYPE_UPDATE_LAYER_DATA = "updateLayerData";

function EnhancedMap(props) {
  const {
    classes,
    theme,

    defaultLanguage,
    languages,
    defaultExtent,
    geometryBorderColor,
    baseMap,
    mapCopyright,
    mapLayer,
    mapLayersConfig,
    maps,

    onGeometryFetch,
    onGeometrySet,
    onGeometryChangedStatusSet,
    onGeometryClear,
    onWarningHide,

    mapId: externalMapId,
    nodeId,
    jsonStat,
    layout,
    labelFormat,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    isFullscreen,
    detailLevel,
    territoryDimCodelist,
    initialBaseLayer,
    defaultSettings,
    settings,
    setSettings,
    onDataClick,
    disableZoomToClickedData = false,
    onDataSelect,
    selectedTerritoryId,
    filteredTerritoryIds,
    onSettingsShow,
    showSelection = false,
    disableBaseLayer = false,
    disableSettings = false,
    showSingleGeometry = false,
    disableWheelZoom = false,
    isPointData = false,
    latAttributeId,
    longAttributeId,
    srid,
    onRenderStart,
    onRenderFinish,
    geometriesYear = null,
    generalizationLevels,
    zoomLevelStepsMap
  } = props;

  const {t} = useTranslation();

  const zoomLevelSteps = useMemo(() => zoomLevelStepsMap?.[detailLevel] || [], [zoomLevelStepsMap, detailLevel]);

  const paginationEnabled = useMemo(() => {
    if ((generalizationLevels || []).length === 0) {
      return false;
    } else {
      if ((zoomLevelSteps || []).length === 0) {
        console.error(
          `appConfig's key "zoomLevelStepsMap" is missing or it's value is empty for current detail level.`
        );
        return false;
      } else if ((zoomLevelSteps || []).findIndex(v => !isNumeric(v)) > -1) {
        console.error(`appConfig's key "zoomLevelStepsMap" value must contains only numeric values.`);
        return false;
      } else if ((zoomLevelSteps || []).length !== (generalizationLevels || []).length - 1) {
        console.error(
          `appConfig's key "zoomLevelStepsMap" value's length is not correct for current detail level (${
            (zoomLevelSteps || []).length
          } instead of ${(generalizationLevels || []).length - 1}).`
        );
        return false;
      } else {
        return true;
      }
    }
  }, [zoomLevelSteps, generalizationLevels]);

  const [mapId, setMapId] = useState(null);

  const [legendTitle] = useState(null);

  const prevJsonStat = usePrevious(jsonStat);
  const prevLayout = usePrevious(layout);

  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [fetchedGeometries, setFetchedGeometries] = useState(null);

  const [geometries, setGeometries] = useState(null);
  const [geometryMap, setGeometryMap] = useState(null);
  const [territoryMap, setTerritoryMap] = useState(null);

  const prevSelectedTerritoryId = usePrevious(selectedTerritoryId);
  const prevFilteredTerritoryIds = usePrevious(filteredTerritoryIds);

  const [mapSettings, setMapSettings] = useState(null);
  const [mapDefaultSettings, setMapDefaultSettings] = useState(null);

  const [renderType, setRenderType] = useState(RENDER_TYPE_NONE);

  const [geometryId, setGeometryId] = useState(null);

  const [dimensionValueTree, setDimensionValueTree] = useState(null);

  const [fullExtent, setFullExtent] = useState(null);

  const [locationObject, setLocationObject] = useState(
    getInitialLocationObject(generalizationLevels, paginationEnabled)
  );

  const [isSpinnerVisible, setSpinnerVisibility] = useState(true);

  const showSpinner = useCallback(() => {
    setSpinnerVisibility(true);
    onRenderStart();
  }, [onRenderStart]);

  const hideSpinner = useCallback(() => {
    setSpinnerVisibility(false);
    onRenderFinish();
  }, [onRenderFinish]);

  const fetchGeometries = useCallback(
    cbParam => {
      const {mapStateUuid, nodeId, detailLevel, territoryDimCodelist, jsonStat, layout, locationObject} = cbParam;

      showSpinner();

      const territoryDim = layout.territoryDim;
      const territoryDimValues = jsonStat.dimension[territoryDim].category.index;
      const timeDimValue = geometriesYear !== null ? geometriesYear : getTimeDimValueFromLayout(jsonStat, layout);

      if (isPointData) {
        const geometries = territoryDimValues.map((value, idx) => ({
          id: value,
          uniqueId: idx + 1
        }));
        onGeometrySet(mapStateUuid, geometries);
      } else {
        const extent =
          paginationEnabled === true
            ? window.mapVariables[mapId].selectedTerritory
              ? null
              : locationObject?.extent || null
            : null;
        const generalizationLevel =
          paginationEnabled === true
            ? window.mapVariables[mapId].selectedTerritory
              ? null
              : locationObject?.generalizationLevel || 0
            : null;

        window.mapVariables[mapId].locationObjectUsed = {
          extent: extent,
          generalizationLevel: generalizationLevel
        };

        const values = window.mapVariables[mapId].selectedTerritory
          ? [window.mapVariables[mapId].selectedTerritory]
          : territoryDimValues;

        onGeometryFetch(
          mapStateUuid,
          nodeId,
          values,
          "geojson",
          detailLevel,
          timeDimValue,
          territoryDimCodelist,
          extent,
          generalizationLevel
        );
      }
    },
    [mapId, isPointData, onGeometryFetch, onGeometrySet, geometriesYear, paginationEnabled, showSpinner]
  );

  // handle mapId and destroy map on component unmount
  useEffect(() => {
    const mapId = externalMapId || uuidv4();
    setMapId(mapId);

    window.mapVariables = window.mapVariables || {};
    window.mapVariables[mapId] = {
      isFirstRender: true,
      areGeometriesUpdated: false,
      areGeometriesChanged: true,
      locationObjectUsed: getInitialLocationObject(null, paginationEnabled),
      selectedTerritory: null
    };

    return () => {
      window.LMap.destroyMap(mapId);

      onGeometryClear(mapId);
      setIsFetchEnabled(true);

      if (onDataSelect) {
        onDataSelect(null);
      }

      window.mapVariables[mapId] = null;
    };
  }, [externalMapId, onGeometryClear, onDataSelect, paginationEnabled]);

  // handle fullscreen
  useEffect(() => {
    if (mapId) {
      window.LMap.handleViewportChange(mapId);
    }
  }, [mapId, isFullscreen]);

  // handle resize
  useEffect(() => {
    if (mapId) {
      const func = () => window.LMap.handleViewportChange(mapId);
      window.addEventListener("resize", func);
      return () => {
        window.removeEventListener("resize", func);
      };
    }
  }, [mapId]);

  // init map
  useEffect(() => {
    if (mapId && !window.LMap.isInitialized(mapId)) {
      const {baseLayer, layers} = getLayerOptions(
        mapLayer,
        initialBaseLayer,
        mapLayersConfig,
        defaultLanguage,
        languages
      );

      const mapOptions = {
        showZoom: true,
        showScale: true,
        baseLayer: baseLayer,
        layers: layers,
        defaultExtent: defaultExtent,
        showBaseLayerRadio: !disableBaseLayer && mapLayer === MAP_LAYERS_ALL && (layers || []).length > 1,
        showSelection: showSelection,
        disableWheelZoom: disableWheelZoom,
        onSettingsChange: settings => {
          if (setSettings) {
            setSettings(getCleanedSetting(settings));
          }
        },
        onLegendCollapsedChange: isCollapsed => {
          if (setSettings) {
            setSettings({isLegendCollapsed: isCollapsed});
          }
        },
        onBaseLayerChange: baseLayer => {
          if (setSettings) {
            setSettings({baseLayer: baseLayer});
          }
        },
        onSettingsShow: settings => {
          if (onSettingsShow) {
            onSettingsShow(settings);
          }
        },
        legendFontFamily: "Roboto",
        primaryColor: theme.palette.primary.main,
        isBaseLayerRadioPopupClosable: true
      };

      const mapTranslations = getTranslationOptions(t);

      window.LMap.initMap(mapId, mapOptions, mapTranslations);
    }
  }, [
    mapId,
    defaultLanguage,
    languages,
    defaultExtent,
    baseMap,
    mapCopyright,
    mapLayer,
    mapLayersConfig,
    initialBaseLayer,
    disableWheelZoom,
    disableBaseLayer,
    showSelection,
    setSettings,
    onSettingsShow,
    t,
    theme.palette.primary.main
  ]);

  // handle jsonStat, layout and location changes
  useEffect(() => {
    if (mapId) {
      const isJsonStatChanged = !_.isEqual(prevJsonStat, jsonStat);

      const isLayoutChanged = !_.isEqual(prevLayout, layout);

      const usedLocalizationLevel = window.mapVariables[mapId].locationObjectUsed.generalizationLevel;
      const currLocalizationLevel = locationObject.generalizationLevel;

      const usedExtent = window.mapVariables[mapId].locationObjectUsed.extent;
      const currExtent = locationObject.extent;

      // TODO: facendo 2 fetch con geometrie vuote rimane lo spinner
      const isLocationChanged =
        usedLocalizationLevel !== currLocalizationLevel ||
        (usedExtent &&
          currExtent &&
          fullExtent &&
          window.LMap.extent3IntersectsDifferenceExtent1Extent2(currExtent, usedExtent, fullExtent)) ||
        false;

      if (isJsonStatChanged || isLayoutChanged || isLocationChanged) {
        setRenderType(RENDER_TYPE_NONE);

        setIsFetchEnabled(true);

        window.mapVariables[mapId].areGeometriesUpdated = false;

        showSpinner();
      }

      if (isJsonStatChanged || isLayoutChanged) {
        setGeometries(null);
        setGeometryMap(null);

        setLocationObject(prevLocationObject => ({
          extent: null,
          generalizationLevel: true // window.LMap.panOrZoomChanged(mapId) TODO: non sembra funzionare correttamente
            ? getInitialLocationObject(generalizationLevels, paginationEnabled).generalizationLevel
            : prevLocationObject.generalizationLevel
        }));

        if (onDataSelect) {
          onDataSelect(null);
        }

        window.mapVariables[mapId].isFirstRender = true;
      }

      setDimensionValueTree(prevVal => {
        const dims = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);
        const layoutDims = [layout.territoryDim, ...layout.filters];

        if (dims.length !== layoutDims.length && (isJsonStatChanged || !prevVal)) {
          const orderedDims = [...layoutDims, ...dims.filter(dim => !layoutDims.includes(dim))];
          return getFilterTreeFromJsonStat(orderedDims, jsonStat);
        } else {
          return prevVal;
        }
      });
    }
  }, [
    mapId,
    prevJsonStat,
    jsonStat,
    prevLayout,
    layout,
    isPointData,
    geometriesYear,
    generalizationLevels,
    paginationEnabled,
    locationObject,
    fullExtent,
    onDataSelect,
    showSpinner
  ]);

  // handle new fetched geometries
  useEffect(() => {
    if (mapId && maps?.[mapId]?.changed === true) {
      const geometries = maps[mapId].geometries;

      setFetchedGeometries(geometries);
      onGeometryChangedStatusSet(mapId, false);

      setIsFetchEnabled(false);

      window.mapVariables[mapId].areGeometriesUpdated = true;
    }
  }, [mapId, maps, showSingleGeometry, onGeometryChangedStatusSet, t]);

  // calculating filtered geometries
  useEffect(() => {
    if (mapId && window.mapVariables[mapId].areGeometriesUpdated && fetchedGeometries !== null) {
      showSpinner();

      const {layoutGeometries, geometryMap, territoryMap} = getLayoutGeometries(
        fetchedGeometries,
        jsonStat,
        layout,
        labelFormat,
        t,
        true,
        dimensionValueTree,
        isPointData,
        latAttributeId,
        longAttributeId
      );

      setGeometries(layoutGeometries);
      if (window.mapVariables[mapId].isFirstRender) {
        setGeometryMap(geometryMap);
        setTerritoryMap(territoryMap);
      }

      window.mapVariables[mapId].areGeometriesChanged = true;
    }
  }, [
    mapId,
    fetchedGeometries,
    jsonStat,
    layout,
    dimensionValueTree,
    labelFormat,
    isPointData,
    latAttributeId,
    longAttributeId,
    t,
    showSingleGeometry,
    showSpinner
  ]);

  // handle map default settings
  useEffect(() => {
    setMapDefaultSettings(prevDefaultSettings =>
      prevDefaultSettings === null ? defaultSettings || {} : prevDefaultSettings
    );
  }, [defaultSettings]);

  // handle map settings
  useEffect(() => {
    setMapSettings(prevSettings => (isSettingsChanged(settings, prevSettings) ? settings : prevSettings));
  }, [settings]);

  // add or update layer with filtered geometries
  useEffect(() => {
    if (geometries !== null && mapSettings !== null && mapDefaultSettings !== null) {
      showSpinner();

      let newSettings = {};
      if (window.mapVariables[mapId].isFirstRender) {
        newSettings = {...newSettings, ...mapDefaultSettings};
      }
      newSettings = {...newSettings, ...mapSettings};

      setTimeout(() => {
        if (!window.mapVariables[mapId].areGeometriesChanged) {
          window.LMap.updateLayerSettings(mapId, newSettings);
        } else {
          if (window.mapVariables[mapId].isFirstRender) {
            const layerOptions = {
              srid: `EPSG:${srid || 4326}`,
              useGeoJSON: true,
              settings: newSettings,
              onDataRender: () => {
                if (window.mapVariables[mapId].selectedTerritory) {
                  window.LMap.zoomToData(mapId, territoryMap[window.mapVariables[mapId].selectedTerritory]);
                  window.mapVariables[mapId].selectedTerritory = null;
                }
                if (window.mapVariables[mapId].isFirstRender) {
                  const newFullExtent = window.LMap.getLayerExtent(mapId);
                  const newFullExtentProjected = window.LMap.ProjectExtent(mapId, newFullExtent, "EPSG:4326");

                  window.LMap.setFullExtent(mapId, newFullExtent);
                  setFullExtent(newFullExtentProjected);
                }
                setRenderType(
                  window.mapVariables[mapId].isFirstRender ? RENDER_TYPE_UPDATE_LAYER : RENDER_TYPE_UPDATE_LAYER_DATA
                );
                window.mapVariables[mapId].isFirstRender = false;
                setTimeout(() => {
                  if (geometries.length > 0) {
                    hideSpinner();
                  }
                }, 200);
              },
              borderColor: geometryBorderColor,
              valueFormatter: val => getFormattedValue(val, decimalSeparator, decimalPlaces, "", roundingStrategy),
              disableSettings: disableSettings,
              dataInfoDuration: 1000,
              onDataClick: (id, isActive, isSelected) => {
                if (id) {
                  if (onDataClick) {
                    onDataClick(geometryMap[id], isActive, isSelected);
                  }
                } else {
                  if (onDataClick) {
                    onDataClick(null, isActive, isSelected);
                  }
                }
              },
              zoomToClickedData: !disableZoomToClickedData,
              onDataSelect: selected => {
                if (onDataSelect) {
                  const territoryDimValues = [];
                  (selected || []).forEach(geomId => territoryDimValues.push(geometryMap[geomId].territoryId));
                  if (territoryDimValues.length > 0) {
                    onDataSelect(territoryDimValues);
                  } else {
                    onDataSelect(null);
                  }
                }
              },
              onDataTooltipClick: id => {
                if (geometryMap[id].hasAttributes) {
                  setGeometryId(id);
                }
              },
              onLocationChanged: (extentToProject, zoomLevel) => {
                if (!isPointData && paginationEnabled === true) {
                  setLocationObject({
                    extent: window.LMap.ProjectExtent(mapId, extentToProject, "EPSG:4326"),
                    generalizationLevel: getGeneralizationLevelFromZoomLevel(
                      zoomLevel,
                      zoomLevelSteps,
                      generalizationLevels
                    )
                  });
                }
              }
            };
            window.LMap.updateLayer(mapId, geometries, legendTitle, layerOptions);

            if (setSettings) {
              const settings = window.LMap.getSettings(mapId);
              if (isSettingsChanged(newSettings, settings)) {
                setSettings(getCleanedSetting(settings));
              }
            }
          } else {
            window.LMap.updateLayerData(mapId, geometries, window.mapVariables[mapId].selectedTerritory !== null);
          }

          window.mapVariables[mapId].areGeometriesChanged = false;
        }
      }, 0);
    }
  }, [
    mapId,
    isPointData,
    srid,
    geometries,
    geometryMap,
    territoryMap,
    mapSettings,
    mapDefaultSettings,
    geometryBorderColor,
    decimalPlaces,
    roundingStrategy,
    decimalSeparator,
    disableZoomToClickedData,
    onDataClick,
    onDataSelect,
    disableSettings,
    legendTitle,
    setSettings,
    paginationEnabled,
    generalizationLevels,
    zoomLevelSteps,
    showSpinner,
    hideSpinner
  ]);

  // handle external geometries filter changes
  useEffect(() => {
    if (mapId && renderType !== RENDER_TYPE_NONE && territoryMap) {
      if (renderType === RENDER_TYPE_UPDATE_LAYER || filteredTerritoryIds !== prevFilteredTerritoryIds) {
        let geometryIds;
        if (filteredTerritoryIds === null) {
          geometryIds = null;
        } else {
          geometryIds = (filteredTerritoryIds || []).map(territoryId => territoryMap[territoryId]).filter(el => el);
        }
        window.LMap.setDataFilter(mapId, geometryIds);
      }
    }
  }, [mapId, renderType, territoryMap, filteredTerritoryIds, prevFilteredTerritoryIds]);

  // handle zoom to data
  useEffect(() => {
    if (mapId && renderType !== RENDER_TYPE_NONE && territoryMap) {
      if (renderType === RENDER_TYPE_UPDATE_LAYER || selectedTerritoryId !== prevSelectedTerritoryId) {
        if (selectedTerritoryId === null) {
          window.LMap.zoomToData(mapId, null);
        } else {
          const valorizedData = window.LMap.getDataIds(mapId, geom => (geom?.geometry?.coordinates || []).length > 0);
          const geometryToZoom = territoryMap[selectedTerritoryId];
          if (valorizedData.includes(selectedTerritoryId)) {
            window.LMap.zoomToData(mapId, geometryToZoom);
          } else {
            window.mapVariables[mapId].selectedTerritory = selectedTerritoryId;
            setIsFetchEnabled(true);
          }
        }
      }
    }
  }, [mapId, renderType, territoryMap, selectedTerritoryId, prevSelectedTerritoryId]);

  if (!mapId) {
    return <span />;
  }

  const territoryDim = layout?.territoryDim;

  const territoryDimMissing = !territoryDim || !jsonStat.dimension[territoryDim];
  const detailLevelMissing = isPointData ? false : !isValidIntegerInInclusiveRange(detailLevel);

  if (territoryDimMissing || detailLevelMissing) {
    return <span />;
  }

  const geometriesMissing = geometryMap && _.isEmpty(geometryMap);
  const geometriesFetchingError = maps[mapId]?.error;

  const isMapUnavailable = geometriesMissing || geometriesFetchingError;

  let unavailableMapErrorText;
  if (isMapUnavailable) {
    if (!isSpinnerVisible) {
      showSpinner();
    }
    if (geometriesMissing) {
      unavailableMapErrorText = t("components.map.noDataToDisplay");
    } else if (geometriesFetchingError) {
      unavailableMapErrorText = t("components.map.fetchingGeometriesError");
    } else {
      unavailableMapErrorText = null;
    }
  }

  return (
    <Fragment>
      <Call
        cb={fetchGeometries}
        cbParam={{
          mapStateUuid: mapId,
          nodeId: nodeId,
          detailLevel: detailLevel,
          territoryDimCodelist: territoryDimCodelist,
          jsonStat: jsonStat,
          layout: layout,
          locationObject: locationObject
        }}
        disabled={territoryDimMissing || !isFetchEnabled}
      >
        <div id={`${mapId}__wrapper`} className={`${classes.root} map`} aria-hidden={true}>
          <div
            className={
              `${classes.overlay} map__overlay map__overlay--transparent ` +
              (isSpinnerVisible ? "map__overlay--visible" : "")
            }
          >
            <CustomEmpty
              text={isMapUnavailable ? unavailableMapErrorText : t("components.map.spinners.loading") + "..."}
              style={getOverlayStyle(theme, false)}
              textStyle={getOverlayTextStyle(theme, false)}
              imageStyle={getOverlayImageStyle(theme, false)}
              image={isMapUnavailable ? <ErrorIcon /> : null}
            />
          </div>
          <div id={mapId} className={`map__lmap-container ${classes.map}`} />
        </div>
      </Call>
      <AttributeDialog
        isOpen={geometryId !== null}
        onClose={() => setGeometryId(null)}
        obsAttributes={geometryMap?.[geometryId]?.obsAttributes}
        dimAttributes={geometryMap?.[geometryId]?.dimAttributes}
        labelFormat={labelFormat}
      />
      <GeometriesWarningDialog
        isOpen={maps[mapId]?.isNoGeometryWarningVisible === true}
        onClose={() => onWarningHide(mapId)}
      />
    </Fragment>
  );
}

export default compose(withStyles(styles), withTheme, connect(mapStateToProps, mapDispatchToProps))(EnhancedMap);
