import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {sanitize} from "dompurify";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {getDashboardDynamicFilterLabelTranslations} from "../../constants/getDashboardDynamicFilterLabelTranslations";
import CustomDialogTitle from "../custom-dialog-title";
import CustomMaterialTable from "../custom-material-table";
import I18nDelayedTextField from "../i18n-delayed-text-field";
import I18nHtmlEditor from "../i18n-html-editor";
import Selector from "../selector";
import {
  DASHBOARD_ELEM_ENABLE_FILTERS_KEY,
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_SHOW_TITLE_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_TEXT,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY,
  DASHBOARD_ELEM_WIDTH_KEY,
  emptyDashboardElem
} from "../../utils/dashboards";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../utils/i18n";

const $ = window.jQuery;

const MAX_VIEW_PER_ROW = 4;

const ROW_ACTION_WIDTH = 48;

const styles = theme => ({
  filterLevels: {
    fontSize: 16
  },
  rowContainer: {
    width: "100%",
    marginTop: 8
  },
  row: {
    width: `calc(100% - ${2 * ROW_ACTION_WIDTH}px)`,
    height: "100%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  rowActions: {
    width: 2 * ROW_ACTION_WIDTH,
    margin: "5px 0",
    display: "inline-block",
    verticalAlign: "middle"
  },
  rowAction: {
    width: ROW_ACTION_WIDTH
  },
  colContainer: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    marginRight: 8,
    padding: "16px 16px 0px 16px",
    height: "100%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  col: {
    width: "100%",
    display: "inline-block"
  },
  divider: {
    margin: "8px 0"
  },
  colActions: {
    width: "100%",
    textAlign: "center",
    paddingBottom: 8,
    "& button": {
      padding: 8
    }
  },
  viewContainer: {
    width: "100%"
  },
  input: {
    width: "100%",
    "& > div": {
      width: "100%"
    },
    "& input": {
      padding: 12,
      height: 20
    }
  },
  multilineInput: {
    width: "100%",
    "& > div": {
      width: "100%",
      padding: 12
    },
    "& textarea": {
      padding: 0
    },
    "& svg": {
      cursor: "pointer",
      marginLeft: 4
    }
  },
  formLabel: {
    marginLeft: 0,
    marginRight: 0
  }
});

const getStrippedHtmlText = html => {
  const $span = $("<span>").get(0);

  $span.innerHTML = sanitize(html, {ADD_ATTR: ["target"]});
  const text = $span.textContent || $span.innerText || "";

  $($span).remove();

  return text;
};

const DashboardBuilderCol = ({
  t,
  classes,
  defaultLanguage,
  languages,
  dashboard,
  rowIdx,
  colIdx,
  col,
  onColRemove,
  onTypeReset,
  fetchViews,
  onViewReset,
  onTextEdit,
  onShowTitleChange,
  onEnableFiltersChange,
  onFilterDimensionChange
}) => (
  <div className={classes.colContainer} style={{width: `calc(${col[DASHBOARD_ELEM_WIDTH_KEY]}% - ${2 * 4}px)`}}>
    <div className={`${classes.col} dashboard-builder__col`}>
      {col[DASHBOARD_ELEM_TYPE_KEY] === null ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Button onClick={() => fetchViews(rowIdx, colIdx)} style={{height: 48}}>
              {t("components.dashboardBuilder.actions.addView")}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => onTextEdit(rowIdx, colIdx, "")} style={{height: 48}}>
              {t("components.dashboardBuilder.actions.addText")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.viewContainer}>
          {col[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW ? (
            <Grid container>
              <Grid item xs={12} style={{marginBottom: 8}}>
                <Selector
                  value={col[DASHBOARD_ELEM_VALUE_KEY] ? dashboard.views[col[DASHBOARD_ELEM_VALUE_KEY]] : null}
                  render={view => (view?.title ? localizeI18nObj(view.title, defaultLanguage, languages) : "")}
                  className={classes.input}
                  selectTitle={t("components.dashboardBuilder.actions.selectView")}
                  onSelect={() => fetchViews(rowIdx, colIdx)}
                  resetTitle={t("components.dashboardBuilder.actions.deselectView")}
                  onReset={() => onViewReset(rowIdx, colIdx, col[DASHBOARD_ELEM_VALUE_KEY])}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item style={{marginRight: 8}}>
                    <FormControlLabel
                      className={classes.formLabel}
                      label={
                        <Typography style={{fontSize: 14}}>
                          {t("components.dashboardBuilder.actions.showTitle") + ":"}
                        </Typography>
                      }
                      labelPlacement="start"
                      control={
                        <Checkbox
                          style={{padding: 5}}
                          checked={col[DASHBOARD_ELEM_SHOW_TITLE_KEY]}
                          onChange={(ev, checked) => onShowTitleChange(rowIdx, colIdx, checked)}
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      className={classes.formLabel}
                      label={
                        <Typography style={{fontSize: 14}}>
                          {t("components.dashboardBuilder.actions.enableFilters") + ":"}
                        </Typography>
                      }
                      labelPlacement="start"
                      style={{fontSize: 14}}
                      control={
                        <Checkbox
                          style={{padding: 5}}
                          checked={col[DASHBOARD_ELEM_ENABLE_FILTERS_KEY]}
                          onChange={(ev, checked) => onEnableFiltersChange(rowIdx, colIdx, checked)}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{marginTop: 8}}>
                {t("components.dashboardBuilder.filterDimension.label") + ":"}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] || ""}
                  placeholder={t("components.dashboardBuilder.filterDimension.noOne")}
                  className={classes.input}
                  variant="outlined"
                  onChange={({target}) => onFilterDimensionChange(rowIdx, colIdx, target.value)}
                />
              </Grid>
            </Grid>
          ) : (
            <TextField
              value={getStrippedHtmlText(
                localizeI18nObj(col[DASHBOARD_ELEM_VALUE_KEY], defaultLanguage, languages) || ""
              )}
              className={classes.multilineInput}
              multiline
              minRows={7}
              variant="outlined"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Tooltip title={t("components.dashboardBuilder.actions.editText")}>
                    <EditIcon onClick={() => onTextEdit(rowIdx, colIdx, col[DASHBOARD_ELEM_VALUE_KEY])} />
                  </Tooltip>
                )
              }}
            />
          )}
        </div>
      )}
    </div>
    <Divider className={classes.divider} />
    <div className={classes.colActions}>
      {col[DASHBOARD_ELEM_TYPE_KEY] !== null && (
        <Tooltip title={t("components.dashboardBuilder.actions.resetColumn")}>
          <IconButton onClick={() => onTypeReset(rowIdx, colIdx)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t("components.dashboardBuilder.actions.removeColumn")}>
        <span>
          <IconButton
            onClick={() => onColRemove(rowIdx, colIdx)}
            disabled={dashboard.dashboardConfig[rowIdx].length === 1}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  </div>
);

const DashboardBuilderRow = ({
  t,
  classes,
  defaultLanguage,
  languages,
  dashboard,
  row,
  rowIdx,
  onRowRemove,
  onColAdd,
  onColRemove,
  onTypeReset,
  fetchViews,
  onViewReset,
  onTextEdit,
  onShowTitleChange,
  onEnableFiltersChange,
  onFilterDimensionChange
}) => (
  <div className={classes.rowContainer}>
    <div className={classes.row}>
      {row.map((col, idx) => (
        <DashboardBuilderCol
          key={idx}
          t={t}
          classes={classes}
          defaultLanguage={defaultLanguage}
          languages={languages}
          dashboard={dashboard}
          rowIdx={rowIdx}
          colIdx={idx}
          col={col}
          onColRemove={onColRemove}
          onTypeReset={onTypeReset}
          fetchViews={fetchViews}
          onViewReset={onViewReset}
          onTextEdit={onTextEdit}
          onShowTitleChange={onShowTitleChange}
          onEnableFiltersChange={onEnableFiltersChange}
          onFilterDimensionChange={onFilterDimensionChange}
        />
      ))}
    </div>
    <div className={classes.rowActions}>
      <Tooltip title={t("components.dashboardBuilder.actions.addColumn")}>
        <span>
          <IconButton
            className={classes.rowAction}
            onClick={() => onColAdd(rowIdx)}
            disabled={row.length === MAX_VIEW_PER_ROW}
          >
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("components.dashboardBuilder.actions.removeRow")}>
        <IconButton className={classes.rowAction} onClick={() => onRowRemove(rowIdx)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  </div>
);

const handleHeight = () => {
  $("#dashboard-builder__row-container")
    .children()
    .each(function () {
      let maxHeight = 0;
      $(this)
        .find(".dashboard-builder__col")
        .each(function () {
          maxHeight = Math.max(maxHeight, $(this).outerHeight());
        });
      $(this).css({height: maxHeight + 48 + 17 + 16 + 2}); // col actions + divider + vertical paddings + border width

      $(this)
        .find(".dashboard-builder__col")
        .each(function () {
          $(this).css({margin: `${(maxHeight - $(this).outerHeight()) / 2}px 0`});
        });
    });
};

function DashboardBuilder(props) {
  const {
    classes,
    defaultLanguage,
    languages,
    dashboardFilterConfig,
    nodes,
    dashboard,
    onChange,
    views,
    fetchViews,
    onViewsHide
  } = props;

  const {t} = useTranslation();

  const [selectedRowIdx, setSelectedRowIdx] = useState(null);
  const [selectedColIdx, setSelectedColIdx] = useState(null);

  const [isEditTextVisible, setEditTextVisibility] = useState(false);
  const [tempTextValue, setTempTextValue] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", handleHeight);
    return () => window.removeEventListener("resize", handleHeight);
  }, []);

  useEffect(() => {
    handleHeight();
  });

  const handleRowAdd = () => {
    const newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig.push([emptyDashboardElem]);
    onChange(newDashboard);
  };

  const handleRowRemove = rowIdx => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig = newDashboard.dashboardConfig.filter((_, idx) => idx !== rowIdx);
    onChange(newDashboard);
  };

  const handleColAdd = rowIdx => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx].push(emptyDashboardElem);

    let totalWidth = 0;
    newDashboard.dashboardConfig[rowIdx] = newDashboard.dashboardConfig[rowIdx].map((col, colIdx) => {
      const width =
        colIdx < newDashboard.dashboardConfig[rowIdx].length - 1
          ? Math.floor(100 / newDashboard.dashboardConfig[rowIdx].length)
          : 0;
      totalWidth += width;

      return {
        ...col,
        [DASHBOARD_ELEM_WIDTH_KEY]: width || 100 - totalWidth
      };
    });
    onChange(newDashboard);
  };

  const handleColRemove = (rowIdx, colIdx) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx] = newDashboard.dashboardConfig[rowIdx].filter((_, idx) => idx !== colIdx);
    newDashboard.dashboardConfig[rowIdx] = newDashboard.dashboardConfig[rowIdx].map(col => ({
      ...col,
      [DASHBOARD_ELEM_WIDTH_KEY]: 100 / newDashboard.dashboardConfig[rowIdx].length
    }));
    onChange(newDashboard);
  };

  const handleTypeReset = (rowIdx, colIdx) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_TYPE_KEY] = null;
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_VALUE_KEY] = null;
    onChange(newDashboard);
  };

  const handleViewSet = (rowIdx, colIdx, view) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_TYPE_KEY] = DASHBOARD_ELEM_TYPE_VALUE_VIEW;
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_VALUE_KEY] = view.viewTemplateId;
    newDashboard.views[view.viewTemplateId] = view;
    onChange(newDashboard);
    onViewsHide();
  };

  const handleViewReset = (rowIdx, colIdx, viewId) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_VALUE_KEY] = null;
    onChange(newDashboard);
    onViewsHide();
  };

  const handleEditTextShow = (rowIdx, colIdx, text) => {
    setSelectedRowIdx(rowIdx);
    setSelectedColIdx(colIdx);
    setTempTextValue(text);
    setEditTextVisibility(true);
  };

  const handleEditTextHide = () => {
    setSelectedRowIdx(null);
    setSelectedColIdx(null);
    setTempTextValue(null);
    setEditTextVisibility(false);
  };

  const handleTextChange = (rowIdx, colIdx, text) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_TYPE_KEY] = DASHBOARD_ELEM_TYPE_VALUE_TEXT;
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_VALUE_KEY] = text;
    onChange(newDashboard);
    setEditTextVisibility(false);
  };

  const handleShowTitleChange = (rowIdx, colIdx, value) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_SHOW_TITLE_KEY] = value;
    onChange(newDashboard);
  };

  const handleEnableFiltersChange = (rowIdx, colIdx, value) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_ENABLE_FILTERS_KEY] = value;
    onChange(newDashboard);
  };

  const handleFilterDimensionChange = (rowIdx, colIdx, value) => {
    let newDashboard = _.cloneDeep(dashboard);
    newDashboard.dashboardConfig[rowIdx][colIdx][DASHBOARD_ELEM_FILTER_DIMENSION_KEY] = value;
    onChange(newDashboard);
  };

  return (
    <Fragment>
      {dashboard && (
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.field}>
              <I18nDelayedTextField
                label={t("components.dashboardBuilder.title")}
                value={dashboard.title}
                variant="outlined"
                required
                onChange={value => onChange({...dashboard, title: value})}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item className={classes.filterLevels} style={{marginRight: 16, lineHeight: "34px"}}>
                {t("components.dashboardBuilder.filterLevels")}:
              </Grid>
              <Grid item>
                <Grid container>
                  {(dashboardFilterConfig?.labels || []).map((label, idx) => (
                    <Grid item key={idx} style={{marginRight: 8}}>
                      <FormControlLabel
                        className={classes.formLabel}
                        label={getDashboardDynamicFilterLabelTranslations(t)[label] || label}
                        control={
                          <Checkbox
                            style={{padding: 5}}
                            checked={!!dashboard.filterLevels[label]}
                            onChange={(ev, checked) =>
                              onChange({
                                ...dashboard,
                                filterLevels: {
                                  ...dashboard.filterLevels,
                                  [label]: checked
                                }
                              })
                            }
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} id="dashboard-builder__row-container">
            {(dashboard.dashboardConfig || []).map((row, idx) => (
              <DashboardBuilderRow
                key={idx}
                t={t}
                classes={classes}
                defaultLanguage={defaultLanguage}
                languages={languages}
                dashboard={dashboard}
                row={row}
                rowIdx={idx}
                onRowRemove={handleRowRemove}
                onColAdd={handleColAdd}
                onColRemove={handleColRemove}
                onTypeReset={handleTypeReset}
                fetchViews={(rowIdx, colIdx) => {
                  fetchViews();
                  setSelectedRowIdx(rowIdx);
                  setSelectedColIdx(colIdx);
                }}
                onViewReset={handleViewReset}
                onTextEdit={handleEditTextShow}
                onShowTitleChange={handleShowTitleChange}
                onEnableFiltersChange={handleEnableFiltersChange}
                onFilterDimensionChange={handleFilterDimensionChange}
              />
            ))}
          </Grid>
          <Grid item className={classes.row}>
            <Grid container justifyContent="center">
              <Button endIcon={<AddIcon />} onClick={handleRowAdd}>
                {t("components.dashboardBuilder.actions.addRow")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Dialog open={views !== null && views !== undefined} fullWidth maxWidth="md" onClose={onViewsHide}>
        <CustomDialogTitle onClose={onViewsHide}>
          {t("components.dashboardBuilder.modals.views.title")}
        </CustomDialogTitle>
        <DialogContent>
          <CustomMaterialTable
            data={(views || []).filter(({nodeId}) => nodes.find(node => node.nodeId === nodeId))}
            columns={[
              {
                field: "nodeId",
                title: t("components.dashboardBuilder.modals.views.columns.node"),
                render: ({nodeId}) => nodes.find(node => node.nodeId === nodeId).code,
                customFilterAndSearch: (str, {nodeId}) =>
                  nodes
                    .find(node => node.nodeId === nodeId)
                    .code.toLowerCase()
                    .includes(str.toLowerCase())
              },
              {
                field: "datasetId",
                title: t("scenes.viewsSettings.table.columns.viewDatasetId")
              },
              {
                field: "title",
                title: t("scenes.viewsSettings.table.columns.viewTitle"),
                render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
                customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
              }
            ]}
            onRowClick={(event, rowData) => {
              handleViewSet(selectedRowIdx, selectedColIdx, rowData);
              setSelectedRowIdx(null);
              setSelectedColIdx(null);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onViewsHide();
              setSelectedRowIdx(null);
              setSelectedColIdx(null);
            }}
          >
            {t("commons.confirm.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isEditTextVisible} fullWidth disableEnforceFocus maxWidth="md" onClose={handleEditTextHide}>
        <CustomDialogTitle onClose={handleEditTextHide}>
          {t("components.dashboardBuilder.modals.text.title")}
        </CustomDialogTitle>
        <DialogContent>
          <I18nHtmlEditor value={tempTextValue} onChange={setTempTextValue} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditTextHide}>{t("commons.confirm.cancel")}</Button>
          <Button onClick={() => handleTextChange(selectedRowIdx, selectedColIdx, tempTextValue)}>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  connect(state => ({
    languages: state.app.languages,
    defaultLanguage: state.app.language,
    dashboardFilterConfig: state.dashboardFilterConfig
  }))
)(DashboardBuilder);
