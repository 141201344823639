import {getItemContainersOfNode, getNodeUrl} from "../../serverApi/urls";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const NODE_FETCH = "node/fetch";
export const NODE_CLEAR = "node/clear";
export const NODE_FETCH_ITEM_CONTAINERS = "node/fetchItemContainerConfigs";

export const fetchNode = (nodeId: number, nodeCode: string) =>
  initRequest(
    NODE_FETCH,
    getNodeUrl(nodeId),
    undefined,
    undefined,
    t => ({
      onStart: t("domains.node.messages.fetch.start")
    }),
    {
      nodeId,
      nodeCode
    }
  );

export const clearNode = () => ({
  type: NODE_CLEAR
});

export const fetchNodeItemContainers = (nodeId: number, type: "dashboard" | "customPages") =>
  external(
    initRequest(
      NODE_FETCH_ITEM_CONTAINERS,
      getItemContainersOfNode(nodeId, type === "dashboard" ? "Dashboard" : "CustomPages"),
      RequestMethod.GET,
      undefined,
      t => ({
        onStart:
          type === "customPages"
            ? t("domains.node.messages.customPages.fetchMinimalInfo.start")
            : t("domains.node.messages.dashboards.fetchMinimalInfo.start")
      }),
      {type}
    ),
    type
  );
