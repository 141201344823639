import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {languageSelector, languagesSelector} from "../app/appSelectors";
import {getI18nObjCustomFilterAndSearch as filterAndSearch, localizeI18nObj as loc} from "../../utils/i18n";

const useLanguages = () => {
  const {t} = useTranslation();
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const localizeI18nObj = (obj: {[lang: string]: string}) => loc(obj, defaultLanguage, languages);
  const getI18nObjCustomFilterAndSearch = filterAndSearch(defaultLanguage, languages);
  return {t, defaultLanguage, languages, localizeI18nObj, getI18nObjCustomFilterAndSearch};
};

export default useLanguages;
