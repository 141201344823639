import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";

export const FETCH_FILTERS = "filters/fetch";
export const DELETE_FILTER = "filters/delete";
export const VALIDATE_FILTER_FILE = "filters/validate-file";
export const GET_FILTER = "filter/id/get";
export const IMPORT_FILTER_FILE = "filters/import/file";

export const deleteFilter = () =>
  external(
    initRequest(DELETE_FILTER, "filters", RequestMethod.DELETE, undefined, t => ({
      onStart: t("components.filtersSettingsForm.filtersManager.deletingMessage")
    })),
    "dashboard"
  );

export const fetchFilters = () =>
  external(
    initRequest(FETCH_FILTERS, "filters", RequestMethod.GET, undefined, t => ({
      onStart: t("components.filtersSettingsForm.filtersManager.fetchingMessage")
    })),
    "dashboard"
  );

export const validateFilterFile = () =>
  external(
    initRequest(
      VALIDATE_FILTER_FILE,
      "filters/info",
      RequestMethod.POST,
      undefined,
      t => ({
        onStart: t("components.filtersSettingsForm.wizard.firstStep.validatingMessage")
      }),
      undefined
    ),
    "dashboard"
  );

export const getFilter = (id: number) =>
  external(
    initRequest(
      GET_FILTER,
      `filters/${id}`,
      RequestMethod.GET,
      undefined,
      t => ({
        onStart: t("components.filtersSettingsForm.filtersManager.fetchingFilterMessage")
      }),
      undefined
    ),
    "dashboard"
  );

export const importFilterFile = () =>
  external(
    initRequest(IMPORT_FILTER_FILE, "filters/import", RequestMethod.POST, undefined, t => ({
      onStart: t("components.filtersSettingsForm.wizard.secondStep.importingMessage")
    })),
    "dashboard"
  );
