import React from "react";
import {Box, Card, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ColorPickerArea from "./ColorPickerArea";

interface ColorsPickerCardProps {
  title: string;
  isPrimary?: boolean;
}

const ColorsPickerCard = ({title, isPrimary = false}: ColorsPickerCardProps) => {
  const {t} = useTranslation();

  return (
    <Card style={{width: "auto", height: "100%", padding: "20px"}} variant="outlined">
      <Box display="flex" flexDirection="column" alignItems="flex-end" gridGap={40}>
        <Typography variant="h5">{title}</Typography>
        <ColorPickerArea
          label={t("scenes.customColorsSettings.main")}
          valueName={isPrimary ? ["primary", "main"] : ["secondary", "main"]}
        />
        <ColorPickerArea
          label={t("scenes.customColorsSettings.textContrast")}
          valueName={isPrimary ? ["primary", "contrastText"] : ["secondary", "contrastText"]}
        />
      </Box>
    </Card>
  );
};

export default ColorsPickerCard;
