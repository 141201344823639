import React, {createContext, PropsWithChildren, useContext} from "react";

const PreviewContext = createContext({
  hideFullscreen: false
});

const PreviewProvider = ({children}: PropsWithChildren<any>) => {
  return <PreviewContext.Provider value={{hideFullscreen: true}}>{children}</PreviewContext.Provider>;
};

export const usePreview = () => useContext(PreviewContext);

export default PreviewProvider;
