import React, {useEffect} from "react";
import {Box} from "@material-ui/core";
import {DatasetData} from "../../model/IDatasetData";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import ChartJsonstatWrapper from "../chart/ChartJsonstatWrapper";
import {getViewerIdxFromType} from "../data-viewer/constant";
import Map from "../map/Map";
import Table from "../table";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../temporal-dim-order-selector/constants";
import {useFullscreen} from "./FullscreenContext";
import useLanguages from "../../state/hooks/useLanguages";
import {handleStyle} from "./utils";

interface ViewItemBodyProps {
  item: ItemViewTemplateDto;
  itemContainer: ItemContainerDto;
  viewIdx: string;
  jsonStat: DatasetData;
  layout: any;
  layoutObj: any;
  timePeriodsByFreq: any;
  invertedDims;
  hierarchyOnlyAttributes: any[];
  hideHierarchyOnlyRows: boolean;
  minItemContainerWidth: number;
  chartId: string;
  mapId: string;
}

const ViewItemBody = ({
  item,
  itemContainer,
  viewIdx,
  jsonStat,
  layout,
  layoutObj,
  timePeriodsByFreq,
  hierarchyOnlyAttributes,
  hideHierarchyOnlyRows,
  minItemContainerWidth,
  chartId,
  mapId
}: ViewItemBodyProps) => {
  const {defaultLanguage} = useLanguages();
  const {isFullscreen} = useFullscreen();
  useEffect(() => {
    handleStyle(item.itemContainerId, viewIdx, item, minItemContainerWidth);
  }, [jsonStat, item, viewIdx, minItemContainerWidth, layout]);

  const view = item.viewTemplate;
  const viewerIdx = getViewerIdxFromType(view.defaultView);
  const timeDim = jsonStat?.role?.time?.[0] || null;
  const invertedDims =
    layoutObj?.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && !(layout?.filters || []).includes(timeDim)
      ? [timeDim]
      : null;

  const tableProps: any = {
    jsonStat: jsonStat,
    layout: layout,
    labelFormat: layoutObj.labelFormat,
    showTrend: layoutObj.showTrend,
    showCyclical: layoutObj.showCyclical,
    decimalSeparator: view.decimalSeparator[defaultLanguage],
    roundingStrategy: view.roundingStrategy,
    decimalPlaces: view.decimalNumber,
    emptyChar: layoutObj.tableEmptyChar,
    isFullscreen: isFullscreen,
    disableWheelZoom: true,
    invertedDims: invertedDims,
    hierarchyOnlyAttributes: hierarchyOnlyAttributes,
    hideHierarchyOnlyRows: hideHierarchyOnlyRows,
    filterable: true,
    sortable: true
  };
  return (
    <Box
      id={`itemContainer_${itemContainer.itemContainerId}__view-container__${viewIdx}__view`}
      className={`itemContainer_view-container__view`}
      sx={{width: "100%", position: "relative"}}
    >
      {viewerIdx === 0 ? (
        <Table {...tableProps} />
      ) : viewerIdx === 1 ? (
        <Map
          mapId={mapId}
          nodeId={view.nodeId}
          jsonStat={jsonStat}
          layout={layout}
          labelFormat={layoutObj.labelFormat}
          decimalSeparator={view.decimalSeparator[defaultLanguage]}
          roundingStrategy={view.roundingStrategy}
          decimalPlaces={view.decimalNumber}
          defaultDetailLevel={layoutObj.detailLevel}
          disableDetailLevelSelector={!item["enableFilters"]}
          initialBaseLayer={layoutObj.mapSettings?.baseLayer}
          defaultSettings={layoutObj.mapSettings}
          isFullscreen={isFullscreen}
          disableSettings
          disableBaseLayer
          showSingleGeometry={!!item.filterDimension && !!itemContainer.filter?.id}
          disableWheelZoom
        />
      ) : (
        <ChartJsonstatWrapper
          chartId={chartId}
          type={view.defaultView}
          jsonStat={jsonStat}
          layout={layout}
          timePeriodsByFreq={timePeriodsByFreq}
          labelFormat={layoutObj.labelFormat}
          showTrend={layoutObj.showTrend}
          showCyclical={layoutObj.showCyclical}
          decimalSeparator={view.decimalSeparator[defaultLanguage]}
          roundingStrategy={view.roundingStrategy}
          decimalPlaces={view.decimalNumber}
          chartSettings={layoutObj.chartSettings}
          disableWheelZoom
          invertedDims={invertedDims}
        />
      )}
    </Box>
  );
};

export default ViewItemBody;
