import {connect} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";
import Results from "../components/results";
import Call from "../hocs/call";
import {goToNode} from "../links";
import DatasetDomain from "./DatasetDomain";
import {getCategoryFromCatalog} from "../utils/catalog";

const CategoriesDomain = ({hub, node, catalog, nodeCode, isDefault, baseURL, setAccessibleDataset}) => {
  const {"*": params} = useParams();
  const [searchParams] = useSearchParams();

  const isAccessible = !!searchParams.get("accessible");
  const fullPath = params.endsWith("/") ? params.slice(0, -1).split("/") : params.split("/");

  if (catalog.isEmpty) {
    return (
      <Call cb={goToNode} cbParam={nodeCode}>
        <span />
      </Call>
    );

    /* list of datasets */
  } else if (
    (fullPath.length === 1 && fullPath[0] === "uncategorized") ||
    getCategoryFromCatalog(fullPath, catalog) !== null
  ) {
    const category = getCategoryFromCatalog(fullPath, catalog);

    const datasets = category
      ? category.datasetIdentifiers.map(id => ({
          ...catalog.datasetMap[id],
          identifier: id,
          categoryPath: fullPath
        }))
      : catalog.uncategorizedDatasets.map(ds => ({
          ...ds,
          categoryPath: ["uncategorized"]
        }));

    const subCategories = category ? category.childrenCategories : [];

    return (
      <Results
        hub={hub}
        node={node}
        nodeCode={nodeCode}
        isDefault={isDefault}
        catalog={catalog}
        categoryPath={fullPath}
        datasets={datasets}
        subCategories={subCategories.map(c => ({
          ...c,
          image: c.image ? baseURL + c.image : undefined
        }))}
        isAccessible={isAccessible}
        onAccessibleDatasetFetch={setAccessibleDataset}
      />
    );

    /* url for dataset */
  } else {
    const datasetId = fullPath[fullPath.length - 1];
    return (
      <DatasetDomain
        key={window.location.href}
        hub={hub}
        node={node}
        nodeCode={nodeCode}
        isDefault={isDefault}
        catalog={catalog}
        categoryPath={fullPath.slice(0, fullPath.length - 1)}
        datasetId={datasetId}
      />
    );
  }
};

export default connect(({config}) => ({
  baseURL: config.baseURL
}))(CategoriesDomain);
