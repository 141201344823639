import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DatasetData} from "../../model/IDatasetData";
import {ItemContainerType} from "../../model/item-containers-models/itemContainerType";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import {getViewerIdxFromType} from "../data-viewer/constant";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../label-format-selector/constants";
import useLanguages from "../../state/hooks/useLanguages";
import {hubSelector} from "../../state/hub/hubSelectors";
import {submitItemContainerDatasetDownload} from "../../state/itemContainer/itemContainerActions";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {getCriteriaObjectFromArray} from "../../utils/criteria";
import {VARIATION_DIMENSION_KEY} from "../../utils/dataset";
import {DOWNLOAD_FORMAT_CSV, DOWNLOAD_FORMAT_EXCEL} from "../../utils/download";
import {itemContainerTypeToServiceNameMap} from "../../utils/itemContainers";

export function useDownload({
  view,
  jsonStat,
  layoutObj,
  mapId,
  itemContainerType: type
}: {
  view: ViewTemplateDto;
  jsonStat: DatasetData;
  layoutObj: any;
  mapId: string;
  itemContainerType: ItemContainerType;
}) {
  const {t, localizeI18nObj, defaultLanguage, languages} = useLanguages();
  const hub = useSelector(hubSelector);
  const dispatch = useDispatch();

  const viewerIdx = view ? getViewerIdxFromType(view.defaultView) : null;
  const handleDownload = useCallback(
    (format, extension, additionalParams) => {
      const timeDim = jsonStat.role?.time?.[0];
      const newCriteria = getCriteriaObjectFromArray(view.criteria);

      if (format !== DOWNLOAD_FORMAT_CSV && format !== DOWNLOAD_FORMAT_EXCEL) {
        Object.keys(layoutObj.layout.filtersValue).forEach(key => {
          if (key !== timeDim) {
            newCriteria[key] = {
              id: key,
              filterValues: [layoutObj.layout.filtersValue[key]]
            };
          }
        });
        const primaryDim = layoutObj.layout?.primaryDim?.[0] || null;
        if (primaryDim && primaryDim !== timeDim) {
          newCriteria[primaryDim] = {
            id: primaryDim,
            filterValues: layoutObj.layout.primaryDimValues
          };
        }
        const secondaryDim = layoutObj.layout?.secondaryDim?.[0] || null;
        if (secondaryDim && secondaryDim !== timeDim) {
          newCriteria[secondaryDim] = {
            id: secondaryDim,
            filterValues: layoutObj.layout.secondaryDimValues
          };
        }
      }

      if (viewerIdx === 1 && layoutObj.layout?.territoryDim) {
        if (
          (format !== DOWNLOAD_FORMAT_CSV && format !== DOWNLOAD_FORMAT_EXCEL) ||
          additionalParams?.exportOnlyCurrentView === true
        ) {
          const territoryDim = layoutObj.layout.territoryDim;
          newCriteria[territoryDim] = {
            id: territoryDim,
            filterValues: window.LMap.getDataIds(mapId)
          };
        }
      }

      const exportParams = {
        decimalNumber: view.decimalNumber,
        roundingStrategy: view.roundingStrategy,
        decimalSeparator: localizeI18nObj(view.decimalSeparator),
        emptyCellPlaceHolder: layoutObj.tableEmptyChar,
        labelFormat: layoutObj.labelFormat,
        customLabelFormat: {
          [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
        },
        hasVariation: jsonStat.id.includes(VARIATION_DIMENSION_KEY),
        showTrend: layoutObj?.showTrend,
        showCyclical: layoutObj?.showCyclical,
        temporalDimOrder: layoutObj?.temporalDimOrder,
        exportConfig: (hub.hub as any).exportConfig,
        ...additionalParams
      };

      dispatch(
        external(
          submitItemContainerDatasetDownload(
            view.nodeId,
            view.datasetId,
            localizeI18nObj(view.title),
            newCriteria,
            layoutObj.layout,
            format,
            extension,
            false,
            exportParams,
            defaultLanguage,
            languages,
            t
          ),
          itemContainerTypeToServiceNameMap[type]
        )
      );
    },
    [
      jsonStat.role?.time,
      jsonStat.id,
      view.criteria,
      view.decimalNumber,
      view.roundingStrategy,
      view.decimalSeparator,
      view.nodeId,
      view.datasetId,
      view.title,
      viewerIdx,
      layoutObj.layout,
      layoutObj.tableEmptyChar,
      layoutObj.labelFormat,
      layoutObj?.showTrend,
      layoutObj?.showCyclical,
      layoutObj?.temporalDimOrder,
      localizeI18nObj,
      hub.hub,
      dispatch,
      defaultLanguage,
      languages,
      t,
      mapId,
      type
    ]
  );

  return handleDownload;
}
