import React, {Fragment, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useSelector} from "react-redux/es/hooks/useSelector";
import {Route, Routes, useParams} from "react-router-dom";
import {compose} from "redux";
import Categories from "../components/categories";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../components/label-format-selector/constants";
import ModulePage from "../components/module-page";
import Node from "../components/node";
import WithCustomTheme from "../components/with-custom-theme";
import CategoriesDomain from "../domains/CategoriesDomain";
import DashboardsDomain from "../domains/DashboardsDomain";
import DatasetDomain from "../domains/DatasetDomain";
import ItemContainersDomain from "../domains/ItemContainersDomain";
import SearchDomain from "../domains/SearchDomain";
import Call from "../hocs/call";
import {goToNode} from "../links";
import {clearCatalog} from "../state/catalog/catalogActions";
import {submitDatasetSVDownload} from "../state/dataset/single-viewer/actions";
import {clearNode} from "../state/node/nodeActions";
import {nodeCustomHomeIdPageSelector} from "../state/node/nodeSelectors";
import {DOWNLOAD_FORMAT_CSV, downloadFormats} from "../utils/download";
import {DECIMAL_SEPARATOR_DEFAULT} from "../utils/formatters";
import themeConfig from "../theme-config/config.json";

const mapStateToProps = state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  modulesConfig: state.app.modulesConfig,
  hub: state.hub,
  node: state.node,
  catalog: state.catalog
});

const mapDispatchToProps = dispatch => ({
  clearNode: () => dispatch(clearNode()),
  clearCatalog: () => dispatch(clearCatalog()),
  onDownloadSubmit: (
    nodeId,
    datasetId,
    datasetTitle,
    criteria,
    layout,
    format,
    extension,
    zipped,
    params,
    defaultLanguage,
    languages,
    t
  ) =>
    dispatch(
      submitDatasetSVDownload(
        nodeId,
        datasetId,
        datasetTitle,
        criteria,
        layout,
        format,
        extension,
        zipped,
        params,
        defaultLanguage,
        languages,
        t
      )
    )
});

const NodeRouter = ({
  t,
  defaultLanguage,
  languages,
  modulesConfig,
  hub,
  node,
  catalog,
  isDefault,
  clearNode,
  clearCatalog,
  onDownloadSubmit
}) => {
  const {nodeCode} = useParams();

  const [accessibleDataset, setAccessibleDataset] = useState(null);

  useEffect(() => {
    return () => {
      clearNode();
      clearCatalog();
    };
  }, [nodeCode, clearNode, clearCatalog]);

  const areNodeAndCatalogOk =
    node && catalog && node.code?.toLowerCase() === nodeCode?.toLowerCase() && node.nodeId === catalog.nodeId;

  const nodeHavingCode = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());
  const customHomePageId = useSelector(nodeCustomHomeIdPageSelector(nodeHavingCode.nodeId));
  return (
    <WithCustomTheme hub={null} nodeCode={nodeCode}>
      <Fragment>
        <Routes>
          <Route
            path="/"
            element={
              customHomePageId ? (
                <ItemContainersDomain
                  hideBreadcrumbs={true}
                  nodeCode={nodeCode}
                  isDefault={isDefault}
                  itemContainerId={customHomePageId}
                />
              ) : (
                <Node nodeCode={nodeCode} hub={hub} node={node} catalog={catalog} isDefault={isDefault} />
              )
            }
          />

          {themeConfig.enableDashboard && (
            <Route
              path="/dashboards"
              element={
                themeConfig.enableNewDashboard ? (
                  <ItemContainersDomain itemContainers={node?.itemContainers} />
                ) : (
                  <DashboardsDomain nodeCode={nodeCode} isDefault={isDefault} />
                )
              }
            />
          )}
          <Route
            path="/search"
            element={
              areNodeAndCatalogOk && (
                <SearchDomain
                  hub={hub}
                  node={node}
                  nodeCode={nodeCode}
                  isDefault={isDefault}
                  catalog={catalog}
                  setAccessibleDataset={setAccessibleDataset}
                />
              )
            }
          />
          <Route
            path="/categories"
            element={
              areNodeAndCatalogOk &&
              (catalog.isEmpty ? (
                <Call cb={goToNode} cbParam={nodeCode}>
                  <span />
                </Call>
              ) : (
                /* list of categories */
                <Categories hub={hub} node={node} catalog={catalog} nodeCode={nodeCode} isDefault={isDefault} />
              ))
            }
          />
          <Route
            path="/categories/*"
            element={
              areNodeAndCatalogOk && (
                <CategoriesDomain
                  hub={hub}
                  node={node}
                  catalog={catalog}
                  nodeCode={nodeCode}
                  isDefault={isDefault}
                  setAccessibleDataset={setAccessibleDataset}
                />
              )
            }
          />
          {(modulesConfig.nodeRoutes || []).map(module => {
            return (
              <Route
                key={module.id}
                path={`/${module.route}/*`}
                element={
                  areNodeAndCatalogOk && (
                    <ModulePage
                      moduleId={module.id}
                      moduleComponent={module.component}
                      moduleFallback={module.fallback}
                      hideAppBar={module.hideAppBar}
                      hideFooter={module.hideFooter}
                      isDefault={isDefault}
                    />
                  )
                }
              />
            );
          })}
          <Route
            path="/:datasetId"
            element={
              areNodeAndCatalogOk &&
              !catalog.isEmpty && (
                <DatasetDomain
                  key={window.location.href}
                  hub={hub}
                  node={node}
                  nodeCode={nodeCode}
                  isDefault={isDefault}
                  catalog={catalog}
                  categoryPath={[]}
                />
              )
            }
          />
          <Route
            element={
              <Call cb={goToNode} cbParam={nodeCode}>
                <span />
              </Call>
            }
          />
          {themeConfig.enableCustomPage && (
            <Route
              path="/pages"
              exact
              element={
                <ItemContainersDomain nodeCode={nodeCode} isDefault={isDefault} itemContainers={node?.itemContainers} />
              }
            />
          )}
          {themeConfig.enableCustomPage && (
            <Route path="/pages/:itemContainerId" exact element={<ItemContainersDomain isDefault={isDefault} />} />
          )}
          {themeConfig.enableDashboard && (
            <Route
              path={`/dashboards/${themeConfig.enableNewDashboard ? ":itemContainerId" : ":dashboardId"}`}
              element={
                themeConfig.enableNewDashboard ? (
                  <ItemContainersDomain isDefault={isDefault} />
                ) : (
                  <DashboardsDomain isDefault={isDefault} />
                )
              }
            />
          )}
        </Routes>
        <Call
          cb={({nodeId, datasetId, criteria, datasetTitle}) => {
            const exportParams = {
              decimalSeparator: DECIMAL_SEPARATOR_DEFAULT,
              labelFormat: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
            };

            onDownloadSubmit(
              nodeId,
              datasetId,
              datasetTitle,
              criteria,
              null,
              DOWNLOAD_FORMAT_CSV,
              downloadFormats()[DOWNLOAD_FORMAT_CSV].extension,
              false,
              exportParams,
              defaultLanguage,
              languages,
              t
            );

            setAccessibleDataset(null);
          }}
          cbParam={{
            nodeId: node?.nodeId,
            datasetId: accessibleDataset?.identifier,
            criteria: {},
            datasetTitle: accessibleDataset?.title
          }}
          disabled={!accessibleDataset}
        >
          <span />
        </Call>
      </Fragment>
    </WithCustomTheme>
  );
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NodeRouter);
