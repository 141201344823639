import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select
} from "@material-ui/core";
import FileSaver from "file-saver";
import {useTranslation} from "react-i18next";
import {getDatasetDownloadUrl} from "../../serverApi/urls";
import useApi from "../../state/api/useApi";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {downloadFormats} from "../../utils/download";

interface SdmxDownloadButtonProps {
  nodeId: number;
  datasetId: string;
  zipped?: boolean;
  classes: any;
  datasetTitle: string;
  avaiLableFormats: string[];
}

const SdmxDownloadButton = ({
  classes,
  datasetId,
  zipped = false,
  datasetTitle,
  nodeId,
  avaiLableFormats
}: SdmxDownloadButtonProps) => {
  const {t} = useTranslation();
  const [downloadFormatDialogVisibility, setDownloadFormatDialogVisibility] = useState(false);

  const downloadSdmxApi = useApi(
    initRequest(
      `dataset/download/sdmx/${datasetId}`,
      getDatasetDownloadUrl(nodeId, datasetId, "compactdata", zipped),
      RequestMethod.POST,
      [],
      t => ({
        onStart: t("scenes.dataset.actions.downloadingDataset")
      }),
      undefined,
      undefined,
      (statusCode: number) => statusCode === 406,
      undefined,
      undefined,
      true
    ),
    {
      onSuccess: data => {
        FileSaver.saveAs(new Blob([data], {type: "text/plain;charset=utf-8"}), `${datasetTitle} (${datasetId}).xml`);
        setDownloadFormatDialogVisibility(false);
      },
      clearOnUnmount: true
    }
  );

  const onDownload = (format: string) => {
    downloadSdmxApi.request.url = getDatasetDownloadUrl(nodeId, datasetId, format, zipped);
    downloadSdmxApi.call({...downloadSdmxApi.request});
  };

  return (
    <>
      <div className={classes.actions}>
        <Button
          size="small"
          className={`${classes.SDMXOptionbutton} category_list_sdmx__button`}
          variant="contained"
          onClick={e => {
            avaiLableFormats.length > 1 ? setDownloadFormatDialogVisibility(true) : onDownload(avaiLableFormats[0]);
          }}
        >
          {t("components.datasetCard.buttons.sdmx")}
        </Button>
      </div>
      <DownloadFormatDialog
        onDownload={onDownload}
        formats={avaiLableFormats}
        open={downloadFormatDialogVisibility}
        onClose={() => setDownloadFormatDialogVisibility(false)}
      ></DownloadFormatDialog>
    </>
  );
};

export default SdmxDownloadButton;

const DownloadFormatDialog = ({open, onClose, formats, onDownload}) => {
  const {t} = useTranslation();
  const [currentFormat, setCurrentFormat] = useState(formats[0]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{t("components.datasetCard.downloadFormatTitle")}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="outlined">
          <Select defaultValue={currentFormat} onChange={e => setCurrentFormat(e.target.value)}>
            {formats.map(format => {
              return <MenuItem value={format}>{downloadFormats(t)[format].label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("commons.confirm.close")}</Button>
        <Button
          color="primary"
          onClick={e => {
            onDownload(currentFormat);
          }}
        >
          {t("commons.confirm.download")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
