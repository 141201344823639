import React, {useState} from "react";
import {Box, Button, Divider, Grid, IconButton, Tooltip} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {NodeCatalogModelView} from "../../model/item-containers-models/nodeCatalogModelView";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import CategoryItem from "./CategoryItem";
import {CategorySelectionDialog} from "./CategorySelectionDialog";
import RichTextItem from "./RichTextItem";
import {TextEditingDialog} from "./TextEditingDialog";
import {useItemContainerBuilder} from "./useItemContainerBuilder";
import {ViewSelectionDialog} from "./ViewSelectionDialog";
import ViewTemplateItem from "./ViewTemplateItem";
import {fetchOtherConfigItemContainerViews} from "../../state/otherConfig/otherConfigActions";
import {currentItemContainerSelector} from "../../state/otherConfig/otherConfigSelectors";
import {ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT, ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW} from "../../utils/itemContainers";

export const ItemContainerBuilderCol = ({classes, rowIdx, colIdx, col}) => {
  const itemContainer = useSelector(currentItemContainerSelector);
  const [isViewSelectionOpen, setViewSelectionIsOpen] = useState(false);
  const [isTextEditorOpen, setTextEditorIsOpen] = useState(false);
  const [isEditCategoryVisible, setEditCategoryVisibility] = useState(false);
  const {
    asDashboard,
    handleColRemove,
    handleViewSet,
    handleTextChange,
    handleCategoryChange,
    handleTypeReset,
    handleViewReset,
    handleShowTitleChange,
    handleEnableFiltersChange,
    getColItemProps,
    getColMoveCursorProps
  } = useItemContainerBuilder();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const openViewSelectionDialog = () => {
    dispatch(fetchOtherConfigItemContainerViews(asDashboard ? "dashboard" : "custompage"));
    setViewSelectionIsOpen(true);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          padding: "16px 16px 0px 16px",
          height: "100%",
          verticalAlign: "middle",
          marginRight: "8px",
          marginLeft: colIdx === 0 ? "8px" : "0px",
          pointerEvents: "auto"
        }}
        {...getColItemProps(rowIdx, colIdx)}
      >
        <div className={`${classes.col} itemContainer-builder__col`}>
          {col.type === null ? (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Button onClick={openViewSelectionDialog} style={{height: 48}}>
                  {t("components.itemContainerBuilder.actions.addView")}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => setTextEditorIsOpen(true)} style={{height: 48}}>
                  {t("components.itemContainerBuilder.actions.addText")}
                </Button>
              </Grid>
              {!asDashboard && (
                <Grid item>
                  <Button onClick={() => setEditCategoryVisibility(true)} style={{height: 48}}>
                    {t("components.itemContainerBuilder.actions.addCategory")}
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : (
            <div className={classes.viewContainer}>
              {col.type === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW ? (
                <ViewTemplateItem
                  classes={classes}
                  rowIdx={rowIdx}
                  colIdx={colIdx}
                  col={col}
                  fetchViews={openViewSelectionDialog}
                  onViewReset={handleViewReset}
                  onShowTitleChange={handleShowTitleChange}
                  onEnableFiltersChange={handleEnableFiltersChange}
                />
              ) : col.type === ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT ? (
                <RichTextItem col={col} onTextEdit={() => setTextEditorIsOpen(true)} classes={classes} />
              ) : (
                <CategoryItem
                  item={col}
                  rowIdx={rowIdx}
                  colIdx={colIdx}
                  onEditClick={() => setEditCategoryVisibility(true)}
                />
              )}
            </div>
          )}
        </div>
        <Box>
          <Divider className={classes.divider} />
          <div className={classes.colActions}>
            {col.type !== null && (
              <Tooltip title={t("components.itemContainerBuilder.actions.resetColumn")}>
                <IconButton onClick={() => handleTypeReset(rowIdx, colIdx)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t("components.itemContainerBuilder.actions.removeColumn")}>
              <span>
                <IconButton
                  onClick={() => handleColRemove(rowIdx, colIdx)}
                  disabled={itemContainer.item[rowIdx].length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            {!asDashboard && (
              <Tooltip title={t("components.itemContainerBuilder.actions.moveRow")}>
                <IconButton {...getColMoveCursorProps(rowIdx, colIdx)}>
                  <OpenWithIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
      </div>

      <ViewSelectionDialog
        open={isViewSelectionOpen}
        onClose={() => setViewSelectionIsOpen(false)}
        handleViewSet={(view: ViewTemplateDto) => handleViewSet(view, rowIdx, colIdx)}
      />

      {isTextEditorOpen && (
        <TextEditingDialog
          initialValue={col.data}
          onClose={() => setTextEditorIsOpen(false)}
          handleTextChange={(text: {[key: string]: string}) => handleTextChange(text, rowIdx, colIdx)}
        />
      )}

      {isEditCategoryVisible && (
        <CategorySelectionDialog
          open={isEditCategoryVisible}
          onClose={() => setEditCategoryVisibility(false)}
          handleCategoryChange={(selectedCatalog: NodeCatalogModelView, fullId: string, nodeId: number) =>
            handleCategoryChange(selectedCatalog, rowIdx, colIdx, fullId, nodeId)
          }
          item={col}
        />
      )}
    </>
  );
};
