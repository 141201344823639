import React from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import CustomDialogTitle from "../custom-dialog-title";
import CustomMaterialTable from "../custom-material-table";
import {languageSelector, languagesSelector} from "../../state/app/appSelectors";
import {nodesSelector} from "../../state/hub/hubSelectors";
import {itemContainerViewsSelector} from "../../state/otherConfig/otherConfigSelectors";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../utils/i18n";

export function ViewSelectionDialog({handleViewSet, onClose, open}) {
  const {t} = useTranslation();
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const nodes = useSelector(nodesSelector);
  const views = useSelector(itemContainerViewsSelector);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>{t("components.itemContainerBuilder.modals.views.title")}</CustomDialogTitle>
      <DialogContent>
        <CustomMaterialTable
          data={(views || []).filter(({nodeId}) => nodes.find(node => node.nodeId === nodeId))}
          columns={[
            {
              field: "nodeId",
              title: t("components.itemContainerBuilder.modals.views.columns.node"),
              render: ({nodeId}) => nodes.find(node => node.nodeId === nodeId).code,
              customFilterAndSearch: (str, {nodeId}) =>
                nodes
                  .find(node => node.nodeId === nodeId)
                  .code.toLowerCase()
                  .includes(str.toLowerCase())
            },
            {
              field: "datasetId",
              title: t("scenes.viewsSettings.table.columns.viewDatasetId")
            },
            {
              field: "title",
              title: t("scenes.viewsSettings.table.columns.viewTitle"),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
            }
          ]}
          onRowClick={(event, rowData) => {
            handleViewSet(rowData);
            onClose();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
