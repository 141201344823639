import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@material-ui/core";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ImportDashboardDto} from "../../../../model/wshub-models/importDashboardDto";
import {ImportViewDto} from "../../../../model/wshub-models/importViewDto";
import {UserData} from "../../../../model/wshub-models/userData";

export interface ObjectContainerProps {
  data: ImportDashboardDto[] & ImportViewDto[] & ImportDashboardDto[];
  importData: ImportDashboardDto[] & ImportViewDto[] & ImportDashboardDto[];
  objectType: "template" | "view" | "dashboard";
  setTitlesToChange: (d: any) => void;
  users: UserData[];
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    margin: "10px 0px",
    alignItems: "center"
  },
  paperInvalid: {
    borderColor: theme.palette.error.main
  },
  mainGridContainer: {
    padding: "15px",
    alignItems: "center"
  },
  endGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  userSelect: {
    minWidth: theme.spacing(11)
  }
}));

function CheckBoxInput({label, name, control}: {label: string; name: string; control: any}) {
  return (
    <FormControlLabel
      label={label}
      control={<Controller render={({field}) => <Checkbox {...field} />} name={name} control={control} />}
    />
  );
}

function UserSelectInput({
  name,
  label,
  errors,
  control,
  users
}: {
  name: string;
  label: string;
  errors: any;
  control: any;
  users: UserData[];
}) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id={name}>{label}</InputLabel>
      <Controller
        render={({field}) => (
          <Select className={classes.userSelect} labelId={name} label={label} error={!!errors?.[name]} {...field}>
            {users &&
              users.map(user => (
                <MenuItem key={user.userId} value={user.userId}>
                  {user.firstName ? [user.firstName, user.lastName].join(" ") : user.username}
                </MenuItem>
              ))}
          </Select>
        )}
        control={control}
        rules={{
          required: true
        }}
        name={name}
      />
    </FormControl>
  );
}

const hasDuplicateTitles = (obj: ImportDashboardDto[] | ImportViewDto[]) => {
  return obj.some(({duplicateTittle}) => duplicateTittle !== undefined);
};
const ObjectContainer = ({data, importData, objectType, setTitlesToChange, users}: ObjectContainerProps) => {
  const classes = useStyles();
  const inputRefs =
    objectType === "template"
      ? ["IncludeTemplate"]
      : objectType === "view"
      ? ["IncludeView", "UserView", "ViewTittle"]
      : ["IncludeDashboards", "UserDashboards", "DashboardTittle"];

  const {t} = useTranslation();
  const {
    control,
    watch,
    formState: {errors},
    getValues
  } = useFormContext();

  const isImportInValid = (importData && hasDuplicateTitles(importData) && watch(inputRefs[0])) || false;
  const paperClasses = isImportInValid ? [classes.paper, classes.paperInvalid].join(" ") : classes.paper;
  if (data.length > 0) {
    return (
      <Paper variant="outlined" className={paperClasses}>
        <Grid container xs={12} className={classes.mainGridContainer}>
          <Grid item xs={4}>
            <CheckBoxInput
              name={inputRefs[0]}
              label={
                objectType === "template"
                  ? t(`components.nodeImportForm.wizard.secondStep.checkBox.templates`, {
                      objNumber: data.length
                    })
                  : objectType === "view"
                  ? t(`components.nodeImportForm.wizard.secondStep.checkBox.views`, {
                      objNumber: data.length
                    })
                  : t(`components.nodeImportForm.wizard.secondStep.checkBox.dashboards`, {
                      objNumber: data.length
                    })
              }
              control={control}
            ></CheckBoxInput>
          </Grid>
          {objectType !== "template" && watch(inputRefs[0]) && (
            <>
              <Grid item xs={4}>
                <UserSelectInput
                  users={users}
                  label={t("components.nodeImportForm.wizard.secondStep.userSelect")}
                  control={control}
                  name={inputRefs[1]}
                  errors={errors}
                ></UserSelectInput>
              </Grid>

              <Grid xs={4} className={classes.endGridItem}>
                {isImportInValid && (
                  <Button onClick={() => setTitlesToChange([inputRefs[2], getValues(inputRefs[2])])}>
                    {t("components.nodeImportForm.wizard.secondStep.changeButton")}
                  </Button>
                )}
              </Grid>
            </>
          )}
          {isImportInValid && (
            <Grid item xs={12}>
              <Box pt={2}>
                <Typography variant="caption" color="error">
                  {t("components.nodeImportForm.wizard.secondStep.changeTexts")}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  } else {
    return null;
  }
};

export default ObjectContainer;
