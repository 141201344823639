/**
 * Istat Data Browser API
 * Istat Data Browser API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export const Roles = {
  Administrator: "Administrator",
  User: "User"
};
