import React, {Fragment, useCallback, useMemo} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import Tooltip from "@material-ui/core/Tooltip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Call from "../../hocs/call";
import ChartJsonstatWrapper from "../chart/ChartJsonstatWrapper";
import CustomEmpty from "../custom-empty";
import AttributeIcon from "../custom-icons/AttributeIcon";
import DatasetFiltersCodelistsWrapper from "../dataset-filters/DatasetFiltersCodelistsWrapper";
import DatasetStaticFiltersCodelistsWrapper from "../dataset-static-filters/DatasetStaticFiltersCodelistsWrapper";
import ExportButton from "../export-button";
import Map from "../map/Map";
import Table from "../table";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../temporal-dim-order-selector/constants";
import {CRITERIA_FILTER_TYPE_CODES, getFilteredCriteriaFromLayout} from "../../utils/criteria";
import {
  DASHBOARD_ELEM_ENABLE_FILTERS_KEY,
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_SHOW_TITLE_KEY,
  DASHBOARD_VIEW_STATUS_EMPTY_DATASET,
  DASHBOARD_VIEW_STATUS_REQUEST_ERROR,
  DASHBOARD_VIEW_STATUS_REQUEST_START
} from "../../utils/dashboards";
import {getUpdatedLayout} from "../../utils/dataset";
import {localizeI18nObj} from "../../utils/i18n";

const styles = () => ({
  viewContainerHeader: {},
  viewContainerHeaderTitle: {
    minHeight: 48,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    "& > button": {
      marginLeft: 4
    }
  },
  viewContainerHeaderTitleAttributeIcon: {
    "& text > tspan": {
      fontWeight: "normal !important"
    }
  },
  viewContainerHeaderControllers: {
    position: "absolute",
    top: 16,
    right: 24,
    display: "flex",
    "& > *": {
      marginLeft: 4
    }
  },
  viewContainerHeaderStaticFilters: {
    paddingBottom: 8
  },
  viewContainerHeaderActiveFilters: {
    paddingBottom: 8
  },
  viewContainerHeaderPlaceholder: {
    paddingBottom: 8
  },
  viewContainerView: {
    width: "100%",
    position: "relative"
  }
});

const TitleComponent = ({
  t,
  classes,
  defaultLanguage,
  languages,
  dashboardId,
  viewIdx,
  view,
  showAttributeIcon,
  setAttributesVisibility
}) => (
  <div
    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__title`}
    className={`${classes.viewContainerHeaderTitle} dashboard__view-container__header__title`}
  >
    {localizeI18nObj(view.title, defaultLanguage, languages)}
    {showAttributeIcon && (
      <Tooltip title={t("components.dashboard.actions.attributes")}>
        <IconButton
          aria-label={t("components.dashboard.actions.attributes")}
          color="primary"
          onClick={() => setAttributesVisibility(true)}
          className={classes.viewContainerHeaderTitleAttributeIcon}
        >
          <AttributeIcon />
        </IconButton>
      </Tooltip>
    )}
  </div>
);

const ControllersComponent = ({
  t,
  classes,
  theme,
  defaultLanguage,
  languages,
  nodeExtras,
  dashboardId,
  viewIdx,
  dashboardElem,
  viewerIdx,
  layoutObj,
  jsonStat,
  view,
  mapId,
  chartId,
  showAttributeIcon,
  setAttributesVisibility,
  hideFullscreen,
  isFullscreen,
  handleFullscreen,
  getDimensionsCombinationCount,
  handleDownload
}) => (
  <div
    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__controllers`}
    className={`${classes.viewContainerHeaderControllers} dashboard__view-container__header__controllers`}
  >
    {showAttributeIcon && !dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY] && (
      <Tooltip title={t("components.dashboard.actions.attributes")}>
        <IconButton color="primary" onClick={() => setAttributesVisibility(true)} iconStyle={{fontWeight: "normal"}}>
          <AttributeIcon />
        </IconButton>
      </Tooltip>
    )}
    {!hideFullscreen && (
      <Tooltip
        title={
          isFullscreen
            ? t("components.dashboard.actions.fullscreen.exit")
            : t("components.dashboard.actions.fullscreen.enter")
        }
      >
        <IconButton
          aria-label={
            isFullscreen
              ? t("components.dashboard.actions.fullscreen.exit")
              : t("components.dashboard.actions.fullscreen.enter")
          }
          color="primary"
          onClick={handleFullscreen}
        >
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      </Tooltip>
    )}
    <ExportButton
      formats={nodeExtras?.DownloadFormats || []}
      jsonStat={jsonStat}
      isTableVisible={viewerIdx === 0}
      isMapVisible={viewerIdx === 1}
      isChartVisible={viewerIdx >= 2}
      tableLayout={layoutObj?.layout}
      mapId={mapId}
      mapLayout={layoutObj?.layout}
      mapSettings={layoutObj?.mapSettings}
      chartId={chartId}
      chartLayout={layoutObj?.layout}
      chartSettings={layoutObj?.chartSettings}
      labelFormat={layoutObj?.labelFormat}
      datasetTitle={localizeI18nObj(view?.title, defaultLanguage, languages)}
      getDimensionsCombinationCount={getDimensionsCombinationCount}
      submitDownload={handleDownload}
      buttonStyle={{color: theme.palette.primary.main}}
    />
  </div>
);

const StaticFiltersComponent = ({
  classes,
  dashboardId,
  viewIdx,
  nodeCode,
  datasetId,
  dimensionsInfo,
  codelistMaps,
  jsonStat,
  layoutObj,
  layout,
  staticFilters
}) => (
  <div
    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__static-filters`}
    className={`${classes.viewContainerHeaderStaticFilters} dashboard__view-container__header__static-filters`}
  >
    <DatasetStaticFiltersCodelistsWrapper
      nodeCode={nodeCode}
      datasetId={datasetId}
      dimensionsInfo={dimensionsInfo}
      codelistMaps={codelistMaps}
      jsonStat={jsonStat}
      layout={layout}
      labelFormat={layoutObj.labelFormat}
      isDimensionAllowed={dim => staticFilters.includes(dim)}
    />
  </div>
);

const ActiveFiltersComponent = ({
  classes,
  filterDim,
  dashboardId,
  viewIdx,
  nodeCode,
  datasetId,
  dimensions,
  dimensionsInfo,
  codelistLists,
  codelistMaps,
  timeDim,
  layout,
  layoutObj,
  jsonStat,
  onFilterSet
}) => (
  <div
    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__active-filters`}
    className={`${classes.viewContainerHeaderActiveFilters} dashboard__view-container__header__active-filters`}
  >
    <DatasetFiltersCodelistsWrapper
      nodeCode={nodeCode}
      datasetId={datasetId}
      dimensions={dimensions}
      dimensionsInfo={dimensionsInfo}
      dimensionsHidden={[filterDim]}
      codelistLists={codelistLists}
      codelistMaps={codelistMaps}
      timeDim={timeDim}
      layout={layout}
      jsonStat={jsonStat}
      labelFormat={layoutObj.labelFormat}
      onSelect={(dimension, value) => {
        const newLayout = getUpdatedLayout(dimension, value, layout);
        onFilterSet(dashboardId, viewIdx, newLayout, true);
      }}
    />
  </div>
);

const PlaceHolderComponent = ({classes, dashboardId, viewIdx}) => (
  <div
    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__placeholder`}
    className={`${classes.viewContainerHeaderPlaceholder} dashboard__view-container__header__pleaceholder`}
  />
);

function DashboardView(props) {
  const {
    t,
    classes,
    theme,
    defaultLanguage,
    languages,
    nodeCode,
    nodeExtras,
    dashboardId,
    viewIdx,
    view,
    viewerIdx,
    filterValue,
    dashboardElem,
    jsonStat,
    layoutObj,
    onFilterSet,
    timePeriodsByFreq,
    mapId,
    chartId,
    handleDownload,
    hideFullscreen,
    isFullscreen,
    handleFullscreen,
    showAttributeIcon,
    setAttributesVisibility,
    hierarchyOnlyAttributes,
    hideHierarchyOnlyRows,
    isDetailLevelFilterEnabled,
    onOptimizedDatasetFetch
  } = props;

  const filterDim = dashboardElem[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] || null;
  const timeDim = view.timeDim || null;

  const layout = useMemo(() => {
    let layout = {...layoutObj.layout};
    const filterDim = dashboardElem[DASHBOARD_ELEM_FILTER_DIMENSION_KEY];
    if (filterDim && filterValue) {
      if ((layout.filters || []).includes(filterDim)) {
        layout.filtersValue[filterDim] = filterValue;
      } else if ((layout.primaryDim || []).includes(filterDim)) {
        layout.primaryDimValues = [filterValue];
      } else if ((layout.secondaryDim || []).includes(filterDim)) {
        layout.secondaryDimValues = [filterValue];
      }
    }
    return layout;
  }, [dashboardElem, layoutObj, filterValue]);

  const criteria = useMemo(() => {
    let newCriteria = getFilteredCriteriaFromLayout(view.criteria, view.dimensionValues, layout, view.timeDim);
    if (filterDim && filterValue) {
      newCriteria = {
        ...newCriteria,
        [filterDim]: {
          ...newCriteria[filterDim],
          id: filterDim,
          type: CRITERIA_FILTER_TYPE_CODES,
          filterValues: [filterValue]
        }
      };
    }
    return newCriteria;
  }, [view, layout, filterDim, filterValue]);

  const staticFilters = useMemo(() => {
    const staticFilters = [];
    layout.filters.forEach(dim => {
      if (
        !dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] ||
        view.codelistLists[dim].length === 1 ||
        (filterDim && dim === filterDim)
      ) {
        staticFilters.push(dim);
      }
    });
    return staticFilters;
  }, [layout, dashboardElem, view, filterDim]);

  const getDimensionsCombinationCount = useCallback(() => 1, []); // used to force export of current view mode only

  const decimalSeparator = localizeI18nObj(view.decimalSeparator, defaultLanguage, languages);
  const roundingStrategy = view.roundingStrategy;
  const decimalPlaces = view.decimalNumber;

  const invertedDims =
    layoutObj?.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && !(layout?.filters || []).includes(timeDim)
      ? [timeDim]
      : null;

  const isFilterValueMissing =
    (filterDim && view.codelistLists[filterDim] && !view.codelistLists[filterDim].includes(filterValue)) || false;

  return (
    <Fragment>
      <div
        id={`dashboard__${dashboardId}__view-container__${viewIdx}__header`}
        className={`${classes.viewContainerHeader} dashboard__view-container__header`}
        style={{
          paddingBottom: dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY] ? 4 : 12
        }}
      >
        {isFilterValueMissing || jsonStat === DASHBOARD_VIEW_STATUS_REQUEST_START ? (
          <span />
        ) : dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY] ? (
          <Fragment>
            <TitleComponent
              t={t}
              classes={classes}
              defaultLanguage={defaultLanguage}
              languages={languages}
              dashboardId={dashboardId}
              viewIdx={viewIdx}
              view={view}
              showAttributeIcon={showAttributeIcon}
              setAttributesVisibility={setAttributesVisibility}
            />
            <ControllersComponent
              t={t}
              classes={classes}
              theme={theme}
              defaultLanguage={defaultLanguage}
              languages={languages}
              nodeExtras={nodeExtras}
              dashboardId={dashboardId}
              viewIdx={viewIdx}
              dashboardElem={dashboardElem}
              viewerIdx={viewerIdx}
              layoutObj={layoutObj}
              jsonStat={jsonStat}
              view={view}
              mapId={mapId}
              chartId={chartId}
              showAttributeIcon={showAttributeIcon}
              setAttributesVisibility={setAttributesVisibility}
              hideFullscreen={hideFullscreen}
              isFullscreen={isFullscreen}
              handleFullscreen={handleFullscreen}
              handleDownload={handleDownload}
              getDimensionsCombinationCount={getDimensionsCombinationCount}
            />
            {staticFilters.length > 0 && (
              <StaticFiltersComponent
                classes={classes}
                dashboardId={dashboardId}
                viewIdx={viewIdx}
                nodeCode={nodeCode}
                datasetId={view.datasetId}
                dimensionsInfo={view.dimensionsInfo}
                codelistMaps={view.codelistMaps}
                jsonStat={jsonStat}
                layoutObj={layoutObj}
                layout={layout}
                staticFilters={staticFilters}
              />
            )}
            {dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && (
              <ActiveFiltersComponent
                classes={classes}
                filterDim={filterDim}
                dashboardId={dashboardId}
                viewIdx={viewIdx}
                nodeCode={nodeCode}
                datasetId={view.datasetId}
                dimensions={view.dimensionValues}
                dimensionsInfo={view.dimensionsInfo}
                codelistLists={view.codelistLists}
                codelistMaps={view.codelistMaps}
                timeDim={timeDim}
                layout={layout}
                layoutObj={layoutObj}
                jsonStat={jsonStat}
                onFilterSet={onFilterSet}
              />
            )}
          </Fragment>
        ) : staticFilters.length > 0 ? (
          <Fragment>
            <StaticFiltersComponent
              classes={classes}
              dashboardId={dashboardId}
              viewIdx={viewIdx}
              nodeCode={nodeCode}
              datasetId={view.datasetId}
              dimensionsInfo={view.dimensionsInfo}
              codelistMaps={view.codelistMaps}
              jsonStat={jsonStat}
              layoutObj={layoutObj}
              layout={layout}
              staticFilters={staticFilters}
            />
            <ControllersComponent
              t={t}
              classes={classes}
              theme={theme}
              defaultLanguage={defaultLanguage}
              languages={languages}
              nodeExtras={nodeExtras}
              dashboardId={dashboardId}
              viewIdx={viewIdx}
              dashboardElem={dashboardElem}
              viewerIdx={viewerIdx}
              layoutObj={layoutObj}
              jsonStat={jsonStat}
              view={view}
              mapId={mapId}
              chartId={chartId}
              showAttributeIcon={showAttributeIcon}
              setAttributesVisibility={setAttributesVisibility}
              hideFullscreen={hideFullscreen}
              isFullscreen={isFullscreen}
              handleFullscreen={handleFullscreen}
              handleDownload={handleDownload}
              getDimensionsCombinationCount={getDimensionsCombinationCount}
            />
            {dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && (
              <ActiveFiltersComponent
                classes={classes}
                filterDim={filterDim}
                dashboardId={dashboardId}
                viewIdx={viewIdx}
                nodeCode={nodeCode}
                datasetId={view.datasetId}
                dimensions={view.dimensionValues}
                dimensionsInfo={view.dimensionsInfo}
                codelistLists={view.codelistLists}
                codelistMaps={view.codelistMaps}
                timeDim={timeDim}
                layout={layout}
                layoutObj={layoutObj}
                jsonStat={jsonStat}
                onFilterSet={onFilterSet}
              />
            )}
          </Fragment>
        ) : dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] ? (
          <Fragment>
            <ActiveFiltersComponent
              classes={classes}
              filterDim={filterDim}
              dashboardId={dashboardId}
              viewIdx={viewIdx}
              nodeCode={nodeCode}
              datasetId={view.datasetId}
              dimensions={view.dimensionValues}
              dimensionsInfo={view.dimensionsInfo}
              codelistLists={view.codelistLists}
              codelistMaps={view.codelistMaps}
              timeDim={timeDim}
              layout={layout}
              layoutObj={layoutObj}
              jsonStat={jsonStat}
              onFilterSet={onFilterSet}
            />
            <ControllersComponent
              t={t}
              classes={classes}
              theme={theme}
              defaultLanguage={defaultLanguage}
              languages={languages}
              nodeExtras={nodeExtras}
              dashboardId={dashboardId}
              viewIdx={viewIdx}
              dashboardElem={dashboardElem}
              viewerIdx={viewerIdx}
              layoutObj={layoutObj}
              jsonStat={jsonStat}
              view={view}
              mapId={mapId}
              chartId={chartId}
              showAttributeIcon={showAttributeIcon}
              setAttributesVisibility={setAttributesVisibility}
              hideFullscreen={hideFullscreen}
              isFullscreen={isFullscreen}
              handleFullscreen={handleFullscreen}
              handleDownload={handleDownload}
              getDimensionsCombinationCount={getDimensionsCombinationCount}
            />
          </Fragment>
        ) : (
          <Fragment>
            <PlaceHolderComponent classes={classes} dashboardId={dashboardId} viewIdx={viewIdx} />
            <ControllersComponent
              t={t}
              classes={classes}
              theme={theme}
              defaultLanguage={defaultLanguage}
              languages={languages}
              nodeExtras={nodeExtras}
              dashboardId={dashboardId}
              viewIdx={viewIdx}
              dashboardElem={dashboardElem}
              viewerIdx={viewerIdx}
              layoutObj={layoutObj}
              jsonStat={jsonStat}
              view={view}
              mapId={mapId}
              chartId={chartId}
              showAttributeIcon={showAttributeIcon}
              setAttributesVisibility={setAttributesVisibility}
              hideFullscreen={hideFullscreen}
              isFullscreen={isFullscreen}
              handleFullscreen={handleFullscreen}
              handleDownload={handleDownload}
              getDimensionsCombinationCount={getDimensionsCombinationCount}
            />
          </Fragment>
        )}
      </div>
      <div
        id={`dashboard__${dashboardId}__view-container__${viewIdx}__view`}
        className={`${classes.viewContainerView} dashboard__view-container__view`}
      >
        <Call
          cb={params => {
            onOptimizedDatasetFetch(params);
          }}
          cbParam={{
            dashboardId: dashboardId,
            viewIdx: viewIdx,
            nodeId: view.nodeId,
            datasetId: view.datasetId,
            criteria: criteria
          }}
          disabled={isFilterValueMissing || (jsonStat !== null && jsonStat !== undefined)}
        >
          {isFilterValueMissing ? (
            <CustomEmpty text={t("components.dashboard.emptyView")} />
          ) : jsonStat === null || jsonStat === undefined ? (
            <CustomEmpty text={""} />
          ) : jsonStat === DASHBOARD_VIEW_STATUS_REQUEST_START ? (
            <CustomEmpty text={t("components.dashboard.fetching") + "..."} image={<CircularProgress />} />
          ) : jsonStat === DASHBOARD_VIEW_STATUS_REQUEST_ERROR ? (
            <CustomEmpty text={t("components.dashboard.fetchingDatasetError")} />
          ) : jsonStat === DASHBOARD_VIEW_STATUS_EMPTY_DATASET ? (
            <CustomEmpty text={t("components.dashboard.emptyView")} />
          ) : viewerIdx === 0 ? (
            <Table
              jsonStat={jsonStat}
              layout={layout}
              labelFormat={layoutObj.labelFormat}
              showTrend={layoutObj.showTrend}
              showCyclical={layoutObj.showCyclical}
              decimalSeparator={decimalSeparator}
              roundingStrategy={roundingStrategy}
              decimalPlaces={decimalPlaces}
              emptyChar={layoutObj.tableEmptyChar}
              isFullscreen={isFullscreen}
              disableWheelZoom
              invertedDims={invertedDims}
              hierarchyOnlyAttributes={hierarchyOnlyAttributes}
              hideHierarchyOnlyRows={hideHierarchyOnlyRows}
              filterable
              sortable
            />
          ) : viewerIdx === 1 ? (
            <Map
              mapId={mapId}
              nodeId={view.nodeId}
              jsonStat={jsonStat}
              layout={layout}
              labelFormat={layoutObj.labelFormat}
              decimalSeparator={decimalSeparator}
              roundingStrategy={roundingStrategy}
              decimalPlaces={decimalPlaces}
              defaultDetailLevel={layoutObj.detailLevel}
              disableDetailLevelSelector={!dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY]}
              initialBaseLayer={layoutObj.mapSettings?.baseLayer}
              defaultSettings={layoutObj.mapSettings}
              isFullscreen={isFullscreen}
              disableSettings
              disableBaseLayer
              showSingleGeometry={(filterDim || "").length > 0 && isDetailLevelFilterEnabled}
              disableWheelZoom
            />
          ) : (
            <ChartJsonstatWrapper
              chartId={chartId}
              type={view.defaultView}
              jsonStat={jsonStat}
              layout={layout}
              timePeriodsByFreq={timePeriodsByFreq}
              labelFormat={layoutObj.labelFormat}
              showTrend={layoutObj.showTrend}
              showCyclical={layoutObj.showCyclical}
              decimalSeparator={decimalSeparator}
              roundingStrategy={roundingStrategy}
              decimalPlaces={decimalPlaces}
              chartSettings={layoutObj.chartSettings}
              disableWheelZoom
              invertedDims={invertedDims}
            />
          )}
        </Call>
      </div>
    </Fragment>
  );
}

export default compose(withTranslation(), withStyles(styles), withTheme)(DashboardView);
