import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import App from "./App";
import init from "./init";
import registerDefaultServiceWorker from "./utils/register-default-service-worker";
import "./a11yStyle.css";
import "./style.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "@fontsource/do-hyeon/index.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/index.css";

async function enableMocking() {
  if (process.env.NODE_ENV === "development") {
    // const {worker} = await import("./mocks/browser");
    // await worker.start({onUnhandledRequest: "bypass"});
  } else {
    registerDefaultServiceWorker();
  }
}

enableMocking().then(() =>
  init(store =>
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    )
  )
);
