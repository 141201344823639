import React, {useEffect} from "react";
import {
  Button,
  CardContent,
  CardHeader,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {IHubMinimalNode} from "../../../model/IHubMinimalNode";
import {ConfigNodeFormInfo} from "../../../model/item-containers-models/configNodeFormInfo";
import {ConfigNodeMenuDto} from "../../../model/item-containers-models/configNodeMenuDto";
import {ConfigNodeMenuId} from "../../../model/item-containers-models/configNodeMenuId";
import CustomDialogTitle from "../../custom-dialog-title";
import {nodesSelector} from "../../../state/hub/hubSelectors";
import {currentItemContainerSelector} from "../../../state/otherConfig/otherConfigSelectors";

interface CustomPageNodeSettingsFormDialogProps {
  open: boolean;
  onClose: () => void;
  initialValues?: ConfigNodeFormInfo | null;
  onSubmit: (v: ConfigNodeFormInfo) => void;
}

interface CustomPageNodeSettingsForm extends ConfigNodeFormInfo {
  menus: {
    sidenav: {active: boolean; order: number};
    central: {active: boolean; order: number};
  };
}

const useStyles = makeStyles(theme => ({
  formControl: {
    // margin: theme.spacing(1),
    margin: "1em 0",
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2)
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(0)
  }
}));

const CustomPageNodeSettingsFormDialog = ({
  open,
  onClose,
  initialValues,
  onSubmit
}: CustomPageNodeSettingsFormDialogProps) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const sidenavMenu = initialValues?.configNodeMenus?.find(v => v.menuId === ConfigNodeMenuId.SIDENAV);
  const centralMenu = initialValues?.configNodeMenus?.find(v => v.menuId === ConfigNodeMenuId.CENTRAL);
  const itemContainer = useSelector(currentItemContainerSelector);
  const nodes = useSelector(nodesSelector);
  const nodesAndHub = [{nodeId: -1, name: "Hub"} as IHubMinimalNode, ...nodes];

  const getConfigurableNodes = () => {
    if (!initialValues) {
      return itemContainer.configNodes
        ? nodesAndHub.filter(node => !itemContainer.configNodes.some(n => n.nodeId === node.nodeId))
        : nodesAndHub;
    } else {
      return nodesAndHub;
    }
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors},
    setValue
  } = useForm<CustomPageNodeSettingsForm>({
    defaultValues: {
      nodeId: initialValues?.nodeId || getConfigurableNodes()[0]?.nodeId || -1,
      hub: initialValues?.nodeId === -1,
      isHomePage: initialValues?.isHomePage || false,
      itemContainerId: itemContainer.itemContainerId || null,
      menus: {
        sidenav: {
          active: !!sidenavMenu,
          order: sidenavMenu?.order || 0
        },
        central: {
          active: !!centralMenu,
          order: centralMenu?.order || 0
        }
      }
    }
  });
  const submitHandler: SubmitHandler<CustomPageNodeSettingsForm> = data => {
    const configNodeMenus: ConfigNodeMenuDto[] = Object.entries(data.menus)
      .filter(([_, value]) => value.active)
      .map(([key, value]) => ({
        menuId: {
          sidenav: ConfigNodeMenuId.SIDENAV,
          central: ConfigNodeMenuId.CENTRAL
        }[key],
        order: value.order,
        configNodeId: data.nodeId
      }));
    onSubmit({
      hub: data.nodeId === -1,
      nodeId: data.nodeId,
      isHomePage: data.isHomePage,
      centralOrder: data.menus.central.order,
      sidenavOrder: data.menus.sidenav.order,
      itemContainerId: itemContainer.itemContainerId,
      configNodeMenus: configNodeMenus
    });
    onClose();
  };
  const sidenavMenuActive = watch("menus.sidenav.active");
  const centralMenuActive = watch("menus.central.active");
  const watchNodeId = watch("nodeId");

  useEffect(() => {
    if (watchNodeId === -1) {
      setValue("menus.sidenav.active", false);
      setValue("menus.central.active", false);
    }
  }, [setValue, watchNodeId]);

  return (
    <Dialog open={open} onClose={onClose} max-Width={"sm"} fullWidth>
      <form onSubmit={handleSubmit(submitHandler)}>
        <CustomDialogTitle onClick={onClose}>
          {initialValues
            ? t("components.customPageNodeSettings.modal.title.edit")
            : t("components.customPageNodeSettings.modal.title.add")}
        </CustomDialogTitle>
        <DialogContent>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="nodeId">{t(`components.customPageNodeSettingsForm.select.nodeSelector.label`)}</InputLabel>
            <Controller
              render={({field: {onChange, value}}) => (
                <Select
                  onChange={e => onChange(e.target.value as number)}
                  value={value}
                  labelId="nodeId"
                  label="Node"
                  disabled={!!initialValues}
                >
                  {getConfigurableNodes().map(v => (
                    <MenuItem key={v.nodeId} value={v.nodeId}>
                      {v.name ? v.name : v.nodeId}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              rules={{required: true}}
              name="nodeId"
            />
          </FormControl>
          <FormGroup>
            <Paper variant="outlined" className={classes.paper}>
              <CardContent>
                <FormControlLabel
                  label={t(`components.customPageNodeSettingsForm.checkBox.isHomepage`)}
                  control={
                    <Controller
                      render={({field}) => <Checkbox {...field} checked={watch("isHomePage")} />}
                      name="isHomePage"
                      control={control}
                    />
                  }
                />
              </CardContent>
            </Paper>
            <Paper variant="outlined" className={classes.paper}>
              <CardHeader subheader={t("components.customPageNodeSettingsForm.paper.header")} />
              <CardContent className={classes.cardContent}>
                <FormControlLabel
                  label={t(`components.customPageNodeSettingsForm.checkBox.sidenav`)}
                  control={
                    <Controller
                      render={({field}) => (
                        <Checkbox {...field} disabled={watchNodeId === -1} checked={watch("menus.sidenav.active")} />
                      )}
                      name="menus.sidenav.active"
                      control={control}
                    />
                  }
                />
                {sidenavMenuActive && (
                  <Controller
                    render={({field}) => (
                      <TextField
                        {...field}
                        error={!!errors.menus?.sidenav}
                        type="number"
                        variant="outlined"
                        label={t(`components.customPageNodeSettingsForm.select.orderSelector.label`)}
                      />
                    )}
                    control={control}
                    rules={{
                      required: true,
                      min: {value: 0, message: "Errore"}
                    }}
                    name="menus.sidenav.order"
                  />
                )}
                <FormControlLabel
                  label={t(`components.customPageNodeSettingsForm.checkBox.central`)}
                  control={
                    <Controller
                      render={({field}) => (
                        <Checkbox {...field} disabled={watchNodeId === -1} checked={watch("menus.central.active")} />
                      )}
                      name="menus.central.active"
                      control={control}
                    />
                  }
                />
                {centralMenuActive && (
                  <Controller
                    render={({field}) => (
                      <TextField
                        {...field}
                        error={errors.menus?.central !== undefined}
                        type="number"
                        variant="outlined"
                        label={t(`components.customPageNodeSettingsForm.select.orderSelector.label`)}
                      />
                    )}
                    control={control}
                    rules={{
                      required: true,
                      min: {value: 0, message: "Errore"}
                    }}
                    name="menus.central.order"
                  />
                )}
              </CardContent>
            </Paper>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} type="button">
            {t("commons.confirm.close")}
          </Button>
          <Button type="submit">{t("commons.confirm.save")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomPageNodeSettingsFormDialog;
