import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {getVariationValueLabels, VARIATION_VALUE_CYCLICAL_KEY, VARIATION_VALUE_TREND_KEY} from "../../utils/dataset";

const styles = theme => ({
  root: {
    margin: "0 8px",
    alignItems: "center"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 13,
    height: 40,
    lineHeight: "40px",
    marginRight: 8
  },
  formControl: {
    margin: "8px 0"
  },
  select: {
    "& .MuiSelect-root.MuiSelect-select": {
      padding: "2px 24px 3px 0",
      fontSize: 14
    }
  },
  menuItem: {
    height: 42,
    fontSize: 14,
    backgroundColor: "#ffffff !important"
  }
});

const VARIATION_VALUE_NONE_KEY = "NONE";

const VariationSelector = ({t, classes, showTrend, showCyclical, onVariationSet}) => {
  const [open, setOpen] = useState(false);

  const values = [];
  if (showTrend) {
    values.push(VARIATION_VALUE_TREND_KEY);
  }
  if (showCyclical) {
    values.push(VARIATION_VALUE_CYCLICAL_KEY);
  }
  if (values.length === 0) {
    values.push(VARIATION_VALUE_NONE_KEY);
  }

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.label} id="variation-selector-label">
        {t("components.variationSelector.variation.title")}:
      </Grid>
      <Grid item>
        <FormControl className={classes.formControl}>
          <Select
            className={classes.select}
            multiple
            value={values}
            renderValue={selected => {
              const newSelected = selected.filter(val => val !== VARIATION_VALUE_NONE_KEY);
              if (newSelected.length === 0) {
                return t("components.variationSelector.variation.none");
              } else if (newSelected.length === 1) {
                return getVariationValueLabels(t)[newSelected[0]];
              } else if (newSelected.length === 2) {
                return t("components.variationSelector.variation.both");
              } else {
                return "";
              }
            }}
            onChange={ev => {
              const values = ev.target.value;
              onVariationSet({
                showTrend: values.includes(VARIATION_VALUE_TREND_KEY),
                showCyclical: values.includes(VARIATION_VALUE_CYCLICAL_KEY)
              });
              setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            MenuProps={{
              open: open,
              variant: "menu"
            }}
            SelectDisplayProps={{
              "aria-haspopup": true,
              "aria-labelledby": "variation-selector-label"
            }}
          >
            <MenuItem className={classes.menuItem} value={VARIATION_VALUE_TREND_KEY}>
              <Checkbox checked={values.includes(VARIATION_VALUE_TREND_KEY)} />
              {getVariationValueLabels(t)[VARIATION_VALUE_TREND_KEY]}
            </MenuItem>
            <MenuItem className={classes.menuItem} value={VARIATION_VALUE_CYCLICAL_KEY}>
              <Checkbox checked={values.includes(VARIATION_VALUE_CYCLICAL_KEY)} />
              {getVariationValueLabels(t)[VARIATION_VALUE_CYCLICAL_KEY]}
            </MenuItem>
            <MenuItem className={classes.menuItem} value={VARIATION_VALUE_NONE_KEY} style={{display: "none"}}>
              <Checkbox checked={values.includes(VARIATION_VALUE_NONE_KEY)} />
              {t("components.variationSelector.variation.none")}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles), withTranslation())(VariationSelector);
