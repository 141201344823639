import React, {Fragment, useCallback, useMemo, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, makeStyles, useTheme} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import {DatasetData} from "../../model/IDatasetData";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import AttributeList from "../attribute-list";
import CustomDialogTitle from "../custom-dialog-title";
import AttributeIcon from "../custom-icons/AttributeIcon";
import {getViewerIdxFromType} from "../data-viewer/constant";
import DatasetFiltersJsonStatWrapper from "../dataset-filters/DatasetFiltersJsonStatWrapper";
import DatasetStaticFiltersJsonStatWrapper from "../dataset-static-filters/DatasetStaticFiltersJsonStatWrapper";
import ExportButton from "../export-button";
import {useFullscreen} from "./FullscreenContext";
import {usePreview} from "./PreviewContext";
import {useDownload} from "./useDownload";
import useLanguages from "../../state/hooks/useLanguages";
import {getDatasetAttributeMap, getSeriesAttributeMap} from "../../utils/dataset";

const useViewItemStyles = makeStyles(theme => ({
  viewContainerView: {
    width: "100%",
    position: "relative"
  },

  viewContainerHeaderControllers: {
    position: "absolute",
    top: 16,
    right: 24,
    display: "flex",
    "& > *": {
      marginLeft: 4
    }
  },

  viewContainerHeaderPlaceholder: {
    paddingBottom: 8
  }
}));

const ActiveFiltersComponent = ({itemContainerId, viewIdx, jsonStat, layoutObj, layout, filterTree, onFilterSet}) => (
  <Box
    id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__active-filters`}
    className={`itemContainer__view-container__header__active-filters`}
    sx={{paddingBottom: 8}}
  >
    <DatasetFiltersJsonStatWrapper
      jsonStat={jsonStat}
      layout={layout}
      filterTree={filterTree}
      labelFormat={layoutObj.labelFormat}
      onSelect={(dimension, value) => onFilterSet({dimension, value})}
      timeDim={undefined}
      onCriteriaShow={undefined}
      enclosingContainerId={undefined}
    />
  </Box>
);

const ControllersComponent = ({
  nodeExtras,
  itemContainerId,
  viewIdx,
  itemContainerElem,
  viewerIdx,
  layoutObj,
  jsonStat,
  view,
  mapId,
  chartId,
  hideFullscreen,
  itemContainerType
}) => {
  const {isFullscreen, handleFullscreen} = useFullscreen();
  const classes = useViewItemStyles();
  const {t, localizeI18nObj} = useLanguages();
  const theme = useTheme();
  const getDimensionsCombinationCount = useCallback(
    dimensions =>
      dimensions && jsonStat && (jsonStat?.id || []).length > 0
        ? dimensions.reduce((acc, dim) => acc * jsonStat.size[jsonStat.id.indexOf(dim)], 1)
        : 0,
    [jsonStat]
  );

  const handleDownload = useDownload({view, layoutObj, jsonStat, mapId, itemContainerType});

  return (
    <div
      id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__controllers`}
      className={`${classes.viewContainerHeaderControllers} itemContainer_view-container__header__controllers`}
    >
      {!itemContainerElem.showTitle && <AttributesToggle jsonStat={jsonStat} layoutObj={layoutObj} view={view} />}
      {!hideFullscreen && (
        <Tooltip
          title={
            isFullscreen
              ? t("components.itemContainer.actions.fullscreen.exit")
              : t("components.itemContainer.actions.fullscreen.enter")
          }
        >
          <IconButton
            aria-label={
              isFullscreen
                ? t("components.itemContainer.actions.fullscreen.exit")
                : t("components.itemContainer.actions.fullscreen.enter")
            }
            color="primary"
            onClick={handleFullscreen}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Tooltip>
      )}
      <ExportButton
        formats={nodeExtras?.DownloadFormats || []}
        jsonStat={jsonStat}
        isTableVisible={viewerIdx === 0}
        isMapVisible={viewerIdx === 1}
        isChartVisible={viewerIdx >= 2}
        tableLayout={layoutObj?.layout}
        mapId={mapId}
        mapLayout={layoutObj?.layout}
        mapSettings={layoutObj?.mapSettings}
        chartId={chartId}
        chartLayout={layoutObj?.layout}
        chartSettings={layoutObj?.chartSettings}
        labelFormat={layoutObj?.labelFormat}
        datasetTitle={localizeI18nObj(view?.title)}
        getDimensionsCombinationCount={getDimensionsCombinationCount}
        submitDownload={handleDownload}
        buttonStyle={{color: theme.palette.primary.main}}
      />
    </div>
  );
};

const PlaceHolderComponent = ({itemContainerId, viewIdx}) => (
  <Box
    id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__placeholder`}
    className={`itemContainer_view-container__header__pleaceholder`}
    sx={{paddingBottom: 8}}
  />
);

const StaticFiltersComponent = ({itemContainerId, viewIdx, jsonStat, layoutObj, layout, staticFilters}) => (
  <Box
    id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__static-filters`}
    className={`itemContainer_view-container__header__static-filters`}
    sx={{paddingBottom: 8}}
  >
    <DatasetStaticFiltersJsonStatWrapper
      jsonStat={jsonStat}
      layout={layout}
      labelFormat={layoutObj.labelFormat}
      isDimensionAllowed={dim => staticFilters.includes(dim)}
    />
  </Box>
);

const AttributesToggle = ({jsonStat, layoutObj, view, iconButtonClassNames = ""}) => {
  const {t} = useLanguages();
  const [isAttributesVisible, setAttributesVisibility] = useState(false);
  const datasetAttributes = useMemo(() => {
    return jsonStat && layoutObj && view?.datasetId ? getDatasetAttributeMap(jsonStat)?.[view.datasetId] || [] : [];
  }, [jsonStat, layoutObj, view?.datasetId]);

  const seriesAttributes = useMemo(() => {
    return jsonStat && layoutObj && view?.datasetId
      ? getSeriesAttributeMap(jsonStat, layoutObj.labelFormat)[view.datasetId] || []
      : [];
  }, [jsonStat, layoutObj, view?.datasetId]);

  const showAttributeIcon = datasetAttributes.concat(seriesAttributes).length > 0;
  return (
    <>
      {showAttributeIcon && (
        <Tooltip title={t("components.itemContainer.actions.attributes")}>
          <IconButton
            aria-label={t("components.itemContainer.actions.attributes")}
            color="primary"
            onClick={() => setAttributesVisibility(true)}
            className={iconButtonClassNames}
          >
            <AttributeIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog open={isAttributesVisible} fullWidth maxWidth="md" onClose={() => setAttributesVisibility(false)}>
        <CustomDialogTitle onClose={() => setAttributesVisibility(false)}>
          {t("components.itemContainer.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={datasetAttributes}
            seriesAttributes={seriesAttributes}
            labelFormat={layoutObj.labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttributesVisibility(false)}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useTitleStyles = makeStyles(() => ({
  viewContainerHeaderTitle: {
    minHeight: 48,
    paddingBottom: 8,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    "& > button": {
      marginLeft: 4
    }
  },
  viewContainerHeaderTitleAttributeIcon: {
    "& text > tspan": {
      fontWeight: "normal !important"
    }
  }
}));

const TitleComponent = ({
  itemContainerId,
  viewIdx,
  view,
  jsonStat,
  layoutObj,
  defaultTitle
}: {
  itemContainerId: number;
  viewIdx: string;
  view: ViewTemplateDto;
  jsonStat: any;
  layoutObj: any;
  defaultTitle: string;
}) => {
  const {localizeI18nObj, defaultLanguage} = useLanguages();
  const classes = useTitleStyles();

  const getViewTitle = (view: ViewTemplateDto) => {
    if (defaultLanguage in view.title) {
      return localizeI18nObj(view.title);
    }
    return defaultTitle;
  };

  return (
    <div
      id={`itemContainer_${itemContainerId}__view-container__${viewIdx}__header__title`}
      className={`${classes.viewContainerHeaderTitle} itemContainer_view-container__header__title`}
    >
      {getViewTitle(view)}
      {
        <AttributesToggle
          jsonStat={jsonStat}
          layoutObj={layoutObj}
          view={view}
          iconButtonClassNames={classes.viewContainerHeaderTitleAttributeIcon}
        />
      }
    </div>
  );
};

interface ViewHeadingProps {
  item: ItemViewTemplateDto;
  itemContainer: ItemContainerDto;
  viewIdx: string;
  jsonStat: DatasetData;
  layout: any;
  defaultTitle: string;
  layoutObj: any;
  timePeriodsByFreq: any;
  nodeExtras: any;
  mapId: string;
  chartId: string;
  filterTree: any;
  onFilterSet: (filters: {dimension: string; value: any}) => void;
}
const ViewItemHeading = ({
  item,
  itemContainer,
  viewIdx,
  jsonStat,
  layoutObj,
  layout,
  defaultTitle,
  nodeExtras,
  mapId,
  chartId,
  filterTree,
  onFilterSet
}: ViewHeadingProps) => {
  const {hideFullscreen} = usePreview();
  const viewerIdx = getViewerIdxFromType(item.viewTemplate.defaultView);
  const staticFilters = useMemo(() => {
    if (layoutObj === null || layoutObj === undefined || jsonStat === null || jsonStat === undefined) {
      return [];
    } else {
      const staticFilters = [];
      layout.filters.forEach(dim => {
        if (!item["enableFilters"] || jsonStat.size[jsonStat.id.indexOf(dim)] === 1) {
          staticFilters.push(dim);
        }
      });
      return staticFilters;
    }
  }, [item, jsonStat, layoutObj, layout]);
  const controllerProps = {
    nodeExtras: nodeExtras,
    itemContainerId: item.itemContainerId,
    viewIdx: viewIdx,
    itemContainerElem: item,
    viewerIdx: viewerIdx,
    layoutObj: layoutObj,
    jsonStat: jsonStat,
    view: item.viewTemplate,
    mapId: mapId,
    chartId: chartId,
    hideFullscreen: hideFullscreen,
    itemContainerType: itemContainer.type
  };

  const staticFilterProps = {
    itemContainerId: item.itemContainerId,
    viewIdx: viewIdx,
    jsonStat: jsonStat,
    layoutObj: layoutObj,
    layout: layout,
    staticFilters: staticFilters
  };

  const activeFilterProps = {
    itemContainerId: item.itemContainerId,
    viewIdx: viewIdx,
    jsonStat: jsonStat,
    layoutObj: layoutObj,
    layout: layout,
    filterTree: filterTree,
    onFilterSet: onFilterSet
  };

  return (
    <div
      id={`itemContainer_${item.itemContainerId}__view-container__${viewIdx}__header`}
      className={`itemContainer_view-container__header`}
      style={{
        paddingBottom: item.showTitle ? 4 : 12
      }}
    >
      {item.showTitle ? (
        <Fragment>
          <TitleComponent
            itemContainerId={item.itemContainerId}
            defaultTitle={defaultTitle}
            viewIdx={viewIdx}
            view={item.viewTemplate}
            jsonStat={jsonStat}
            layoutObj={layoutObj}
          />
          <ControllersComponent {...controllerProps} />
          {staticFilters.length > 0 && <StaticFiltersComponent {...staticFilterProps} />}
          {item.enableFilters && filterTree && <ActiveFiltersComponent {...activeFilterProps} />}
        </Fragment>
      ) : staticFilters.length > 0 ? (
        <Fragment>
          <StaticFiltersComponent {...staticFilterProps} />
          <ControllersComponent {...controllerProps} />
          {item.enableFilters && filterTree && <ActiveFiltersComponent {...activeFilterProps} />}
        </Fragment>
      ) : item.enableFilters && filterTree ? (
        <Fragment>
          <ActiveFiltersComponent {...activeFilterProps} />
          <ControllersComponent {...controllerProps} />
        </Fragment>
      ) : (
        <Fragment>
          <PlaceHolderComponent itemContainerId={item.itemContainerId} viewIdx={viewIdx} />
          <ControllersComponent {...controllerProps} />
        </Fragment>
      )}
    </div>
  );
};

export default ViewItemHeading;
