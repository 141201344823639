import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CustomEmpty from "../custom-empty";
import Chart from "./Chart";
import {CHART_TYPE_LINE, CHART_TYPE_PYRAMID, getChartType, getVariationChartType} from "./constants";
import {
  FREQ_DIMENSION_KEY,
  getCompleteValueDims,
  getDataIdxFromCoordinatesArray,
  getDimensionAttributeMap,
  getDimensionValuesIndexesMap,
  getFilterTreeFromJsonStat,
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getObservationAttributeMap,
  getVariationValueLabels,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_CYCLICAL_KEY,
  VARIATION_VALUE_TREND_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../utils/dataset";

const getFilterDims = (jsonStat, chartLayout) =>
  jsonStat.id.filter(dim => chartLayout.secondaryDim.includes(dim) || chartLayout.filters.includes(dim));

const getDimensionValueTree = (jsonStat, layout) => {
  const dims = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);
  const layoutDims = [...layout.primaryDim, ...layout.secondaryDim, ...layout.filters];

  if (dims.length !== layoutDims.length) {
    const orderedDims = [...layoutDims, ...dims.filter(dim => !layoutDims.includes(dim))];
    return getFilterTreeFromJsonStat(orderedDims, jsonStat);
  } else {
    return null;
  }
};

const getData = (
  chartType,
  jsonStat,
  layout,
  dimensionValueTree,
  timePeriodsByFreq,
  labelFormat,
  showTrend = false,
  showCyclical = false,
  t,
  invertedDims
) => {
  const {
    primaryDim: primaryDimArr,
    primaryDimValues: originalPrimaryDimValues,
    secondaryDim: secondaryDimArr,
    secondaryDimValues: originalSecondaryDimValues,
    filters,
    filtersValue
  } = layout;

  const primaryDim = primaryDimArr[0];
  const primaryDimValues = primaryDim
    ? jsonStat.dimension[primaryDim].category.index.filter(dimVal => originalPrimaryDimValues.includes(dimVal))
    : originalPrimaryDimValues;
  if (chartType === CHART_TYPE_PYRAMID) {
    primaryDimValues.reverse();
  }

  const secondaryDim = secondaryDimArr[0];
  const secondaryDimValues = secondaryDim
    ? jsonStat.dimension[secondaryDim].category.index.filter(dimVal => originalSecondaryDimValues.includes(dimVal))
    : originalSecondaryDimValues;

  const datasetId = jsonStat?.extension?.datasets?.[0];

  const indexesMap = getDimensionValuesIndexesMap(jsonStat);
  const dimAttributeMap = getDimensionAttributeMap(jsonStat, t)[datasetId];
  const obsAttributeMap = getObservationAttributeMap(jsonStat);
  if (obsAttributeMap === null) {
    console.error(t("components.chart.error.observationAttribute"));
  }

  const valorizedDataMap = {};
  secondaryDimValues.forEach(secondaryDimValue => {
    valorizedDataMap[secondaryDimValue] = new Array(primaryDimValues.length).fill(false);
  });
  const valorizedDataArr = new Array(primaryDimValues.length).fill(false);

  const timeDim = jsonStat.role?.time?.[0];

  let reversedData = false;
  if ((invertedDims || []).includes(layout.primaryDim[0])) {
    reversedData = true;
  }

  let reversedDatasets = false;
  if ((invertedDims || []).includes(layout.secondaryDim[0])) {
    reversedDatasets = true;
  }

  const orderedDims = [
    primaryDim,
    secondaryDim,
    ...layout.filters,
    ...jsonStat.id.filter(dim => dim !== primaryDim && dim !== secondaryDim && !layout.filters.includes(dim))
  ];

  let datasets = [];

  const getDataset = (secondaryDimValue, secDimIdx) => {
    const datasets = [];

    const variations = [];
    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY) && getVariationChartType(chartType)) {
      if (showTrend) {
        variations.push(VARIATION_VALUE_TREND_KEY);
      }
      if (showCyclical) {
        variations.push(VARIATION_VALUE_CYCLICAL_KEY);
      }
    }

    const getDataAndAttributes = filtersValue => {
      const data = [];
      const dataAttributes = [];

      primaryDimValues.forEach((primaryDimValue, primDimIdx) => {
        let dimValueArray = jsonStat.id.map(dim => {
          if (dim === primaryDim) {
            return primaryDimValue;
          } else if (secondaryDim && dim === secondaryDim) {
            return secondaryDimValue;
          } else if (filtersValue[dim]) {
            return filtersValue[dim];
          } else {
            return null;
          }
        });

        if (dimValueArray.findIndex(val => val === null) > -1 && dimensionValueTree) {
          dimValueArray = getCompleteValueDims(dimValueArray, jsonStat.id, orderedDims, dimensionValueTree);
        }

        if (dimValueArray) {
          const dimValueIndexArray = dimValueArray.map((value, idx) => indexesMap[jsonStat.id[idx]][value]);
          const valueIdx = getDataIdxFromCoordinatesArray(dimValueIndexArray, jsonStat.size);

          const jsonStatValue = jsonStat.value[valueIdx];
          const value =
            jsonStatValue !== null && jsonStatValue !== "" && !isNaN(jsonStatValue) ? Number(jsonStatValue) : null;

          if (value !== null) {
            if (
              secondaryDim &&
              chartType === CHART_TYPE_PYRAMID &&
              secondaryDimValues.length === 2 &&
              secDimIdx === 0
            ) {
              data.push(-value);
            } else {
              data.push(value);
            }
            if (secondaryDimValue) {
              valorizedDataMap[secondaryDimValue][primDimIdx] = true;
            } else {
              valorizedDataArr[primDimIdx] = true;
            }
          } else {
            data.push(null);
          }

          const attributes = {
            observation: obsAttributeMap?.[valueIdx] || null,
            primaryDim: dimAttributeMap?.[primaryDim]?.[primaryDimValue] || null,
            secondaryDim: dimAttributeMap?.[secondaryDim]?.[secondaryDimValue] || null
          };
          dataAttributes.push(attributes);
        } else {
          data.push(null);
          dataAttributes.push({
            observation: null,
            primaryDim: null,
            secondaryDim: null
          });
        }
      });

      if (reversedData) {
        data.reverse();
        dataAttributes.reverse();
      }

      return {
        data: data,
        dataAttributes: dataAttributes
      };
    };

    const filtersValueFull = {...filtersValue};

    if (secondaryDim) {
      filtersValueFull[secondaryDim] = secondaryDimValue;
    }

    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
      filtersValueFull[VARIATION_DIMENSION_KEY] = VARIATION_VALUE_VALUE_KEY;
    }

    const {data, dataAttributes} = getDataAndAttributes(filtersValueFull);
    const label =
      variations.length === 0
        ? secondaryDim
          ? getFormattedDimensionValueLabel(jsonStat, null, secondaryDim, secondaryDimValue, labelFormat, t)
          : ""
        : secondaryDim
        ? getFormattedDimensionValueLabel(jsonStat, null, secondaryDim, secondaryDimValue, labelFormat, t) +
          ` (${getVariationValueLabels(t)[VARIATION_VALUE_VALUE_KEY]})`
        : getVariationValueLabels(t)[VARIATION_VALUE_VALUE_KEY];

    datasets.push({
      type: getChartType(chartType),
      data: data,
      label: label,
      dimValue: secondaryDimValue,
      dataAttributes: dataAttributes,
      filtersValue: filtersValueFull
    });

    if (variations.length > 0) {
      variations.forEach(variationDimValue => {
        filtersValueFull[VARIATION_DIMENSION_KEY] = variationDimValue;

        const {data, dataAttributes} = getDataAndAttributes(filtersValueFull);
        const label = secondaryDim
          ? getFormattedDimensionValueLabel(jsonStat, null, secondaryDim, secondaryDimValue, labelFormat, t) +
            ` (${getVariationValueLabels(t)[variationDimValue]})`
          : getVariationValueLabels(t)[variationDimValue];

        datasets.push({
          type: getVariationChartType(chartType),
          data: data,
          label: label,
          dimValue: secondaryDimValue,
          dataAttributes: dataAttributes,
          filtersValue: filtersValueFull,
          yAxisID: "varAxis",
          isVariation: true
        });
      });
    }

    return datasets;
  };

  if (secondaryDimValues && secondaryDimValues.length > 0) {
    secondaryDimValues.forEach((secondaryDimValue, secDimIdx) => {
      datasets = datasets.concat(getDataset(secondaryDimValue, secDimIdx));
    });
  } else {
    datasets = datasets.concat(getDataset());
  }

  let valorizedValues;

  if (chartType === CHART_TYPE_LINE && primaryDim === timeDim && timePeriodsByFreq) {
    let availableTimePeriods = [];
    if (filters.includes(FREQ_DIMENSION_KEY)) {
      availableTimePeriods = timePeriodsByFreq[filtersValue[FREQ_DIMENSION_KEY]];
    } else {
      // FREQ as secondary dimension
      secondaryDimValues.forEach(freq => (availableTimePeriods = availableTimePeriods.concat(timePeriodsByFreq[freq])));
    }
    valorizedValues = primaryDimValues.map(primDimVal => availableTimePeriods.includes(primDimVal));
  } else if (secondaryDimValues && secondaryDimValues.length > 0) {
    valorizedValues = new Array(primaryDimValues.length);
    primaryDimValues.forEach((_, idx) => {
      let found = false;
      secondaryDimValues.forEach(secDimVal => {
        if (!found && valorizedDataMap[secDimVal][idx] === true) {
          found = true;
        }
      });
      valorizedValues[idx] = found;
    });
  } else {
    valorizedValues = [...valorizedDataArr];
  }

  datasets.forEach(dataset => {
    dataset.data = dataset.data.filter((_, idx) => valorizedValues[idx]);
    dataset.dataAttributes = dataset.dataAttributes.filter((_, idx) => valorizedValues[idx]);
  });

  const primaryDimInfo = {
    valuesInfo: [],
    label: getFormattedDimensionLabel(jsonStat, null, primaryDim, labelFormat, t)
  };
  primaryDimValues.forEach((value, idx) => {
    if (valorizedValues[idx]) {
      primaryDimInfo.valuesInfo.push({
        label: getFormattedDimensionValueLabel(jsonStat, null, primaryDim, value, labelFormat, t),
        dimValue: value
      });
    }
  });

  if (reversedDatasets) {
    datasets.reverse();
  }
  if (reversedData) {
    primaryDimInfo.valuesInfo.reverse();
  }

  return {
    primaryDimInfo: primaryDimInfo,
    datasets: datasets,
    filterDims: getFilterDims(jsonStat, layout)
  };
};

const ChartJsonstatWrapper = props => {
  const {
    chartId,
    type,
    jsonStat,
    layout,
    disableWheelZoom,
    timePeriodsByFreq,
    labelFormat,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    chartSettings,
    showTrend,
    showCyclical,
    invertedDims,
    showArithmeticMean,
    arithmeticMeanDims,
    arithmeticMeans
  } = props;
  const {t} = useTranslation();

  const [dimensionValueTree, setDimensionValueTree] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    setDimensionValueTree(getDimensionValueTree(jsonStat, layout));
  }, [jsonStat, layout]);

  useEffect(() => {
    setData(
      getData(
        type,
        jsonStat,
        layout,
        dimensionValueTree,
        timePeriodsByFreq,
        labelFormat,
        showTrend,
        showCyclical,
        t,
        invertedDims
      )
    );
  }, [
    dimensionValueTree,
    invertedDims,
    jsonStat,
    labelFormat,
    layout,
    showCyclical,
    showTrend,
    t,
    timePeriodsByFreq,
    type
  ]);

  return data == null ? (
    <CustomEmpty text={t("components.chart.loading")} />
  ) : (
    <Chart
      chartId={chartId}
      chartData={data}
      type={type}
      layout={layout}
      disableWheelZoom={disableWheelZoom}
      labelFormat={labelFormat}
      decimalSeparator={decimalSeparator}
      roundingStrategy={roundingStrategy}
      decimalPlaces={decimalPlaces}
      chartSettings={chartSettings}
      showTrend={showTrend}
      showCyclical={showCyclical}
      showArithmeticMean={showArithmeticMean}
      arithmeticMeanDims={arithmeticMeanDims}
      arithmeticMeans={arithmeticMeans}
    />
  );
};

export default ChartJsonstatWrapper;
