import {Reducer} from "redux";
import {CLEAR_API} from "./apiActions";
import {
  errorRequest,
  REQUEST_ERROR,
  REQUEST_INIT,
  REQUEST_SUCCESS,
  successRequest
} from "../../middlewares/request/requestActions";

export type RequestState = "PENDING" | "SUCCESS" | "ERROR";
export const DEFAULT_SLICE_KEY = Symbol();
export type ApiState<D = any, E = any> = {[key: string | symbol | number]: ApiResponseState<D, E>};
export type ApiResponseState<D = any, E = any> = {
  data: D;
  error: E;
  loading: boolean;
  state: RequestState;
  responsePayload: (ReturnType<typeof successRequest> | ReturnType<typeof errorRequest>)["payload"];
};
export type AllApiState = {
  [requestLabel: string]: ApiState;
};

const apiReducer: Reducer<AllApiState> = (state = {[DEFAULT_SLICE_KEY]: {}}, action) => {
  const requestKey = action?.payload?.extra?.requestKey || "lastRequest";
  const cacheKey = action?.payload?.extra?.cacheKey || DEFAULT_SLICE_KEY;
  switch (action.type) {
    case REQUEST_INIT: {
      return {
        ...state,
        [requestKey]: {
          ...state[requestKey],
          [cacheKey]: {
            loading: true,
            data: null,
            error: null,
            state: "PENDING",
            responsePayload: null
          }
        }
      };
    }
    case REQUEST_SUCCESS: {
      return {
        ...state,
        [requestKey]: {
          ...state[requestKey],
          [cacheKey]: {
            loading: false,
            data: action.payload.response,
            error: null,
            state: "SUCCESS",
            responsePayload: action.payload
          }
        }
      };
    }
    case REQUEST_ERROR: {
      return {
        ...state,
        [requestKey]: {
          ...state[requestKey],
          [cacheKey]: {
            loading: false,
            data: null,
            error: action.payload.response,
            state: "ERROR",
            responsePayload: action.payload
          }
        }
      };
    }

    case CLEAR_API: {
      return {
        ...state,
        [action.payload]: {
          [DEFAULT_SLICE_KEY]: {}
        }
      };
    }
    default:
      return state;
  }
};

export default apiReducer;
