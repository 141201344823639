import React, {useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemRichTextDto} from "../../model/item-containers-models/itemRichTextDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import CustomEmpty from "../custom-empty";
import ItemContainerCategoriesList from "../item-container-categories-list";
import SanitizedHTML from "../sanitized-html";
import FullscreenProvider from "./FullscreenContext";
import ViewItem from "./ViewItem";
import {appConfigStateSelector} from "../../state/appConfig/appConfigSelectors";
import useLanguages from "../../state/hooks/useLanguages";
import {
  getViewIdxFromRowAndColItemContainer,
  ITEMCONTAINER_ELEM_TYPE_KEY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT,
  ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW,
  ITEMCONTAINER_ELEM_WIDTH_KEY
} from "../../utils/itemContainers";
import {handleStyle} from "./utils";

const VIEW_FIXED_HEIGHT = 580;
const useStyles = makeStyles(theme => ({
  col: {
    display: "inline-block",
    verticalAlign: "top",
    padding: 8
  },
  viewContainer: {
    position: "relative",
    padding: 16,
    background: "#FFFFFF !important"
  },
  viewContainerHeader: {},

  attributeId: {
    cursor: "default",
    fontSize: 13,
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(108, 108, 108)",
    borderRadius: 3,
    padding: "0 4px",
    marginLeft: 4
  },
  attributeAst: {
    cursor: "default",
    fontSize: 15,
    color: theme.palette.primary.main,
    fontFamily: "Do Hyeon",
    marginLeft: 4
  }
}));

const itemIsView = (v: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto): v is ItemViewTemplateDto =>
  v.type === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW;
const itemIsText = (v: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto): v is ItemRichTextDto =>
  v.type === ITEMCONTAINER_ELEM_TYPE_VALUE_TEXT;
const itemIsCategory = (
  v: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto
): v is ItemCategoryTemplateDto => v.type === ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY;

interface ItemContainerColProps {
  itemContainer: ItemContainerDto;
  itemContainerElem: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto;
  rowIdx: number;
  colIdx: number;
}
function ItemContainerCol({itemContainer, rowIdx, colIdx, itemContainerElem}: ItemContainerColProps) {
  const itemContainerId = itemContainer.itemContainerId;
  const {t, localizeI18nObj} = useLanguages();
  const mapId = useRef("map__" + uuidv4());

  const classes = useStyles();
  const appConfig = useSelector(appConfigStateSelector);
  const minItemContainerWidth = appConfig.minItemContainerWidth;
  const itemIdx = getViewIdxFromRowAndColItemContainer(rowIdx, colIdx);

  useEffect(() => {
    const func = () =>
      handleStyle(
        itemContainerId,
        itemIdx,
        itemContainerElem,
        minItemContainerWidth,
        itemContainer.type === "Widget" &&
          itemIsView(itemContainerElem) &&
          itemContainerElem.viewTemplate.defaultView === "map"
          ? mapId
          : null
      );
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [itemContainerId, itemIdx, itemContainerElem, minItemContainerWidth, itemContainer.type, mapId]);

  useEffect(() => {
    handleStyle(itemContainerId, itemIdx, itemContainerElem, minItemContainerWidth);
  }, [itemContainerElem, itemContainerId, itemIdx, minItemContainerWidth]);

  return (
    <div
      id={`itemContainer_${itemContainerId}__col__${itemIdx}`}
      className={`${classes.col} itemContainer_col`}
      style={{
        width: `${window.innerWidth > minItemContainerWidth ? itemContainerElem[ITEMCONTAINER_ELEM_WIDTH_KEY] : 100}%`
      }}
    >
      <FullscreenProvider item={itemContainerElem} itemIdx={itemIdx}>
        <Card
          id={`itemContainer_${itemContainerId}__view-container__${itemIdx}`}
          className={`${classes.viewContainer} itemContainer_view-container`}
          style={{
            width: "100%",
            height: itemIsView(itemContainerElem)
              ? itemContainer.type === "Widget"
                ? "97vh"
                : VIEW_FIXED_HEIGHT
              : "auto",
            minHeight: itemIsView(itemContainerElem) ? VIEW_FIXED_HEIGHT : "auto",
            overflowY:
              itemContainerElem[ITEMCONTAINER_ELEM_TYPE_KEY] === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW ? "hidden" : "auto",
            overflowX: "hidden"
          }}
        >
          {(() => {
            if (itemIsText(itemContainerElem)) {
              return <SanitizedHTML className={null} html={localizeI18nObj(itemContainerElem.data)} allowTarget />;
            } else if (
              itemIsCategory(itemContainerElem) &&
              (!itemContainerElem?.catalog || itemContainerElem.catalog?.categoryGroups?.length === 0)
            ) {
              return <CustomEmpty text={t("components.itemContainer.missingCategory")} />;
            } else if (itemIsCategory(itemContainerElem)) {
              return <ItemContainerCategoriesList item={itemContainerElem} />;
            } else if (itemIsView(itemContainerElem) && !itemContainerElem.viewTemplate) {
              return <CustomEmpty text={t("components.itemContainer.missingView")} />;
            } else if (itemIsView(itemContainerElem)) {
              return (
                <ViewItem
                  mapId={mapId.current}
                  itemContainerElem={itemContainerElem}
                  viewIdx={itemIdx}
                  itemContainer={itemContainer}
                  minItemContainerWidth={minItemContainerWidth}
                />
              );
            } else {
              return <span />;
            }
          })()}
        </Card>
      </FullscreenProvider>
    </div>
  );
}

export default ItemContainerCol;
