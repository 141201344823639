import {Reducer} from "@reduxjs/toolkit";
import _ from "lodash";
import {getViewerIdxFromStructure, getViewerIdxFromType} from "../../../components/data-viewer/constant";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../../../components/label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC} from "../../../components/temporal-dim-order-selector/constants";
import {
  Criteria,
  CRITERIA_SELECTION_TYPE_PARTIAL,
  GENERATING_HTML_TIME_KEY,
  OBSERVATION_COUNT_KEY,
  SERVER_TIMINGS_KEY,
  ViewerMode
} from "../constants";
import {
  DATASET_SV_CHART_FILTERS_TREE_SUBMIT,
  DATASET_SV_CHART_LAYOUT_SUBMIT,
  DATASET_SV_CHART_SETTINGS_SET,
  DATASET_SV_CRITERIA_ALERT_HIDE,
  DATASET_SV_CRITERIA_HIDE,
  DATASET_SV_CRITERIA_OBS_COUNT_WARNING_HIDE,
  DATASET_SV_CRITERIA_SHOW,
  DATASET_SV_DATASET_FETCH_ENABLE,
  DATASET_SV_DATASET_FETCH_ERROR,
  DATASET_SV_DATASET_FETCH_INIT,
  DATASET_SV_DATASET_FETCH_SUCCESS,
  DATASET_SV_DOWNLOAD_SUBMIT,
  DATASET_SV_DOWNLOAD_WARNING_HIDE,
  DATASET_SV_HTML_GENERATING_TIME_SET,
  DATASET_SV_LABEL_FORMAT_SET,
  DATASET_SV_LAYOUT_HIDE,
  DATASET_SV_LAYOUT_SHOW,
  DATASET_SV_MAP_DETAIL_LEVEL_SET,
  DATASET_SV_MAP_LAYOUT_SUBMIT,
  DATASET_SV_MAP_SETTINGS_SET,
  DATASET_SV_SDMX_QUERY_FETCH,
  DATASET_SV_SDMX_QUERY_HIDE,
  DATASET_SV_SDMX_QUERY_SHOW,
  DATASET_SV_SET_FULLSCREEN,
  DATASET_SV_STATE_BACKUP,
  DATASET_SV_STRUCTURE_CODELIST_FETCH,
  DATASET_SV_STRUCTURE_CODELIST_FULL_FETCH,
  DATASET_SV_STRUCTURE_CODELIST_FULL_HIDE,
  DATASET_SV_STRUCTURE_CRITERIA_SET,
  DATASET_SV_STRUCTURE_FETCH_ERROR,
  DATASET_SV_STRUCTURE_FETCH_INIT,
  DATASET_SV_STRUCTURE_FETCH_SUCCESS,
  DATASET_SV_TABLE_FILTERS_TREE_SUBMIT,
  DATASET_SV_TABLE_LAYOUT_SUBMIT,
  DATASET_SV_TEMPORAL_DIM_ORDER_SET,
  DATASET_SV_UNAVAILABLE_VIEW_WARNING_HIDE,
  DATASET_SV_VARIATION_SET,
  DATASET_SV_VIEW_ERROR_HIDE,
  DATASET_SV_VIEW_TEMPLATE_HIDE,
  DATASET_SV_VIEW_TEMPLATE_SHOW,
  DATASET_SV_VIEW_TEMPLATE_SUBMIT,
  DATASET_SV_VIEWER_SET
} from "./actions";
import {DatasetSingleViewerState, getViewTemplateBackupFromState} from "./constants";
import {
  getActionExtras,
  getAppConfig,
  getCurrentNodeConfig
} from "../../../middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../../middlewares/request/requestActions";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  getCriteriaObjectFromArray
} from "../../../utils/criteria";
import {TABLE_EMPTY_CHAR_DEFAULT} from "../../../utils/formatters";
import {localizeI18nObj} from "../../../utils/i18n";
import {getDatasetStorageKey} from "../../../utils/other";
import {getMinAndMax} from "../../../utils/timePeriodAndFreq";
import {getTreeFromArray} from "../../../utils/tree";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {
  getChartSettingsFromViewTemplateLayouts,
  getMapSettingsFromViewTemplateLayouts
} from "../../../utils/viewTemplate";

const initialState = {
  datasetUuid: null,
  dataset: null,
  isFirstFetch: false,
  datasetFetchStart: false,
  datasetFetchError: false,
  isPartialData: false,
  isEmptyData: false,
  isTooBigData: false,
  isTooLongQuery: false,
  isCriteriaVisible: false,
  isCriteriaAlertVisible: false,
  isObsCountWarningVisible: false,
  dimensions: null,
  dimensionsInfo: {},
  territoryDim: null,
  timeDim: null,
  freqDim: null,
  viewerIdx: null,
  view: null,
  template: null,
  hasViewLayout: false,
  hasTemplateLayout: false,
  hasAnnotationLayout: false,
  isLayoutVisible: false,
  tableLayout: null,
  mapLayout: null,
  chartLayout: null,
  tableDefaultFiltersValue: null,
  chartDefaultFiltersValue: null,
  tableFilterTree: null,
  mapFilterTree: null,
  chartFilterTree: null,
  timePeriodsByFreq: null,
  labelFormat: null,
  temporalDimOrder: null,
  showTrend: false,
  showCyclical: false,
  criteria: {},
  initialCriteria: {},
  decimalSeparator: null,
  roundingStrategy: null,
  decimalPlaces: null,
  tableEmptyChar: TABLE_EMPTY_CHAR_DEFAULT,
  chartSettings: {},
  mapSettings: {},
  enableCriteria: true,
  enableLayout: true,
  enableVariation: false,
  codelists: null,
  codelistsLength: null,
  codelistFetchError: false,
  criteriaObsCount: null,
  timings: null,
  isFetchDatasetDisabled: true,
  isDownloadWarningVisible: false,
  isUnavailableViewWarningVisible: false,
  isViewVisible: false,
  isViewErrorVisible: false,
  viewErrorMessage: null,
  isTemplateVisible: false,
  isQueryVisible: false,
  structureQuery: null,
  dataQuery: null,
  detailLevel: null,
  isFullscreen: false,
  isTableEnabled: true,
  isMapEnabled: true,
  isChartEnabled: true,
  tableLockedDimensions: null,
  graphLockedDimensions: null,
  missingFilterValues: null,
  checkDatasetSize: false
};

const datasetSVReducer: Reducer<DatasetSingleViewerState> = (state = initialState, action) => {
  switch (action.type) {
    case DATASET_SV_STATE_BACKUP: {
      if (state.datasetUuid !== null && state.datasetUuid === action.datasetUuid && state.dataset !== null) {
        sessionStorage.setItem(state.datasetUuid, JSON.stringify(getViewTemplateBackupFromState(state)));
      }
      return state;
    }
    case DATASET_SV_STRUCTURE_FETCH_INIT: {
      return initialState;
    }
    case DATASET_SV_STRUCTURE_FETCH_SUCCESS: {
      const {language, languages} = getActionExtras(action);

      const VIEW_KEY = "view";
      const TEMPLATE_KEY = "template";

      const {datasetUuid, structure, viewId} = action.payload;

      const dimensions = structure?.criteria || [];

      const territoryDim = structure?.territorialDimension || null;
      const timeDim = structure?.timeDimension || null;
      const freqDim = structure?.freqDimension || null;

      const hasView = structure[VIEW_KEY]?.mode === ViewerMode.SingleViewer;
      const view = hasView
        ? {
            ...structure[VIEW_KEY],
            layouts: JSON.parse(structure[VIEW_KEY].layouts)
          }
        : null;

      const hasTemplate = structure[TEMPLATE_KEY]?.mode === ViewerMode.SingleViewer;
      const template = hasTemplate
        ? {
            ...structure[TEMPLATE_KEY],
            layouts: JSON.parse(structure[TEMPLATE_KEY].layouts)
          }
        : null;

      let sessionBackup;
      const storageItem = sessionStorage.getItem(datasetUuid);
      if (storageItem && storageItem.length > 0) {
        sessionBackup = JSON.parse(storageItem || "{}");
        sessionBackup.layouts = JSON.parse(sessionBackup?.layouts || "{}");
        sessionBackup = sessionBackup.mode === ViewerMode.SingleViewer ? sessionBackup : null;

        const storedDatasets = JSON.parse(sessionStorage.getItem("datasets") || "[]");
        if (!storedDatasets.map(getDatasetStorageKey).includes(datasetUuid)) {
          sessionStorage.removeItem(datasetUuid);
        }
      }

      let viewTemplate = sessionBackup || view || template || null;
      let viewTemplateLayouts = null;

      if (viewTemplate) {
        viewTemplate = {
          ...viewTemplate,
          decimalSeparator: hasTemplate ? template.decimalSeparator : null,
          roundingStrategy: hasTemplate ? template.roundingStrategy : null,
          decimalNumber: hasTemplate ? template.decimalNumber : null,
          enableCriteria: hasTemplate ? template.enableCriteria : true,
          enableLayout: hasTemplate ? template.enableLayout : true,
          enableVariation: hasTemplate ? template.enableVariation : false
        };
        viewTemplateLayouts = {
          ...(template?.layouts || {}),
          ...(view?.layouts || {}),
          ...(sessionBackup?.layouts || {})
        };
      }

      const structureCriteria = (structure.filters || []).length > 0 ? structure.filters : null;

      const criteria = getCriteriaObjectFromArray(viewTemplate?.criteria || structureCriteria);
      const isCriteriaVisible = _.isEmpty(criteria);

      const disabledViewers = (structure?.disabledViewers || []).map((v: string) => v.toLowerCase());

      let viewerIdx = null;
      let isTableEnabled = !disabledViewers.includes("table");
      let isMapEnabled = !disabledViewers.includes("map");
      let isChartEnabled = !disabledViewers.includes("graph");

      if (viewTemplate) {
        viewerIdx = getViewerIdxFromType(viewTemplate.defaultView);
        if (viewerIdx !== null && viewerIdx === 0) {
          isTableEnabled = true;
        } else if (viewerIdx !== null && viewerIdx === 1) {
          isMapEnabled = true;
        } else if (viewerIdx !== null && viewerIdx >= 2) {
          isChartEnabled = true;
        }
      } else if (structure.defaultView) {
        viewerIdx = getViewerIdxFromStructure(structure.defaultView);
        if (viewerIdx !== null && viewerIdx === 0) {
          isTableEnabled = true;
        } else if (viewerIdx !== null && viewerIdx === 1) {
          isMapEnabled = true;
        } else if (viewerIdx !== null && viewerIdx >= 2) {
          isChartEnabled = true;
        }
      } else if (isTableEnabled) {
        viewerIdx = 0;
      } else if (isMapEnabled) {
        viewerIdx = 1;
      } else if (isChartEnabled) {
        viewerIdx = 2;
      }

      const tableLayout =
        viewTemplateLayouts && viewTemplateLayouts?.tableLayout
          ? viewTemplateLayouts.tableLayout
          : structure?.layoutTable
          ? {
              ...structure.layoutTable,
              filtersValue: undefined
            }
          : null;
      const mapLayout = territoryDim
        ? viewTemplateLayouts && viewTemplateLayouts?.mapLayout
          ? viewTemplateLayouts.mapLayout
          : structure?.layoutMap
          ? {
              ...structure.layoutMap,
              territoryDim: territoryDim,
              filtersValue: undefined
            }
          : null
        : null;
      const chartLayout =
        viewTemplateLayouts && viewTemplateLayouts?.chartLayout
          ? viewTemplateLayouts.chartLayout
          : structure?.layoutChart
          ? {
              ...structure.layoutChart,
              filtersValue: undefined
            }
          : null;

      return {
        ...state,
        datasetUuid: datasetUuid,
        isFirstFetch: true,
        hasViewLayout: hasView,
        hasTemplateLayout: hasTemplate,
        hasAnnotationLayout: !!structure.layout,
        view: view,
        template: template,
        dimensions: dimensions,
        territoryDim: territoryDim,
        timeDim: timeDim,
        freqDim: freqDim,
        detailLevel: !viewTemplateLayouts
          ? null
          : viewTemplateLayouts.detailLevel !== null && viewTemplateLayouts.detailLevel !== undefined
          ? viewTemplateLayouts.detailLevel
          : viewTemplateLayouts.mapDetailLevel !== null && viewTemplateLayouts.mapDetailLevel !== undefined
          ? viewTemplateLayouts.mapDetailLevel
          : null,
        codelistsLength: state.codelistsLength ? state.codelistsLength : dimensions.map(() => null),
        isCriteriaVisible: isCriteriaVisible,
        viewerIdx: viewerIdx,
        isUnavailableViewWarningVisible: viewId !== null && !structure?.[VIEW_KEY],
        tableLayout: tableLayout,
        mapLayout: mapLayout,
        chartLayout: chartLayout,
        tableDefaultFiltersValue: structure?.layoutTable?.filtersValue || null,
        chartDefaultFiltersValue: structure?.layoutChart?.filtersValue || null,
        labelFormat: viewTemplateLayouts?.labelFormat || LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
        temporalDimOrder:
          viewTemplateLayouts?.temporalDimOrder || structure.temporalDimOrder || TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
        showTrend: viewTemplateLayouts?.showTrend || false,
        showCyclical: viewTemplateLayouts?.showCyclical || false,
        criteria: criteria,
        initialCriteria: criteria,
        decimalSeparator:
          Object.keys(viewTemplate?.decimalSeparator || {}).length > 0
            ? localizeI18nObj(viewTemplate.decimalSeparator, language, languages)
            : structure.decimalSeparator,
        roundingStrategy:
          viewTemplate?.roundingStrategy !== undefined ? viewTemplate?.roundingStrategy : structure.roundingStrategy,
        decimalPlaces:
          viewTemplate?.decimalNumber !== null &&
          viewTemplate?.decimalNumber !== undefined &&
          viewTemplate?.decimalNumber !== -1
            ? viewTemplate?.decimalNumber
            : structure.decimalPlaces,
        tableEmptyChar:
          viewTemplateLayouts?.tableEmptyChar !== null && viewTemplateLayouts?.tableEmptyChar !== undefined
            ? viewTemplateLayouts?.tableEmptyChar
            : structure.emptyCellPlaceHolder,
        chartSettings: {
          ...getAppConfig(action).chartDefaultSettings,
          ...getChartSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        mapSettings: {
          ...getAppConfig(action).mapDefaultSettings,
          ...getMapSettingsFromViewTemplateLayouts(viewTemplateLayouts)
        },
        enableCriteria: viewTemplate?.enableCriteria !== false && dimensions.length > 0,
        enableLayout: viewTemplate?.enableLayout !== false && dimensions.length > 0,
        enableVariation: viewTemplate?.enableVariation === true,
        isFetchDatasetDisabled: isCriteriaVisible,
        isTableEnabled: isTableEnabled,
        isMapEnabled: isMapEnabled,
        isChartEnabled: isChartEnabled,
        tableLockedDimensions:
          hasTemplate &&
          template.layouts?.lockTableDimensions &&
          (template.layouts?.tableLockedDimensions || []).length > 0
            ? template.layouts?.tableLockedDimensions
            : structure?.tableLockedDimensions || [],
        graphLockedDimensions:
          hasTemplate &&
          template.layouts?.lockGraphDimensions &&
          (template.layouts?.graphLockedDimensions || []).length > 0
            ? template.layouts?.graphLockedDimensions
            : structure?.graphLockedDimensions || [],
        checkDatasetSize: structure.checkDatasetSize || false
      };
    }
    case DATASET_SV_STRUCTURE_FETCH_ERROR: {
      return {
        ...initialState,
        enableCriteria: false,
        enableLayout: false
      };
    }
    case DATASET_SV_DATASET_FETCH_ENABLE: {
      const tooMuchObs =
        state.criteriaObsCount !== null &&
        action.payload.maxObservations !== undefined &&
        state.criteriaObsCount > action.payload.maxObservations;

      return {
        ...state,
        isFetchDatasetDisabled: tooMuchObs,
        criteriaObsCount: tooMuchObs ? state.criteriaObsCount : null,
        isObsCountWarningVisible: tooMuchObs
      };
    }
    case DATASET_SV_DATASET_FETCH_INIT: {
      return {
        ...state,
        isFetchDatasetDisabled: true,
        datasetFetchStart: true,
        datasetFetchError: false
      };
    }
    case DATASET_SV_DATASET_FETCH_SUCCESS: {
      if (action.payload.worker) {
        action.payload.worker.terminate();
      }

      const isResponseValid = !action.payload.isResponseNotValid;

      const dataset = isResponseValid ? action.payload.response : state.dataset;

      const tableLayout = isResponseValid ? action.payload.tableLayout : state.tableLayout;
      const mapLayout = isResponseValid ? action.payload.mapLayout : state.mapLayout;
      const chartLayout = isResponseValid ? action.payload.chartLayout : state.chartLayout;

      const dimensionsInfo: {[key: string]: any} = {};
      (dataset?.id || []).forEach((dim: string) => {
        dimensionsInfo[dim] = {
          id: dim,
          label: dataset.dimension[dim].label,
          size: dataset.size[dataset.id.indexOf(dim)]
        };
      });

      return {
        ...state,
        dataset: dataset,

        dimensionsInfo: dimensionsInfo,

        isFirstFetch: false,
        datasetFetchStart: false,
        datasetFetchError: false,

        isEmptyData: action.payload.isEmptyData,
        isPartialData: action.payload.isPartialData,

        enableCriteria: state.enableCriteria,
        isCriteriaVisible: state.isCriteriaVisible && !isResponseValid,
        isCriteriaAlertVisible: !isResponseValid,

        tableLayout: tableLayout,
        mapLayout: mapLayout,
        chartLayout: chartLayout,

        tableFilterTree: isResponseValid ? action.payload.tableFilterTree : state.tableFilterTree,
        mapFilterTree: isResponseValid ? action.payload.mapFilterTree : state.mapFilterTree,
        chartFilterTree: isResponseValid ? action.payload.chartFilterTree : state.chartFilterTree,
        timePeriodsByFreq: isResponseValid ? action.payload.timePeriodsByFreq : state.timePeriodsByFreq,

        initialCriteria: isResponseValid ? state.criteria : state.initialCriteria,

        codelists: isResponseValid ? null : state.codelists,

        timings: {
          [OBSERVATION_COUNT_KEY]: action.payload.response?.value
            ? Object.keys(action.payload.response.value).length
            : null,
          [SERVER_TIMINGS_KEY]: action.payload.responseHeaders.backendtimers
            ? JSON.parse(action.payload.responseHeaders.backendtimers)
            : null
        },

        structureQuery: null,
        dataQuery: null
      };
    }
    case DATASET_SV_DATASET_FETCH_ERROR: {
      const isPayloadTooLarge = action.payload.statusCode === 413;
      const isTooLongQuery = action.payload.statusCode === 414;

      return {
        ...state,
        dataset: null,
        dimensionsInfo: {},
        datasetFetchStart: false,
        datasetFetchError: true,
        isPartialData: false,
        isEmptyData: false,
        isTooBigData: isPayloadTooLarge,
        isTooLongQuery: isTooLongQuery,
        isCriteriaAlertVisible: isPayloadTooLarge || isTooLongQuery
      };
    }
    case DATASET_SV_CRITERIA_SHOW: {
      return {
        ...state,
        isCriteriaVisible: true
      };
    }
    case DATASET_SV_CRITERIA_HIDE: {
      return {
        ...state,
        isCriteriaVisible: false,
        criteria: state.initialCriteria,
        codelists: null
      };
    }
    case DATASET_SV_MAP_DETAIL_LEVEL_SET: {
      return {
        ...state,
        detailLevel: action.detailLevel
      };
    }
    case DATASET_SV_CRITERIA_ALERT_HIDE: {
      return {
        ...state,
        isCriteriaAlertVisible: false
      };
    }
    case DATASET_SV_CRITERIA_OBS_COUNT_WARNING_HIDE: {
      return {
        ...state,
        isObsCountWarningVisible: false
      };
    }
    case DATASET_SV_STRUCTURE_CRITERIA_SET: {
      return {
        ...state,
        criteria: action.criteria,
        criteriaObsCount: null
      };
    }
    case DATASET_SV_VIEWER_SET: {
      return {
        ...state,
        viewerIdx: action.viewerIdx,
        isTableVisible: action.viewerIdx === 0,
        isMapVisible: action.viewerIdx === 1,
        isChartVisible: action.viewerIdx >= 2
      };
    }
    case DATASET_SV_LAYOUT_SHOW: {
      return {
        ...state,
        isLayoutVisible: true
      };
    }
    case DATASET_SV_LAYOUT_HIDE: {
      return {
        ...state,
        isLayoutVisible: false
      };
    }
    case DATASET_SV_TABLE_LAYOUT_SUBMIT: {
      return {
        ...state,
        tableLayout: action.layout
      };
    }
    case DATASET_SV_TABLE_FILTERS_TREE_SUBMIT: {
      return {
        ...state,
        tableFilterTree: action.filterTree
      };
    }
    case DATASET_SV_MAP_LAYOUT_SUBMIT: {
      return {
        ...state,
        mapLayout: action.layout
      };
    }
    case DATASET_SV_CHART_LAYOUT_SUBMIT: {
      return {
        ...state,
        chartLayout: action.layout
      };
    }
    case DATASET_SV_CHART_FILTERS_TREE_SUBMIT: {
      return {
        ...state,
        chartFilterTree: action.filterTree
      };
    }
    case DATASET_SV_LABEL_FORMAT_SET: {
      return {
        ...state,
        labelFormat: action.labelFormat
      };
    }
    case DATASET_SV_VARIATION_SET: {
      return {
        ...state,
        showTrend: action.variation?.showTrend || false,
        showCyclical: action.variation?.showCyclical || false
      };
    }
    case DATASET_SV_TEMPORAL_DIM_ORDER_SET: {
      return {
        ...state,
        temporalDimOrder: action.temporalDimOrder
      };
    }
    case DATASET_SV_CHART_SETTINGS_SET: {
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          ...action.chartSettings
        }
      };
    }
    case DATASET_SV_MAP_SETTINGS_SET: {
      return {
        ...state,
        mapSettings: {
          ...state.mapSettings,
          ...action.mapSettings
        }
      };
    }
    case DATASET_SV_DOWNLOAD_WARNING_HIDE: {
      return {
        ...state,
        isDownloadWarningVisible: false
      };
    }
    case DATASET_SV_UNAVAILABLE_VIEW_WARNING_HIDE: {
      return {
        ...state,
        isUnavailableViewWarningVisible: false
      };
    }
    case DATASET_SV_HTML_GENERATING_TIME_SET: {
      return {
        ...state,
        timings: {
          ...state.timings,
          [GENERATING_HTML_TIME_KEY]: action.time
        }
      };
    }
    case DATASET_SV_VIEW_TEMPLATE_SHOW: {
      return {
        ...state,
        isViewVisible: action.isView ? true : state.isViewVisible,
        isTemplateVisible: !action.isView ? true : state.isTemplateVisible
      };
    }
    case DATASET_SV_VIEW_TEMPLATE_HIDE: {
      return {
        ...state,
        isViewVisible: false,
        isTemplateVisible: false
      };
    }
    case DATASET_SV_VIEW_ERROR_HIDE: {
      return {
        ...state,
        isViewErrorVisible: false,
        viewErrorMessage: null
      };
    }
    case DATASET_SV_SDMX_QUERY_SHOW: {
      return {
        ...state,
        isQueryVisible: true
      };
    }
    case DATASET_SV_SDMX_QUERY_HIDE: {
      return {
        ...state,
        isQueryVisible: false
      };
    }
    case DATASET_SV_STRUCTURE_CODELIST_FULL_HIDE: {
      return {
        ...state,
        missingFilterValues: null
      };
    }
    case DATASET_SV_SET_FULLSCREEN: {
      return {
        ...state,
        isFullscreen: action.isFullscreen
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DATASET_SV_STRUCTURE_CODELIST_FETCH: {
          return {
            ...state,
            criteriaObsCount: null,
            codelistFetchError: false
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DATASET_SV_STRUCTURE_CODELIST_FETCH: {
          const dimensions = state.dimensions;
          const timeDim = state.timeDim;
          const freqDim = state.freqDim;
          const codelistsLength = state.codelistsLength;
          const criteria = state.criteria;

          const newCodelists: {[key: string]: any[]} = {};
          const newCodelistLength = codelistsLength ? [...codelistsLength] : (dimensions || []).map(() => null);
          const newCriteria: {[key: string]: Criteria} = _.cloneDeep(criteria);

          (dimensions || []).forEach((dimension, idx) => {
            const codelist = action.payload.response.criteria.find(({id}: any) => id === dimension.id);

            if (codelist) {
              let length = 0;
              const values = (codelist.values || []).map((code: any) => {
                if (code.isSelectable) {
                  // todo: what if it is undefined?
                  length++;
                }
                return {
                  ...code,
                  label: `[${code.id}] ${code.name}`
                };
              });

              newCodelists[dimension.id] = getTreeFromArray(values, "parentId", "children");

              if (!timeDim || dimension.id !== timeDim) {
                newCodelistLength[idx] = length;

                if (values.length === 1) {
                  newCriteria[dimension.id] = {
                    id: dimension.id,
                    type: CRITERIA_FILTER_TYPE_CODES,
                    filterValues: [values[0].id],
                    period: undefined,
                    from: undefined,
                    to: undefined
                  };
                }
              } else if (!newCriteria[timeDim]) {
                if (
                  freqDim &&
                  isValidIntegerInInclusiveRange(action.payload.extra.defaultLastNPeriods, 1) &&
                  action.payload.extra.type === CRITERIA_SELECTION_TYPE_PARTIAL
                ) {
                  newCriteria[timeDim] = {
                    id: timeDim,
                    type: CRITERIA_FILTER_TYPE_PERIODS,
                    period: action.payload.extra.defaultLastNPeriods,
                    filterValues: undefined,
                    from: undefined,
                    to: undefined
                  };
                } else {
                  const {min, max} = getMinAndMax(values, action.payload.extra.freq, getCurrentNodeConfig(action));

                  newCriteria[timeDim] = {
                    id: timeDim,
                    type: CRITERIA_FILTER_TYPE_RANGE,
                    from: min,
                    to: max,
                    filterValues: undefined,
                    period: undefined
                  };
                }
              }
            }
          });

          return {
            ...state,
            criteria: newCriteria,
            codelists: newCodelists,
            codelistsLength: newCodelistLength
          };
        }
        case DATASET_SV_VIEW_TEMPLATE_SUBMIT: {
          return action.payload.extra.isView
            ? {
                ...state,
                isViewVisible: false,
                isTemplateVisible: false
              }
            : {
                ...initialState
              };
        }
        case DATASET_SV_SDMX_QUERY_FETCH: {
          return {
            ...state,
            structureQuery: action.payload.response?.structureUrl || null,
            dataQuery: action.payload.response?.dataflowUrl || null
          };
        }
        case DATASET_SV_STRUCTURE_CODELIST_FULL_FETCH: {
          const codelist = (action.payload.response.criteria[0] || {}).values || [];
          return {
            ...state,
            missingFilterValues: codelist.filter((code: any) =>
              action.payload.extra.missingFilterValueIds.includes(code.id)
            )
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DATASET_SV_STRUCTURE_CODELIST_FETCH: {
          const isTooLongQuery = action.payload.statusCode === 414;

          return {
            ...state,
            isTooLongQuery: isTooLongQuery,
            isCriteriaAlertVisible: isTooLongQuery,
            codelistFetchError: true
          };
        }
        case DATASET_SV_DOWNLOAD_SUBMIT: {
          return {
            ...state,
            isDownloadWarningVisible: action.payload.statusCode === 406
          };
        }
        case DATASET_SV_VIEW_TEMPLATE_SUBMIT: {
          return {
            ...state,
            isViewErrorVisible: !!(
              action.payload.extra.isView &&
              action.payload.statusCode === 409 &&
              action.payload.response
            ),
            viewErrorMessage: action.payload.response
          };
        }
        case DATASET_SV_SDMX_QUERY_FETCH: {
          return {
            ...state,
            isQueryVisible: false,
            structureQuery: null,
            dataQuery: null
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default datasetSVReducer;
