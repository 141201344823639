const registerDefaultServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      navigator.serviceWorker
        .register("./service-worker.js", {scope: "/"})
        .then(function (registration) {
          console.debug("Worker registration successful", registration.scope);
        })
        .catch(function (err) {
          console.debug(err);
        });
    });
  } else {
    console.debug("Service Worker is not supported by browser.");
  }
};

export default registerDefaultServiceWorker;
