import React, {Fragment, useEffect, useRef, useState} from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import ButtonSelect from "../button-select";
import ModuleMenuItemPlaceholder from "../module-menu-item-placeholder";
import ModulesPlaceholder from "../modules-placeholder";
import SettingsDialog from "../settings-dialog";
import UserDashboardsSettingsForm from "../settings-select/user-dashboards-settings-form";
import UserViewsSettingsForm from "../settings-select/user-views-settings-form";
import UserSettingsForm from "../settings-select/users-settings-form/user-settings-form";
import EditUserForm from "./edit-user-form";
import UserChangePasswordForm from "./user-change-password-form";
import {
  fetchExternalUser,
  hideUserChangePasswordForm,
  hideUserEditUserModal,
  hideUserRegisterModal,
  logoutUser,
  showUserChangePasswordForm,
  showUserEditUserModal,
  showUserLoginForm
} from "../../state/user/userActions";
import {canDisplayUserViews, canManagePersonalDashboards} from "../../utils/user";
import themeConfig from "../../theme-config/config.json";

const mapStateToProps = state => ({
  modulesConfig: state.app.modulesConfig,
  user: state.user,
  supportedAuths: state.hub.supportedAuths
});

const mapDispatchToProps = dispatch => ({
  onExternalLogin: (providerId, loginCode) => dispatch(fetchExternalUser(providerId, loginCode)),
  onLogout: userId => dispatch(logoutUser(userId)),
  onLoginFormShow: () => dispatch(showUserLoginForm()),
  onChangePasswordShow: () => dispatch(showUserChangePasswordForm()),
  onChangePasswordHide: () => dispatch(hideUserChangePasswordForm()),
  onRegisterModalHide: () => dispatch(hideUserRegisterModal()),
  onEditModalShow: () => dispatch(showUserEditUserModal()),
  onEditModalHide: () => dispatch(hideUserEditUserModal())
});

const styles = () => ({
  email: {
    cursor: "initial",
    color: "gray"
  },
  divider: {
    width: "100%",
    color: "gray",
    fontStyle: "italic",
    fontSize: 14
  }
});

const BASIC_LOGIN = "Base";
let providerId = null;

const UserSelect = ({
  classes,
  modulesConfig,
  user,
  supportedAuths,
  onExternalLogin,
  onLogout,
  onLoginFormShow,
  onChangePasswordShow,
  onChangePasswordHide,
  onRegisterModalHide,
  onEditModalShow,
  onEditModalHide,
  nodes,
  disabled
}) => {
  const {t} = useTranslation();

  const registerFormRef = useRef();
  const changePasswordFormRef = useRef();
  const editUserFormRef = useRef();
  const viewsSettingsRef = useRef();
  const dashboardsSettingsRef = useRef();

  const [isViewsOpen, setIsViewsOpen] = useState(false);
  const [isDashboardsOpen, setIsDashboardsOpen] = useState(false);

  useEffect(() => {
    function loginHandler(e) {
      const loginCode = localStorage.getItem("loginCode");
      if (e.storageArea === localStorage && loginCode && providerId) {
        onExternalLogin(providerId, loginCode);
        localStorage.removeItem("loginCode");
        providerId = null;
      }
    }

    window.addEventListener("storage", loginHandler);
    return () => {
      window.removeEventListener("storage", loginHandler);
    };
  }, [onExternalLogin]);

  const onDashboardsOpen = () => {
    setIsDashboardsOpen(true);
  };

  const onDashboardsClose = () => {
    setIsDashboardsOpen(false);
  };

  const onViewsOpen = () => {
    setIsViewsOpen(true);
  };

  const onViewsClose = () => {
    setIsViewsOpen(false);
  };

  const handleExternalLogin = (provider, loginURL) => {
    providerId = provider;
    localStorage.removeItem("loginCode");
    window.open(loginURL, "", "width=720,height=720");
  };

  return (
    <Fragment>
      {(() => {
        if (user.isAuthenticated) {
          return (
            <Tooltip title={user.email}>
              <ButtonSelect
                value=""
                icon={<AccountCircleIcon />}
                ariaLabel={t("components.header.actions.user.ariaLabel")}
                tooltip={t("components.header.actions.user.title")}
                onChange={actionId => {
                  switch (actionId) {
                    case "views":
                      onViewsOpen();
                      break;
                    case "dashboards":
                      onDashboardsOpen();
                      break;
                    case "changePassword":
                      onChangePasswordShow();
                      break;
                    case "editUser":
                      onEditModalShow();
                      break;
                    case "logout":
                      onLogout(user.userId);
                      break;
                    default:
                      break;
                  }
                }}
              >
                {[
                  <div key="email" className={classes.email}>
                    {user.email}
                  </div>,
                  canDisplayUserViews(user) || (canManagePersonalDashboards(user) && themeConfig.enableDashboard) ? (
                    <div key="divider1" className={classes.divider}>
                      {t("components.userSelect.userObjects")}
                    </div>
                  ) : null,
                  canDisplayUserViews(user) ? (
                    <div key="views" data-value={"views"} className={classes.option}>
                      {t("components.userSelect.views")}
                    </div>
                  ) : null,
                  !themeConfig.enableNewDashboard &&
                  canManagePersonalDashboards(user) &&
                  themeConfig.enableDashboard ? (
                    <div key="dashboard" id="user-dashboards-btn" data-value={"dashboards"} className={classes.option}>
                      {t("components.userSelect.dashboards")}
                    </div>
                  ) : null,
                  ...(modulesConfig.placeholders?.["user-select-menu"] || []).map((module, idx) => (
                    <ModuleMenuItemPlaceholder
                      isModulePlaceholder
                      key={idx}
                      module={module}
                      className={classes.option}
                    />
                  )),
                  <div key="divider2" className={classes.divider}>
                    {t("components.userSelect.accountSettings")}
                  </div>,
                  user.provider ? null : (
                    <div key="changePassword" data-value={"changePassword"}>
                      {t("components.userSelect.changePassword")}
                    </div>
                  ),
                  user.provider ? null : (
                    <div key="editUser" data-value={"editUser"}>
                      {t("components.userSelect.editUser")}
                    </div>
                  ),
                  <div key="logout" data-value={"logout"}>
                    {t("components.userSelect.logout")}
                  </div>
                ]}
              </ButtonSelect>
            </Tooltip>
          );
        } else if ((supportedAuths || []).length === 1) {
          const provider = supportedAuths[0];
          return (
            <Button
              color="inherit"
              onClick={() =>
                provider.identifier === BASIC_LOGIN
                  ? onLoginFormShow()
                  : handleExternalLogin(provider.identifier, provider.loginURL)
              }
            >
              {t("components.userSelect.login")}
            </Button>
          );
        } else if ((supportedAuths || []).length > 1) {
          return (
            <ButtonSelect
              value={t("components.userSelect.login")}
              ariaLabel={t("components.header.actions.user.ariaLabel")}
              tooltip={t("components.header.actions.user.title")}
              onChange={({identifier, loginURL}) =>
                identifier === BASIC_LOGIN ? onLoginFormShow() : handleExternalLogin(identifier, loginURL)
              }
              removeEndIcon
            >
              {supportedAuths.map(({identifier, label, loginURL}) => (
                <div key={identifier} data-value={{identifier, loginURL}}>
                  {identifier === BASIC_LOGIN
                    ? t("components.userSelect.loginWithBasicAuth")
                    : t("components.userSelect.loginWith", {provider: label || identifier})}
                </div>
              ))}
            </ButtonSelect>
          );
        }
      })()}

      <SettingsDialog
        title={t("components.userSelect.registerModal.title")}
        open={user.isRegisterDialogOpen && !disabled}
        onClose={() => {
          if (registerFormRef.current) {
            registerFormRef.current.cancel(() => {
              onRegisterModalHide();
            });
          } else {
            onRegisterModalHide();
          }
        }}
        onSubmit={() => {
          if (registerFormRef.current) {
            registerFormRef.current.submit(() => {});
          }
        }}
        hasSubmit
        customSubmitLabel={t("components.userSelect.registerModal.submit.label")}
      >
        <UserSettingsForm ref={registerFormRef} userId={-1} isAnonymous />
      </SettingsDialog>

      <SettingsDialog
        title={t("components.userSelect.editUserModal.title")}
        open={user.isEditUserDialogOpen && !disabled}
        onClose={() => {
          if (editUserFormRef.current) {
            editUserFormRef.current.cancel(() => {
              onEditModalHide();
            });
          } else {
            onEditModalHide();
          }
        }}
        onSubmit={() => {
          if (editUserFormRef.current) {
            editUserFormRef.current.submit(() => {});
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <EditUserForm ref={editUserFormRef} />
      </SettingsDialog>

      <SettingsDialog
        title={t("components.userSelect.changePasswordModal.title")}
        maxWidth={"xs"}
        open={user.isChangePasswordDialogOpen && !disabled}
        onClose={() => {
          if (changePasswordFormRef.current) {
            changePasswordFormRef.current.cancel(() => {
              onChangePasswordHide();
            });
          } else {
            onChangePasswordHide();
          }
        }}
        onSubmit={() => {
          if (changePasswordFormRef.current) {
            changePasswordFormRef.current.submit(() => {});
          }
        }}
        hasSubmit
        noMinHeight
        noFullScreen
      >
        <UserChangePasswordForm ref={changePasswordFormRef} />
      </SettingsDialog>

      <SettingsDialog
        title={t("scenes.viewsSettings.title")}
        open={isViewsOpen}
        onClose={() => {
          if (viewsSettingsRef.current) {
            viewsSettingsRef.current.cancel(() => {
              onViewsClose();
            });
          } else {
            onViewsClose();
          }
        }}
      >
        <UserViewsSettingsForm ref={viewsSettingsRef} nodes={nodes} onViewsClose={onViewsClose} />
      </SettingsDialog>
      <SettingsDialog
        title={t("scenes.dashboardsSettings.title")}
        open={isDashboardsOpen && themeConfig.enableDashboard}
        onClose={() => {
          if (dashboardsSettingsRef.current) {
            dashboardsSettingsRef.current.cancel(() => {
              onDashboardsClose();
            });
          } else {
            onDashboardsClose();
          }
        }}
      >
        <UserDashboardsSettingsForm ref={dashboardsSettingsRef} nodes={nodes} onDashboardsClose={onDashboardsClose} />
      </SettingsDialog>

      <ModulesPlaceholder id="user-select-dialog" />
    </Fragment>
  );
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(UserSelect);
