import {t} from "i18next";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {getDatasetCustomDownloadUrl, getDatasetDownloadUrl, getDatasetUrl} from "../../serverApi/urls";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBody
} from "../../utils/download";

export const ITEMCONTAINERS_ITEMCONTAINER_FETCH = "itemContainers/itemContainer/fetch";
export const ITEMCONTAINERS_ITEMCONTAINERS_CLEAR = "itemContainers/itemContainers/clear";
export const ITEMCONTAINERS_DATASET_FETCH = "itemContainers/dataset/fetch";
export const ITEMCONTAINERS_DATASET_DOWNLOAD_SUBMIT = "itemContainer/dataset/download/submit";
export const ITEMCONTAINERS_ITEMCONTAINER_GET = "itemContainers/itemContainer/id";
export const clearItemContainersItemContainers = () => ({
  type: ITEMCONTAINERS_ITEMCONTAINERS_CLEAR
});

export const fetchItemContainerViewDataset = (item: ItemViewTemplateDto) =>
  initRequest(
    ITEMCONTAINERS_DATASET_FETCH + item.nodeId + item.viewTemplate.datasetId,
    getDatasetUrl(item.nodeId, item.viewTemplate.datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(item.viewTemplate.criteria),
    t => ({
      onStart: t("scenes.itemContainer.actions.fetchingDataset")
    }),
    null,
    "",
    () => true,
    true
  );

export const submitItemContainerDatasetDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: any,
  layout: any,
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any
) => {
  let url, body;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetCustomDownloadUrl(nodeId, format, zipped);
    body = getCustomExportRequestBody(datasetId, datasetTitle, criteria, layout, params, defaultLanguage, languages, t);
  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL ? "application/vnd.ms-excel" : "text/plain;charset=utf-8";

  return initRequest(
    ITEMCONTAINERS_DATASET_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const getItemContainerById = (itemContainerId: number, nodeId: number, type: string) => {
  const asCustomPage = t("scenes.itemContainer.asCustomPage");
  return external(
    initRequest(
      ITEMCONTAINERS_ITEMCONTAINER_GET,
      `ItemContainers/${itemContainerId}${nodeId ? `?nodeId=${nodeId} ` : ""}`,
      RequestMethod.GET,
      undefined,
      t => ({
        onStart: t("scenes.itemContainer.actions.fetchingItemContainer", {
          type: type === "dashboard" ? "Dashboard" : asCustomPage
        })
      })
    ),
    type === "dashboard" ? "dashboard" : "customPages"
  );
};
