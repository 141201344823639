import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import ErrorIcon from "@material-ui/icons/Error";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {v4 as uuidv4} from "uuid";
import Call from "../../../hocs/call";
import CustomEmpty from "../../custom-empty";
import MultiViewerHeader from "./Header";
import MultiViewerSidebar from "./Sidebar";
import MultiViewerViewer from "./Viewer";
import {
  fetchDatasetMVDataset,
  handleDatasetMVDetailLevels,
  hideDatasetMVDownloadWarning,
  hideDatasetMVUnavailableViewWarning
} from "../../../state/dataset/multi-viewer/actions";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  header: {
    width: "100%",
    padding: "4px 16px 12px"
  },
  sidebarViewerContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "0 16px 16px",
    minHeight: 400,
    minWidth: 560
  },
  sidebar: {
    height: "100%"
  },
  viewer: {
    height: "100%"
  }
});

const mapStateToProps = ({config, app, catalog, detailLevel, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  defaultLanguage: app.language,
  languages: app.languages,
  datasetMap: (catalog.uncategorizedDatasets || []).reduce(
    (acc, v) => ({...acc, [v.identifier]: v}),
    catalog.datasetMap || {}
  ),
  catalogDetailLevelTree: catalog?.detailLevelTree,
  nodeDetailLevel: detailLevel.detailLevel,
  datasetUuid: dataset.multiViewer.datasetUuid,
  notCompatibleDataset: dataset.multiViewer.notCompatibleDataset,
  territoryDim: dataset.multiViewer.territoryDim,
  timeDim: dataset.multiViewer.timeDim,
  criteria: dataset.multiViewer.criteria,
  isFetchDatasetDisabled: dataset.multiViewer.isFetchDatasetDisabled,
  isDownloadWarningVisible: dataset.multiViewer.isDownloadWarningVisible,
  isUnavailableViewWarningVisible: dataset.multiViewer.isUnavailableViewWarningVisible,
  datasetDetailLevelTree: dataset.multiViewer.detailLevelTree,
  datasetDetailLevel: dataset.multiViewer.detailLevel,
  indicators: dataset.multiViewer.indicators,
  additionalDatasets: dataset.multiViewer.additionalDatasets
});

const mapDispatchToProps = dispatch => ({
  handleDetailLevels: ({
    nodeId,
    datasetId,
    catalogDetailLevelTree,
    datasetDetailLevels,
    datasetDetailLevel,
    nodeDetailLevel
  }) =>
    dispatch(
      handleDatasetMVDetailLevels(
        nodeId,
        datasetId,
        catalogDetailLevelTree,
        datasetDetailLevels,
        datasetDetailLevel,
        nodeDetailLevel
      )
    ),
  onDatasetFetch: ({
    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    criteria,
    timeDim,
    territoryDim,
    indicators,
    additionalDatasets,
    defaultLanguage,
    languages,
    indicatorsBaseUrl
  }) =>
    dispatch(
      fetchDatasetMVDataset(
        nodeId,
        nodeCode,
        datasetId,
        datasetTitle,
        criteria,
        timeDim,
        territoryDim,
        indicators,
        additionalDatasets,
        defaultLanguage,
        languages,
        indicatorsBaseUrl
      )
    ),
  onDownloadWarningHide: () => dispatch(hideDatasetMVDownloadWarning()),
  onUnavailableViewHide: () => dispatch(hideDatasetMVUnavailableViewWarning())
});

const handleStyle = () => {
  const headerHeight = document.getElementById("data-viewer__header")?.offsetHeight || 0;
  $("#data-viewer__sidebar-viewer-container").height(`calc(100% - ${headerHeight}px)`);

  const sidebarWidth = document.getElementById("data-viewer__sidebar")?.offsetWidth || 0;
  $("#data-viewer__viewer").width(`calc(100% - ${sidebarWidth}px)`);
};

function MultiViewer(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    nodeExtras,

    indicatorsBaseUrl,
    defaultLanguage,
    languages,
    datasetMap,
    catalogDetailLevelTree,
    nodeDetailLevel,
    datasetUuid,
    notCompatibleDataset,
    territoryDim,
    timeDim,
    criteria,
    isFetchDatasetDisabled,
    isDownloadWarningVisible,
    isUnavailableViewWarningVisible,
    datasetDetailLevelTree,
    datasetDetailLevel,
    indicators,
    additionalDatasets,

    handleDetailLevels,
    onDatasetFetch,
    onDownloadWarningHide,
    onUnavailableViewHide
  } = props;

  const {t} = useTranslation();

  const [chartId] = useState("chart__" + uuidv4());

  const [mapId] = useState("map__" + uuidv4());

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  return (
    <Fragment>
      <div id="data-viewer" className={`${classes.root} data-viewer__multi-viewer`}>
        <Call
          cb={handleDetailLevels}
          cbParam={{
            nodeId: nodeId,
            datasetId: datasetId,
            catalogDetailLevelTree: catalogDetailLevelTree,
            datasetDetailLevels: datasetMap[datasetId]?.detailsLevels,
            datasetDetailLevel: datasetDetailLevel,
            nodeDetailLevel: nodeDetailLevel
          }}
          disabled={!datasetUuid || datasetDetailLevelTree !== null}
        >
          <Call
            cb={onDatasetFetch}
            cbParam={{
              nodeId: nodeId,
              nodeCode: nodeCode,
              datasetId: datasetId,
              criteria: criteria,
              datasetTitle: datasetTitle,
              timeDim: timeDim,
              territoryDim: territoryDim,
              indicators: indicators,
              additionalDatasets: additionalDatasets,
              defaultLanguage: defaultLanguage,
              languages: languages,
              indicatorsBaseUrl: indicatorsBaseUrl
            }}
            disabled={!datasetUuid || criteria === null || datasetDetailLevelTree === null || isFetchDatasetDisabled}
          >
            {notCompatibleDataset ? (
              <CustomEmpty
                id="data-viewer__error"
                text={t("scenes.dataViewer.multiViewer.errors.notCompatibleData")}
                image={<ErrorIcon />}
              />
            ) : (
              <Fragment>
                <div id="data-viewer__header" className={classes.header}>
                  <MultiViewerHeader
                    nodeId={nodeId}
                    nodeCode={nodeCode}
                    nodeExtras={nodeExtras}
                    datasetId={datasetId}
                    datasetTitle={datasetTitle}
                    viewId={viewId}
                    attachedFiles={attachedFiles}
                    chartId={chartId}
                    mapId={mapId}
                    onRender={handleStyle}
                    datasetMap={datasetMap}
                  />
                </div>
                <div id="data-viewer__sidebar-viewer-container" className={classes.sidebarViewerContainer}>
                  <div id="data-viewer__sidebar" className={classes.sidebar}>
                    <MultiViewerSidebar
                      nodeId={nodeId}
                      nodeCode={nodeCode}
                      nodeExtras={nodeExtras}
                      datasetId={datasetId}
                      datasetTitle={datasetTitle}
                      chartId={chartId}
                      mapId={mapId}
                      datasetMap={datasetMap}
                    />
                  </div>
                  <div id="data-viewer__viewer" className={classes.viewer}>
                    <MultiViewerViewer nodeId={nodeId} nodeExtras={nodeExtras} chartId={chartId} mapId={mapId} />
                  </div>
                </div>
              </Fragment>
            )}
          </Call>
        </Call>
      </div>

      <Dialog open={isDownloadWarningVisible} maxWidth="md" onClose={onDownloadWarningHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.downloadFormat.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onDownloadWarningHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isUnavailableViewWarningVisible} maxWidth="md" onClose={onUnavailableViewHide}>
        <DialogContent>{t("scenes.dataViewer.dialogs.unavailableView.content")}</DialogContent>
        <DialogActions>
          <Button onClick={onUnavailableViewHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(MultiViewer);
