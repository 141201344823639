import React, {Fragment} from "react";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import ItemContainerRow from "./ItemContainerRow";

function ItemContainer({itemContainer}: {itemContainer: ItemContainerDto}) {
  return (
    <Fragment>
      {itemContainer ? (
        <Fragment>
          {(itemContainer.item || []).map((row, idx) => (
            <ItemContainerRow
              key={`${itemContainer.itemContainerId}-${idx}`}
              itemContainer={itemContainer}
              row={row}
              rowIdx={idx}
            />
          ))}
        </Fragment>
      ) : (
        <span />
      )}
    </Fragment>
  );
}

export default ItemContainer;
