import React, {useEffect} from "react";
import {Button, DialogActions, FormControl} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {getImportFiltersErrorTranslations} from "../../../constants/getImportFiltersErrorTranslations";
import {FilterDto} from "../../../model/item-containers-models/filterDto";
import FileInputField from "../../file-input-field";
import useApi from "../../../state/api/useApi";
import {validateFilterFile} from "../../../state/dashboardFilters/dashboardFilterActions";

export interface FilterFileUploadProps {
  onClose: () => void;
  onFileImport: (f: {data: number & FilterDto; file?: File; filterToEdit?: number}) => void;
  filterToEdit?: number;
}

interface ValidationFileForm {
  file?: File;
}

const FilterFileUpload = ({onClose, onFileImport, filterToEdit}: FilterFileUploadProps) => {
  const {
    register,
    formState: {errors},
    handleSubmit,
    getValues
  } = useForm<ValidationFileForm>();

  const validateFilterApi = useApi<number>(validateFilterFile(), {
    onSuccess: data => {
      onFileImport({
        data,
        file: getValues("file")[0]
      });
    }
  });

  useEffect(() => {
    return () => {
      validateFilterApi.clear();
    };
  }, []);

  const submitHandler: SubmitHandler<ValidationFileForm> = ({file}) => {
    const validationFormData = new FormData();
    validationFormData.append("file", file[0]);
    validateFilterApi.call({
      ...validateFilterApi.request,
      data: validationFormData
    });
  };

  const {t} = useTranslation();
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <h3>{t("components.filtersSettingsForm.wizard.firstStep.uploadText")}</h3>
      {validateFilterApi.error && (
        <Alert
          style={{
            marginBottom: 16
          }}
          severity="error"
        >
          {getImportFiltersErrorTranslations(t)[validateFilterApi.error] || t("errors.importFilters.generic")}
        </Alert>
      )}
      <FormControl fullWidth>
        <FileInputField
          inputProps={{
            ...register("file", {
              required: true
            })
          }}
          textFieldProps={{
            fullWidth: true,
            error: !!errors.file,
            helperText:
              (!!errors.file && t("commons.validation.required")) ||
              t("components.filtersSettingsForm.wizard.firstStep.uploadHelperText")
          }}
        />
      </FormControl>

      <DialogActions>
        <Button type="button" onClick={onClose}>
          {t("commons.confirm.close")}
        </Button>
        <Button type="submit">{t("components.filtersSettingsForm.wizard.firstStep.nextButton")}</Button>
      </DialogActions>
    </form>
  );
};

export default FilterFileUpload;
