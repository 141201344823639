import React, {useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {t} from "i18next";
import {SketchPicker} from "react-color";
import {useFormContext} from "react-hook-form";

interface ColorPickerAreaProps {
  label: string;
  valueName: string[];
}

const ColorPickerArea = ({label, valueName}: ColorPickerAreaProps) => {
  const handleColorPickerClose = () => {
    setColorPickerVisibility(false);
  };

  const [isColorPickerVisible, setColorPickerVisibility] = useState(false);
  const {setValue, watch} = useFormContext();
  const formValue = `customColors.palette.${valueName[0]}${valueName?.[1] ? "." + valueName[1] : ""}`;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography style={{fontSize: "16px"}}>{label}</Typography>
      <Box
        style={{
          margin: "0px 20px 0px 20px",
          width: "170px",
          height: "40px",
          background: watch(formValue),
          border: "1px solid black",
          borderRadius: "4px",
          cursor: "pointer"
        }}
        onClick={() => setColorPickerVisibility(true)}
      />
      <EditIcon
        style={{
          cursor: "pointer"
        }}
        onClick={() => setColorPickerVisibility(true)}
      />
      <Dialog open={isColorPickerVisible} onClose={handleColorPickerClose} maxWidth="sm">
        <DialogContent style={{padding: 0}}>
          <SketchPicker
            color={watch(formValue)}
            onChange={({rgb}) => {
              const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
              setValue(formValue, rgba);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleColorPickerClose}>{t("commons.confirm.close")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ColorPickerArea;
