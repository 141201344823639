import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import CustomDialogTitle from "../custom-dialog-title";
import I18nHtmlEditor from "../i18n-html-editor";

export function TextEditingDialog({onClose, handleTextChange, initialValue}) {
  const [textValue, setTextValue] = useState(initialValue);
  const {t} = useTranslation();
  return (
    <Dialog open fullWidth disableEnforceFocus maxWidth="md" onClose={onClose}>
      <CustomDialogTitle onClose={onClose}>{t("components.itemContainerBuilder.modals.text.title")}</CustomDialogTitle>
      <DialogContent>
        <I18nHtmlEditor value={textValue} onChange={setTextValue} enableImageUpload enableSourceEditing />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("commons.confirm.cancel")}</Button>
        <Button
          onClick={() => {
            handleTextChange(textValue);
            onClose();
          }}
        >
          {t("commons.confirm.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
