import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "react-router-dom";

const styles = theme => ({
  link: {
    borderRadius: 4,
    fontSize: 16,
    height: "100%",
    width: "100%",
    "& > a": {
      outlineOffset: -1
    }
  },
  linkEnabled: {
    cursor: "pointer",
    "& > a": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    },
    "&:hover": {
      background: "#f5f5f5"
    },
    "&:hover > a": {
      textDecoration: "underline"
    }
  },
  linkDisabled: {
    color: "rgba(0, 0, 0, 0.42)"
  },
  linkContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  linkContentImage: {
    "& > img": {
      height: "100%",
      width: "100%",
      objectFit: "cover"
    }
  },
  linkContentText: {
    height: "100%",
    width: "100%",
    padding: 8,
    display: "flex",
    alignItems: "center"
  },
  linkContentTextIcon: {
    marginRight: 8,
    display: "flex"
  },
  linkContentTextText: {
    display: "flex",
    width: "100%"
  },
  linkContentSubText: {},
  linkButton: {
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    font: "inherit",
    padding: "4px 8px",
    border: "none",
    cursor: "pointer",
    letterSpacing: "inherit",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

const CustomLink = props => {
  const {classes, to = "#", text, textStyle, subText, subTextStyle, icon, image, imageStyle, onClick, disabled} = props;

  const linkContent = (
    <div className={classes.linkContent}>
      {image && (
        <div className={classes.linkContentImage} style={imageStyle}>
          <img src={image} alt="" />
        </div>
      )}
      <div className={classes.linkContentText} style={textStyle}>
        {icon && <div className={classes.linkContentTextIcon}>{icon}</div>}
        {text && <div className={classes.linkContentTextText}>{text}</div>}
      </div>
      <div className={classes.linkContentSubText} style={subTextStyle}>
        {subText}
      </div>
    </div>
  );

  return (
    <div
      className={`${classes.link} ${
        disabled ? classes.linkDisabled + " custom-link--disabled" : classes.linkEnabled + " custom-link--enabled"
      }`}
    >
      {disabled ? (
        linkContent
      ) : to ? (
        <Link to={to} onClick={onClick}>
          {linkContent}
        </Link>
      ) : (
        <button className={classes.linkButton} onClick={onClick}>
          {linkContent}
        </button>
      )}
    </div>
  );
};

export default withStyles(styles)(CustomLink);
