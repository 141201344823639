import {getViewerTypeFromIdx} from "../../../components/data-viewer/constant";
import {RoundingMode} from "../../../model/wshub-models/roundingMode";
import {ChartSettings, Criteria, MapSettings, ViewerMode} from "../constants";
import {getCriteriaArrayFromObject} from "../../../utils/criteria";
import {
  getViewTemplateLayoutsFromChartSettings,
  getViewTemplateLayoutsFromMapSettings
} from "../../../utils/viewTemplate";

export type DatasetSingleViewerState = {
  datasetUuid: string | null;
  dataset: any | null;
  isFirstFetch: boolean;
  datasetFetchStart: boolean;
  datasetFetchError: boolean;
  isPartialData: boolean;
  isEmptyData: boolean;
  isTooBigData: boolean;
  isTooLongQuery: boolean;
  isCriteriaVisible: boolean;
  isCriteriaAlertVisible: boolean;
  isObsCountWarningVisible: boolean;
  dimensions: any[] | null;
  dimensionsInfo: {[key: string]: any} | {};
  territoryDim: string | null;
  timeDim: string | null;
  freqDim: string | null;
  viewerIdx: number | null;
  view: any | null;
  template: any | null;
  hasViewLayout: boolean;
  hasTemplateLayout: boolean;
  hasAnnotationLayout: boolean;
  isLayoutVisible: boolean;
  tableLayout: any | null;
  mapLayout: any | null;
  chartLayout: any | null;
  tableDefaultFiltersValue: {[key: string]: string} | null;
  chartDefaultFiltersValue: {[key: string]: string} | null;
  tableFilterTree: any | null;
  mapFilterTree: any | null;
  chartFilterTree: any | null;
  timePeriodsByFreq: any | null;
  labelFormat: string | null;
  temporalDimOrder: string | null;
  showTrend: boolean;
  showCyclical: boolean;
  criteria: {[key: string]: Criteria} | {};
  initialCriteria: {[key: string]: Criteria} | {};
  decimalSeparator: string | null;
  roundingStrategy: RoundingMode | null;
  decimalPlaces: number | null;
  tableEmptyChar: string;
  chartSettings: ChartSettings;
  mapSettings: MapSettings;
  enableCriteria: boolean;
  enableLayout: boolean;
  enableVariation: boolean;
  codelists: {[key: string]: any[]} | null;
  codelistsLength: (number | null)[] | null;
  codelistFetchError: boolean;
  criteriaObsCount: number | null;
  timings: any | null;
  isFetchDatasetDisabled: boolean;
  isDownloadWarningVisible: boolean;
  isUnavailableViewWarningVisible: boolean;
  isViewVisible: boolean;
  isViewErrorVisible: boolean;
  viewErrorMessage: string | null;
  isTemplateVisible: boolean;
  isQueryVisible: boolean;
  structureQuery: string | null;
  dataQuery: string | null;
  detailLevel: number | null;
  isFullscreen: boolean;
  isTableEnabled: boolean;
  isMapEnabled: boolean;
  isChartEnabled: boolean;
  tableLockedDimensions: string[] | null;
  graphLockedDimensions: string[] | null;
  missingFilterValues: any[] | null;
  checkDatasetSize: boolean;
};

export const getViewTemplateBackupFromState = (state: DatasetSingleViewerState) => ({
  mode: ViewerMode.SingleViewer,
  defaultView: getViewerTypeFromIdx(state.viewerIdx),
  criteria: getCriteriaArrayFromObject(state.criteria),
  layouts: JSON.stringify({
    detailLevel: state.detailLevel,
    labelFormat: state.labelFormat,
    temporalDimOrder: state.temporalDimOrder,
    showTrend: state.showTrend,
    showCyclical: state.showCyclical,
    tableLayout: state.tableLayout,
    tableEmptyChar: state.tableEmptyChar,
    mapLayout: state.mapLayout,
    ...getViewTemplateLayoutsFromMapSettings(state.mapSettings),
    chartLayout: state.chartLayout,
    ...getViewTemplateLayoutsFromChartSettings(state.chartSettings)
  })
});
