const getParamsStr = (opts = {}) => {
  let paramsStrs = [];

  if (opts.viewId) {
    paramsStrs.push("view=" + opts.viewId);
  }
  if (opts.query) {
    paramsStrs.push("q=" + encodeURIComponent(opts.query));
  }
  if (opts.filters && opts.filters.length > 0) {
    // filters = c1/c2,c3/c4
    paramsStrs.push("c=" + encodeURIComponent(opts.filters.map(f => encodeURIComponent(f.join("/"))).join("//")));
  }
  if (window.detailLevel !== null) {
    paramsStrs.push("t=" + encodeURIComponent(window.detailLevel));
  }
  if (window.isA11y) {
    paramsStrs.push("accessible=true");
  }

  if (paramsStrs.length > 0) {
    return "?" + paramsStrs.join("&");
  } else {
    return "";
  }
};

export const getHomeInternalUrl = noLanguage => `/${noLanguage ? "" : window.language}${getParamsStr()}`;

export const getNodeInternalUrl = nodeCode => `/${window.language}/${(nodeCode || "").toLowerCase()}${getParamsStr()}`;

export const getNodeCategoriesInternalUrl = nodeCode =>
  `/${window.language}/${(nodeCode || "").toLowerCase()}/categories${getParamsStr()}`;

export const getDatasetsInternalUrl = (nodeCode, categoryPath) =>
  `/${window.language}/${(nodeCode || "").toLowerCase()}/categories/${categoryPath.join("/")}${getParamsStr()}`;

export const getDatasetInternalUrl = (nodeCode, categoryPath, datasetId, viewId) =>
  `/${window.language}/${(nodeCode || "").toLowerCase()}/${
    categoryPath.length > 0 ? "categories/" + categoryPath.filter(el => el).join("/") + "/" : ""
  }` +
  datasetId +
  getParamsStr({viewId});

export const getDatasetInternalUrlFromDatasetId = (nodeCode, datasetId) =>
  `/${window.language}/${(nodeCode || "").toLowerCase()}/${datasetId}`;

export const getDatasetsSearchInternalUrl = (nodeCode, query, filters) =>
  `/${window.language}/${(nodeCode || "").toLowerCase()}/search${getParamsStr({query, filters})}`;

export const getHubDashboardsInternalUrl = () => `/${window.language}/dashboards${getParamsStr()}`;

export const getNodeDashboardsInternalUrl = nodeCode =>
  `/${window.language}/${(nodeCode || "").toLowerCase()}/dashboards${getParamsStr()}`;

export const getDashboardInternalUrl = (dashboardId, nodeId) =>
  `/${window.language}/${nodeId ? `${nodeId}/` : ""}dashboards/${dashboardId}${getParamsStr()}`;

export const getCustomPageInternalUrl = (itemContainerId, nodeId) =>
  `/${window.language}/${nodeId ? `${nodeId}/` : ""}pages/${itemContainerId}${getParamsStr()}`;

export const goToHome = noLanguage => window.open("./#" + getHomeInternalUrl(noLanguage), "_self");
export const goToNode = nodeCode => window.open("./#" + getNodeInternalUrl(nodeCode), "_self");
export const goToNodeCategories = nodeCode => window.open("./#" + getNodeCategoriesInternalUrl(nodeCode), "_self");
export const goToDatasets = (nodeCode, categoryPath) =>
  window.open("./#" + getDatasetsInternalUrl(nodeCode, categoryPath), "_self");
export const goToDataset = (nodeCode, categoryPath, datasetId, viewId) =>
  window.open("./#" + getDatasetInternalUrl(nodeCode, categoryPath, datasetId, viewId), "_self");
export const goToDatasetsSearch = (nodeCode, query, filters) =>
  window.open("./#" + getDatasetsSearchInternalUrl(nodeCode, query, filters), "_self");
export const goToHubDashboards = () => window.open("./#" + getHubDashboardsInternalUrl(), "_self");
export const goToNodeDashboards = nodeCode => window.open("./#" + getNodeDashboardsInternalUrl(nodeCode), "_self");
export const goToDashboard = (dashboardId, nodeId) =>
  window.open("./#" + getDashboardInternalUrl(dashboardId, nodeId), "_self");
export const goToCustomPage = (itemContainerId, nodeId) =>
  window.open("./#" + getCustomPageInternalUrl(itemContainerId, nodeId), "_self");
