import React, {Fragment, useCallback, useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import SettingsIcon from "@material-ui/icons/Settings";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import ChartSettingsColors from "../../chart-settings-forms/Colors";
import ChartSettingsGeneral from "../../chart-settings-forms/General";
import ChartSettingsLayout from "../../chart-settings-forms/Layout";
import ChartJsonstatWrapper from "../../chart/ChartJsonstatWrapper";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomEmpty from "../../custom-empty";
import DatasetMergedFilters from "../../dataset-merged-filters";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH} from "../../label-format-selector/constants";
import MapSettingsSettings from "../../map-settings-form/Settings";
import EnhancedMap from "../../map/EnhancedMap";
import Table from "../../table";
import {
  hideDatasetMVIndicatorWarning,
  setDatasetMVChartLayoutTerritoryDimValues,
  setDatasetMVChartSettings,
  setDatasetMVHtmlGeneratingTime,
  setDatasetMVIndicatorArithmeticMeanValues,
  setDatasetMVMapSettings,
  setDatasetMVViewerChartType,
  submitDatasetMVChartLayout,
  submitDatasetMVMapLayout
} from "../../../state/dataset/multi-viewer/actions";
import {numberFormatter} from "../../../utils/formatters";
import {getNode} from "../../../utils/tree";
import {DIM_VALUE_LABEL_MODIFIER_REMOVE, TABLE_HEADER_MERGED} from "../../table/utils";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 8
  },
  viewerHeader: {
    width: "100%",
    marginBottom: 8
  },
  viewers: {
    width: "100%",
    position: "relative"
  },
  viewer: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  filtersAndActions: {
    marginBottom: 4
  },
  actions: {
    height: 30
  },
  settingsTabs: {
    height: 48
  },
  settingsContent: {
    marginTop: 12,
    padding: 12,
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 60px)",
    display: "flex"
  }
});

const mapStateToProps = ({appConfig, hub, dataset}) => ({
  maxChartTerritoryValueCount: appConfig.chartConfig.maxTerritoryValueCount,
  maxObservations: hub.hub.maxObservationsAfterCriteria || Number.MAX_SAFE_INTEGER,
  dataset: dataset.multiViewer.dataset,
  datasetFetchStart: dataset.multiViewer.datasetFetchStart,
  datasetFetchError: dataset.multiViewer.datasetFetchError,
  isPartialData: dataset.multiViewer.isPartialData,
  isEmptyData: dataset.multiViewer.isEmptyData,
  isTooBigData: dataset.multiViewer.isTooBigData,
  isPointData: dataset.multiViewer.isPointData,
  latAttributeId: dataset.multiViewer.latAttributeId,
  longAttributeId: dataset.multiViewer.longAttributeId,
  srid: dataset.multiViewer.srid,
  dimensions: dataset.multiViewer.dimensions,
  territoryDimCodelist: dataset.multiViewer.territoryDimCodelist,
  territoryDim: dataset.multiViewer.territoryDim,
  timeDim: dataset.multiViewer.timeDim,
  pointDim: dataset.multiViewer.pointDim,
  isTableVisible: dataset.multiViewer.isTableVisible,
  isMapVisible: dataset.multiViewer.isMapVisible,
  isChartVisible: dataset.multiViewer.isChartVisible,
  chartType: dataset.multiViewer.chartType,
  tableLayout: dataset.multiViewer.tableLayout,
  mapLayout: dataset.multiViewer.mapLayout,
  chartLayout: dataset.multiViewer.chartLayout,
  mapFilterTree: dataset.multiViewer.mapFilterTree,
  chartFilterTree: dataset.multiViewer.chartFilterTree,
  timePeriodsByFreq: dataset.multiViewer.timePeriodsByFreq,
  labelFormat: dataset.multiViewer.labelFormat,
  decimalSeparator: dataset.multiViewer.decimalSeparator,
  roundingStrategy: dataset.multiViewer.roundingStrategy,
  decimalPlaces: dataset.multiViewer.decimalPlaces,
  tableEmptyChar: dataset.multiViewer.tableEmptyChar,
  chartSettings: dataset.multiViewer.chartSettings,
  mapSettings: dataset.multiViewer.mapSettings,
  detailLevelTree: dataset.multiViewer.detailLevelTree,
  detailLevel: dataset.multiViewer.detailLevel,
  missingIndicators: dataset.multiViewer.missingIndicators,
  showArithmeticMean: dataset.multiViewer.showArithmeticMean,
  arithmeticMeans: dataset.multiViewer.arithmeticMeans,
  arithmeticMeanDims: dataset.multiViewer.arithmeticMeanDims,
  showStandardDeviation: dataset.multiViewer.showStandardDeviation,
  showCoefficientOfVariation: dataset.multiViewer.showCoefficientOfVariation,
  geometriesYear: dataset.multiViewer.geometriesYear
});

const mapDispatchToProps = dispatch => ({
  onChartTerritoryDimValuesSet: territoryDimValues =>
    dispatch(setDatasetMVChartLayoutTerritoryDimValues(territoryDimValues)),
  onChartSettingsSet: chartSetting => dispatch(setDatasetMVChartSettings(chartSetting)),
  onChartLayoutSet: layout => dispatch(submitDatasetMVChartLayout(layout)),
  onChartTypeSet: type => dispatch(setDatasetMVViewerChartType(type)),
  onMapSettingsSet: mapSettings => dispatch(setDatasetMVMapSettings(mapSettings)),
  onMapLayoutSet: layout => dispatch(submitDatasetMVMapLayout(layout)),
  onIndicatorWarningHide: layout => dispatch(hideDatasetMVIndicatorWarning(layout)),
  onArithmeticMeanValuesSet: (arithmeticMeanDims, arithmeticMeans) =>
    dispatch(setDatasetMVIndicatorArithmeticMeanValues(arithmeticMeanDims, arithmeticMeans)),
  onTimeSet: time => dispatch(setDatasetMVHtmlGeneratingTime(time))
});

const handleStyle = mapId => {
  const viewerHeaderHeight = $("#data-viewer__viewer__header").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers").height(`calc(100% - ${viewerHeaderHeight}px)`);

  const mapSettingsWidth = $("#data-viewer__viewer__viewers__map__settings").outerWidth(true) || 0;
  $("#data-viewer__viewer__viewers__map__filters").css({maxWidth: `calc(100% - ${mapSettingsWidth}px)`});

  const mapFiltersAndSettingsHeight =
    $("#data-viewer__viewer__viewers__map__filters-settings-container").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers__map__viewer").height(`calc(100% - ${mapFiltersAndSettingsHeight}px)`);

  const chartSettingsWidth = $("#data-viewer__viewer__viewers__chart__settings").outerWidth(true) || 0;
  $("#data-viewer__viewer__viewers__chart__filters").css({maxWidth: `calc(100% - ${chartSettingsWidth}px)`});

  const chartFiltersAndSettingsHeight =
    $("#data-viewer__viewer__viewers__chart__filters-settings-container").outerHeight(true) || 0;
  $("#data-viewer__viewer__viewers__chart__viewer").height(`calc(100% - ${chartFiltersAndSettingsHeight}px)`);

  if (window.LMap && mapId) {
    window.LMap.handleViewportChange(mapId);
  }
};

function MultiViewerViewer(props) {
  const {
    classes,

    nodeId,
    nodeExtras,
    chartId,
    mapId,

    maxChartTerritoryValueCount,
    maxObservations,
    dataset,
    datasetFetchStart,
    datasetFetchError,
    isPartialData,
    isEmptyData,
    isTooBigData,
    isPointData,
    latAttributeId,
    longAttributeId,
    srid,
    dimensions,
    territoryDimCodelist,
    territoryDim,
    timeDim,
    pointDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    chartType,
    tableLayout,
    mapLayout,
    chartLayout,
    mapFilterTree,
    chartFilterTree,
    timePeriodsByFreq,
    labelFormat,
    decimalSeparator,
    roundingStrategy,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    detailLevelTree,
    detailLevel,
    missingIndicators,
    showArithmeticMean,
    arithmeticMeans,
    arithmeticMeanDims,
    showStandardDeviation,
    showCoefficientOfVariation,
    geometriesYear,

    onChartTerritoryDimValuesSet,
    onChartSettingsSet,
    onChartLayoutSet,
    onChartTypeSet,
    onMapSettingsSet,
    onMapLayoutSet,
    onIndicatorWarningHide,
    onArithmeticMeanValuesSet,
    onTimeSet
  } = props;

  const {t} = useTranslation();

  const [isChartSettingVisible, setChartSettingsVisibility] = useState(false);
  const [chartSettingsTabId, setChartSettingsTabId] = useState(null);
  const [tmpChartSettings, setTmpChartSettings] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);
  const [tmpChartType, setTmpChartType] = useState(null);

  const [isMapSettingVisible, setMapSettingsVisibility] = useState(false);
  const [tmpMapSettings, setTmpMapSettings] = useState(null);
  const [isMapSettingsValid, setMapSettingsValidity] = useState(true);
  const [isMapRendering, setIsMapRendering] = useState(true);

  const [tableDimensionFilterValues, setTableDimensionFilterValues] = useState(null);
  const [tableHighlightedRowsDimValues, setTableHighlightedRowsDimValues] = useState(null);
  const [tableFirstRowDimValues, setTableFirstRowDimValues] = useState(null);

  const [selectedTerritoryId, setSelectedTerritoryId] = useState(null);

  const [filteredTerritoryIds, setFilteredTerritoryIds] = useState(null);

  const [customLabelFormats] = useState({
    [territoryDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH
  });
  const [dimensionValueModifiers, setDimensionValueModifiers] = useState({
    [territoryDim]: {
      [DIM_VALUE_LABEL_MODIFIER_REMOVE]:
        getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || null
    }
  });

  const [invertedDims] = useState([timeDim]);

  const [referenceYear, setReferenceYear] = useState(
    getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.referenceYear || null
  );

  const [generalizationLevels, setGeneralizationLevels] = useState(
    getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.generalizationLevels || []
  );

  const [hierarchyOnlyAttributes] = useState(nodeExtras?.HierarchyOnlyAttributes || []);
  const [hideHierarchyOnlyRows] = useState(nodeExtras?.HideHierarchyOnlyRows || false);

  useEffect(() => {
    const func = () => handleStyle(mapId);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [mapId]);

  useEffect(() => {
    handleStyle(mapId);
    window.dispatchEvent(new Event("resize"));
  }, [mapId, dataset, isTableVisible, isMapVisible, isChartVisible, isMapRendering]);

  useEffect(() => {
    setTableDimensionFilterValues(null);
    setSelectedTerritoryId(null);
  }, [dataset]);

  useEffect(() => {
    setTableHighlightedRowsDimValues(selectedTerritoryId ? {[territoryDim]: selectedTerritoryId} : null);
  }, [territoryDim, selectedTerritoryId, mapLayout]);

  useEffect(() => {
    setDimensionValueModifiers(prevValue => {
      const detailLevelSuffix =
        getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || null;

      return prevValue?.[territoryDim]?.[DIM_VALUE_LABEL_MODIFIER_REMOVE] !== detailLevelSuffix
        ? {[territoryDim]: {[DIM_VALUE_LABEL_MODIFIER_REMOVE]: detailLevelSuffix}}
        : prevValue;
    });
  }, [territoryDim, detailLevelTree, detailLevel]);

  useEffect(() => {
    if (detailLevelTree && detailLevel) {
      setReferenceYear(getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.referenceYear || null);
    }
  }, [detailLevelTree, detailLevel]);

  useEffect(() => {
    if (detailLevelTree && detailLevel) {
      setGeneralizationLevels(
        getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.generalizationLevels || []
      );
    }
  }, [detailLevelTree, detailLevel]);

  const onTablePageGenerationComplete = useCallback(
    tableStructures => {
      if ((tableStructures?.renderedRows || []).length > 0) {
        const chartTerritoryDim = isPointData ? pointDim : territoryDim;
        onChartTerritoryDimValuesSet(
          tableStructures.renderedRows
            .map(renderedRow => renderedRow.split("+")[tableStructures.layout.rows.indexOf(chartTerritoryDim)])
            .filter((val, idx, arr) => idx === arr.indexOf(val))
            .slice(0, maxChartTerritoryValueCount)
        );
      }

      const timings = tableStructures.timings;
      if (timings) {
        onTimeSet(Math.round(tableStructures.timings * 100) / 100);
      }
    },
    [maxChartTerritoryValueCount, onTimeSet, onChartTerritoryDimValuesSet, territoryDim, isPointData, pointDim]
  );

  const onTableStructureGenerationComplete = useCallback(
    tableStructures => {
      const {arithmeticMeanDims = null, arithmeticMeans = null} = tableStructures || {};

      onArithmeticMeanValuesSet(arithmeticMeanDims, arithmeticMeans);
    },
    [onArithmeticMeanValuesSet]
  );

  const handleMapSelect = useCallback(
    terrDimValues => {
      if ((terrDimValues || []).length > 0) {
        setTableDimensionFilterValues({[territoryDim]: terrDimValues});
      } else {
        setTableDimensionFilterValues(null);
      }
    },
    [territoryDim]
  );

  const handleMapClick = useCallback(
    (geometry, isActive, isSelected) => {
      if (!geometry) {
        setSelectedTerritoryId(null);
      } else if (geometry && isActive) {
        const territoryId = geometry.territoryId;
        setSelectedTerritoryId(isSelected ? territoryId : null);
        setTableFirstRowDimValues(isSelected ? {[territoryDim]: territoryId} : null);
      }
    },
    [territoryDim]
  );

  const handleTableRowClick = useCallback(
    (clickedRow, isActive) => {
      setSelectedTerritoryId(isActive ? clickedRow[territoryDim] : null);
    },
    [territoryDim]
  );

  const onMapRenderStart = useCallback(() => {
    setIsMapRendering(true);
  }, []);

  const onMapRenderFinish = useCallback(() => {
    setIsMapRendering(false);
  }, []);

  const handleTableFilter = useCallback(
    filteredTerritories => {
      setFilteredTerritoryIds(
        filteredTerritories
          ? filteredTerritories.map(
              filteredTerritory => filteredTerritory.split("+")[tableLayout.rows.indexOf(territoryDim)]
            )
          : null
      );
    },
    [tableLayout, territoryDim]
  );

  const handleChartSettingsOpen = () => {
    setTmpChartSettings(chartSettings);
    setTmpChartLayout(chartLayout);
    setTmpChartType(chartType);
    setChartSettingsTabId("layout");
    setChartSettingsVisibility(true);
  };

  const handleChartSettingsClose = () => {
    setChartSettingsVisibility(false);
  };

  const handleChartSettingsSubmit = () => {
    setChartSettingsVisibility(false);
    onChartSettingsSet(tmpChartSettings);
    onChartLayoutSet(tmpChartLayout);
    onChartTypeSet(tmpChartType);
  };

  const handleMapSettingsOpen = settings => {
    setTmpMapSettings(settings);
    setMapSettingsValidity(true);
    setMapSettingsVisibility(true);
  };

  const handleMapSettingsClose = () => {
    setMapSettingsVisibility(false);
  };

  const handleMapSettingsSubmit = () => {
    setMapSettingsVisibility(false);
    onMapSettingsSet(tmpMapSettings);
  };

  if (!dataset) {
    let text = "";
    if (datasetFetchStart) {
      text = "";
    } else if (datasetFetchError) {
      text = t("scenes.dataViewer.errors.fetchData");
    } else if (isEmptyData) {
      text = t("scenes.dataViewer.errors.emptyData");
    } else if (dimensions) {
      text = t("scenes.dataViewer.errors.applyCriteria");
    }
    return (
      <Card className={classes.viewer}>
        <CustomEmpty text={text} />
      </Card>
    );
  }

  const leftPanelWidth = isMapVisible || isChartVisible ? (isTableVisible ? "60%" : "100%") : "0%";
  const rightPanelWidth = `calc(100% - ${leftPanelWidth})`;

  const leftPanelTopHeight = isMapVisible ? (isChartVisible ? "60%" : "100%") : "0%";
  const leftPanelBottomHeight = `calc(100% - ${leftPanelTopHeight})`;

  return (
    <Fragment>
      <Card className={classes.root}>
        {dataset && isPartialData && (
          <div id="data-viewer__viewer__header" className={classes.viewerHeader}>
            <Alert severity="warning" className={classes.warningAlert}>
              {t("scenes.dataViewer.warnings.maxObservations.label", {
                maxObservations: maxObservations ? numberFormatter(maxObservations) : ""
              })}
            </Alert>
          </div>
        )}
        <div id="data-viewer__viewer__viewers" className={classes.viewers}>
          {(isMapVisible || isChartVisible) && (
            <div style={{display: "inline-block", verticalAlign: "top", height: "100%", width: leftPanelWidth}}>
              {isMapVisible && (
                <div style={{height: leftPanelTopHeight, width: "100%", padding: 8}}>
                  <Grid
                    container
                    id="data-viewer__viewer__viewers__map__filters-settings-container"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.filtersAndActions}
                    wrap="nowrap"
                  >
                    <Grid item id="data-viewer__viewer__viewers__map__filters">
                      <DatasetMergedFilters
                        jsonStat={dataset}
                        layout={mapLayout}
                        filterTree={mapFilterTree}
                        timeDim={timeDim}
                        onChange={onMapLayoutSet}
                      />
                    </Grid>
                    <Grid item id="data-viewer__viewer__viewers__map__settings" className={classes.actions}>
                      {!isMapRendering && (
                        <Tooltip title={t("scenes.dataViewer.actions.mapSettings.tooltip")}>
                          <IconButton
                            aria-label={t("scenes.dataViewer.actions.mapSettings.ariaLabel")}
                            size={"small"}
                            onClick={() => handleMapSettingsOpen(mapSettings)}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  <div id="data-viewer__viewer__viewers__map__viewer" className={classes.viewer}>
                    <EnhancedMap
                      isMultiViewer
                      mapId={mapId}
                      nodeId={nodeId}
                      jsonStat={dataset}
                      layout={mapLayout}
                      decimalSeparator={decimalSeparator}
                      roundingStrategy={roundingStrategy}
                      decimalPlaces={decimalPlaces}
                      detailLevel={detailLevel}
                      territoryDimCodelist={territoryDimCodelist}
                      initialBaseLayer={mapSettings.baseLayer}
                      defaultSettings={{
                        isLegendCollapsed: mapSettings.isLegendCollapsed,
                        opacity: mapSettings.opacity
                      }}
                      settings={{
                        classificationMethod: mapSettings.classificationMethod,
                        paletteStartColor: mapSettings.paletteStartColor,
                        paletteEndColor: mapSettings.paletteEndColor,
                        paletteCardinality: mapSettings.paletteCardinality,
                        customIntervals: mapSettings.customIntervals
                      }}
                      setSettings={onMapSettingsSet}
                      showSelection
                      disableSettings
                      onDataClick={handleMapClick}
                      onDataSelect={handleMapSelect}
                      selectedTerritoryId={selectedTerritoryId}
                      filteredTerritoryIds={filteredTerritoryIds}
                      onSettingsShow={handleMapSettingsOpen}
                      onRenderStart={onMapRenderStart}
                      onRenderFinish={onMapRenderFinish}
                      showSingleGeometry
                      isPointData={isPointData}
                      latAttributeId={latAttributeId}
                      longAttributeId={longAttributeId}
                      srid={srid}
                      geometriesYear={referenceYear || geometriesYear}
                      generalizationLevels={generalizationLevels}
                    />
                  </div>
                </div>
              )}
              {isChartVisible && (
                <div style={{height: leftPanelBottomHeight, width: "100%", padding: 8}}>
                  <Grid
                    container
                    id="data-viewer__viewer__viewers__chart__filters-settings-container"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.filtersAndActions}
                  >
                    <Grid item id="data-viewer__viewer__viewers__chart__filters">
                      <DatasetMergedFilters
                        jsonStat={dataset}
                        layout={chartLayout}
                        filterTree={chartFilterTree}
                        timeDim={timeDim}
                        onChange={onChartLayoutSet}
                      />
                    </Grid>
                    <Grid item id="data-viewer__viewer__viewers__chart__settings" className={classes.actions}>
                      <Tooltip title={t("scenes.dataViewer.actions.chartSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.chartSettings.ariaLabel")}
                          size={"small"}
                          onClick={handleChartSettingsOpen}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <div id="data-viewer__viewer__viewers__chart__viewer" className={classes.viewer}>
                    <ChartJsonstatWrapper
                      chartId={chartId}
                      type={chartType}
                      jsonStat={dataset}
                      layout={chartLayout}
                      timePeriodsByFreq={timePeriodsByFreq}
                      decimalSeparator={decimalSeparator}
                      roundingStrategy={roundingStrategy}
                      decimalPlaces={decimalPlaces}
                      chartSettings={chartSettings}
                      showArithmeticMean={showArithmeticMean}
                      arithmeticMeanDims={arithmeticMeanDims}
                      arithmeticMeans={arithmeticMeans}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {isTableVisible && (
            <div style={{display: "inline-block", verticalAlign: "top", height: "100%", width: rightPanelWidth}}>
              <div
                id="data-viewer__viewer__viewers__table__viewer"
                className={classes.viewer}
                style={{height: "100%", width: "100%", padding: 8}}
              >
                {!isTooBigData ? (
                  <Table
                    jsonStat={dataset}
                    tableHeaderType={TABLE_HEADER_MERGED}
                    layout={tableLayout}
                    rowHover
                    labelFormat={labelFormat}
                    customLabelFormats={customLabelFormats}
                    dimensionValueModifiers={dimensionValueModifiers}
                    decimalSeparator={decimalSeparator}
                    roundingStrategy={roundingStrategy}
                    decimalPlaces={decimalPlaces}
                    emptyChar={tableEmptyChar}
                    dimensionFilterValues={tableDimensionFilterValues}
                    firstRowDimValues={tableFirstRowDimValues}
                    highlightedRowsDimValues={tableHighlightedRowsDimValues}
                    onRowClick={handleTableRowClick}
                    scrollToLastRow
                    filterable
                    onFilter={handleTableFilter}
                    isPointData={isPointData}
                    invertedDims={invertedDims}
                    enableMeasuresOfSynthesisAndVariability
                    showArithmeticMean={showArithmeticMean}
                    showStandardDeviation={showStandardDeviation}
                    showCoefficientOfVariation={showCoefficientOfVariation}
                    onPageGenerationComplete={onTablePageGenerationComplete}
                    onStructureGenerationComplete={onTableStructureGenerationComplete}
                    hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                    hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                  />
                ) : (
                  <CustomEmpty text={t("scenes.dataViewer.errors.tooBigData")} />
                )}
              </div>
            </div>
          )}
        </div>
      </Card>

      <Dialog open={isChartSettingVisible} maxWidth="md" fullWidth onClose={handleChartSettingsClose}>
        <CustomDialogTitle onClose={handleChartSettingsClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.chartSettings.title")}
        </CustomDialogTitle>
        <DialogContent style={{height: 500}}>
          <Box className={classes.settingsTabs}>
            <Tabs
              value={chartSettingsTabId}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => setChartSettingsTabId(newValue)}
            >
              <Tab value={"layout"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.layout")} />
              <Tab value={"general"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.general")} />
              <Tab value={"colors"} label={t("scenes.dataViewer.multiViewer.dialogs.chartSettings.tabs.colors")} />
            </Tabs>
          </Box>
          <div className={classes.settingsContent}>
            {chartSettingsTabId === "layout" && (
              <ChartSettingsLayout
                jsonStat={dataset}
                layout={tmpChartLayout}
                onLayoutSet={setTmpChartLayout}
                type={tmpChartType}
                onTypeSet={setTmpChartType}
              />
            )}
            {chartSettingsTabId === "general" && (
              <ChartSettingsGeneral settings={tmpChartSettings} onSettingsSet={setTmpChartSettings} />
            )}
            {chartSettingsTabId === "colors" && (
              <ChartSettingsColors
                jsonStat={dataset}
                settings={tmpChartSettings}
                onSettingsSet={setTmpChartSettings}
                dimensionsFilter={dim =>
                  [...(chartLayout?.primaryDim || []), ...(chartLayout?.secondaryDim || [])].includes(dim)
                }
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleChartSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isMapSettingVisible} maxWidth="md" fullWidth onClose={handleMapSettingsClose}>
        <CustomDialogTitle onClose={handleMapSettingsClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent>
          <MapSettingsSettings
            mapId={mapId}
            settings={tmpMapSettings}
            onSettingsSet={setTmpMapSettings}
            setSettingsValidity={setMapSettingsValidity}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMapSettingsClose}>{t("commons.confirm.cancel")}</Button>
          <Button autoFocus onClick={handleMapSettingsSubmit} color="primary" disabled={!isMapSettingsValid}>
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={missingIndicators !== null} maxWidth="md" onClose={onIndicatorWarningHide}>
        <CustomDialogTitle onClose={onIndicatorWarningHide}>
          {t("scenes.dataViewer.multiViewer.dialogs.missingIndicator.title")}
        </CustomDialogTitle>
        <DialogContent>
          {t("scenes.dataViewer.multiViewer.dialogs.missingIndicator.content", {
            missingIndicators: (missingIndicators || []).join(", ")
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onIndicatorWarningHide}>{t("commons.confirm.confirm")}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(MultiViewerViewer);
