import React, {Component, Fragment} from "react";
import {Box, withStyles} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {compose} from "redux";
import {
  getHomeInternalUrl,
  getNodeDashboardsInternalUrl,
  getNodeInternalUrl,
  goToDatasetsSearch,
  goToHome,
  goToNode,
  goToNodeDashboards
} from "../../links";
import {NodeVisibility} from "../../model/IHubMinimalNode.d.ts";
import {ConfigNodeMenuId} from "../../model/item-containers-models/configNodeMenuId";
import {canDisplayNewsManagerForm} from "../../modules/news/constants";
import A11ySelect from "../a11y-select";
import AppInfo from "../app-info";
import AppLanguageSelect from "../app-language-select";
import ButtonSelect from "../button-select";
import CategoriesTree from "../categories-tree";
import CustomLink from "../custom-link";
import DetailLevelSelect from "../detail-level-select";
import ModulesPlaceholder from "../modules-placeholder";
import SearchDialog from "../search-dialog";
import SettingsSelect from "../settings-select";
import TerritoryTools from "../territory-tools/TerritoryTools";
import UserSelect from "../user-select";
import {WINDOW_SCREEN_WIDTH_MD, WINDOW_SCREEN_WIDTH_SM} from "../../utils/constants";
import {localizeI18nObj} from "../../utils/i18n";
import {getCustomPagesInMenu} from "../../utils/itemContainers";
import {areElementsOverlap} from "../../utils/other";
import {
  canClearServerCache,
  canDisplayAppSettingsForm,
  canDisplayNodesSettingsForm,
  canDisplayUsersSettingsForm,
  canGetQueryLog
} from "../../utils/user";
import themeConfig from "../../theme-config/config.json";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    position: "fixed",
    width: "100%",
    zIndex: theme.zIndex.appBar
  },
  firstRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 64
  },
  firstRowContainer: {
    position: "absolute",
    padding: "0 8px"
  },
  firstRowLeftContainer: {
    left: 0
  },
  firstRowCenterContainer: {
    cursor: "pointer"
  },
  firstRowRightContainer: {
    right: 0
  },
  hiddenContainer: {
    visibility: "hidden"
  },
  secondRow: {
    display: "flex",
    justifyContent: "center",
    "& $hubName": {
      height: "unset",
      padding: 0,
      fontSize: 24
    }
  },
  thirdRow: {
    paddingLeft: 12
  },
  nodeSelector: {
    fontSize: 20,
    fontWeight: 500,
    textTransform: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  hubLogo: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    "& img": {
      height: 32
    }
  },
  hubName: {
    height: 48,
    padding: 8,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  hubNameLink: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    textDecoration: "none"
  },
  navigationDrawer: {
    width: 520,
    maxWidth: "100%"
  },
  navigationDrawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px"
  },
  navigationDrawerHeaderTitle: {
    fontSize: 20,
    fontWeight: 500,
    padding: "16px 0"
  },
  navigationDrawerDivider: {
    margin: "0 8px 8px"
  },
  navigationDrawerLink: {
    margin: "1px 4px",
    "& div.custom-link--disabled": {
      padding: "4px 8px"
    },
    "& div.custom-link--enabled > a > div": {
      padding: "4px 8px"
    }
  },
  toolbarDrawer: {
    overflow: "hidden",
    padding: 8
  },
  toolbarDrawerDivider: {
    width: "100%",
    margin: "4px 0"
  }
});

const mapStateToProps = state => ({
  baseURL: state.config.baseURL,
  enableHeaderAutoResponsiveness: state.appConfig.enableHeaderAutoResponsiveness,
  user: state.user,
  filteredCatalog: state.detailLevel.filteredCatalog,
  isA11y: state.app.isA11y,
  defaultLanguage: state.app.language,
  languages: state.app.languages
});

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: 0
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    fontSize: 16,
    minHeight: 48,
    margin: "0 4px",
    padding: "0 4px",
    "&$expanded": {
      minHeight: 48
    },
    "&:hover": {
      backgroundColor: "#f5f5f5"
    },
    "&:focus": {
      outline: "-webkit-focus-ring-color auto 1px",
      outlineOffset: -1
    },
    "&:focus:hover": {
      backgroundColor: "#f5f5f5"
    },
    "&:focus:not(:hover)": {
      backgroundColor: "#ffffff"
    }
  },
  content: {
    margin: 12,
    "&$expanded": {
      margin: 12
    }
  },
  expanded: {},
  expandIcon: {
    transition: "none",
    margin: 0
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    display: "block"
  }
})(MuiAccordionDetails);

const NavigationDrawerButton = ({t, onNavigationDrawerOpen}) => (
  <Grid item className="navigation-drawer-btn">
    <Tooltip title={t("components.header.actions.openNavigationDrawer.title")}>
      <IconButton
        color="inherit"
        onClick={onNavigationDrawerOpen}
        aria-label={t("components.header.actions.openNavigationDrawer.ariaLabel")}
      >
        <MenuIcon />
      </IconButton>
    </Tooltip>
  </Grid>
);

const NodeSelector = ({t, classes, isDefaultUniqueNode, node, nodes}) => {
  if (!isDefaultUniqueNode) {
    return (
      <Grid item className="node-selector">
        <ButtonSelect
          value={<span className={classes.nodeSelector}>{node?.name || node?.code}</span>}
          onChange={code => goToNode(code.toLowerCase())}
          tooltip={node?.name}
          buttonStyle={{maxWidth: "100%"}}
          ariaLabel={t("components.header.actions.nodeSelector.ariaLabel")}
        >
          {nodes
            .filter(({visible}) => visible !== NodeVisibility.No)
            .sort((a, b) => a.order - b.order)
            .map(({code, name}, i) => (
              <span key={i} data-value={code}>
                {name || code}
              </span>
            ))}
        </ButtonSelect>
      </Grid>
    );
  } else {
    return null;
  }
};

const showDashboardsButton = (isA11y, noNode, node) => {
  if (!isA11y || !noNode) {
    if (themeConfig.enableNewDashboard) {
      return node?.itemContainers?.dashboard?.length > 0;
    } else {
      return node?.dashboards && node?.dashboards.length > 0;
    }
  } else {
    return false;
  }
};

const HubLogo = ({
  classes,
  hub,
  headerLogoTitle,
  headerLogoHref,
  baseURL,
  headerLogoAlt,
  handleStyle,
  headerLogoSmallURL,
  isCollapsed
}) => {
  if ((!isCollapsed && hub.headerLogoURL) || (isCollapsed && headerLogoSmallURL)) {
    const img = (
      <img
        src={baseURL + (!isCollapsed ? hub.headerLogoURL : headerLogoSmallURL)}
        alt={headerLogoAlt}
        onLoad={handleStyle}
      />
    );

    return (
      <Grid item className="hub-logo">
        <Tooltip title={headerLogoTitle}>
          {headerLogoHref.length > 0 ? (
            <a href={headerLogoHref} target="_blank" rel="noopener noreferrer">
              <span className={classes.hubLogo}>{img}</span>
            </a>
          ) : (
            <Link to={getHomeInternalUrl()}>
              <span className={classes.hubLogo}>{img}</span>
            </Link>
          )}
        </Tooltip>
      </Grid>
    );
  } else {
    return null;
  }
};

const HubName = ({classes, hub}) => {
  if ((hub.name || "").length > 0) {
    return (
      <Grid item className="hub-name">
        <Link to={getHomeInternalUrl()} className={classes.hubNameLink}>
          <span className={classes.hubName}>{hub.name}</span>
        </Link>
      </Grid>
    );
  } else {
    return null;
  }
};

const SearchButton = ({noNode, query, node, catalog}) => {
  if (!noNode && catalog && !catalog.isEmpty) {
    return (
      <Grid item className="search-btn">
        <SearchDialog
          query={query}
          modalWidth={320}
          dialogTop={56}
          onSubmit={value => goToDatasetsSearch(node.code, value)}
        />
      </Grid>
    );
  } else {
    return null;
  }
};

const LanguageButton = () => (
  <Grid item className="language-btn">
    <AppLanguageSelect />
  </Grid>
);

const A11yButton = ({getAdditionalA11yUrlParams, getCustomA11yPath}) => (
  <Grid item className="a11y-btn">
    <A11ySelect getAdditionalA11yUrlParams={getAdditionalA11yUrlParams} getCustomA11yPath={getCustomA11yPath} />
  </Grid>
);

const DashboardButton = ({t, noNode, isA11y, node}) => {
  if (showDashboardsButton(isA11y, noNode, node)) {
    return (
      <Grid item className="dashboards-btn">
        <Tooltip title={t("components.header.actions.dashboard.title")}>
          <IconButton
            onClick={() => goToNodeDashboards(node.code)}
            color="inherit"
            aria-label={t("components.header.actions.dashboard.ariaLabel")}
          >
            <DashboardIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    );
  } else {
    return null;
  }
};

const AdminSettingButton = ({
  user,
  defaultAppConfigOpen,
  defaultNodesConfigOpen,
  defaultUsersConfigOpen,
  defaultNodeConfigOpen,
  nodes
}) => {
  if (
    canDisplayAppSettingsForm(user) ||
    canDisplayUsersSettingsForm(user) ||
    canDisplayNodesSettingsForm(user) ||
    canGetQueryLog(user) ||
    canClearServerCache(user) ||
    canDisplayNewsManagerForm(user)
  ) {
    return (
      <Grid item className="admin-settings-btn">
        <SettingsSelect
          defaultAppOpen={defaultAppConfigOpen}
          defaultNodesOpen={defaultNodesConfigOpen}
          defaultUsersOpen={defaultUsersConfigOpen}
          defaultNodeOpen={defaultNodeConfigOpen}
          nodes={nodes}
        />
      </Grid>
    );
  } else {
    return null;
  }
};

const UserSettingsButton = ({nodes, disabled}) => (
  <Grid item className="user-settings-btn">
    <UserSelect nodes={nodes} disabled={disabled} />
  </Grid>
);

const InfoButton = ({t, onInfoOpen}) => (
  <Grid item className="info-btn">
    <Tooltip title={t("components.header.actions.info.title")}>
      <IconButton onClick={onInfoOpen} color="inherit" aria-label={t("components.header.actions.info.ariaLabel")}>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  </Grid>
);

const TerritoryToolsButton = () => (
  <Grid item className="territory-tools-btn">
    <TerritoryTools />
  </Grid>
);

const isCategoriesExpandedDefault = true;
const isDetailLevelsExpandedDefault = true;

class NodeHeader extends Component {
  constructor(props) {
    super(props);

    const hubExtras = JSON.parse(props.hub.extras || "{}");

    this.state = {
      isNavigationDrawerOpen: props.defaultTreeOpen || false,
      isToolbarDrawerOpen: false,
      isInfoOpen: false,
      headerLogoSmallURL: hubExtras.HeaderLogoSmallURL || "",
      headerLogoAlt: localizeI18nObj(hubExtras.HeaderLogoAlt, props.defaultLanguage, props.languages) || "",
      headerLogoHref: hubExtras.HeaderLogoHref || "",
      headerLogoTitle: localizeI18nObj(hubExtras.headerLogoTitle, props.defaultLanguage, props.languages) || "",
      isCategoriesExpanded: isCategoriesExpandedDefault,
      isDetailLevelsExpanded: isDetailLevelsExpandedDefault,
      isFirstRowCollapsed: false,
      isCenterContainerCollapsed: false
    };
    this.handleStyle = this.handleStyle.bind(this);
    this.handleCategoriesExpand = this.handleCategoriesExpand.bind(this);
    this.handleDetailLevelsExpand = this.handleDetailLevelsExpand.bind(this);
  }

  handleStyle = () => {
    const isFirstRowCollapsed = this.props.enableHeaderAutoResponsiveness
      ? areElementsOverlap("node-header__first-row__center-container", "node-header__first-row__left-container") ||
        areElementsOverlap("node-header__first-row__center-container", "node-header__first-row__right-container")
      : window.innerWidth < WINDOW_SCREEN_WIDTH_MD;

    const isCenterContainerCollapsed = this.props.enableHeaderAutoResponsiveness
      ? areElementsOverlap(
          "node-header__first-row__center-container",
          "node-header__first-row__left-container--collapsed"
        ) ||
        areElementsOverlap(
          "node-header__first-row__center-container",
          "node-header__first-row__right-container--collapsed"
        )
      : window.innerWidth < WINDOW_SCREEN_WIDTH_SM;

    if (!this.props.enableHeaderAutoResponsiveness) {
      const centerContainerWidth = $("#node-header__first-row__center-container").outerWidth(true) || 0;
      const navigationDrawerBtnWidth =
        $("#node-header__first-row__left-container .navigation-drawer-btn").outerWidth(true) || 0;
      const homeBtnWidth = $("#node-header__first-row__left-container .home-btn").outerWidth(true) || 0;
      const territoryToolsBtnWidth =
        $("#node-header__first-row__left-container .territory-tools-btn").outerWidth(true) || 0;

      $("#node-header__first-row__left-container .node-selector").css({
        maxWidth: `calc(50vw - ${
          centerContainerWidth / 2 + navigationDrawerBtnWidth + homeBtnWidth + territoryToolsBtnWidth + 16
        }px)`
      });
    }

    if (this.state.isFirstRowCollapsed !== isFirstRowCollapsed) {
      this.setState({
        isFirstRowCollapsed: isFirstRowCollapsed
      });
    }

    if (this.state.isCenterContainerCollapsed !== isCenterContainerCollapsed) {
      this.setState({
        isCenterContainerCollapsed: isCenterContainerCollapsed
      });
    }
  };

  componentDidMount() {
    this.handleStyle();
    window.addEventListener("resize", this.handleStyle);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleStyle);
  }

  componentDidUpdate() {
    this.handleStyle();
  }

  onNavigationDrawerOpen = () => {
    this.setState({
      isNavigationDrawerOpen: true,
      isCategoriesExpanded: isCategoriesExpandedDefault,
      isDetailLevelsExpanded: isDetailLevelsExpandedDefault
    });
  };

  onNavigationDrawerClose = () => {
    this.setState({
      isNavigationDrawerOpen: false,
      isCategoriesExpanded: isCategoriesExpandedDefault,
      isDetailLevelsExpanded: isDetailLevelsExpandedDefault
    });
  };

  onToolbarDrawerOpen = () => {
    this.setState({
      isToolbarDrawerOpen: true
    });
  };

  onToolbarDrawerClose = () => {
    this.setState({
      isToolbarDrawerOpen: false
    });
  };

  onInfoOpen = () => {
    this.setState({
      isInfoOpen: true
    });
  };

  onInfoClose = () => {
    this.setState({
      isInfoOpen: false
    });
  };

  handleCategoriesExpand = () => {
    this.setState({
      isCategoriesExpanded: !this.state.isCategoriesExpanded
    });
  };

  handleDetailLevelsExpand = () => {
    this.setState({
      isDetailLevelsExpanded: !this.state.isDetailLevelsExpanded
    });
  };

  render() {
    const {
      classes,
      selectedCategoryPath,
      selectedDataset,
      title,
      defaultAppConfigOpen,
      defaultUsersConfigOpen,
      defaultNodesConfigOpen,
      defaultNodeConfigOpen,
      nodes,
      noNode,
      catalog,
      node,
      query,
      hub,
      baseURL,
      user,
      isA11y,
      isDefault,
      filteredCatalog,
      getCustomA11yPath,
      getAdditionalA11yUrlParams,
      t,
      enableHeaderAutoResponsiveness,
      emptyHeader,
      defaultLanguage,
      languages
    } = this.props;

    const {
      isNavigationDrawerOpen,
      isToolbarDrawerOpen,
      isInfoOpen,
      headerLogoSmallURL,
      headerLogoAlt,
      headerLogoHref,
      headerLogoTitle,
      isCategoriesExpanded,
      isDetailLevelsExpanded,
      isFirstRowCollapsed,
      isCenterContainerCollapsed
    } = this.state;

    const isDefaultUniqueNode =
      !noNode && isDefault && node
        ? nodes.filter(n => n.code.toLowerCase() !== node.code.toLowerCase()).length === 0
        : false;

    const nodeMinimalInfo =
      !noNode && node ? (nodes || []).find(({code}) => code?.toLowerCase() === node.code?.toLowerCase()) : null;

    const isSecondRowVisible = isCenterContainerCollapsed;
    const isThirdRowVisible = isFirstRowCollapsed && !noNode && !isDefaultUniqueNode && !emptyHeader;
    const nodeSidenavCustomPages = getCustomPagesInMenu(node?.itemContainers?.customPages, ConfigNodeMenuId.SIDENAV);
    return (
      <div className={classes.root} id="node-header">
        <AppBar
          position="static"
          color="primary"
          role="navigation"
          aria-label={t("components.header.appBar.ariaLabel")}
        >
          <div id="node-header__first-row" className={classes.firstRow}>
            {(enableHeaderAutoResponsiveness || !isFirstRowCollapsed) && (
              <div
                id="node-header__first-row__left-container"
                className={`${classes.firstRowContainer} ${classes.firstRowLeftContainer} ${
                  isFirstRowCollapsed ? classes.hiddenContainer : ""
                }`}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  {!emptyHeader && (
                    <Fragment>
                      {node?.visible !== NodeVisibility.No ? (
                        !noNode ? (
                          <Fragment>
                            {catalog && !catalog.isEmpty && (
                              <NavigationDrawerButton t={t} onNavigationDrawerOpen={this.onNavigationDrawerOpen} />
                            )}
                            <Grid item className="node-home-btn">
                              <Tooltip title={t("components.header.actions.nodeHome.title")}>
                                <IconButton
                                  color="inherit"
                                  onClick={() => goToNode((node?.code || "").toLowerCase())}
                                  aria-label={t("components.header.actions.nodeHome.ariaLabel")}
                                >
                                  <HomeIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <NodeSelector
                              t={t}
                              classes={classes}
                              isDefaultUniqueNode={isDefaultUniqueNode}
                              node={node}
                              nodes={nodes}
                            />
                          </Fragment>
                        ) : (
                          <span />
                        )
                      ) : (
                        <Grid item className="home-btn">
                          <Tooltip title={t("components.header.actions.home.title")}>
                            <IconButton
                              color="inherit"
                              onClick={() => goToHome()}
                              aria-label={t("components.header.actions.home.ariaLabel")}
                            >
                              <HomeIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <TerritoryToolsButton />
                    </Fragment>
                  )}
                </Grid>
              </div>
            )}
            {(enableHeaderAutoResponsiveness || isFirstRowCollapsed) && (
              <div
                id="node-header__first-row__left-container--collapsed"
                className={`${classes.firstRowContainer} ${classes.firstRowLeftContainer} ${
                  !isFirstRowCollapsed ? classes.hiddenContainer : ""
                }`}
              >
                {!noNode && !emptyHeader && node?.visible !== NodeVisibility.No && catalog && !catalog.isEmpty && (
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <NavigationDrawerButton t={t} onNavigationDrawerOpen={this.onNavigationDrawerOpen} />
                  </Grid>
                )}
              </div>
            )}
            {(enableHeaderAutoResponsiveness || !isCenterContainerCollapsed) && (
              <div
                id="node-header__first-row__center-container"
                className={`${classes.firstRowContainer} ${classes.firstRowCenterContainer} ${
                  isCenterContainerCollapsed ? classes.hiddenContainer : ""
                }`}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <HubLogo
                    classes={classes}
                    hub={hub}
                    headerLogoTitle={headerLogoTitle}
                    headerLogoHref={headerLogoHref}
                    baseURL={baseURL}
                    headerLogoAlt={headerLogoAlt}
                    handleStyle={this.handleStyle}
                    headerLogoSmallURL={headerLogoSmallURL}
                  />
                  <HubName classes={classes} hub={hub} />
                </Grid>
              </div>
            )}
            {(enableHeaderAutoResponsiveness || isCenterContainerCollapsed) && (
              <div
                id="node-header__first-row__center-container--collapsed"
                className={`${classes.firstRowContainer} ${classes.firstRowCenterContainer} ${
                  !isCenterContainerCollapsed ? classes.hiddenContainer : ""
                }`}
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <HubLogo
                    classes={classes}
                    hub={hub}
                    headerLogoTitle={headerLogoTitle}
                    headerLogoHref={headerLogoHref}
                    baseURL={baseURL}
                    headerLogoAlt={headerLogoAlt}
                    handleStyle={this.handleStyle}
                    headerLogoSmallURL={headerLogoSmallURL}
                    isCollapsed
                  />
                </Grid>
              </div>
            )}
            {(enableHeaderAutoResponsiveness || !isFirstRowCollapsed) && (
              <div
                id="node-header__first-row__right-container"
                className={`${classes.firstRowContainer} ${classes.firstRowRightContainer} ${
                  isFirstRowCollapsed ? classes.hiddenContainer : ""
                }`}
              >
                {!emptyHeader && (
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <SearchButton noNode={noNode} query={query} node={node} catalog={catalog} />
                    <LanguageButton />
                    <A11yButton
                      getAdditionalA11yUrlParams={getAdditionalA11yUrlParams}
                      getCustomA11yPath={getCustomA11yPath}
                    />
                    <DashboardButton t={t} noNode={noNode} isA11y={isA11y} node={node} />
                    <AdminSettingButton
                      user={user}
                      defaultAppConfigOpen={defaultAppConfigOpen}
                      defaultNodesConfigOpen={defaultNodesConfigOpen}
                      defaultUsersConfigOpen={defaultUsersConfigOpen}
                      defaultNodeConfigOpen={defaultNodeConfigOpen}
                      nodes={nodes}
                    />
                    <UserSettingsButton nodes={nodes} disabled={isFirstRowCollapsed} />
                    <InfoButton t={t} onInfoOpen={this.onInfoOpen} />
                  </Grid>
                )}
              </div>
            )}
            {(enableHeaderAutoResponsiveness || isFirstRowCollapsed) && (
              <div
                id="node-header__first-row__right-container--collapsed"
                className={`${classes.firstRowContainer} ${classes.firstRowRightContainer} ${
                  !isFirstRowCollapsed ? classes.hiddenContainer : ""
                }`}
              >
                {!emptyHeader && (
                  <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item className="toolbar-drawer-btn">
                      <Tooltip title={t("components.header.actions.openToolbarDrawer.title")}>
                        <IconButton
                          color="inherit"
                          onClick={this.onToolbarDrawerOpen}
                          aria-label={t("components.header.actions.openToolbarDrawer.ariaLabel")}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </div>
            )}
          </div>
          {isSecondRowVisible && (
            <div
              id="node-header__second-row"
              className={classes.secondRow}
              style={{paddingBottom: isThirdRowVisible ? 0 : 8}}
            >
              <HubName classes={classes} hub={hub} />
            </div>
          )}
          {isThirdRowVisible && (
            <div id="node-header__third-row" className={classes.thirdRow}>
              <NodeSelector
                t={t}
                classes={classes}
                isDefaultUniqueNode={isDefaultUniqueNode}
                node={node}
                nodes={nodes}
              />
            </div>
          )}
        </AppBar>

        {!noNode && !emptyHeader && (
          <Drawer
            anchor="left"
            open={isNavigationDrawerOpen}
            classes={{
              paper: classes.navigationDrawer
            }}
            onClose={this.onNavigationDrawerClose}
          >
            <nav id="navigation-drawer" aria-label={t("components.header.navigationDrawer.ariaLabel")}>
              <div className={classes.navigationDrawerHeader}>
                <span className={classes.navigationDrawerHeaderTitle}>{title || node?.code}</span>
                <Tooltip title={t("components.header.actions.closeNavigationDrawer.title")}>
                  <IconButton
                    onClick={this.onNavigationDrawerClose}
                    aria-label={t("components.header.actions.closeNavigationDrawer.ariaLabel")}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <Divider className={classes.navigationDrawerDivider} />
              {node &&
                (nodes || []).length > 1 &&
                window.location.hash.toLowerCase().includes(node.code?.toLowerCase()) &&
                window.location.hash
                  .toLowerCase()
                  .split("/")
                  .filter(str => (str || "").length > 0)
                  .slice(-1)[0] !== node.code?.toLowerCase() && (
                  <div className={classes.navigationDrawerLink}>
                    <CustomLink
                      to={getNodeInternalUrl(node?.code)}
                      text={t("components.header.navigationDrawer.backToNodeHome")}
                      icon={<HomeIcon />}
                    />
                  </div>
                )}
              {(nodeMinimalInfo?.dashboards || []).length > 0 && themeConfig.enableDashboard && (
                <div className={classes.navigationDrawerLink}>
                  <CustomLink
                    to={getNodeDashboardsInternalUrl(node?.code)}
                    text={t("components.header.navigationDrawer.goToDashboard")}
                    icon={<DashboardIcon />}
                  />
                </div>
              )}
              <ModulesPlaceholder
                id="navigation-drawer-placeholder"
                className={classes.navigationDrawerLink}
                moduleFallback={<div className={classes.navigationDrawerLink} style={{height: 48}} />}
              />
              {nodeSidenavCustomPages?.length > 0 &&
                nodeSidenavCustomPages?.map(v => (
                  <Box paddingLeft="12px">
                    <CustomLink
                      key={v.itemContainerId}
                      to={`/${defaultLanguage}/${node?.code}/pages/${v.itemContainerId}`}
                      text={localizeI18nObj(v.title, defaultLanguage, languages) || ""}
                    />
                  </Box>
                ))}

              <ModulesPlaceholder
                id="pages-placeholder"
                className={classes.navigationDrawerLink}
                moduleFallback={<div className={classes.navigationDrawerLink} style={{height: 48}} />}
              />

              {catalog && (
                <Fragment>
                  {catalog.detailLevels.length > 0 && (
                    <Accordion expanded={isDetailLevelsExpanded} onChange={this.handleDetailLevelsExpand}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {t("components.header.navigationDrawer.detailLevelSelectTitle")}
                      </AccordionSummary>
                      <AccordionDetails>
                        <DetailLevelSelect variant="outlined" />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Accordion expanded={isCategoriesExpanded} onChange={this.handleCategoriesExpand}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {t("components.header.navigationDrawer.categoriesTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <CategoriesTree
                        selectedCategoryPath={selectedCategoryPath}
                        selectedDataset={selectedDataset}
                        catalog={filteredCatalog}
                        node={node}
                        onClose={this.onNavigationDrawerClose}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Fragment>
              )}
            </nav>
          </Drawer>
        )}

        {!emptyHeader && (
          <Drawer
            anchor="right"
            open={isToolbarDrawerOpen}
            classes={{
              paper: classes.toolbarDrawer
            }}
            onClose={this.onToolbarDrawerClose}
          >
            <nav id="toolbar-drawer" aria-label={t("components.header.toolbarDrawer.ariaLabel")}>
              <Grid container direction="column" spacing={1} alignItems="center">
                <Grid item>
                  <Tooltip title={t("components.header.actions.closeToolbarDrawer.title")}>
                    <IconButton
                      color="inherit"
                      onClick={this.onToolbarDrawerClose}
                      aria-label={t("components.header.actions.closeToolbarDrawer.ariaLabel")}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item className={classes.toolbarDrawerDivider}>
                  <Divider />
                </Grid>
                <SearchButton noNode={noNode} query={query} node={node} catalog={catalog} />
                <LanguageButton />
                <A11yButton
                  getAdditionalA11yUrlParams={getAdditionalA11yUrlParams}
                  getCustomA11yPath={getCustomA11yPath}
                />
                <DashboardButton t={t} noNode={noNode} isA11y={isA11y} node={node} />
                <AdminSettingButton
                  user={user}
                  defaultAppConfigOpen={defaultAppConfigOpen}
                  defaultNodesConfigOpen={defaultNodesConfigOpen}
                  defaultUsersConfigOpen={defaultUsersConfigOpen}
                  defaultNodeConfigOpen={defaultNodeConfigOpen}
                  nodes={nodes}
                />
                <UserSettingsButton nodes={nodes} disabled={!isFirstRowCollapsed} />
                <InfoButton t={t} onInfoOpen={this.onInfoOpen} />
                <TerritoryToolsButton />
              </Grid>
            </nav>
          </Drawer>
        )}

        <Dialog open={isInfoOpen} maxWidth="sm" onClose={this.onInfoClose}>
          <DialogContent>
            <AppInfo hub={hub} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onInfoClose}>{t("commons.confirm.close")}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps))(NodeHeader);
