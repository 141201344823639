import React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import CustomDialogTitle from "../../custom-dialog-title";
import FullscreenDialog from "../../fullscreen-dialog";
import ItemContainer from "../../item-container";
import PreviewProvider from "../../item-container/PreviewContext";
import {clearOtherConfigItemContainersDataset} from "../../../state/otherConfig/otherConfigActions";
import {otherConfigSelector} from "../../../state/otherConfig/otherConfigSelectors";
import {ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY} from "../../../utils/itemContainers";

const getStaticItemContainer = itemContainer => {
  if (!itemContainer) {
    return null;
  }
  const staticItemContainer = _.cloneDeep(itemContainer);
  staticItemContainer.item.forEach(row => {
    row.forEach(col => {
      col[ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY] = null;
    });
  });

  return staticItemContainer;
};

function PreviewDialog({classes, isPreviewVisible, setPreviewVisibility}) {
  const {itemContainer} = useSelector(otherConfigSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const clearDatasets = () => dispatch(clearOtherConfigItemContainersDataset());
  return (
    <FullscreenDialog
      open={isPreviewVisible}
      onClose={() => setPreviewVisibility(false)}
      className={classes.previewDialog}
    >
      <CustomDialogTitle onClose={() => setPreviewVisibility(false)}>
        {t("components.itemContainerBuilder.modals.preview.title")}
      </CustomDialogTitle>
      <DialogContent>
        <PreviewProvider>
          <ItemContainer itemContainer={getStaticItemContainer(itemContainer)} />
        </PreviewProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setPreviewVisibility(false);
            clearDatasets();
          }}
        >
          {t("commons.confirm.close")}
        </Button>
      </DialogActions>
    </FullscreenDialog>
  );
}

export default withStyles(() => ({
  previewDialog: {
    "& .MuiDialogTitle-root, & .MuiDialogContent-root, & .MuiDialogActions-root": {
      background: "#f5f5f5"
    }
  }
}))(PreviewDialog);
