import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {goToCustomPage, goToDashboard} from "../../../links";
import ConfirmationDialog from "../../confirmation-dialog";
import CustomMaterialTable from "../../custom-material-table";
import ItemContainerConfigurationDialog from "./ItemContainerConfigurationDialog";
import {ItemContainerSettingsFormContext} from "./ItemContainerSettingsFormProvider";
import {useItemContainerSettingsLabels} from "./useItemContainerSettingsLabels";
import {languageSelector, languagesSelector, modulesConfigSelector} from "../../../state/app/appSelectors";
import {nodeSelector} from "../../../state/node/nodeSelectors";
import {
  clearOtherConfigItemContainers,
  closeOtherConfigItemContainer,
  createOtherConfigItemContainer,
  deleteOtherConfigItemContainer,
  fetchOtherConfigItemContainers,
  hideOtherConfigItemContainer,
  updateOtherConfigItemContainer
} from "../../../state/otherConfig/otherConfigActions";
import {otherConfigSelector} from "../../../state/otherConfig/otherConfigSelectors";
import {userSelector} from "../../../state/user/userSelectors";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";

interface UserItemContainerSettingsForm {
  asDashboard: boolean;
}

const UserItemContainersSettingsForm = ({asDashboard}: UserItemContainerSettingsForm, ref) => {
  const {scenes} = useItemContainerSettingsLabels(asDashboard);
  const {itemContainer, itemContainers, needItemContainers} = useSelector(otherConfigSelector);
  const node = useSelector(nodeSelector);
  const modulesConfigs = useSelector(modulesConfigSelector);
  const itemContainersActions = modulesConfigs.tableActionFactories["item-containers-settings"] || [];
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const dispatch = useDispatch();
  const {setAsDashboard} = useContext(ItemContainerSettingsFormContext);

  const [deleteItemContainerId, setDeleteItemContainerId] = useState<number>(null);
  const [selectedItemContainerId, setSelectedItemContainerId] = useState<number>(null);
  const user = useSelector(userSelector);
  const {t} = useTranslation();

  useEffect(() => {
    setAsDashboard(asDashboard);
    dispatch(fetchOtherConfigItemContainers(asDashboard ? "dashboard" : "custompage"));
  }, [asDashboard, dispatch, setAsDashboard]);

  useEffect(() => {
    return () => {
      dispatch(hideOtherConfigItemContainer());
    };
  }, [dispatch]);

  useEffect(() => {
    if (needItemContainers) {
      dispatch(fetchOtherConfigItemContainers(asDashboard ? "dashboard" : "custompage"));
    }
  }, [asDashboard, dispatch, itemContainers, needItemContainers]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      dispatch(clearOtherConfigItemContainers());
      dispatch(closeOtherConfigItemContainer());
      f();
    }
  }));

  const handleItemContainerClose = () => {
    dispatch(hideOtherConfigItemContainer());
    setSelectedItemContainerId(null);
  };

  const goToItemContainer = (itemContainerId: string) => {
    if (
      !RegExp(`${`/${asDashboard ? "dashboards" : "pages"}/${itemContainerId}`}$`).test(
        window.location.href.toLowerCase()
      )
    ) {
      const withNode = node?.code || null;
      asDashboard ? goToDashboard(itemContainerId, withNode) : goToCustomPage(itemContainerId, withNode);
    } else {
      window.location.reload();
    }
  };

  return (
    itemContainers && (
      <>
        <CustomMaterialTable
          rightActions={
            <Button
              size="small"
              startIcon={<AddIcon />}
              onClick={() =>
                dispatch(
                  createOtherConfigItemContainer({
                    type: asDashboard ? "Dashboard" : "CustomPage",
                    title: {},
                    item: [],
                    viewIds: [],
                    views: {},
                    filterLevels: {},
                    isPrivate: true,
                    userId: user.userId
                  })
                )
              }
            >
              {t(scenes.createElement)}
            </Button>
          }
          columns={[
            {
              field: "title",
              title: t(scenes.table.columns.title),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
            }
          ]}
          data={itemContainers}
          actions={[
            {
              icon: VisibilityIcon,
              tooltip: t(scenes.table.viewElement),
              onClick: (_, {itemContainerId}) => goToItemContainer(itemContainerId)
            },
            ...itemContainersActions.map(getAction => getAction({asDashboard})),
            {
              icon: EditIcon,
              tooltip: t(scenes.table.editElement),
              onClick: (_, {itemContainerId}) => {
                dispatch(updateOtherConfigItemContainer(itemContainerId, asDashboard ? "dashboard" : "custompage"));
                setSelectedItemContainerId(itemContainerId);
              }
            },
            {
              icon: DeleteIcon,
              tooltip: t(scenes.table.deleteElement),
              onClick: (_, {itemContainerId}) => setDeleteItemContainerId(itemContainerId)
            }
          ]}
          options={{
            actionsColumnIndex: 1
          }}
        />
        <ConfirmationDialog
          open={deleteItemContainerId !== null}
          onClose={() => setDeleteItemContainerId(null)}
          onConfirm={() => {
            dispatch(deleteOtherConfigItemContainer(deleteItemContainerId, asDashboard ? "dashboard" : "custompage"));
            setDeleteItemContainerId(null);
          }}
          title={t(scenes.deleteElement.title)}
          content={t(scenes.deleteElement.content)}
        />

        {itemContainer && (
          <ItemContainerConfigurationDialog
            onClose={handleItemContainerClose}
            itemContainerId={selectedItemContainerId}
          />
        )}
      </>
    )
  );
};

export default forwardRef(UserItemContainersSettingsForm);
