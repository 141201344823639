import React, {createContext, PropsWithChildren, useRef} from "react";

export const ItemContainerBuilderContext = createContext<any>({current: -1});

const ItemContainerBuilderProvider = (props: PropsWithChildren<any>) => {
  const draggingRow = useRef(-1);

  return (
    <ItemContainerBuilderContext.Provider value={draggingRow}>{props.children}</ItemContainerBuilderContext.Provider>
  );
};

export default ItemContainerBuilderProvider;
