import React, {useState} from "react";
import {Button, IconButton, InputAdornment, TextField, TextFieldProps} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import PublishIcon from "@material-ui/icons/Publish";
import {useTranslation} from "react-i18next";

interface FileInputFieldProps {
  textFieldProps?: TextFieldProps;
  uploadDisabled?: boolean;
  initialTextValue?: string;
  onUpload?: (file: FileList) => void;
  OnReset?: () => void;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

const FileInputField = (props: FileInputFieldProps) => {
  const [file, setFile] = useState<File | null>(null);
  const {t} = useTranslation();
  const id = props?.inputProps?.id || "file";

  const {onChange, ...otherFileProps} = props.inputProps;

  const resetFileInput = () => {
    setFile(null);
    if (props?.OnReset) {
      props.OnReset();
    } else {
      const inputElement = document.getElementById(id) as HTMLInputElement | null;
      if (inputElement) {
        inputElement.value = "";
      }
    }
  };

  return (
    <>
      <input
        style={{display: "none"}}
        onChange={e => {
          onChange(e);
          setFile(e.currentTarget.files[0]);
          if (props?.onUpload) {
            props.onUpload(e.currentTarget.files);
          }
        }}
        id={id}
        type="file"
        {...otherFileProps}
      ></input>
      <TextField
        value={file?.name || props.initialTextValue || ""}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <label
                htmlFor={id}
                style={{
                  pointerEvents: props.textFieldProps?.disabled ? "none" : "auto"
                }}
              >
                <Button
                  startIcon={<PublishIcon />}
                  variant="outlined"
                  title={t("components.fileInput.title")}
                  component="span"
                  disabled={props.textFieldProps?.disabled}
                >
                  {t("components.fileInput.upload")}
                </Button>
              </label>
            </InputAdornment>
          ),
          endAdornment: !!file && (
            <InputAdornment position="end">
              <IconButton
                title={t("components.fileInput.reset")}
                onClick={e => resetFileInput()}
                disabled={props.textFieldProps?.disabled}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...props?.textFieldProps}
      />
    </>
  );
};

export default FileInputField;
