import {DatasetData} from "../../model/IDatasetData";
import {IHubMinimalNode} from "../../model/IHubMinimalNode";
import {FilterCriteria} from "../../model/item-containers-models/filterCriteria";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import {CRITERIA_FILTER_TYPE_PERIODS} from "../../utils/criteria";
import {ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY, ITEMCONTAINER_ELEM_WIDTH_KEY} from "../../utils/itemContainers";
import {
  FREQ_DIMENSION_KEY,
  getFilteredChartLayout,
  getFilteredMapLayout,
  getFilteredTableLayout,
  getFilterTreeFromJsonStat
} from "../../utils/jsonStat";
import {getChartSettingsFromViewTemplateLayouts, getMapSettingsFromViewTemplateLayouts} from "../../utils/viewTemplate";

export const handleStyle = (itemContainerId, viewIdx, itemContainerElem, minItemContainerWidth, mapId = null) => {
  const $viewContainer = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}`);

  if ($viewContainer.is(":visible")) {
    const $col = $(`#itemContainer_${itemContainerId}__col__${viewIdx}`);
    const $header = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}__header`);
    const $title = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}__header__title`);
    const $controllers = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}__header__controllers`);
    const $placeHolder = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}__header__placeholder`);
    const $staticFilters = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}__header__static-filters`);
    const $activeFilters = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}__header__active-filters`);
    const $view = $(`#itemContainer_${itemContainerId}__view-container__${viewIdx}__view`);
    $col.css({
      width: `${window.innerWidth > minItemContainerWidth ? itemContainerElem[ITEMCONTAINER_ELEM_WIDTH_KEY] : 100}%`
    });

    if ($title.length) {
      $title.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
      $staticFilters.css({
        width: "100%"
      });
      $activeFilters.css({
        width: "100%"
      });
    } else if ($staticFilters.length) {
      $staticFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });
      $activeFilters.css({
        width: "100%"
      });
    } else if ($activeFilters.length) {
      $activeFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });
    } else {
      $placeHolder.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
    }

    $view.css({
      height: `calc(100% - ${$header.outerHeight() || 0}px)`
    });
    if (mapId && window.LMap) {
      window.LMap.handleViewportChange(mapId);
    }
  }
};

export const makeViewData = (col: ItemViewTemplateDto, jsonStat: DatasetData, criteria: FilterCriteria[]) => {
  const view = {...col.viewTemplate, layouts: JSON.parse(col.viewTemplate.layouts)};

  if ((jsonStat?.id || []).length === 0) {
    return {
      jsonStat: jsonStat,
      layouts: null,
      filterTrees: null,
      maps: null
    };
  } else {
    const timeDim = jsonStat.role?.time?.[0];

    let layout;
    if (view.layouts.tableLayout) {
      layout = getFilteredTableLayout(view.layouts.tableLayout, jsonStat);
    } else if (view.layouts.mapLayout) {
      layout = getFilteredMapLayout(view.layouts.mapLayout, jsonStat);
    } else {
      layout = getFilteredChartLayout(view.layouts.chartLayout, jsonStat);
    }

    const hasLastNPeriods = criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS;

    if (hasLastNPeriods) {
      const timeDimValues = jsonStat.dimension[timeDim].category.index;
      const lastTimeDimValue = timeDimValues[timeDimValues.length - 1];

      if ((layout?.filters || []).includes(timeDim)) {
        layout.filtersValue[timeDim] = lastTimeDimValue;
      } else if ((layout.primaryDim || []).includes(timeDim)) {
        layout.primaryDimValues = timeDimValues;
      } else if ((layout.secondaryDim || []).includes(timeDim)) {
        layout.secondaryDimValues = timeDimValues;
      }
    }

    let timePeriodsByFreq;
    if (jsonStat.id.includes(FREQ_DIMENSION_KEY) && jsonStat.id.includes(timeDim)) {
      const timePeriodsByFreqTree = getFilterTreeFromJsonStat([FREQ_DIMENSION_KEY, timeDim], jsonStat);
      timePeriodsByFreq = {};
      Object.keys(timePeriodsByFreqTree).forEach(freq => {
        timePeriodsByFreq[freq] = Object.keys(timePeriodsByFreqTree[freq]);
      });
    }

    return {
      jsonStat: jsonStat,
      layouts: {
        layout: layout,
        labelFormat: view.layouts.labelFormat,
        temporalDimOrder: view.layouts.temporalDimOrder,
        showTrend: view.layouts.showTrend,
        showCyclical: view.layouts.showCyclical,
        tableEmptyChar: view.layouts.tableEmptyChar,
        chartSettings: getChartSettingsFromViewTemplateLayouts(view.layouts),
        mapSettings: getMapSettingsFromViewTemplateLayouts(view.layouts),
        detailLevel:
          view.layouts.detailLevel !== null && view.layouts.detailLevel !== undefined
            ? view.layouts.detailLevel
            : view.layouts.mapDetailLevel !== null && view.layouts.mapDetailLevel !== undefined
            ? view.layouts.mapDetailLevel
            : null
      },
      filterTrees: col[ITEMCONTAINER_ELEM_ENABLE_FILTERS_KEY]
        ? getFilterTreeFromJsonStat(
            [...(layout.primaryDim || []), ...(layout.secondaryDim || []), ...(layout.filters || [])],
            jsonStat
          )
        : null,
      maps: null,
      timePeriodsByFreq
    };
  }
};

export function getNodeExtras(nodes: IHubMinimalNode[], view: ViewTemplateDto) {
  return (
    (
      nodes?.find(({nodeId}) => nodeId === view.nodeId) as IHubMinimalNode & {
        extras: any[];
      }
    )?.extras?.reduce((acc, {key, value}) => {
      try {
        return {...acc, [key]: JSON.parse(value)};
      } catch (e) {
        return {...acc, [key]: value};
      }
    }, {}) || {}
  );
}
