import {useEffect} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {clearPendingRequests} from "../state/pending-request/pendingRequestActions";
import {flushSpinner} from "../state/spinner/spinnerActions";
import {usePrevious} from "../utils/customHooks";

const mapDispatchToProps = dispatch => ({
  pendingRequestsClear: () => dispatch(clearPendingRequests()),
  spinnerFlush: () => dispatch(flushSpinner())
});

const exitFullscreen = () => {
  const appContainer = document.getElementById("app-container");
  appContainer.style.visibility = "";
  const fullscreenContainer = document.getElementById("fullscreen-container");
  fullscreenContainer.style.display = "none";
};

const AppDomain = ({pendingRequestsClear, spinnerFlush, children}) => {
  const location = useLocation().pathname;
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (prevLocation && prevLocation !== location) {
      pendingRequestsClear();
      spinnerFlush();
      exitFullscreen();
    }
  }, []);

  return children;
};

export default connect(null, mapDispatchToProps)(AppDomain);
