import React, {createContext, PropsWithChildren, useState} from "react";

interface dashboardState {
  asDashboard: boolean;
  setAsDashboard: (value: boolean) => void;
}

export const ItemContainerSettingsFormContext = createContext<dashboardState>(null);

const ItemContainerSettingsFormProvider = (props: PropsWithChildren<any>) => {
  const [asDashboard, setAsDashboard] = useState<boolean>(false);

  return (
    <ItemContainerSettingsFormContext.Provider value={{asDashboard, setAsDashboard}}>
      {props.children}
    </ItemContainerSettingsFormContext.Provider>
  );
};

export default ItemContainerSettingsFormProvider;
