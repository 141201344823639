import React, {useState} from "react";
import {Button} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ConfigNodeFormInfo} from "../../../model/item-containers-models/configNodeFormInfo";
import {ConfigNodeMenuId} from "../../../model/item-containers-models/configNodeMenuId";
import {ConfigNodesDto} from "../../../model/item-containers-models/configNodesDto";
import {ItemContainerDto} from "../../../model/item-containers-models/itemContainerDto";
import CustomMaterialTable from "../../custom-material-table";
import CustomPageNodeSettingsFormDialog from "./CustomPageNodeSettingsFormDialog";
import {nodesSelector} from "../../../state/hub/hubSelectors";
import {changeOtherConfigItemContainer} from "../../../state/otherConfig/otherConfigActions";
import {currentItemContainerSelector} from "../../../state/otherConfig/otherConfigSelectors";

const CustomPageNodeSettings = () => {
  const nodes = useSelector(nodesSelector);
  const itemContainer = useSelector(currentItemContainerSelector);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const addNodeConfig = (configNode: ConfigNodesDto) => {
    const newCustomPage: ItemContainerDto = {
      ...itemContainer,
      configNodes: (itemContainer.configNodes || []).concat(configNode)
    };
    dispatch(changeOtherConfigItemContainer(newCustomPage));
  };

  const changeNodeConfig = (cfg: ConfigNodeFormInfo) => {
    setInitialValue(cfg);
    setAddingConfiguration(true);
  };

  const editNodeConfig = (configNode: ConfigNodesDto) => {
    const newCustomPage: ItemContainerDto = {
      ...itemContainer,
      configNodes: itemContainer.configNodes.map(node => (node.nodeId !== configNode.nodeId ? node : configNode))
    };
    dispatch(changeOtherConfigItemContainer(newCustomPage));
  };

  const removeNodeConfig = (nodeId: number) => {
    const newCustomPage: ItemContainerDto = {
      ...itemContainer,
      configNodes: (itemContainer.configNodes || []).filter(v => v.nodeId !== nodeId)
    };
    dispatch(changeOtherConfigItemContainer(newCustomPage));
  };
  const [isAddingConfiguration, setAddingConfiguration] = useState(false);

  const [initialValue, setInitialValue] = useState<ConfigNodeFormInfo | null>(null);

  const getNodeTitle = nodeId => {
    if (nodeId !== -1) {
      const node = nodes.find(n => n.nodeId === nodeId);
      return node.name || node.nodeId;
    } else {
      return "Hub";
    }
  };

  const data: ConfigNodeFormInfo[] = (itemContainer.configNodes || []).map(v => ({
    ...v,
    sidenavOrder: v.configNodeMenus?.find(m => m.menuId === ConfigNodeMenuId.SIDENAV)?.order,
    centralOrder: v.configNodeMenus?.find(m => m.menuId === ConfigNodeMenuId.CENTRAL)?.order
  }));

  return (
    <>
      <CustomMaterialTable
        rightActions={
          <Button
            size="small"
            disabled={itemContainer?.configNodes?.length >= nodes.length + 1}
            startIcon={<AddIcon />}
            onClick={() => {
              setInitialValue(null);
              setAddingConfiguration(true);
            }}
          >
            {t("components.customPageNodeSettings.actions.add")}
          </Button>
        }
        columns={[
          {
            field: "name",
            title: t("components.customPageNodeSettings.table.columns.nodeName"),
            render: ({nodeId}) => getNodeTitle(nodeId)
          },
          {
            field: "isHomePage",
            title: t("components.customPageNodeSettings.table.columns.isHomePage"),
            render: ({isHomePage}) =>
              isHomePage
                ? t("scenes.nodesSettings.table.columns.isNodeActive.values.true")
                : t("scenes.nodesSettings.table.columns.isNodeActive.values.false")
          },
          {
            field: "sidenavOrder",
            title: t("components.customPageNodeSettings.table.columns.sidenav"),
            render: ({sidenavOrder}) => (sidenavOrder !== undefined ? sidenavOrder : "")
          },
          {
            field: "centralOrder",
            title: t("components.customPageNodeSettings.table.columns.central"),
            render: ({centralOrder}) => (centralOrder !== undefined ? centralOrder : "")
          }
        ]}
        data={data}
        actions={[
          (cfg: ConfigNodeFormInfo) => ({
            icon: EditIcon,
            tooltip: t("components.customPageNodeSettings.actions.edit"),
            onClick: () => changeNodeConfig(cfg)
          }),
          () => ({
            icon: DeleteIcon,
            tooltip: t("components.customPageNodeSettings.actions.delete"),
            onClick: (_, {nodeId}) => removeNodeConfig(nodeId)
          })
        ]}
        options={{
          actionsColumnIndex: 5
        }}
      />

      {isAddingConfiguration && (
        <CustomPageNodeSettingsFormDialog
          initialValues={initialValue}
          open={isAddingConfiguration}
          onClose={() => setAddingConfiguration(false)}
          onSubmit={initialValue ? editNodeConfig : addNodeConfig}
        />
      )}
    </>
  );
};

export default CustomPageNodeSettings;
