import FileSaver from "file-saver";
import {QUERY_LOG_DOWNLOAD} from "../../components/settings-select/actions";
import {REQUEST_SUCCESS} from "../request/requestActions";
import {DASHBOARDS_DATASET_DOWNLOAD_SUBMIT} from "../../state/dashboard/dashboardActions";
import {
  DATASET_MV_DOWNLOAD_METADATA_SUBMIT,
  DATASET_MV_DOWNLOAD_SUBMIT
} from "../../state/dataset/multi-viewer/actions";
import {DATASET_SVP_DOWNLOAD_SUBMIT} from "../../state/dataset/single-viewer-plus/actions";
import {DATASET_SV_DOWNLOAD_SUBMIT} from "../../state/dataset/single-viewer/actions";

export const FILE_SAVE_GENERIC_ACTION_LABEL = "fileSave/genericAction/label";

const fileSaveMiddleware = () => next => action => {
  const result = next(action);

  if (action.type === REQUEST_SUCCESS && action.payload.extra.fileSave) {
    if (
      action.payload.label === FILE_SAVE_GENERIC_ACTION_LABEL ||
      action.payload.label === QUERY_LOG_DOWNLOAD ||
      action.payload.label === DATASET_SV_DOWNLOAD_SUBMIT ||
      action.payload.label === DATASET_SVP_DOWNLOAD_SUBMIT ||
      action.payload.label === DATASET_MV_DOWNLOAD_SUBMIT ||
      action.payload.label === DATASET_MV_DOWNLOAD_METADATA_SUBMIT ||
      action.payload.label === DASHBOARDS_DATASET_DOWNLOAD_SUBMIT ||
      action.payload.label === "itemContainer/dataset/download/submit"
    ) {
      let name = action.payload.extra.fileSave.name;
      if (action.payload?.extra?.responseHeaders?.partialcontent) {
        name += " - partial";
      }
      let extension = action.payload.extra.fileSave.extension;
      let type = action.payload.extra.fileSave.type;

      FileSaver.saveAs(
        new Blob(
          action.payload?.extra?.stringifyResponse
            ? [JSON.stringify(action.payload.response, null, 2)]
            : [action.payload.response],
          {type: type}
        ),
        `${name}.${extension}`
      );
    }
  }

  return result;
};

export default fileSaveMiddleware;
