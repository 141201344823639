import {Reducer} from "redux";
import {IHubNode} from "../../model/IHubNode";
import {NODE_CLEAR, NODE_FETCH, NODE_FETCH_ITEM_CONTAINERS} from "./nodeActions";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";

export type NodeState = IHubNode | null;

const nodeReducer: Reducer<NodeState> = (state = null, action) => {
  switch (action.type) {
    case NODE_CLEAR: {
      return null;
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          return null;
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          return action.payload.response;
        }
        case NODE_FETCH_ITEM_CONTAINERS: {
          return {
            ...state,
            itemContainers: {
              ...(state?.itemContainers || {}),
              [action.payload.extra.type]: action.payload.response
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case NODE_FETCH: {
          return state;
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default nodeReducer;
