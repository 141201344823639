import React, {Fragment} from "react";
import {connect} from "react-redux";
import DeleteConflictModal from "../components/delete-conflict-modal";
import ErrorSnackbar from "../components/error-snackbar/ErrorSnackbar";
import NotImplementedSnackbar from "../components/not-implemented-snackbar/NotImplementedSnackbar";
import UserLoginDialog from "../components/user-login-dialog";
import UserSetPasswordDialog from "../components/user-set-password-dialog";
import Call from "../hocs/call";
import {fetchHub, fetchHubItemContainers} from "../state/hub/hubActions";
import {fetchUser, hideUserLoginForm, showUserRegisterModal} from "../state/user/userActions";

const mapStateToProps = state => ({
  hub: state.hub,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  fetchHub: () => dispatch(fetchHub()),
  onLoginHide: () => dispatch(hideUserLoginForm()),
  onLogin: (email, password) => dispatch(fetchUser(email, password)),
  onRegisterModalShow: () => dispatch(showUserRegisterModal()),
  fetchHubItemContainers: type => dispatch(fetchHubItemContainers(type))
});

const HubDomain = ({hub, user, fetchHub, onLoginHide, onLogin, onRegisterModalShow, children}) => {
  return (
    <Call cb={() => fetchHub()} disabled={hub !== null}>
      {hub && (
        <Fragment>
          <NotImplementedSnackbar />
          <ErrorSnackbar />
          <DeleteConflictModal />
          <UserSetPasswordDialog />
          <UserLoginDialog
            open={user.isLoginDialogOpen}
            disableRegistration={hub.disableRegistration || false}
            onSubmit={({email, password}) => onLogin(email, password)}
            onRegister={onRegisterModalShow}
            onHide={onLoginHide}
            alert={user.message}
            alertSeverity={user.messageSeverity}
            loginOrHome={user.loginOrHome}
          />
          {children}
        </Fragment>
      )}
    </Call>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HubDomain);
