import React, {useState} from "react";
import {IconButton, InputAdornment, TextField, TextFieldProps} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const PasswordInput = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={e => e.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        autoComplete: "off"
      }}
      {...props}
    />
  );
};

export default PasswordInput;
