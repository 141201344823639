import _ from "lodash";
import {ViewTemplateDto} from "../../model/item-containers-models/viewTemplateDto";
import {
  getCreateDashboardUrl,
  getCreateItemContainerUrl,
  getDashboardsUrl,
  getDatasetUrl,
  getDeleteDashboardUrl,
  getDeleteItemContainerUrl,
  getDeleteViewUrl,
  getItemContainerNodeView,
  getItemContainersUrl,
  getUpdateDashboardUrl,
  getUpdateItemContainerUrl,
  getUserDashboardsUrl,
  getUserItemContainersUrl,
  getViewsUrl
} from "../../serverApi/urls";
import {external} from "../../middlewares/external-service-redirect-middleware/middleware";
import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY
} from "../../utils/dashboards";
import {
  ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY,
  ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW,
  ITEMCONTAINER_ELEM_VALUE_KEY
} from "../../utils/itemContainers";
import config from "../../theme-config/config.json";

export const OTHER_CONFIG_VIEWS_FETCH = "otherConfig/fetchViews";
export const OTHER_CONFIG_VIEWS_CLEAR = "otherConfig/clearViews";
export const OTHER_CONFIG_VIEW_DELETE = "otherConfig/deleteView";
export const OTHER_CONFIG_DASHBOARD_VIEWTEMPLATE_UPDATE = "otherConfig/dashboard/viewTemplateUpdate";

export const OTHER_CONFIG_DASHBOARDS_FETCH = "otherConfig/fetchDashboards";
export const OTHER_CONFIG_DASHBOARDS_CLEAR = "otherConfig/clearDashboards";
export const OTHER_CONFIG_DASHBOARDS_CLOSE = "otherConfig/closeDashboards";

export const OTHER_CONFIG_DASHBOARD_CREATE = "otherConfig/dashboard/create";
export const OTHER_CONFIG_DASHBOARD_UPDATE = "otherConfig/dashboard/update";
export const OTHER_CONFIG_DASHBOARD_CHANGE = "otherConfig/dashboard/change";
export const OTHER_CONFIG_DASHBOARD_CREATE_SUBMIT = "otherConfig/dashboard/create/submit";
export const OTHER_CONFIG_DASHBOARD_UPDATE_SUBMIT = "otherConfig/dashboard/update/submit";
export const OTHER_CONFIG_DASHBOARD_HIDE = "otherConfig/dashboard/hide";
export const OTHER_CONFIG_DASHBOARD_DELETE = "otherConfig/dashboard/create";

export const OTHER_CONFIG_DASHBOARD_VIEWS_FETCH = "otherConfig/dashboard/views/fetch";
export const OTHER_CONFIG_DASHBOARD_VIEWS_CLEAR = "otherConfig/dashboard/views/clear";

export const OTHER_CONFIG_DASHBOARD_DATASET_FETCH = "otherConfig/dashboard/dataset/fetch";
export const OTHER_CONFIG_DASHBOARD_DATASET_FILTER_SET = "otherConfig/dashboard/dataset/filter/set";
export const OTHER_CONFIG_DASHBOARD_DATASET_CLEAR = "otherConfig/dashboard/dataset/clear";

export const OTHER_CONFIG_ITEMCONTAINERS_FETCH = "otherConfig/fetchItemContainers";
export const OTHER_CONFIG_ITEMCONTAINERS_CLEAR = "otherConfig/clearItemContainers";
export const OTHER_CONFIG_ITEMCONTAINERS_CLOSE = "otherConfig/closeItemContainers";

export const OTHER_CONFIG_ITEMCONTAINER_CREATE = "otherConfig/itemContainer/create";
export const OTHER_CONFIG_ITEMCONTAINER_UPDATE = "otherConfig/itemContainer/update";
export const OTHER_CONFIG_ITEMCONTAINER_CHANGE = "otherConfig/itemContainer/change";
export const OTHER_CONFIG_ITEMCONTAINER_CREATE_SUBMIT = "otherConfig/itemContainer/create/submit";
export const OTHER_CONFIG_ITEMCONTAINER_UPDATE_SUBMIT = "otherConfig/itemContainer/update/submit";
export const OTHER_CONFIG_ITEMCONTAINER_HIDE = "otherConfig/itemContainer/hide";
export const OTHER_CONFIG_ITEMCONTAINER_DELETE = "otherConfig/itemContainer/create";
export const OTHER_CONFIG_ITEMCONTAINER_VIEWS_FETCH = "otherConfig/itemContainer/views/fetch";
export const OTHER_CONFIG_ITEMCONTAINER_DATASET_CLEAR = "otherConfig/itemContainer/dataset/clear";

export const fetchOtherConfigViews = () =>
  initRequest(OTHER_CONFIG_VIEWS_FETCH, getViewsUrl(), undefined, undefined, t => ({
    onStart: t("scenes.viewsConfig.messages.fetchViews.start")
  }));

export const clearOtherConfigViews = () => ({
  type: OTHER_CONFIG_VIEWS_CLEAR
});

export const deleteOtherConfigView = (nodeId: number, id: number) =>
  initRequest(OTHER_CONFIG_VIEW_DELETE, getDeleteViewUrl(nodeId, id), RequestMethod.DELETE, undefined, t => ({
    onStart: t("scenes.viewsConfig.messages.deleteView.start")
  }));

export const fetchOtherConfigDashboards = () =>
  initRequest(OTHER_CONFIG_DASHBOARDS_FETCH, getUserDashboardsUrl(), undefined, undefined, t => ({
    onStart: t("scenes.dashboardsSettings.messages.fetchDashboards.start")
  }));

export const clearOtherConfigDashboards = () => ({
  type: OTHER_CONFIG_DASHBOARDS_CLEAR
});

export const closeOtherConfigDashboard = () => ({
  type: OTHER_CONFIG_DASHBOARDS_CLOSE
});

export const createOtherConfigDashboard = (dashboard: any) => ({
  type: OTHER_CONFIG_DASHBOARD_CREATE,
  payload: {
    dashboard: dashboard
  }
});

export const updateOtherConfigDashboard = (dashboardId: number) =>
  external(
    initRequest(OTHER_CONFIG_DASHBOARD_UPDATE, getDashboardsUrl(dashboardId), undefined, undefined, t => ({
      onStart: t("scenes.dashboardsSettings.messages.fetchDashboard.start")
    })),
    config.enableNewDashboard ? "dashboard" : null
  );

export const changeOtherConfigDashboard = (dashboard: any) => ({
  type: OTHER_CONFIG_DASHBOARD_CHANGE,
  payload: {
    dashboard: dashboard
  }
});

const getNewDashboard = (dashboard: any, dashboardId: number | null, dashboardConfig: any) => {
  const newDashboard = _.cloneDeep(dashboard);
  const ids: string[] = [];

  newDashboard.dashboardConfig.forEach((row: any, rowIdx: number) => {
    row.forEach((col: any, colIdx: number) => {
      const type = col[DASHBOARD_ELEM_TYPE_KEY];
      const value = col[DASHBOARD_ELEM_VALUE_KEY];
      const filterDimension = col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY];

      if (type === DASHBOARD_ELEM_TYPE_VALUE_VIEW && value !== null && value !== undefined && !ids.includes(value)) {
        ids.push(value);
      }
      if (filterDimension === "") {
        newDashboard.dashboardConfig[rowIdx][colIdx].filterDimension = null;
      }
    });
  });

  let newFilterLevels = {};
  (dashboardConfig?.labels || []).forEach((label: string) => {
    newFilterLevels = {
      ...newFilterLevels,
      [label]: newDashboard.filterLevels[label] || false
    };
  });

  return {
    dashboardId: dashboardId,
    title: newDashboard.title,
    dashboardConfig: JSON.stringify(newDashboard.dashboardConfig),
    viewIds: ids,
    filterLevels: JSON.stringify(newFilterLevels)
  };
};

export const submitOtherConfigDashboardCreate = (dashboard: any, dashboardConfig: any) =>
  external(
    initRequest(
      OTHER_CONFIG_DASHBOARD_CREATE_SUBMIT,
      getCreateDashboardUrl(),
      RequestMethod.POST,
      getNewDashboard(dashboard, null, dashboardConfig),
      t => ({
        onStart: t("scenes.dashboardsSettings.messages.submitDashboardCreate.start")
      })
    ),
    config.enableNewDashboard ? "dashboard" : null
  );

export const submitOtherConfigDashboardUpdate = (dashboardId: number, dashboard: any, dashboardConfig: any) =>
  external(
    initRequest(
      OTHER_CONFIG_DASHBOARD_UPDATE_SUBMIT,
      getUpdateDashboardUrl(dashboardId),
      RequestMethod.PUT,
      getNewDashboard(dashboard, dashboardId, dashboardConfig),
      t => ({
        onStart: t("scenes.dashboardsSettings.messages.submitDashboardUpdate.start")
      }),
      {
        dashboardId: dashboardId
      }
    ),
    config.enableNewDashboard ? "dashboard" : null
  );

export const deleteOtherConfigDashboard = (dashboardId: number) =>
  external(
    initRequest(
      OTHER_CONFIG_DASHBOARD_DELETE,
      getDeleteDashboardUrl(dashboardId),
      RequestMethod.DELETE,
      undefined,
      t => ({
        onStart: t("scenes.dashboardsSettings.messages.deleteDashboard.start")
      })
    ),
    config.enableNewDashboard ? "dashboard" : null
  );

export const hideOtherConfigDashboard = () => ({
  type: OTHER_CONFIG_DASHBOARD_HIDE
});

export const fetchOtherConfigDashboardViews = () =>
  initRequest(OTHER_CONFIG_DASHBOARD_VIEWS_FETCH, getViewsUrl(), undefined, undefined, t => ({
    onStart: t("scenes.dashboardsSettings.messages.fetchDashboardViews.start")
  }));

export const clearOtherConfigDashboardViews = () => ({
  type: OTHER_CONFIG_DASHBOARD_VIEWS_CLEAR
});

export const fetchOtherConfigDashboardsDataset = (
  nodeId: number,
  datasetId: string,
  criteria: any,
  requestIds: string[]
) =>
  initRequest(
    OTHER_CONFIG_DASHBOARD_DATASET_FETCH,
    getDatasetUrl(nodeId, datasetId),
    RequestMethod.POST,
    getCriteriaArrayFromObject(criteria),
    t => ({
      onStart: t("scenes.dashboard.actions.fetchingDataset")
    }),
    {
      requestIds: requestIds
    },
    "",
    () => true,
    true
  );

export const setOtherConfigDashboardsDatasetFilter = (viewIdx: string, layout: any) => ({
  type: OTHER_CONFIG_DASHBOARD_DATASET_FILTER_SET,
  payload: {
    viewIdx,
    layout
  }
});

export const clearOtherConfigDashboardsDataset = () => ({
  type: OTHER_CONFIG_DASHBOARD_DATASET_CLEAR
});

export const fetchOtherConfigItemContainers = (type: string) =>
  external(
    initRequest(OTHER_CONFIG_ITEMCONTAINERS_FETCH, getUserItemContainersUrl(type), undefined, undefined, t => ({
      onStart:
        type === "dashboard"
          ? t("scenes.dashboardsSettings.messages.fetchDashboards.start")
          : t("scenes.customPagesSettings.messages.fetchCustomPages.start")
    })),
    type === "dashboard" ? "dashboard" : "customPages"
  );

export const clearOtherConfigItemContainers = () => ({
  type: OTHER_CONFIG_ITEMCONTAINERS_CLEAR
});

export const closeOtherConfigItemContainer = () => ({
  type: OTHER_CONFIG_ITEMCONTAINERS_CLOSE
});

export const createOtherConfigItemContainer = (itemContainer: any) => ({
  type: OTHER_CONFIG_ITEMCONTAINER_CREATE,
  payload: {
    itemContainer: itemContainer
  }
});

export const updateOtherConfigItemContainer = (itemContainerId: number, type: string) =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_UPDATE,
      getItemContainersUrl(itemContainerId, type === "dashboard"),
      undefined,
      undefined,
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.fetchDashboard.start")
            : t("scenes.customPagesSettings.messages.fetchCustomPage.start")
      })
    ),
    type === "dashboard" ? "dashboard" : "customPages"
  );

export const changeOtherConfigItemContainer = (itemContainer: any) => ({
  type: OTHER_CONFIG_ITEMCONTAINER_CHANGE,
  payload: {
    itemContainer: itemContainer
  }
});

export const updateViewSelectedTemplate = (view: ViewTemplateDto, rowidx: number, colIdx: number) =>
  initRequest(
    OTHER_CONFIG_DASHBOARD_VIEWTEMPLATE_UPDATE,
    getItemContainerNodeView(view.nodeId, view.viewTemplateId),
    undefined,
    undefined,
    t => ({
      onStart: t("scenes.itemContainersSettings.messages.updateViewSelectedTemplate.start")
    }),
    {
      rowIdx: rowidx,
      colIdx: colIdx
    }
  );

const getNewItemContainer = (itemContainer: any, item: any) => {
  const newItemContainer = _.cloneDeep(itemContainer);
  const ids: string[] = [];
  let counterIndex = 0;
  newItemContainer.item.forEach((row: any, rowIdx: number) => {
    row.forEach((col: any, colIdx: number) => {
      counterIndex = counterIndex + rowIdx + colIdx;
      const type = col.type;
      const value = col[ITEMCONTAINER_ELEM_VALUE_KEY];
      const filterDimension = col[ITEMCONTAINER_ELEM_FILTER_DIMENSION_KEY];
      if (
        (type === ITEMCONTAINER_ELEM_TYPE_VALUE_VIEW || type === ITEMCONTAINER_ELEM_TYPE_VALUE_CATEGORY) &&
        value !== null &&
        value !== undefined &&
        !ids.includes(value)
      ) {
        ids.push(value);
      }
      if (filterDimension === "") {
        newItemContainer.item[rowIdx][colIdx].filterDimension = null;
      }
    });
  });
  let newFilterLevels = {};
  (item?.labels || []).forEach((label: string) => {
    newFilterLevels = {
      ...newFilterLevels,
      [label]: newItemContainer.filterLevels[label] || false
    };
  });

  let newItemContainerItem = newItemContainer.item;
  for (var row = 0; row < newItemContainerItem.length; row++) {
    for (var col = 0; col < newItemContainerItem[row].length; col++) {
      newItemContainerItem[row][col].row = row;
      newItemContainerItem[row][col].column = col;
      if (newItemContainerItem[row][col].type === "itemViewDto")
        newItemContainerItem[row][col].type = "itemViewTemplateDto";
      if (
        newItemContainerItem[row][col].type === "itemCategoryTemplateDto" ||
        newItemContainerItem[row][col].type === "itemCategoryDto"
      ) {
        newItemContainerItem[row][col].type = "itemCategoryTemplateDto";
      }
      if (newItemContainerItem[row][col].type === "itemRichTextDTO") {
        newItemContainerItem[row][col].data = newItemContainerItem[row][col].id;
        delete newItemContainerItem[row][col].idM;
      }
    }
  }
  return {
    ...newItemContainer,
    title: newItemContainer.title,
    item: newItemContainerItem,
    type: newItemContainer.type,
    hubId: 1,
    weight: 0,
    configNodes: newItemContainer.configNodes
  };
};

export const submitOtherConfigItemContainerCreate = (itemContainer: any, item: any, type: string) =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_CREATE_SUBMIT,
      getCreateItemContainerUrl(),
      RequestMethod.POST,
      getNewItemContainer(itemContainer, item),
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.submitDashboardCreate.start")
            : t("scenes.customPagesSettings.messages.submitCustomPageCreate.start")
      })
    ),
    type === "dashboard" ? "dashboard" : "customPages"
  );

export const submitOtherConfigItemContainerUpdate = (
  itemContainerId: number,
  itemContainer: any,
  item: any,
  type: string
) =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_UPDATE_SUBMIT,
      getUpdateItemContainerUrl(itemContainerId),
      RequestMethod.PUT,
      getNewItemContainer(itemContainer, item),
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.submitDashboardUpdate.start")
            : t("scenes.customPagesSettings.messages.submitCustomPageUpdate.start")
      }),
      {
        itemContainerId: itemContainerId
      }
    ),
    type === "dashboard" ? "dashboard" : "customPages"
  );

export const deleteOtherConfigItemContainer = (itemContainerId: number, type: string) =>
  external(
    initRequest(
      OTHER_CONFIG_ITEMCONTAINER_DELETE,
      getDeleteItemContainerUrl(itemContainerId),
      RequestMethod.DELETE,
      undefined,
      t => ({
        onStart:
          type === "dashboard"
            ? t("scenes.dashboardsSettings.messages.deleteDashboard.start")
            : t("scenes.customPagesSettings.messages.deleteCustomPage.start")
      })
    ),
    type === "dashboard" ? "dashboard" : "customPages"
  );

export const hideOtherConfigItemContainer = () => ({
  type: OTHER_CONFIG_ITEMCONTAINER_HIDE
});

export const fetchOtherConfigItemContainerViews = (type: string) =>
  initRequest(OTHER_CONFIG_ITEMCONTAINER_VIEWS_FETCH, getViewsUrl(), undefined, undefined, t => ({
    onStart:
      type === "dashboard"
        ? t("scenes.dashboardsSettings.messages.fetchDashboardViews.start")
        : t("scenes.customPagesSettings.messages.fetchCustomPageViews.start")
  }));

export const clearOtherConfigItemContainersDataset = () => ({
  type: OTHER_CONFIG_ITEMCONTAINER_DATASET_CLEAR
});
