import {Middleware} from "@reduxjs/toolkit";
import {initRequest, REQUEST_INIT} from "../request/requestActions";
import {RootState} from "../../state/rootReducer";

const EXTERNAL = Symbol();

/**
 * Redirects the request to the specified external service.
 * If no match is found with the external service registry in config.json or if null/undefined is passed
 * the base URL will be set to the default (also specified in the config.json file)
 * @returns Action that will be redirected to the specified external service once dispatched
 */
export const external = (requestAction: ReturnType<typeof initRequest>, externalService: string) => ({
  ...requestAction,
  [EXTERNAL]: externalService
});

const middleware: Middleware<{}, RootState> =
  ({dispatch, getState}) =>
  next =>
  action => {
    if (action.type === REQUEST_INIT && !!action[EXTERNAL]) {
      const {baseURL, externalServices} = getState().config;
      return next({...action, payload: {...action.payload, baseURL: externalServices[action[EXTERNAL]] || baseURL}});
    }

    return next(action);
  };

export default middleware;
