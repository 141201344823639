import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import CustomEmpty from "../custom-empty";
import {useItemContainerFilters} from "../item-containers/ItemContainerFilters";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../temporal-dim-order-selector/constants";
import {useViewDataset} from "./useViewDataset";
import ViewItemBody from "./ViewItemBody";
import ViewItemHeading from "./ViewItemHeading";
import useLanguages from "../../state/hooks/useLanguages";
import {hubSelector} from "../../state/hub/hubSelectors";
import {getUpdatedLayout} from "../../utils/dataset";
import {getNodeExtras, handleStyle, makeViewData} from "./utils";

declare global {
  interface Window {
    LMap: any;
  }
}

interface ViewItemProps {
  itemContainerElem: ItemViewTemplateDto;
  viewIdx: string;
  minItemContainerWidth: number;
  itemContainer: ItemContainerDto;
  mapId: string;
}
const ViewItem = ({itemContainerElem, viewIdx, itemContainer, minItemContainerWidth, mapId}: ViewItemProps) => {
  const itemContainerId = itemContainer.itemContainerId;
  const {t} = useLanguages();
  const hub = useSelector(hubSelector);
  const [internalFilters, setInternalFilters] = useState<{dimension: string; value: any}>(null);
  const chartId = useRef("chart__" + uuidv4());
  const pageFilterValue = useItemContainerFilters();
  const {data, error, loading} = useViewDataset(itemContainerElem, itemContainer.type);

  const view = itemContainerElem.viewTemplate;
  const nodeExtras = getNodeExtras(hub?.nodes, view);

  const {
    jsonStat,
    filterTrees: filterTree,
    layouts: layoutObj,
    timePeriodsByFreq
  } = makeViewData(itemContainerElem, data, itemContainerElem.viewTemplate.criteria);

  const layout = useMemo(() => {
    if (layoutObj === null || layoutObj === undefined || jsonStat === null || jsonStat === undefined) {
      return null;
    } else {
      let layout = {...layoutObj.layout};
      const filterDim = itemContainerElem.filterDimension;
      if (filterDim && pageFilterValue) {
        if ((layout.filters || []).includes(filterDim)) {
          layout.filtersValue = {
            ...layout.filtersValue,
            [filterDim]: pageFilterValue
          };
        } else if ((layout.primaryDim || []).includes(filterDim)) {
          layout.primaryDimValues = [pageFilterValue];
        } else if ((layout.secondaryDim || []).includes(filterDim)) {
          layout.secondaryDimValues = [pageFilterValue];
        }
      }
      return internalFilters
        ? getUpdatedLayout(internalFilters.dimension, internalFilters.value, layout, jsonStat, filterTree)
        : layout;
    }
  }, [itemContainerElem, jsonStat, layoutObj, pageFilterValue, internalFilters, filterTree]);

  useEffect(() => {
    handleStyle(itemContainerId, viewIdx, itemContainerElem, minItemContainerWidth);
  }, [data, itemContainerId, viewIdx, itemContainerElem, minItemContainerWidth]);

  if (data && loading) {
    return <CustomEmpty text={t("components.itemContainer.applyFilters")} />;
  } else if (loading) {
    return <CustomEmpty text={t("components.itemContainer.fetching") + "..."} image={<CircularProgress />} />;
  } else if (error) {
    return <CustomEmpty text={t("components.itemContainer.fetchingDatasetError")} />;
  } else if (!data) {
    return <CustomEmpty text={t("components.itemContainer.emptyView")} />;
  } else if (layoutObj) {
    try {
      const timeDim = jsonStat.role?.time?.[0] || null;
      const invertedDims =
        layoutObj.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && !layout.filters.includes(timeDim)
          ? [timeDim]
          : null;

      return (
        <Fragment>
          <ViewItemHeading
            item={itemContainerElem}
            defaultTitle={data.label}
            jsonStat={jsonStat}
            layout={layout}
            layoutObj={layoutObj}
            chartId={chartId.current}
            mapId={mapId}
            filterTree={filterTree}
            itemContainer={itemContainer}
            nodeExtras={nodeExtras}
            onFilterSet={setInternalFilters}
            timePeriodsByFreq={timePeriodsByFreq}
            viewIdx={viewIdx}
          />
          <ViewItemBody
            item={itemContainerElem}
            itemContainer={itemContainer}
            jsonStat={jsonStat}
            layout={layout}
            hierarchyOnlyAttributes={nodeExtras?.HierarchyOnlyAttributes || []}
            hideHierarchyOnlyRows={nodeExtras?.HideHierarchyOnlyRows || false}
            invertedDims={invertedDims}
            layoutObj={layoutObj}
            timePeriodsByFreq={timePeriodsByFreq}
            viewIdx={viewIdx}
            minItemContainerWidth={minItemContainerWidth}
            mapId={mapId}
            chartId={chartId.current}
          />
        </Fragment>
      );
    } catch (e) {
      return <CustomEmpty text={t("components.itemContainer.genericError")} />;
    }
  } else {
    return <span />;
  }
};

export default ViewItem;
