import React, {Fragment, useMemo} from "react";
import {createTheme, CssBaseline} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Color from "color";
import _ from "lodash";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import {compose} from "redux";
import ErrorBoundary from "../error-boundary";
import Spinner from "../spinner/Spinner";
import {DEFAULT_ALL1_THEME, DEFAULT_THEME} from "../../utils/defaultThemes";

const styles = theme => ({
  appContainer: {
    width: "100%",
    height: "100%"
  },
  fullscreenContainer: {
    display: "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#f5f5f5",
    zIndex: 1299,
    borderRadius: "unset",
    boxShadow: "unset",
    "& > *": {
      width: "100% !important",
      height: "100% !important",
      padding: "16px !important",
      margin: "0 !important"
    }
  }
});

const mapStateToProps = state => ({
  colorSchemes: state.appConfig.colorSchemes,
  a11yColorSchemes: state.appConfig.a11yColorSchemes,
  isA11y: state.app.isA11y,
  user: state.user,
  hub: state.hub?.hub,
  nodes: state.hub?.nodes
});

const WithCustomTheme = ({classes, colorSchemes, a11yColorSchemes, isA11y, user, nodeCode, children, hub, nodes}) => {
  const getFullPaletteFromCustomColors = palette => {
    return {
      palette: {
        text: palette?.text?.hasOwnProperty("primary")
          ? palette?.text
          : {
              primary: palette?.text
            },
        primary: {
          main: palette?.primary?.main,
          contrastText: palette?.primary?.contrastText,
          dark: Color(palette?.primary?.main).darken(0.5).hex(),
          light: Color(palette?.primary?.main).lighten(0.5).hex()
        },
        secondary: {
          main: palette?.secondary?.main,
          contrastText: palette?.secondary?.contrastText,
          dark: Color(palette?.secondary?.main).darken(0.5).hex(),
          light: Color(palette?.secondary?.main).lighten(0.5).hex()
        }
      }
    };
  };

  const theme = useMemo(() => {
    let newTheme = _.cloneDeep(DEFAULT_THEME);

    if (colorSchemes?.hub) {
      newTheme = _.merge(newTheme, colorSchemes.hub);
    }
    if (nodeCode && colorSchemes?.nodes[nodeCode]) {
      newTheme = _.merge(newTheme, colorSchemes.nodes?.[nodeCode]);
    }
    if (isA11y) {
      newTheme = _.merge(newTheme, DEFAULT_ALL1_THEME);
      if (a11yColorSchemes?.hub) {
        newTheme = _.merge(newTheme, a11yColorSchemes.hub);
      }
      if (nodeCode && a11yColorSchemes?.nodes?.[nodeCode]) {
        newTheme = _.merge(newTheme, a11yColorSchemes.nodes[nodeCode]);
      }
    }
    if (hub) {
      const customColorsExtra = JSON.parse((hub?.extra || []).find(e => e.key === "CustomColors")?.value || "{}");
      if (customColorsExtra?.isEnabled) {
        newTheme = _.merge(newTheme, getFullPaletteFromCustomColors(customColorsExtra.palette));
      }
    }

    if (nodeCode) {
      const currentNode = nodes?.find(n => n.code.toLowerCase() === nodeCode);
      const customColorsExtra = JSON.parse(
        (currentNode?.extras || []).find(e => e.key === "CustomColors")?.value || "{}"
      );
      if (customColorsExtra?.isEnabled) {
        newTheme = _.merge(newTheme, getFullPaletteFromCustomColors(customColorsExtra.palette));
      }
    }
    return createTheme(newTheme);
  }, [colorSchemes, a11yColorSchemes, nodeCode, isA11y, hub, nodes]);

  return theme ? (
    <Fragment>
      <Helmet>
        {/* eslint-disable-next-line react/style-prop-object */}
        <body
          style={"background-color: " + theme.palette.primary.main}
          authenticated={user.isAuthenticated ? "true" : "false"}
        />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Spinner>
            <div id="fullscreen-container" className={classes.fullscreenContainer} style={{display: "none"}} />
            <div id="app-container" className={classes.appContainer}>
              {children}
            </div>
          </Spinner>
        </ErrorBoundary>
      </ThemeProvider>
    </Fragment>
  ) : null;
};

export default compose(withStyles(styles), connect(mapStateToProps))(WithCustomTheme);
