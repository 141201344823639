import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Box, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {NodeVisibility} from "../../../model/IHubMinimalNode.d.ts";
import CustomDialogTitle from "../../custom-dialog-title";
import CustomMaterialTable from "../../custom-material-table";
import SettingsDialog from "../../settings-dialog";
import AdvancedConfigurationForm from "./advanced-configuration-form/index.tsx";
import CacheSettingsForm from "./cache-setting-form/index";
import DashboardsSettingsForm from "./dashboards-settings-form";
import MergedNodeSettingsForm from "./merged-nodes-setting-form";
import NodeExportForm from "./node-export-form";
import NodeImportDialog from "./node-import-form";
import NodeSettingsForm from "./node-settings-form";
import PermissionsSettingsForm from "./permissions-settings-form";
import TemplatesSettingsForm from "./templates-settings-form";
import {
  clearNodesConfigNodes,
  closeNodesConfig,
  deleteNodesConfigNode,
  fetchNodesConfigNodes,
  sendNodesConfigNodesOrder,
  setNodesConfigMergedNodeDialogVisibility,
  setNodesConfigMergedNodeId,
  setNodesConfigNodeId,
  setNodesConfigNodeVisibility
} from "../../../state/nodesConfig/nodesConfigActions";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";
import {
  canCreateNode,
  canDeleteNodes,
  canDisplayCacheSettingForm,
  canDisplayPermissionsSettingsForm,
  canDisplayTemplatesSettingsForm,
  canEditNode,
  canManageNodeDashboards,
  canSortNodes
} from "../../../utils/user";
import "./style.css";
import themeConfig from "../../../theme-config/config.json";

const styles = theme => ({
  tableToolbar: {
    marginBottom: theme.spacing(1)
  },
  buttonAction: {
    ...theme.typography.button
  },
  rightActionsButton: {
    marginLeft: theme.spacing(1)
  }
});

const mapStateToProps = state => ({
  config: state.nodesConfig.nodes,
  isNodeVisible: state.nodesConfig.isNodeVisible,
  nodeId: state.nodesConfig.nodeId,
  isMergedNodeDialogVisible: state.nodesConfig.isMergedNodeDialogVisible,
  mergedNodeId: state.nodesConfig.mergedNodeId,
  user: state.user,
  language: state.app.language,
  languages: state.app.languages
});

const mapDispatchToProps = dispatch => ({
  setNodeVisibility: visible => dispatch(setNodesConfigNodeVisibility(visible)),
  setNodeId: nodeId => dispatch(setNodesConfigNodeId(nodeId)),
  fetchConfig: () => dispatch(fetchNodesConfigNodes()),
  sendOrders: orderedIds => dispatch(sendNodesConfigNodesOrder(orderedIds)),
  deleteNode: nodeId => dispatch(deleteNodesConfigNode(nodeId)),
  clearConfig: () => dispatch(clearNodesConfigNodes()),
  onClose: () => dispatch(closeNodesConfig()),
  setMergedNodeId: nodeId => dispatch(setNodesConfigMergedNodeId(nodeId)),
  setMergedNodeDialogVisibility: visible => dispatch(setNodesConfigMergedNodeDialogVisibility(visible))
});

const NodesSettingsForm = (
  {
    classes,
    defaultNodeOpen,
    config,
    isNodeVisible,
    nodeId,
    setNodeVisibility,
    setNodeId,
    fetchConfig,
    sendOrders,
    deleteNode,
    clearConfig,
    nodes,
    user,
    onClose,
    language,
    languages,
    onNodesClose,
    isMergedNodeDialogVisible,
    mergedNodeId,
    setMergedNodeId,
    setMergedNodeDialogVisibility
  },
  ref
) => {
  const [needConfig, setNeedConfig] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig();
    }
  }, [config, needConfig, setNeedConfig, fetchConfig]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearConfig();
      onClose();
      f();
    }
  }));

  const [nodeDeleteFormNodeId, setNodeDeleteFormNodeId] = useState(null);
  const [cacheFormNodeId, setCacheFormNodeId] = useState(null);
  const [isCacheFormVisible, setCacheFormVisibility] = useState(false);
  const [templatesNodeId, setTemplatesNodeId] = useState(null);
  const [permissionsNodeId, setPermissionsNodeId] = useState(null);
  const [dashboardsNodeId, setDashboardsNodeId] = useState(null);
  const [isImportDialog, setImportDialog] = useState(false);
  const [exportNodeFormNodeId, setExportNodeFormNodeId] = useState(null);
  const [additionalSettingsNodeId, setAdditionalSettingsNodeId] = useState(null);

  const exportRef = useRef();
  const nodeFormRef = useRef();
  const cacheFormRef = useRef();
  const templatesRef = useRef();
  const permissionsRef = useRef();
  const dashboardsRef = useRef();
  const mergedNodeFormRef = useRef();

  const allowedNodes = (config || []).filter(
    ({nodeId}) =>
      canCreateNode(user) ||
      canSortNodes(user) ||
      canDisplayCacheSettingForm(user, nodeId) ||
      canDisplayTemplatesSettingsForm(user, nodeId) ||
      canDisplayPermissionsSettingsForm(user) ||
      canEditNode(user, nodeId) ||
      canDeleteNodes(user)
  );
  const orderedNodes = (allowedNodes || []).sort((a, b) => a.order - b.order);
  const orderedNodesIds = orderedNodes.map(({nodeId}) => nodeId);

  const moveUp = rowIndex =>
    sendOrders([
      ...orderedNodesIds.slice(0, rowIndex - 1),
      orderedNodesIds[rowIndex],
      orderedNodesIds[rowIndex - 1],
      ...orderedNodesIds.slice(rowIndex + 1)
    ]);

  const moveDown = rowIndex =>
    sendOrders([
      ...orderedNodesIds.slice(0, rowIndex),
      orderedNodesIds[rowIndex + 1],
      orderedNodesIds[rowIndex],
      ...orderedNodesIds.slice(rowIndex + 2)
    ]);

  const handleEditNode = (nodeId, type) => {
    if (type === "SDMX-REST") {
      setMergedNodeDialogVisibility(false);
      setMergedNodeId(null);
      setNodeId(nodeId);
      setNodeVisibility(true);
    } else {
      setNodeId(null);
      setNodeVisibility(false);
      setMergedNodeDialogVisibility(true);
      setMergedNodeId(nodeId);
    }
    setCacheFormVisibility(false);
  };

  return (
    config && (
      <Fragment>
        <Box className="nodes-settings-form__table" style={{height: "100%"}} id="nodes-table">
          <CustomMaterialTable
            rightActions={
              canCreateNode(user) ? (
                <>
                  <Button
                    className={classes.rightActionsButton}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setImportDialog(true);
                    }}
                  >
                    {t("scenes.nodesSettings.importNode")}
                  </Button>
                  <Button
                    className={classes.rightActionsButton}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setNodeId(null);
                      setNodeVisibility(true);
                      setCacheFormVisibility(false);
                    }}
                  >
                    {t("scenes.nodesSettings.createNode")}
                  </Button>
                  <Button
                    className={classes.rightActionsButton}
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setMergedNodeId(null);
                      setMergedNodeDialogVisibility(true);
                      setCacheFormVisibility(false);
                    }}
                  >
                    {t("scenes.nodesSettings.createMergedNode")}
                  </Button>
                </>
              ) : null
            }
            columns={[
              {
                field: "code",
                title: t("scenes.nodesSettings.table.columns.nodeCode")
              },
              {
                field: "title",
                title: t("scenes.nodesSettings.table.columns.nodeName"),
                render: ({title}) => localizeI18nObj(title, language, languages),
                customFilterAndSearch: getI18nObjCustomFilterAndSearch(language, languages)
              },
              {
                field: "active",
                title: t("scenes.nodesSettings.table.columns.isNodeActive.title"),
                render: ({active}) =>
                  active
                    ? t("scenes.nodesSettings.table.columns.isNodeActive.values.true")
                    : t("scenes.nodesSettings.table.columns.isNodeActive.values.false")
              },
              {
                field: "visible",
                title: t("scenes.nodesSettings.table.columns.isNodeVisible.title"),
                render: ({visible}) =>
                  visible === NodeVisibility.Yes
                    ? t("scenes.nodesSettings.table.columns.isNodeVisible.values.true")
                    : t("scenes.nodesSettings.table.columns.isNodeVisible.values.false")
              },
              {
                field: "default",
                title: t("scenes.nodesSettings.table.columns.isNodeDefault.title"),
                render: node =>
                  node.default
                    ? t("scenes.nodesSettings.table.columns.isNodeDefault.values.true")
                    : t("scenes.nodesSettings.table.columns.isNodeDefault.values.false")
              }
            ]}
            data={orderedNodes}
            actions={[
              ({tableData}) =>
                canSortNodes(user)
                  ? {
                      icon: ArrowDropUpIcon,
                      tooltip: t("scenes.nodesSettings.table.actions.nodeOrderMoveUp"),
                      onClick: (_, {tableData}) => moveUp(tableData.id), // tableData.id is the rowIndex
                      disabled: tableData.id === 0
                    }
                  : null,
              ({tableData}) =>
                canSortNodes(user)
                  ? {
                      icon: ArrowDropDownIcon,
                      tooltip: t("scenes.nodesSettings.table.actions.nodeOrderMoveDown"),
                      onClick: (_, {tableData}) => moveDown(tableData.id), // tableData.id is the rowIndex
                      disabled: tableData.id === config.length - 1
                    }
                  : null,
              ({nodeId, type}) =>
                canDisplayCacheSettingForm(user, nodeId)
                  ? {
                      icon: () => (
                        <div className={classes.buttonAction}>
                          {t("scenes.nodesSettings.table.actions.nodeDataflowsCache.label")}
                        </div>
                      ),
                      tooltip: t("scenes.nodesSettings.table.actions.nodeDataflowsCache.tooltip"),
                      onClick: (_, {nodeId, type}) => {
                        setCacheFormNodeId(nodeId);
                        setNodeVisibility(false);
                        setCacheFormVisibility(true);
                      },
                      disabled: type !== "SDMX-REST"
                    }
                  : null,
              ({nodeId}) =>
                canDisplayTemplatesSettingsForm(user, nodeId)
                  ? {
                      icon: () => (
                        <div className={classes.buttonAction}>
                          {t("scenes.nodesSettings.table.actions.nodeTemplates.label")}
                        </div>
                      ),
                      tooltip: t("scenes.nodesSettings.table.actions.nodeTemplates.tooltip"),
                      onClick: (_, {nodeId}) => {
                        setTemplatesNodeId(nodeId);
                      }
                    }
                  : null,
              () =>
                canDisplayPermissionsSettingsForm(user)
                  ? {
                      icon: PersonIcon,
                      tooltip: t("scenes.nodesSettings.table.actions.nodePermissions"),
                      onClick: (_, {nodeId}) => {
                        setPermissionsNodeId(nodeId);
                      }
                    }
                  : null,
              ({nodeId}) =>
                canManageNodeDashboards(user, nodeId) && themeConfig.enableDashboard
                  ? {
                      icon: DashboardIcon,
                      tooltip: t("scenes.nodesSettings.table.actions.nodeDashboards"),
                      onClick: (_, {nodeId}) => {
                        setDashboardsNodeId(nodeId);
                      }
                    }
                  : null,
              ({nodeId}) =>
                canEditNode(user, nodeId)
                  ? {
                      icon: EditIcon,
                      tooltip: t("scenes.nodesSettings.table.actions.editNode"),
                      onClick: (_, {nodeId, type}) => {
                        handleEditNode(nodeId, type);
                      }
                    }
                  : null,
              ({type, nodeId}) =>
                canEditNode(user, nodeId)
                  ? {
                      icon: SettingsIcon,
                      tooltip: t("scenes.nodesSettings.table.actions.advancedConfiguration"),
                      onClick: (_, {nodeId}) => {
                        setAdditionalSettingsNodeId(nodeId);
                      },
                      disabled: type !== "SDMX-REST"
                    }
                  : null,
              () =>
                canDeleteNodes(user)
                  ? {
                      icon: DeleteIcon,
                      tooltip: t("scenes.nodesSettings.table.actions.deleteNode"),
                      onClick: (_, {nodeId}) => setNodeDeleteFormNodeId(nodeId)
                    }
                  : null,
              ({type}) => ({
                icon: GetAppIcon,
                tooltip: t("scenes.nodesSettings.table.actions.exportNode"),
                onClick: (_, {nodeId}) => setExportNodeFormNodeId(nodeId),
                disabled: type !== "SDMX-REST"
              })
            ]}
            options={{
              actionsColumnIndex: 5
            }}
          />
        </Box>
        <SettingsDialog
          title={
            nodeId === null ? t("scenes.nodesSettings.modals.createNode") : t("scenes.nodesSettings.modals.editNode")
          }
          open={isNodeVisible}
          onClose={() => {
            if (nodeFormRef.current) {
              nodeFormRef.current.cancel(() => {
                setNodeVisibility(false);
                setNodeId(null);
              });
            } else {
              setNodeVisibility(false);
              setNodeId(null);
            }
          }}
          onSubmit={() => {
            if (nodeFormRef.current) {
              nodeFormRef.current.submit(() => {
                // setNodeVisibility(false);
                // setNodeId(null);
              });
            } else {
              // setNodeVisibility(false);
              // setNodeId(null);
            }
          }}
          hasSubmit
        >
          <NodeSettingsForm ref={nodeFormRef} nodeId={nodeId} />
        </SettingsDialog>

        <SettingsDialog
          title={
            mergedNodeId === null
              ? t("scenes.nodesSettings.modals.createMergedNode")
              : t("scenes.nodesSettings.modals.editMergedNode")
          }
          open={isMergedNodeDialogVisible}
          onClose={() => {
            if (mergedNodeFormRef.current) {
              mergedNodeFormRef.current.cancel(() => {
                setMergedNodeDialogVisibility(false);
                setMergedNodeId(null);
              });
            } else {
              setMergedNodeDialogVisibility(false);
              setMergedNodeId(null);
            }
          }}
          onSubmit={() => {
            if (mergedNodeFormRef.current) {
              mergedNodeFormRef.current.submit(() => {});
            }
          }}
          hasSubmit
        >
          <MergedNodeSettingsForm ref={mergedNodeFormRef} nodeId={mergedNodeId} />
        </SettingsDialog>

        {isImportDialog && <NodeImportDialog open={isImportDialog} onClose={() => setImportDialog(false)} />}
        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodeCache", {
            nodeCode: config.find(node => node.nodeId === cacheFormNodeId)?.code
          })}
          open={isCacheFormVisible}
          onClose={() => {
            if (cacheFormRef.current) {
              cacheFormRef.current.cancel(() => {
                setCacheFormVisibility(false);
                setCacheFormNodeId(null);
              });
            } else {
              setCacheFormVisibility(false);
              setCacheFormNodeId(null);
            }
          }}
          onSubmit={() => {
            if (cacheFormRef.current) {
              cacheFormRef.current.submit(() => {
                setCacheFormVisibility(false);
                setCacheFormNodeId(null);
              });
            } else {
              setCacheFormVisibility(false);
              setCacheFormNodeId(null);
            }
          }}
          false
        >
          <CacheSettingsForm ref={cacheFormRef} nodeId={cacheFormNodeId} />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodeTemplates", {
            nodeCode: config.find(node => node.nodeId === templatesNodeId)?.code
          })}
          open={templatesNodeId !== null}
          onClose={() => {
            if (templatesRef.current) {
              templatesRef.current.cancel(() => setTemplatesNodeId(null));
            } else {
              setTemplatesNodeId(null);
            }
          }}
        >
          <TemplatesSettingsForm
            ref={templatesRef}
            nodeId={templatesNodeId}
            nodes={config}
            onTemplatesClose={() => {
              setTemplatesNodeId(null);
              onNodesClose();
            }}
          />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodePermissions", {
            nodeCode: config.find(node => node.nodeId === permissionsNodeId)?.code
          })}
          open={permissionsNodeId !== null}
          onClose={() => {
            if (permissionsRef.current) {
              permissionsRef.current.cancel(() => {
                setPermissionsNodeId(null);
              });
            } else {
              setPermissionsNodeId(null);
            }
          }}
          onSubmit={() => {
            if (permissionsRef.current) {
              permissionsRef.current.submit(() => {
                setPermissionsNodeId(null);
              });
            } else {
              setPermissionsNodeId(null);
            }
          }}
          hasSubmit
        >
          <PermissionsSettingsForm ref={permissionsRef} nodeId={permissionsNodeId} />
        </SettingsDialog>

        <SettingsDialog
          title={t("scenes.nodesSettings.modals.nodeDashboards", {
            nodeCode: config.find(node => node.nodeId === dashboardsNodeId)?.code
          })}
          open={dashboardsNodeId !== null && themeConfig.enableDashboard}
          onClose={() => {
            if (dashboardsRef.current) {
              dashboardsRef.current.destroy(() => {
                setDashboardsNodeId(null);
              });
            } else {
              setDashboardsNodeId(null);
            }
          }}
          onSubmit={() => {
            if (dashboardsRef.current) {
              dashboardsRef.current.destroy(() => {
                setDashboardsNodeId(null);
              });
            } else {
              setDashboardsNodeId(null);
            }
          }}
        >
          <DashboardsSettingsForm ref={dashboardsRef} nodeId={dashboardsNodeId} />
        </SettingsDialog>

        {exportNodeFormNodeId && (
          <NodeExportForm
            ref={exportRef}
            nodeCode={config.find(node => node.nodeId === exportNodeFormNodeId)?.code}
            onClose={() => {
              if (exportRef.current) {
                exportRef.current.cancel(() => {
                  setExportNodeFormNodeId(null);
                });
              } else {
                setExportNodeFormNodeId(null);
              }
            }}
          />
        )}

        {additionalSettingsNodeId && (
          <AdvancedConfigurationForm
            nodeId={additionalSettingsNodeId}
            onClose={() => setAdditionalSettingsNodeId(null)}
          />
        )}

        <Dialog
          maxWidth="xs"
          open={nodeDeleteFormNodeId !== null}
          onClose={() => {
            setNodeDeleteFormNodeId(null);
            setCacheFormNodeId(null);
          }}
        >
          <CustomDialogTitle
            onClose={() => {
              setNodeDeleteFormNodeId(null);
              setCacheFormNodeId(null);
            }}
          >
            {t("scenes.nodesSettings.modals.nodeDelete.title")}
          </CustomDialogTitle>
          <DialogContent>{t("scenes.nodesSettings.modals.nodeDelete.content")}</DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setNodeDeleteFormNodeId(null);
                setCacheFormNodeId(null);
              }}
            >
              {t("commons.confirm.cancel")}
            </Button>
            <Button
              onClick={() => {
                deleteNode(nodeDeleteFormNodeId);
                setNodeDeleteFormNodeId(null);
                setCacheFormNodeId(null);
              }}
            >
              {t("commons.confirm.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(NodesSettingsForm);
