import React, {createContext, PropsWithChildren, useContext, useState} from "react";
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import {ItemCategoryTemplateDto} from "../../model/item-containers-models/itemCategoryTemplateDto";
import {ItemRichTextDto} from "../../model/item-containers-models/itemRichTextDto";
import {ItemViewTemplateDto} from "../../model/item-containers-models/itemViewTemplateDto";
import {appConfigStateSelector} from "../../state/appConfig/appConfigSelectors";
import {toggleFullscreen} from "../../utils/other";
import {handleStyle} from "./utils";

const FullscreenContext = createContext({
  isFullscreen: false,
  handleFullscreen: () => {}
});

const FullscreenProvider = ({
  children,
  itemIdx,
  item
}: PropsWithChildren<{itemIdx: string; item: ItemCategoryTemplateDto | ItemRichTextDto | ItemViewTemplateDto}>) => {
  const [isFullscreen, setFullscreen] = useState(false);
  const appConfig = useSelector(appConfigStateSelector);
  const minItemContainerWidth = appConfig.minItemContainerWidth;
  const handleFullscreen = () => {
    toggleFullscreen(!isFullscreen);
    handleStyle(item.itemContainerId, itemIdx, item, minItemContainerWidth);
    setFullscreen(!isFullscreen);
  };
  const fullscreenContainer = "fullscreen-container";

  return (
    <FullscreenContext.Provider value={{isFullscreen, handleFullscreen}}>
      {isFullscreen ? createPortal(children, document.getElementById(fullscreenContainer)) : children}
    </FullscreenContext.Provider>
  );
};

export const useFullscreen = () => useContext(FullscreenContext);

export default FullscreenProvider;
