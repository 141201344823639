import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {FilterDto} from "../../../model/item-containers-models/filterDto";
import ConfirmationDialog from "../../confirmation-dialog";
import CustomMaterialTable from "../../custom-material-table";
import FilterConfigurationDialog from "./FilterConfigurationDialog";
import useApi from "../../../state/api/useApi";
import {languageSelector, languagesSelector} from "../../../state/app/appSelectors";
import {deleteFilter, fetchFilters} from "../../../state/dashboardFilters/dashboardFilterActions";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";

const FilterSettingsForm = () => {
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const {t} = useTranslation();
  const [filterToEdit, setFilterToEdit] = useState<number | null>(null);
  const [filterToDelete, setFilterToDelete] = useState<number | null>(null);

  const fetchFiltersApi = useApi<FilterDto[]>(fetchFilters(), {
    invokeImmediately: true
  });
  const deleteFilterApi = useApi(deleteFilter(), {
    onSuccess: _ => fetchFiltersApi.call()
  });

  useEffect(() => {
    return () => {
      fetchFiltersApi.clear();
      deleteFilterApi.clear();
    };
  }, []);

  return (
    <Fragment>
      <CustomMaterialTable
        rightActions={
          <Button size="small" startIcon={<AddIcon />} onClick={() => setFilterToEdit(-1)}>
            {t("components.filtersSettingsForm.filtersManager.add")}
          </Button>
        }
        columns={[
          {
            field: "title",
            title: t("components.filtersSettingsForm.filtersManager.columnTitle"),
            render: ({name}) => localizeI18nObj(name, defaultLanguage, languages),
            customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
          }
        ]}
        data={fetchFiltersApi.data || []}
        actions={[
          {
            icon: EditIcon,
            tooltip: t("components.filtersSettingsForm.filtersManager.edit"),
            onClick: (_, {id}) => {
              setFilterToEdit(id);
            }
          },
          {
            icon: DeleteIcon,
            tooltip: t("components.filtersSettingsForm.filtersManager.delete"),
            onClick: (_, {id}) => {
              setFilterToDelete(id);
            }
          }
        ]}
        options={{
          actionsColumnIndex: 1
        }}
      />

      <ConfirmationDialog
        open={filterToDelete !== null}
        onClose={() => setFilterToDelete(null)}
        onConfirm={() => {
          deleteFilterApi.request.url = `filters/${filterToDelete}`;
          deleteFilterApi.call({...deleteFilterApi.request});
          setFilterToDelete(null);
        }}
        title={t("components.filtersSettingsForm.filtersManager.deleteDialog.title")}
        content={t("components.filtersSettingsForm.filtersManager.deleteDialog.content")}
      />

      {!!filterToEdit && (
        <FilterConfigurationDialog
          filterToEdit={filterToEdit}
          onClose={() => setFilterToEdit(null)}
          open={!!filterToEdit}
        />
      )}
    </Fragment>
  );
};

export default FilterSettingsForm;
