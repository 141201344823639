import _ from "lodash";
import {REQUEST_SUCCESS} from "../request/requestActions";
import {CATALOG_FETCH} from "../../state/catalog/catalogActions";
import {
  DETAIL_LEVEL_SET,
  setDetailLevel,
  setDetailLevelFilteredCatalog
} from "../../state/detailLevel/detailLevelActions";
import {INIT} from "../../state/rootActions";
import {getFilteredCatalog} from "../../utils/catalog";

const detailLevelMiddleware =
  ({getState, dispatch}) =>
  next =>
  action => {
    const res = next(action);

    if (action.type === DETAIL_LEVEL_SET) {
      window.detailLevel = action.payload.detailLevel;

      let params = {};

      const searchStr = window.location.hash.split("?")[1];
      if (searchStr) {
        searchStr.split("&").forEach(str => {
          const keyVal = str.split("=");
          if (keyVal.length === 2) {
            params[keyVal[0]] = keyVal[1];
          }
        });
      }

      if (action.payload.detailLevel !== null) {
        params["t"] = encodeURIComponent(action.payload.detailLevel);
      } else if (params["t"]) {
        delete params["t"];
      }

      let newUrl = window.location.href.split("?")[0];
      if (Object.keys(params).length > 0) {
        newUrl +=
          "?" +
          Object.keys(params)
            .map(key => key + "=" + params[key])
            .join("&");
      }
      window.history.replaceState(null, document.title, newUrl);
    }

    if (action.type === INIT) {
      dispatch(setDetailLevel(action.payload.detailLevel));
    }

    if (
      action.type === DETAIL_LEVEL_SET ||
      (action.type === REQUEST_SUCCESS && action.payload.label === CATALOG_FETCH)
    ) {
      const {catalog} = getState();
      let detailLevel =
        action.payload.detailLevel !== undefined ? action.payload.detailLevel : getState().detailLevel.detailLevel;

      let datasets;
      if (catalog) {
        const catalogDetailLevels = catalog.detailLevels.map(({id}) => id);
        datasets = _.cloneDeep(catalog.datasetMap);
        if (detailLevel !== null) {
          if (catalogDetailLevels.includes(detailLevel)) {
            datasets = {};
            Object.keys(catalog.datasetMap)
              .filter(id => (catalog.datasetMap[id].detailsLevels || []).includes(detailLevel))
              .forEach(id => (datasets[id] = catalog.datasetMap[id]));
          } else {
            detailLevel = null;
            dispatch(setDetailLevel(detailLevel));
          }
        }
      }

      const filteredCatalog =
        catalog && detailLevel !== null
          ? getFilteredCatalog(catalog, (datasetId, dataset) => (dataset?.detailsLevels || []).includes(detailLevel))
          : catalog;

      dispatch(setDetailLevelFilteredCatalog(filteredCatalog));
    }

    return res;
  };

export default detailLevelMiddleware;
