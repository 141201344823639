import {useTranslation} from "react-i18next";

export const useItemContainerSettingsLabels = (asDashboard: boolean) => {
  const {t} = useTranslation();

  if (asDashboard) {
    return {
      scenes: {
        createElement: t("scenes.dashboardsSettings.createDashboard"),
        deleteElement: {
          title: t("scenes.dashboardsSettings.modals.deleteDashboard.title"),
          content: t("scenes.dashboardsSettings.modals.deleteDashboard.content")
        },
        table: {
          columns: {
            title: t("scenes.dashboardsSettings.table.columns.dashboardTitle")
          },
          deleteElement: t("scenes.dashboardsSettings.table.actions.deleteDashboard"),
          editElement: t("scenes.dashboardsSettings.table.actions.editDashboard"),
          viewElement: t("scenes.dashboardsSettings.table.actions.viewDashboard")
        }
      }
    };
  } else {
    return {
      scenes: {
        createElement: t("scenes.customPagesSettings.createCustomPage"),
        deleteElement: {
          title: t("scenes.customPagesSettings.modals.deleteCustomPage.title"),
          content: t("scenes.customPagesSettings.modals.deleteCustomPage.content")
        },
        table: {
          columns: {
            title: t("scenes.customPagesSettings.table.columns.customPageTitle")
          },
          deleteElement: t("scenes.customPagesSettings.table.actions.deleteCustomPage"),
          editElement: t("scenes.customPagesSettings.table.actions.editCustomPage"),
          viewElement: t("scenes.customPagesSettings.table.actions.viewCustomPage")
        }
      }
    };
  }
};
