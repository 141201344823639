import React from "react";
import {Box, Checkbox, FormControlLabel} from "@material-ui/core";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ColorPickerArea from "./ColorPickerArea";
import ColorsPickerCard from "./ColorsPickerCard";

const CustomColorsForm = () => {
  const {t} = useTranslation();
  const {setValue, watch} = useFormContext();

  return (
    <Box display="flex" flexDirection="column">
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            checked={watch("customColors.isEnabled")}
            onChange={(_, checked) => setValue("customColors.isEnabled", checked)}
          />
        }
        label={t("scenes.customColorsSettings.enable")}
      />
      {watch("customColors.isEnabled") && (
        <Box display="flex" flexDirection="column" marginTop="30px">
          <ColorPickerArea label={t("scenes.customColorsSettings.text")} valueName={["text", "primary"]} />
          <Box display="flex" marginTop="30px" flexDirection="row" gridGap={20}>
            <ColorsPickerCard title={t("scenes.customColorsSettings.primary")} isPrimary />
            <ColorsPickerCard title={t("scenes.customColorsSettings.secondary")} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomColorsForm;
