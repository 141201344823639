import React from "react";
import {TextField, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {sanitize} from "dompurify";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {languageSelector, languagesSelector} from "../../state/app/appSelectors";
import {localizeI18nObj} from "../../utils/i18n";
import {ITEMCONTAINER_ELEM_VALUE_KEY} from "../../utils/itemContainers";

const getStrippedHtmlText = html => {
  const $span = $("<span>").get(0);

  $span.innerHTML = sanitize(html, {ADD_ATTR: ["target"]});
  const text = $span.textContent || $span.innerText || "";

  $($span).remove();

  return text;
};

export default function RichTextItem({col, onTextEdit, classes}) {
  const defaultLanguage = useSelector(languageSelector);
  const languages = useSelector(languagesSelector);
  const {t} = useTranslation();
  return (
    <TextField
      value={getStrippedHtmlText(
        localizeI18nObj(
          col.data ? col.data : col[ITEMCONTAINER_ELEM_VALUE_KEY] ? col[ITEMCONTAINER_ELEM_VALUE_KEY] : "",
          defaultLanguage,
          languages
        ) || ""
      )}
      multiline
      minRows={7}
      variant="outlined"
      className={classes.multilineInput}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <Tooltip title={t("components.itemContainerBuilder.actions.editText")}>
            <EditIcon onClick={onTextEdit} />
          </Tooltip>
        )
      }}
    />
  );
}
