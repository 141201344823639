import React from "react";
import {InputLabel, MenuItem, Select, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {FilterDto} from "../../model/item-containers-models/filterDto";
import {ItemContainerDto} from "../../model/item-containers-models/itemContainerDto";
import I18nDelayedTextField from "../i18n-delayed-text-field";
import ItemContainerBuilderProvider from "./itemContainerBuiderProvider";
import {ItemContainerBuilderRow} from "./ItemContainerBuilderRow";
import {useItemContainerBuilder} from "./useItemContainerBuilder";
import useApi from "../../state/api/useApi";
import {fetchFilters} from "../../state/dashboardFilters/dashboardFilterActions";
import useLanguages from "../../state/hooks/useLanguages";
import {hubSelector} from "../../state/hub/hubSelectors";
import {changeOtherConfigItemContainer} from "../../state/otherConfig/otherConfigActions";
import {currentItemContainerSelector} from "../../state/otherConfig/otherConfigSelectors";

const ROW_ACTION_WIDTH = 48;

const styles = () => ({
  filterLevels: {
    fontSize: 16
  },
  rowContainer: {
    width: "100%"
  },
  row: {
    width: `calc(100% - ${ROW_ACTION_WIDTH}px)`,
    height: "100%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  rowActions: {
    width: "min-content",
    margin: "5px 0",
    display: "inline-block",
    verticalAlign: "middle"
  },
  rowAction: {
    width: ROW_ACTION_WIDTH
  },
  colContainer: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "16px 16px 0px 16px",
    height: "100%",
    display: "inline-block",
    verticalAlign: "middle"
  },
  col: {
    width: "100%",
    display: "inline-block"
  },
  divider: {
    margin: "8px 0"
  },
  colActions: {
    width: "100%",
    textAlign: "center" as const,
    paddingBottom: 8,
    "& button": {
      padding: 8
    }
  },
  viewContainer: {
    width: "100%"
  },
  input: {
    width: "100%",
    "& > div": {
      width: "100%"
    },
    "& input": {
      padding: 12,
      height: 20
    }
  },
  multilineInput: {
    width: "100%",
    "& > div": {
      width: "100%",
      padding: 12
    },
    "& textarea": {
      padding: 0
    },
    "& svg": {
      cursor: "pointer",
      marginLeft: 4
    }
  },
  formLabel: {
    marginLeft: 0,
    marginRight: 0
  }
});

function ItemContainerBuilder({classes, isTitleDuplicate}) {
  const itemContainer = useSelector(currentItemContainerSelector);
  const hub = useSelector(hubSelector);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onChange = itemContainer => dispatch(changeOtherConfigItemContainer(itemContainer));
  const {handleRowAdd, getGridContainerProps, getRowContainerProps, asDashboard} = useItemContainerBuilder();

  const hasNodeConfiguration = () => {
    return itemContainer?.configNodes?.length > 0;
  };

  const someNodesVisible = () => {
    return hub?.nodes?.some(n => n.visible === 1 && itemContainer?.configNodes?.some(i => i.nodeId === n.nodeId));
  };

  const isCustomPagePublic = () => {
    if (itemContainer?.configNodes?.some(i => i.hub === true) || someNodesVisible()) {
      return true;
    }
    return !itemContainer.isPrivate;
  };

  return (
    <ItemContainerBuilderProvider>
      {itemContainer && (
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs={12}>
            <FormControl fullWidth className={classes.field}>
              <I18nDelayedTextField
                label={t("components.itemContainerBuilder.title")}
                error={isTitleDuplicate()}
                helperText={isTitleDuplicate() ? "Duplicate title" : null}
                value={itemContainer.title}
                variant="outlined"
                required
                onChange={value => onChange({...itemContainer, title: value})}
              />
            </FormControl>
          </Grid>
          {!asDashboard && (
            <Grid container alignItems="center">
              <FormControlLabel
                label={t("components.itemContainerBuilder.checkbox.isPublic")}
                labelPlacement="end"
                control={
                  <Checkbox
                    disabled={hasNodeConfiguration()}
                    checked={isCustomPagePublic()}
                    onChange={(_, checked) => onChange({...itemContainer, isPrivate: !checked})}
                  />
                }
              />
              {hasNodeConfiguration() && (
                <Tooltip title={t("components.itemContainerBuilder.checkbox.tooltip")}>
                  <InfoOutlinedIcon />
                </Tooltip>
              )}
            </Grid>
          )}
          {asDashboard && <FilterContainer classes={classes} itemContainer={itemContainer}></FilterContainer>}
          <Grid item xs={12} style={{pointerEvents: "auto"}} {...getGridContainerProps()}>
            <Grid item style={{margin: "20px 0px"}}>
              {(itemContainer.item || []).map((row, idx) => (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    pointerEvents: "auto",
                    marginBottom: "8px",
                    marginTop: idx === 0 ? "8px" : "0px"
                  }}
                  {...getRowContainerProps(idx)}
                >
                  <ItemContainerBuilderRow classes={classes} row={row} rowIdx={idx} />
                </div>
              ))}
            </Grid>
          </Grid>

          <Grid item className={classes.row}>
            <Grid container justifyContent="center">
              <Button endIcon={<AddIcon />} onClick={handleRowAdd}>
                {t("components.itemContainerBuilder.actions.addRow")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ItemContainerBuilderProvider>
  );
}

const FilterContainer = ({classes, itemContainer}: {classes: any; itemContainer: ItemContainerDto}) => {
  const {localizeI18nObj, t} = useLanguages();
  const {handleFilterChange, handleFilterLevelsChange} = useItemContainerBuilder();

  const {data} = useApi<FilterDto[]>(fetchFilters(), {
    invokeImmediately: true
  });

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item>
          <Grid container xs={12} alignItems="center" style={{marginTop: 10}}>
            <Grid item>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="filter-label">{t("components.filterContainer.title")}</InputLabel>
                <Select
                  labelId="filter-label"
                  label={t("components.filterContainer.title")}
                  style={{minWidth: 125}}
                  onChange={({target}) => handleFilterChange(data?.find(({id}) => id === target.value))}
                  value={itemContainer?.filter?.id | 0}
                >
                  <MenuItem value="0">
                    <em>{t("components.map.layers.none")}</em>
                  </MenuItem>
                  {data &&
                    data.map(({id, name}) => (
                      <MenuItem key={id} value={id}>
                        {localizeI18nObj(name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {itemContainer?.filter &&
              itemContainer.filter.levels.map(({level, label}, index) => (
                <Grid key={index} item style={{marginRight: 8}}>
                  <FormControlLabel
                    value={level}
                    className={classes.formLabel}
                    label={localizeI18nObj(label)}
                    control={
                      <Checkbox
                        checked={itemContainer.filterLevelsSelected.includes(level)}
                        style={{padding: 5}}
                        onChange={({target}, checked) => handleFilterLevelsChange(checked, Number(target.value))}
                      />
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ItemContainerBuilder);
