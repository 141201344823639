import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {NodeDataAccessibility, NodeVisibility} from "../../../../model/IHubMinimalNode.d.ts";
import InfiniteScrollTable from "../../../infinite-scroll-table/index.jsx";
import {
  clearNodePermissionsConfig,
  fetchNodePermissionsConfig,
  sendNodePermissionsConfig
} from "../../../../state/nodePermissionsConfig/nodePermissionsConfigActions";

const styles = theme => ({
  table: {
    "& .infinite-scroll-table__header__cell": {
      color: theme.palette.text.primary
    },
    "& .infinite-scroll-table__grid__cell": {
      color: theme.palette.text.primary
    }
  }
});

const getPermissions = (visible, dataAccess, type, t) => {
  const permissions = [];

  if (visible === NodeVisibility.Profiled && dataAccess === NodeDataAccessibility.Profiled) {
    permissions.push({
      id: "ViewNode",
      label: t ? t("scenes.nodeSettings.permissions.table.columns.viewNode") : "ViewNode"
    });
  }

  if (type === "SDMX-REST") {
    permissions.push({
      id: "ManageCache",
      label: t ? t("scenes.nodeSettings.permissions.table.columns.manageCache") : "ManageCache"
    });
  }

  permissions.push(
    {
      id: "ManageTemplate",
      label: t ? t("scenes.nodeSettings.permissions.table.columns.manageTemplate") : "ManageTemplate"
    },
    {
      id: "ManageConfig",
      label: t ? t("scenes.nodeSettings.permissions.table.columns.manageConfig") : "ManageConfig"
    }
  );

  return permissions;
};

const Form = compose(
  withStyles(styles),
  forwardRef
)(({classes, config, modulesPermissions, nodeId, node, onSubmit, onCancel}, ref) => {
  const {t} = useTranslation();

  const {register, handleSubmit, watch, setValue} = useForm({
    defaultValues: {
      config: config?.map((user, index) => {
        let res = {...user};
        getPermissions(node.visible, node.dataAccess, node.type, t)
          .concat(modulesPermissions)
          .forEach(({id}) => {
            res[id] = config[index].permission.includes(`${id}_SingleNode_${nodeId}`) === true;
          });
        return res;
      })
    }
  });

  useEffect(() => {
    if (config) {
      config.forEach((_, index) =>
        getPermissions(node.visible, node.dataAccess, node.type, t)
          .concat(modulesPermissions)
          .forEach(({id}) => register(`config[${index}].${id}`))
      );
    }
  }, [register, config, modulesPermissions, node, t]);

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        const data = val.config.map((userVal, index) => {
          let res = {
            userId: config[index].userId,
            nodePermissions: []
          };
          getPermissions(node.visible, node.dataAccess, node.type, t)
            .concat(modulesPermissions)
            .map(({id}) => id)
            .forEach(p => {
              if (userVal[p] === true) {
                res.nodePermissions.push(p);
              }
            });
          res.tableData = undefined;
          return res;
        });

        onSubmit(nodeId, data);
        f(data);
      })();
    },
    cancel(f) {
      onCancel();
      f();
    }
  }));

  const [parsedConfig, setParsedConfig] = useState([]);
  useEffect(() => {
    setParsedConfig(nodeId === null ? undefined : config.map((row, idx) => ({...row, idx: idx})) || []);
  }, [nodeId, config]);

  const handleCheckBoxChange = (valueKey, value) => {
    setValue(valueKey, value);
    setParsedConfig(nodeId === null ? undefined : config.map((row, idx) => ({...row, idx: idx})) || []);
  };

  return parsedConfig && node ? (
    <span className={classes.table}>
      <InfiniteScrollTable
        data={parsedConfig}
        getRowKey={({userId}) => userId}
        rowHeight={52}
        columns={[
          {
            dataIndex: "firstName",
            title: t("scenes.nodeSettings.permissions.table.columns.userFirstName"),
            noFilter: true,
            noSort: false
          },
          {
            dataIndex: "lastName",
            title: t("scenes.nodeSettings.permissions.table.columns.userLastName"),
            noFilter: true,
            noSort: false
          },
          {
            dataIndex: "organization",
            title: t("scenes.nodeSettings.permissions.table.columns.userOrganization"),
            noFilter: true,
            noSort: false
          },
          {
            dataIndex: "email",
            title: t("scenes.nodeSettings.permissions.table.columns.userEmail"),
            noFilter: true,
            noSort: false
          },
          ...getPermissions(node.visible, node.dataAccess, node.type, t)
            .concat(modulesPermissions)
            .map(({id, label}) => ({
              title: label,
              render: (_, rowData) => (
                <Checkbox
                  style={{padding: 4}}
                  checked={watch(`config[${rowData.idx}].${id}`)}
                  onChange={(_, value) => handleCheckBoxChange(`config[${rowData.idx}].${id}`, value)}
                />
              ),
              width: 150,
              noFilter: true,
              noSort: true,
              renderText: () => ""
            }))
        ]}
      />
    </span>
  ) : null;
});

const mapStateToProps = state => ({
  config: state.nodePermissionsConfig.permissions,
  modulesNodePermissions: state.app.modulesConfig.nodePermissions,
  nodes: state.nodesConfig.nodes
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: nodeId => dispatch(fetchNodePermissionsConfig(nodeId)),
  sendConfig: (nodeId, config) => dispatch(sendNodePermissionsConfig(nodeId, config)),
  clearConfig: () => dispatch(clearNodePermissionsConfig())
});

const PermissionsSettingsForm = (
  {config, modulesNodePermissions, nodes, nodeId, fetchConfig, sendConfig, clearConfig},
  ref
) => {
  const [needConfig, setNeedConfig] = useState(nodeId !== null);

  useEffect(() => {
    if (needConfig) {
      setNeedConfig(false);
      fetchConfig(nodeId);
    }
  }, [config, needConfig, setNeedConfig, fetchConfig, nodeId]);

  return (
    (nodeId === null || config) && (
      <Form
        nodeId={nodeId}
        node={nodes.find(node => node.nodeId === nodeId)}
        config={nodeId === null ? undefined : config}
        modulesPermissions={modulesNodePermissions}
        ref={ref}
        onSubmit={sendConfig}
        onCancel={clearConfig}
      />
    )
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}),
  forwardRef
)(PermissionsSettingsForm);
