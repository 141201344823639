import {createSelector} from "@reduxjs/toolkit";
import rootSelector from "../rootSelector";

export const hubSelector = createSelector(rootSelector, v => v?.hub);
export const nodesSelector = createSelector(hubSelector, v => v?.nodes);
export const hubItemContainersSelector = createSelector(hubSelector, v => v?.hub?.itemContainers);
export const hubCustomPagesSelector = createSelector(
  hubItemContainersSelector,
  itemContainers => itemContainers?.customPages || []
);
export const hubDashboardsSelector = createSelector(
  hubItemContainersSelector,
  itemContainers => itemContainers?.dashboard || []
);
export const hubCustomHomeIdPageSelector = createSelector(
  hubCustomPagesSelector,
  hubCustomPages =>
    hubCustomPages
      ?.map(v => v.configNodes)
      .reduce((acc, cur) => acc.concat(cur), [])
      .find(v => v.hub && v.isHomePage)?.itemContainerId
);
